import {createAction, createSlice} from '@reduxjs/toolkit';

export const AprrovedReturnItemsState = {
  isApproveLoading: false,
  approveStates: [],
  approveDivisions: [],
  approveHeadQuarters: [],
  selectedApproveState: '',
  selectedApproveDivision: '',
  selectedApproveHeadQuarter: '',
  autoSuggestionData: [],
  ApproveReturnData: [],
  arReqApiCalled: false,
  filteredData: [],
  ReqPayloadForApproveReturnData: {},
  selectedApproveReturnItems: [],
  approvedItems: [],
  rejectItems: [],
};
export const fetchInventoryApproveCreator = createAction('APPROVE_RETURN');
export const fetchApproveStatesAndDivisionsCreator = createAction(
  'APPROVE_STATES_DIVISIONS',
);
export const approveSearchCreator = createAction<any>('APPROVE_SEARCH_AUTO');
export const approveFilteredDataCreator = createAction<any>(
  'FILTERED_DATA_APPROVE',
);
export const fetchApproveReturnItemsFilterCreator = createAction<any>(
  'APPROVE_RETURN_ITEMS_FILTER',
);
export const ARIApproveCreator = createAction<any>('ARI_APPROVED_ITEMS');
export const ARIrejectItemsCreator = createAction<any>('ARI_REJECTS_ITEMS');
export const fetchApproveHeadquartersCreator = createAction<any>(
  'APPROVE_HEAD_QUARTERS',
);
export const fetchInventoryApproveName = fetchInventoryApproveCreator().type;
export const fetchApproveStatesAndDivisionsName =
  fetchApproveStatesAndDivisionsCreator().type;
export const fetchApproveHeadquartersName = fetchApproveHeadquartersCreator(
  {},
).type;
export const fetchautoSuggestions = approveSearchCreator({}).type;
export const fetchFilteredDataApprove = approveFilteredDataCreator({}).type;
export const fetchApproveReturnItemsFilterName =
  fetchApproveReturnItemsFilterCreator({}).type;
export const fetchApprovedItems = ARIApproveCreator({}).type;
export const fetchRejectItems = ARIrejectItemsCreator({}).type;

export const approvedReturnItemHandler = createSlice({
  name: 'APPROVED_RETURN_ITEMS',
  initialState: AprrovedReturnItemsState,
  reducers: {
    setInventoryAdminApproveSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        ApproveReturnData: payload,
        error: '',
      };
    },
    getArReqApiCalled: (prevState, {payload}) => {
      return {
        ...prevState,
        arReqApiCalled: payload,
      };
    },
    setInventoryAdminApprovePayload: (prevState, {payload}) => {
      return {
        ...prevState,
        ReqPayloadForApproveReturnData: payload,
      };
    },
    setInventoryAdminApproveLoading: prevState => {
      return {
        ...prevState,
        isApproveLoading: false,
        ApproveReturnData: [],
      };
    },
    setInventoryAdminApproveError: prevState => {
      return {
        ...prevState,
        isApproveLoading: false,
        ApproveReturnData: [],
        error: '',
      };
    },
    setInventoryAdminisApproveLoading: prevState => {
      return {
        ...prevState,
        isApproveLoading: false,
      };
    },
    setStatesData: (prevState, {payload}) => {
      return {
        ...prevState,
        statesLoading: false,
        approveStates: payload,
      };
    },
    setDivisions: (prevState, {payload}) => {
      return {
        ...prevState,
        divisionLoading: false,
        approveDivisions: payload,
      };
    },
    setHeadQuarters: (prevState, {payload}) => {
      return {
        ...prevState,
        HQLoading: false,
        approveHeadQuarters: payload,
      };
    },
    setSelectedApproveState: (prevState, {payload}) => {
      return {
        ...prevState,
        selectedApproveState: payload,
      };
    },
    setSelectedApproveDivison: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        selectedApproveDivision: payload,
      };
    },
    setSelectedApproveHeadquarter: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        selectedApproveHeadQuarter: payload,
      };
    },
    setApproveAutoSuggestionDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        autoSuggestionData: payload,
      };
    },
    setApproveFilteredData: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        filteredData: payload,
      };
    },
    setSelectedApproveReturnItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        selectedApproveReturnItems: payload,
      };
    },
    setApprovedItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        approvedItems: payload,
      };
    },
    setrejectItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isApproveLoading: false,
        rejectItems: payload,
      };
    },
  },
});

export const approvedReturnItemActions = approvedReturnItemHandler.actions;

export const approvedReturnItemReducers = approvedReturnItemHandler.reducer;
