import { POSITION_TYPE } from '@app/common/constants';
import {positionManagementMock} from '@app/mocks';
import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IPositionCloneItem {
    id: number;
    fromStaffPositionId: number;
    toStaffPositionId: number;
    statusId: number;
    createdOn: string;
    reason:string;
}

export interface IPositionTransferItem {
    id: number;
    fromStaffPositionId: number;
    toStaffPositionId: number;
    fromEmpId: string,
    fromEmpName: string,
    toEmpId: string,
    toEmpName:string,
    statusId: number;
    createdOn: string;
    reason:string;
    transferRequestType: string;
}

export interface PartyFilterOptions {
    specialities?: Array<any>;
    pools: Array<any>;
    stations: Array<any>;
    territories: Array<any>;
 }

export const positionManagementInitialState = {
    partyDetails: {
        from: {
            searchText: '',
            details: {}
        },
        to: {
            searchText: '',
            details: {}
        }
    },
    showTransferPartiesModal: false,
    showTransferAreasModal: false,
    areaTransferType: '',
    partiesList: [],
    areasList: [],
    partiesSelectedForTransfer: [],
    areasSelectedForTransfer: [],
    cloningTimePeriod: [],
    positionType: POSITION_TYPE.transfer,
    transferPositionResponse: null,
    positionCloneListGridOptions: {},
    positionCloneListData: [],
    positionCloneListRows: 0,
    clonePositionResponse:null,
    allSelected: false,
    partiesFetched: false,
    areasFetched: false,
    cloningListResponse:false,
    positionTransferListData:[],
    positionTransferListRows: 0,
    positionTransferListGridOptions: {},
    transferListResponse: false,
    validateTransferResponse: null,
    partyFilterOptions: {
        specialities: [],
        pools: [],
        stations: [],
        territories: [],
    },
    effectiveDate: null,
    areaAllocationResponse: null
}


export const fetchPartiesBySpidCreator = createAction < any > (
    'POSITION_MANAGEMENT_HANDLER/PARTIES_BY_SPID',
  );
export const fetchPartiesBySpidCreatorTypeName = fetchPartiesBySpidCreator(null).type;

export const fetchAreasBySpidCreator = createAction < any > (
    'POSITION_MANAGEMENT_HANDLER/AREAS_BY_SPID',
  );
export const fetchAreasBySpidCreatorTypeName = fetchAreasBySpidCreator(null).type;

export const fetchPartyDetailBySpidCreator = createAction < any > (
    'POSITION_MANAGEMENT_HANDLER/PARTY_BY_SPID',
  );
export const fetchPartyDetailBySpidCreatorTypeName = fetchPartyDetailBySpidCreator(null).type;


export const fetchCloningTimePeriodCreator = createAction(
    'POSITION_MANAGEMENT_HANDLER/CLONINING_TIME_PERIOD',
);
export const fetchCloningTimePeriodCreatorTypeName = fetchCloningTimePeriodCreator().type;

export const transferPositionUpdateCreator = createAction(
    'POSITION_MANAGEMENT_HANDLER/TRANSFER_PARTY_UPDATE',
);
export const transferPositionUpdateCreatorTypeName = transferPositionUpdateCreator().type;

export const fetchPositionCloneListCreator = createAction(
    'POSITION_MANAGEMENT_HANDLER/FETCH_POSITION_CLONE_LIST',
);
export const fetchPositionCloneListCreatorTypeName = fetchPositionCloneListCreator().type;


export const clonePositionUpdateCreator = createAction(
    'POSITION_MANAGEMENT_HANDLER/CLONE_PARTY_UPDATE',
);
export const clonePositionUpdateCreatorTypeName = clonePositionUpdateCreator().type;


export const fetchPositionTransferListCreator = createAction(
    'POSITION_MANAGEMENT_HANDLER/FETCH_POSITION_TRANSFER_LIST',
);
export const fetchPositionTransferListCreatorTypeName = fetchPositionTransferListCreator().type;

export const validatePartyTransferCreator = createAction(
    'POSITION_MANAGEMENT_HANDLER/VALIDATE_TRANSFER_PARTY',
);
export const validatePartyTransferCreatorTypeName = validatePartyTransferCreator().type;


export const areaAllocationCreator = createAction<any>(
    'POSITION_MANAGEMENT_HANDLER/AREA_ALLOCATION',
);
export const areaAllocationCreatorTypeName = areaAllocationCreator(null).type;

const positionManagementStateHandler = createSlice({
    name: 'PLAN_AND_MEET_STATUS_HANDLER',
    initialState: positionManagementInitialState,
    reducers: {
        setShowTransferPartiesModal: (prevState, action) => {
            return {
                ...prevState,
                showTransferPartiesModal: action.payload,
            };
        },
        setShowTransferAreasModal: (prevState, action) => {
            return {
                ...prevState,
                showTransferAreasModal: action.payload,
            };
        },
        setAreaTransferType: (prevState, action) => {
            return {
                ...prevState,
                areaTransferType: action.payload,
            };
        },
        setPartiesList: (prevState, action) => {
            return {
                ...prevState,
                partiesList: action.payload,
            };
        },
        setAreasList: (prevState, action) => {
            return {
                ...prevState,
                areasList: action.payload,
            };
        },
        setPartiesDetails: (prevState, action) => {
            return {
                ...prevState,
                partyDetails: action.payload
            }
        },
        setPartiesSelectedForTransfer: (prevState, action) => {
            return {
                ...prevState,
                partiesSelectedForTransfer: action.payload
            }
        },
        setAreasSelectedForTransfer: (prevState, action) => {
            return {
                ...prevState,
                areasSelectedForTransfer: action.payload
            }
        },
        setCloningTimePeriod: (prevState, action) => {
            return {
                ...prevState,
                cloningTimePeriod: action.payload
            }
        },
        setPositionType: (prevState, action) => {
            return {
                ...prevState,
                positionType: action.payload
            }
        },
        setTransferPositionResponse: (prevState, action) => {
            return {
                ...prevState,
                transferPositionResponse: action.payload
            }
        },
        resetPositionManagementState: (prevState,action) => {
            return {
                ...prevState,
                partyDetails: {
                    from: {
                        searchText: '',
                        details: {}
                    },
                    to: {
                        searchText: '',
                        details: {}
                    }
                },
                partiesList: [],
                partiesSelectedForTransfer: [],
                transferPositionResponse: null,
                clonePositionResponse: null,
                positionType: POSITION_TYPE.transfer,
                areasSelectedForTransfer: [],
                areaTransferType: '',
                areasList: []
            }
        },
        setPositionCloneListGridOptions: (prevState, action) => {
            return {
                ...prevState,
                positionCloneListGridOptions: action.payload,
            };
        },
        setPositionCloneListData: (prevState, action) => {
            return {
                ...prevState,
                positionCloneListData: action.payload,
            };
        },
        setPositionCloneListRows: (prevState, action) => {
            return {
                ...prevState,
                positionCloneListRows: action.payload,
            };
        },
        setClonePositionRespone: (prevState,action) => {
            return {
                ...prevState,
                clonePositionResponse: action.payload
            }
        },
        setAllSelected: (prevState,action) => {
            return {
                ...prevState,
                allSelected: action.payload
            }
        },
        setPartiesFetched: (prevState,action) => {
            return {
                ...prevState,
                partiesFetched: action.payload
            }
        },
        setAreasFetched: (prevState,action) => {
            return {
                ...prevState,
                areasFetched: action.payload
            }
        },
        setCloningListResponseUpdated: (prevState,action) => {
            return {
                ...prevState,
                cloningListResponse: action.payload
            }
        },
        setPositionTransferListData: (prevState, action) => {
            return {
                ...prevState,
                positionTransferListData: action.payload,
            };
        },
        setPositionTransferListRows: (prevState, action) => {
            return {
                ...prevState,
                positionTransferListRows: action.payload,
            };
        },
        setPositionTransferListGridOptions: (prevState, action) => {
            return {
                ...prevState,
                positionTransferListGridOptions: action.payload,
            };
        },
        setTransferListResponseUpdated: (prevState,action) => {
            return {
                ...prevState,
                transferListResponse: action.payload
            }
        },
        setValidateTransferResponse: (prevState, action) => {
            return {
                ...prevState,
                validateTransferResponse: action.payload
            }
        },
        setPartiesFilterOptions: (prevState, action) => {
            return {
                ...prevState,
                partyFilterOptions: action.payload,
            };
        },
        setEffectiveDate: (prevState, action) => {
            return {
                ...prevState,
                effectiveDate: action.payload,
            };
        },
        setAreaAllocationResponse: (prevState, action) => {
            return {
                ...prevState,
                areaAllocationResponse: action.payload
            }
        },
    }
})

export const positionManagementStateActions =
    positionManagementStateHandler.actions;
export const positionManagementStateReducer =
    positionManagementStateHandler.reducer;
