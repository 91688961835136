import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@mui/styles';
import {routeSelector} from '@app/router/redux';
import {billLibraryStateSelector} from '@app/screens/module-specific-utilities/pages/bill-library/redux/selectors';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import {billLibraryStateActions} from '@app/screens/module-specific-utilities/pages/bill-library/redux';
import {useCallback, useEffect} from 'react';
import {appSelector} from '@app/store/selectors';
import Colors from '@app/themes/colors';
import {Button, CircularProgress} from '@mui/material';
import theme from '@app/themes';
import {strings} from '@app/common/strings';
import {fetchExportFileCreator} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '5px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 12.7,
    minWidth: '0px',
  },
});
export const CommonNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const isExcelDownloading = useSelector(
    expenseDAMasterStateSelector.getIsExcelDownloading(),
  );
  const {isWriteAccess = false} = useSelector(appSelector.getUserInfo());
  const tabViewv = useSelector(billLibraryStateSelector.getGridVeiw());
  const downloadExcel = () => {
    dispatch(fetchExportFileCreator('statusreport'));
  };
  useEffect(() => {
    return () => {
      dispatch(
        filterStateActions.setFilterOptions({
          isActive: true,
          searchText: '',
          pageNumber: 0,
          pageLimit: 100,
          divisionIds: [],
          designationIds: [],
          hqIds: [],
        }),
      );
    };
  }, []);
  const GridVeiw = useCallback(
    () => dispatch(billLibraryStateActions.setGridVeiw(false)),
    [],
  );

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontWeight={theme.sizing.weight800}
          fontFamily={theme.fonts.poppins}
          fontStyle={theme.sizing.fontSize}
          display={'flex'}>
          {tabViewv ? (
            <ArrowCircleLeftOutlinedIcon
              style={{margin: '3px', fontSize: '24px'}}
              onClick={GridVeiw}
            />
          ) : (
            ''
          )}
          {navbarComponentHeading}
        </Typography>
        {navbarComponentHeading?.props?.children === 'Generate Report' && (
          <Button
            variant="text"
            className={classes.button}
            startIcon={
              <img
                alt="excel"
                width={20}
                src={require('../../../assets/images/MSExcel.png')}
              />
            }
            disabled={isExcelDownloading}
            sx={{
              ...(!isWriteAccess && {color: 'grey'}),
            }}
            onClick={downloadExcel}
            style={{color: Colors.primary, textDecoration: 'none'}}>
            {isExcelDownloading ? (
              <CircularProgress size={20} />
            ) : (
              strings.DownloadExcel
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
