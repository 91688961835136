import {leaveSelector} from '@app/screens/leave-management/redux';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
};
export const LeaveNavBar = () => {
  const title = useSelector(leaveSelector.getNavTitle());

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
