import { put, takeLatest } from "redux-saga/effects";
import {
  directoryActions,
  directoryLoadingHandlerCreatorTypeName,
  directoryNavActionHandlerCreatorTypeName,
  directoryNavSearchQueryCreatorTypeName,
  directoryNavToggleCreatorTypeName,
  directoryUpdateCurrentPageCreatorTypeName,
  updateDirectoryNavDataCreatorTypeName,
} from "./slice";

/**
 * Update Admin Nav watcher
 */
export function* updateDirectoryNavWatcher() {
  yield takeLatest(
    updateDirectoryNavDataCreatorTypeName,
    updateDirectoryNavWorker
  );
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateDirectoryNavWorker(action: any) {
  yield put(directoryActions.updateNavData(action.payload));
}

/**
 * Update Admin Nav watcher
 */
export function* directoryNavActionHandlerWatcher() {
  yield takeLatest(
    directoryNavActionHandlerCreatorTypeName,
    directoryNavActionHandlerWorker
  );
}

function* directoryNavActionHandlerWorker(action: any) {
  yield put(directoryActions.updateModalHandler(action.payload));
}

/**
 * Update Admin Nav watcher
 */
export function* directoryLoadingHandlerWatcher() {
  yield takeLatest(
    directoryLoadingHandlerCreatorTypeName,
    directoryLoadingHandlerWorker
  );
}

function* directoryLoadingHandlerWorker(action: any) {
  yield put(directoryActions.updateLoading(action.payload));
}

export function* directoryNavSearchQueryWatcher(action: any) {
  yield takeLatest(
    directoryNavSearchQueryCreatorTypeName,
    directoryNavSearchQueryWorker,
  );
}

function* directoryNavSearchQueryWorker(action: any) {
  yield put(directoryActions.updateNavSearchQuery(action.payload));
}

export function* directoryNavToggleWatcher(action: any) {
  yield takeLatest(
    directoryNavToggleCreatorTypeName,
    directoryNavToggleWorker,
  );
}

function* directoryNavToggleWorker(action: any) {
  yield put(directoryActions.updateNavToggle(action.payload));
}

export function* directoryUpdateCurrentPageWatcher(action: any) {
  yield takeLatest(
    directoryUpdateCurrentPageCreatorTypeName,
    directoryUpdateCurrentPageWorker,
  );
}

function* directoryUpdateCurrentPageWorker(action: any) {
  yield put(directoryActions.updateCurrentPage(action.payload));
}
