export const navbarCss={
    containercss: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }  as React.CSSProperties,
    bottomNavigationListItemIcon: { minWidth: 0, marginRight: 10 },

    flexSpaceBetween: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    } as React.CSSProperties,

    margintopcss: {
      height: 80,
      paddingTop: "5%",
      cursor: "pointer",
      dispaly: "flex",
      fontSize: 12.7,
    },
    childheading: {
      marginRight: "15%",
      fontSize: "12.7 px",
      padding: "10px 0px",
      fontWeight:"800"
    },
    button: {
      backgroundColor: "#322b7c",
      borderRadius: "5px",
      width: "150px",
      color: "#fff",
      fontFamily: "Poppins",
      fontStyle: "normal",
      textAlign: "center",
      fontWeight: "normal",
      fontSize: 12,
    } as React.CSSProperties,
}