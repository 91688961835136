import {createSelector} from '@reduxjs/toolkit';

const getInventoryComplianceState = (state: any) =>
  state.inventoryComplianceMaster;

const getInventoryComplianceStateSelector = createSelector(
  [getInventoryComplianceState],
  data => data,
);

const getInventoryMasterDataSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.inventoryMasterData,
);

const getInventoryListSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.inventoryComplianceList,
);

const getGridOptionSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.gridOptions,
);

const getTotalRowSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.totalRows,
);

const getSearchTextSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.searchText,
);

const getAppliedFilterSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.filters,
);

const getEditableDataSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.editableData,
);

const getSkuStatusSelector = createSelector(
  [getInventoryComplianceState],
  data =>
    data?.filters?.skuStatus === true || data?.filters?.skuStatus === 'true'
      ? true
      : false,
);
const getDeactivateInventoryResponseSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.deactivateInventoryResponse,
);

const getSelectedFYSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.selectedFY,
);

const getSaveInventoryResponseSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.saveInventoryResponse,
);
const getDownloadExcelErrorSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.downloadExcelError,
);
const getBulkUploadInventoryResponseSelector = createSelector(
  [getInventoryComplianceState],
  data => data?.bulkUploadInventoryResponse,
);

export const inventoryComplianceSelector = {
  getInventoryComplianceState: () => getInventoryComplianceStateSelector,
  getInventoryList: () => getInventoryListSelector,
  getGridOptions: () => getGridOptionSelector,
  getTotalRows: () => getTotalRowSelector,
  getAppliedFilter: () => getAppliedFilterSelector,
  getEditableData: () => getEditableDataSelector,
  getSkuStatus: () => getSkuStatusSelector,
  getSearchText: () => getSearchTextSelector,
  getSelectedFY: () => getSelectedFYSelector,
  getDeactivateInventoryResponse: () => getDeactivateInventoryResponseSelector,
  getInventoryMasterData: () => getInventoryMasterDataSelector,
  getSaveInventoryResponse: () => getSaveInventoryResponseSelector,
  getDownloadExcelError: () => getDownloadExcelErrorSelector,
  getBulkUploadInventoryResponse: () => getBulkUploadInventoryResponseSelector,
};
