import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IDCRUnlockRequestInitialState {
  dcrUnlockRequestList: IDCRUnlockRequest[];
  loader: boolean;
  isApprovedSelected: boolean;
  searchText: string;
  gridOptions: any;
  totalRows: number;
  dcrUnlockReasons: Array<IDCRUnlockReason>;
  approveDCRResponse: any;
  modifyDCRResponse: any;
  filters: {
    approvalStatus: any;
    zones: any;
    regions: any;
    headQuarters: any;
    divisions: any;
  };
  totalDcrUnfilledRequest:any;
  downloadExcelResponse:any;
  downloadExcelError:any;
  allRequestIds: Array<number>;
}

export interface IDCRUnlockRequest {
  employeeId: string;
  employeeName: string;
  unlockDate: string;
  raisedOn: string;
  unlockType: string;
  unlockReason: string;
  status: string;
  statusId: number;
  staffPositionId: number;
  requestId: number;
  approvedDate: string | null;
  approverId: number;
  dcrModifiedReason: Array<number>;
  id?: string;
}

export interface IDCRUnlockReason {
  createdBy: string;
  createdOn: string;
  id: number;
  isFineApplicable: boolean;
  reason: string;
}

export const dcrUnlockRequestInitialState: IDCRUnlockRequestInitialState = {
  dcrUnlockRequestList: [],
  loader: false,
  isApprovedSelected: false,
  searchText: '',
  gridOptions: {},
  totalRows: 0,
  dcrUnlockReasons: [],
  approveDCRResponse: null,
  modifyDCRResponse: null,
  filters: {
    approvalStatus: null,
    zones: null,
    regions: null,
    headQuarters: null,
    divisions: null,
  },
  totalDcrUnfilledRequest:0,
  downloadExcelResponse: null,
  downloadExcelError: null,
  allRequestIds: [],
};

export const fetchDcrUnlockRequestListCreator = createAction(
  'DCR_UNLOCK_REQUEST_HANDLER/FETCH_DCR_UNLOCK_REQUEST_LIST',
);
export const fetchDcrUnlockRequestListCreatorTypeName =
  fetchDcrUnlockRequestListCreator().type;

export const fetchDCRUnlockReasonsCreator = createAction(
  'DCR_UNLOCK_REQUEST_HANDLER/FETCH_DCR_UNLOCK_REASONS',
);
export const fetchDCRUnlockReasonsCreatorTypeName =
  fetchDCRUnlockReasonsCreator().type;

export const approveDCRUnlockRequestCreator = createAction<any>(
  'DCR_UNLOCK_REQUEST_HANDLER/APPROVE_DCR_REQUEST',
);
export const approveDCRUnlockRequestCreatorTypeName =
  approveDCRUnlockRequestCreator(null).type;

export const modifyDCRRequestCreator = createAction<any>(
  'DCR_UNLOCK_REQUEST_HANDLER/MODIFY_DCR_REQUEST',
);
export const modifyDCRRequestCreatorTypeName =
  modifyDCRRequestCreator(null).type;

export const fetchDCRHQsCreator = createAction<any>(
  'DCR_UNLOCK_REQUEST_HANDLER/FETCH_DCR_HQS',
);
export const fetchDCRHQsCreatorTypeName = fetchDCRHQsCreator(null).type;

export const dcrDownloadExcelCreator = createAction<any>(
  'DCR_UNLOCK_REQUEST_HANDLER/DCR_DOWNLOAD_EXCEL',
);
export const dcrDownloadExcelCreatorTypeName = dcrDownloadExcelCreator(null).type;


export const dcrBulkUnlockCreator = createAction<any>(
  'DCR_UNLOCK_REQUEST_HANDLER/DCR_BULK_UNLOCK',
);
export const dcrBulkUnlockCreatorTypeName = dcrBulkUnlockCreator({}).type;

const dcrUnlockRequestStateHandler = createSlice({
  name: 'DCR_UNLOCK_REQUEST_HANDLER',
  initialState: dcrUnlockRequestInitialState,
  reducers: {
    setDcrUnlockRequestList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        dcrUnlockRequestList: payload,
      };
    },
    setDcrUnlockRequestLoader: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        loader: payload,
      };
    },
    setDcrUnlockRequestSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action.payload,
      };
    },
    setDcrUnlockRequestApprovedSelected: (prevState, action) => {
      return {
        ...prevState,
        isApprovedSelected: action.payload,
      };
    },
    setDcrUnlockRequestGridOptions: (prevState, action) => {
      return {
        ...prevState,
        gridOptions: action.payload,
      };
    },
    setTotalRowsInGrid: (prevState, action) => {
      return {
        ...prevState,
        totalRows: action.payload,
      };
    },
    resetDcrUnlockRequestState: () => {
      return {
        ...dcrUnlockRequestInitialState,
      };
    },
    setDCRUnlockReasons: (prevState, action) => {
      return {
        ...prevState,
        dcrUnlockReasons: action.payload,
      };
    },
    setApproveDCRResponse: (prevState, action) => {
      return {
        ...prevState,
        approveDCRResponse: action.payload,
      };
    },
    setModifyDCRResponse: (prevState, action) => {
      return {
        ...prevState,
        modifyDCRResponse: action.payload,
      };
    },
    setFilters: (prevState, action) => {
      return {
        ...prevState,
        filters: action.payload,
      };
    },
    setTotalDCRUnfilledRequest: (prevState, action) => {
      return {
        ...prevState,
        totalDcrUnfilledRequest: action.payload
      }
    },
    setDownloadExcelResponse: (prevState, action) => {
      return {
        ...prevState,
        downloadExcelResponse: action.payload
      }
    },
    setDownloadExcelError: (prevState, action) => {
      return {
        ...prevState,
        downloadExcelError: action.payload
      }
    },
    setAllRequestIds: (prevState, action) => {
      return {
        ...prevState,
        allRequestIds: action.payload
      }
    }
  },
});

export const dcrUnlockRequestStateActions =
  dcrUnlockRequestStateHandler.actions;
export const dcrUnlockRequestStateReducer =
  dcrUnlockRequestStateHandler.reducer;
