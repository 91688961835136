import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {expenseTAMasterStateSelector} from './selectors';
import {filterSelector} from '@app/components/right-sidebar/redux/filterHandlerSelector';
import {filterStateActions} from '@app/components/right-sidebar/redux/filterHandler';
import {
  activeInactiveExpenseTAMasterCreatorCreatorTypeName,
  AddExpenseValidationTAMasterCreatorTypeName,
  editExpenseTAMasterCreatorTypeName,
  expenseTAMasterStateActions,
  fetchAddExpenseTAMasterCreatorTypeName,
  fetchExpenseTAMasterGridCreatorTypeName,
  fetchExpenseTAMasterInAutoSuggestCreatorTypeName,
} from './slice';
import {strings} from '@app/common/strings';

/**
 * Employee Details Watcher
 */
export function* fetchExpenseTAMasterGridDataWatcher() {
  yield takeLatest(
    fetchExpenseTAMasterGridCreatorTypeName,
    fetchExpenseTAMasterGridDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseTAMasterGridDataWatcherWorker(action: any): any {
  try {
    const isActiveStatus = yield select(
      expenseTAMasterStateSelector.getIsActiveState(),
    );
    const filter = yield select(filterSelector.getFilterOptions());
    let request = {
      ...filter,
      pageNumber: filter?.pageNumber + 1,
    };
    yield put(expenseTAMasterStateActions.setExpenseTAMasterLoading(true));
    const url = `${API_PATH.expenseDATAMaster.expenseTAMasterGird}`;
    const gridData = yield call(NetworkService.post, url, request);
    yield put(
      expenseTAMasterStateActions.setExpenseTAMasterGrid(gridData?.data?.items),
    );
    yield put(
      expenseTAMasterStateActions.setExpenseTAMasterSnaShot(
        gridData?.data?.items,
      ),
    );
    yield put(
      expenseTAMasterStateActions.setTotalCount(gridData?.data?.totalCount),
    );

    yield put(expenseTAMasterStateActions.setExpenseTAMasterLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchExpenseTAMasterInAutoSuggestDataWatcher() {
  yield takeLatest(
    fetchExpenseTAMasterInAutoSuggestCreatorTypeName,
    fetchExpenseTAMasterInAutoSuggestDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseTAMasterInAutoSuggestDataWatcherWorker(): any {
  try {
    const userSearchText = yield select(
      expenseTAMasterStateSelector.getSearchText(),
    );
    yield put(
      expenseTAMasterStateActions.setExpenseTAMasterAutoSuggestLoading(
        !!userSearchText,
      ),
    );
    yield put(expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggest([]));
    const isUserActive = yield select(
      expenseTAMasterStateSelector.getIsActiveState(),
    );

    if (userSearchText?.length > 0) {
      const url = `${API_PATH.expenseDATAMaster.expenseDATAMasterAutosuggest}`;
      const AutosuggestData = yield call(NetworkService.post, url, {
        isActive: isUserActive,
        searchData: userSearchText,
      });
      yield put(
        expenseTAMasterStateActions.setExpenseTAMasterAutoSuggestLoading(false),
      );
      yield put(
        expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggest(
          AutosuggestData?.data,
        ),
      );
    }
  } catch (error) {
    yield put(
      expenseTAMasterStateActions.setExpenseTAMasterAutoSuggestLoading(false),
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* activeInactiveExpenseTAMasterWatcher() {
  yield takeLatest(
    activeInactiveExpenseTAMasterCreatorCreatorTypeName,
    activeInactiveExpenseTAMasterWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* activeInactiveExpenseTAMasterWatcherWorker(action: any): any {
  try {
    const isActive = yield select(
      expenseTAMasterStateSelector.getIsActiveState(),
    );

    const url = `${API_PATH.expenseDATAMaster.expenseTAMasterActiveInactive}`;
    const disableUser = yield call(NetworkService.post, url, {
      id: action?.payload?.expenseTAMasterId,
    });
    if (disableUser?.data) {
      !isActive &&
        ToasterService.showToaster(
          strings.masterDataManagement.expenseTaActive,
          ToastType.SUCCESS,
        );
      isActive &&
        ToasterService.showToaster(
          strings.masterDataManagement.expenseTaInActive,
          ToastType.SUCCESS,
        );

      yield put({type: fetchExpenseTAMasterGridCreatorTypeName, payload: {}});
    }
  } catch (error) {
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongActiveInActiveTAMsg,
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* AddExpenseValidationTAMasterWatcher() {
  yield takeLatest(
    AddExpenseValidationTAMasterCreatorTypeName,
    AddExpenseValidationTAMasterWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* AddExpenseValidationTAMasterWatcherWorker(action: any): any {
  try {
    yield put(
      expenseTAMasterStateActions.setaddExpenseTAMasterPopupVisible(false),
    );
    yield put(
      filterStateActions.setFilterOptions({
        isActive: true,
        searchText: '',
        pageNumber: 0,
        pageLimit: 100,
        divisionIds: [],
        designationIds: [],
        hqIds: [],
      }),
    );
    const resetNavbarVersionTAMaster = yield select(
      expenseTAMasterStateSelector.getResetNavbarVersion(),
    );
    const url = `${API_PATH.expenseDATAMaster.addExpensevalidateTAMaster}`;
    const addUser = yield call(NetworkService.post, url, {
      divisionIds: action?.payload?.divisionIds,
      designationIds: action?.payload?.designationIds,
      hqIds: action?.payload?.hqIds,
      taPerKM: action?.payload?.taPerKM,
      startDate: action?.payload?.startDate
        ? new Date(action?.payload?.startDate)?.toLocaleDateString('en-ca')
        : null,
    });
    yield put(expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggest({}));
    yield put(
      expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggestFilter([]),
    );
    yield put(expenseTAMasterStateActions.setSearchText(''));
    yield put(
      expenseTAMasterStateActions.setResetNavbarVersion(
        resetNavbarVersionTAMaster + 1,
      ),
    );

    yield put({type: fetchExpenseTAMasterGridCreatorTypeName, payload: {}});
    if (addUser?.data?.message) {
      ToasterService.showToaster(addUser?.data?.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(addUser?.data?.message, ToastType.ERROR);
    }
    yield put(
      expenseTAMasterStateActions.setExpenseTAMasterNewDataValidation(
        addUser?.data,
      ),
    );
  } catch (error) {
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongAddingTaMasterMsg,
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* addAddExpenseTAMasterWatcher() {
  yield takeLatest(
    fetchAddExpenseTAMasterCreatorTypeName,
    addAddExpenseTAMasterWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* addAddExpenseTAMasterWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.expenseDATAMaster.addExpenseTAMaster}`;
    const addUser = yield call(NetworkService.post, url, action?.payload);
    yield put(
      expenseTAMasterStateActions.setaddExpenseTAMasterPopupVisible(false),
    );
    yield put({type: fetchExpenseTAMasterGridCreatorTypeName, payload: {}});
    if (addUser?.data?.message) {
      ToasterService.showToaster(addUser?.data?.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(addUser?.data?.message, ToastType.ERROR);
    }
  } catch (error) {
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongAddingTaMasterMsg,
      ToastType.ERROR,
    );
  }
}
/**
 * Employee Details Watcher
 */
export function* editExpenseTAMastereWatcher() {
  yield takeLatest(
    editExpenseTAMasterCreatorTypeName,
    editExpenseTAMasterWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* editExpenseTAMasterWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.expenseDATAMaster.editExpenseTAMaster}`;
    const editData = yield call(NetworkService.post, url, {
      expenseTAMasterId: action?.payload?.expenseTAMasterId,
      divisionId: action?.payload?.divisionId,
      designationId: action?.payload?.designationId,
      hqId: action?.payload?.hqId,
      taPerKM: action?.payload?.taPerKM,
      startDate: new Date(action?.payload?.startDate)?.toLocaleDateString(
        'en-ca',
      ),
      endDate: new Date(action?.payload?.endDate)?.toLocaleDateString('en-ca'),
      isActive: action?.payload?.isActive,
    });
    yield put(
      expenseTAMasterStateActions.setEditExpenseTAMasterPopupVisible(false),
    );
    yield put({type: fetchExpenseTAMasterGridCreatorTypeName, payload: {}});
    if (editData?.data) {
      ToasterService.showToaster(
        strings.masterDataManagement.updateSuccessfull,
        ToastType.SUCCESS,
      );
    } else {
      ToasterService.showToaster(
        strings.masterDataManagement.somethingWrongUpdatingTaMasterMsg,
        ToastType.ERROR,
      );
    }
  } catch (error) {
    yield put(
      expenseTAMasterStateActions.setEditExpenseTAMasterPopupVisible(false),
    );
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongEditingTaMasterMsg,
      ToastType.ERROR,
    );
  }
}
