export const handleRoute = (appRoutes: any, path: any) => {
  let selectedRoute: any = {};
  appRoutes.map((route: any) => {
    if (route.webPath === path) {
      selectedRoute = route;
    }
    return null;
  });
  if (
    selectedRoute &&
    selectedRoute.firstLevel &&
    selectedRoute.firstLevel.length > 0
  ) {
    const firstRoute: any = selectedRoute.firstLevel[0];
    return firstRoute.webPath;
  }
};
