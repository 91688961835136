import { API_PATH, HTTP_OK, SUCCESS_NO_CONTENT, ERROR, AREA_ACTIONS, MONTH_NAME_FORMAT } from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import { call, takeLatest, put, all } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import { positionManagementStateActions, fetchPartiesBySpidCreatorTypeName, fetchPartyDetailBySpidCreatorTypeName, fetchCloningTimePeriodCreatorTypeName, transferPositionUpdateCreatorTypeName, fetchPositionCloneListCreatorTypeName, clonePositionUpdateCreatorTypeName, fetchPositionTransferListCreatorTypeName, validatePartyTransferCreatorTypeName, fetchAreasBySpidCreatorTypeName, areaAllocationCreatorTypeName } from './slice'
import { positionManagementSelector } from './selector';
import {appSelector} from '@app/store/selectors';
import {createAreaFilterOptions, createPartyFilterOptions, filterDoctorParties} from '@app/helper/position-management';
import {getFormatDate} from '@app/utils/dateTimeHelper';
import dayjs from 'dayjs';


/**
 * Get all Parties by SPID Watcher
 */
export function* fetchPartiesBySpidWatcher() {
    yield takeLatest(fetchPartiesBySpidCreatorTypeName, fetchPartiesBySpidWorker);
}

export function* fetchPartiesBySpidWorker(action: any): any {
    try {
        const url = `${API_PATH.positionManagement.partyBySPID}/${action.payload}?isAdmin=true`;
        const response = yield call(NetworkService.get, url, {}, {})

        if (response?.status === HTTP_OK) {
            const filteredParties = filterDoctorParties(response.data);

            const partyFilterOptions = createPartyFilterOptions(filteredParties);
            yield put(
                positionManagementStateActions.setPartiesList(filteredParties),
            );
            yield put(
                positionManagementStateActions.setPartiesFilterOptions(partyFilterOptions),
            );
            yield put(
                positionManagementStateActions.setPartiesFetched(true),
            );
        }
    } catch (error) { }
}

/**
 * Get all Areas by SPID Watcher
 */
 export function* fetchAreasBySpidWatcher() {
    yield takeLatest(fetchAreasBySpidCreatorTypeName, fetchAreasBySpidWorker);
}

export function* fetchAreasBySpidWorker(action: any): any {
    try {
        const url = `${API_PATH.positionManagement.areasBySPID}/${action.payload.staffPositionId}?isAdmin=${action.payload.isAdmin}`;
        const response = yield call(NetworkService.get, url, {}, {})

        if (response?.status === HTTP_OK) {
            const areaFilterOptions = createAreaFilterOptions(response.data);
            yield put(
                positionManagementStateActions.setAreasList(response.data),
            );
            yield put(
                positionManagementStateActions.setPartiesFilterOptions(areaFilterOptions),
            );
            yield put(
                positionManagementStateActions.setAreasFetched(true),
            );
        }
    } catch (error) { }
}


/**
 * Party details by SPID Watcher
 */
export function* fetchPartyDetailBySpidWatcher() {
    yield takeLatest(fetchPartyDetailBySpidCreatorTypeName, fetchPartyDetailBySpidWorker);
}

export function* fetchPartyDetailBySpidWorker(action: any): any {
    try {
        const obj = yield select(positionManagementSelector.getPartyDetails());
        const { payload, type } = action.payload
        const url = `${API_PATH.positionManagement.positioncloningtransfer}`;
        const response = yield call(NetworkService.post, url,{...payload},{})

        if (response?.status === HTTP_OK || response.status === SUCCESS_NO_CONTENT) {
            if(type){
                yield put(
                    positionManagementStateActions.setPartiesDetails({ ...obj, [type]: { ...obj[type], details: response?.data?.length === 1 ? response?.data?.[0] : response.data } }),
                );
            }else {
                yield put(
                    positionManagementStateActions.setPartiesDetails({details:response?.data?.length === 1 ? response?.data?.[0] : response.data}),
                );
            }
        }
    } catch (error) { }
}



/**
 * Get Cloning Period Watcher
 */
export function* fetchCloningPeriodWatcher() {
    yield takeLatest(fetchCloningTimePeriodCreatorTypeName, fetchCloningPeriodWorker);
}

export function* fetchCloningPeriodWorker(action: any): any {
    try {
        const url = API_PATH.positionManagement.cloningTimePeriod;
        const response = yield call(NetworkService.get, url, {}, {})

        if (response?.status === HTTP_OK) {
            yield put(
                positionManagementStateActions.setCloningTimePeriod(response.data),
            );
        }
    } catch (error) { }
}


/**
 * Transfer party Watcher
 */
export function* transferPositionUpdateWatcher() {
    yield takeLatest(transferPositionUpdateCreatorTypeName, transferPositionUpdateWorker);
}

export function* transferPositionUpdateWorker(action: any): any {
    try {
        const partyDetails = yield select(positionManagementSelector.getPartyDetails());
        const selectedParties = yield select(positionManagementSelector.getPartiesSelectedForTransfer());
        const selectedPartyIds = selectedParties?.map((party:any) => ({
            partyId: party.id,
            areaId: party?.areas[0]?.id,
            areaName: party?.areas[0]?.name
        }))
        const selectedAreas = yield select(positionManagementSelector.getAreasSelectedForTransfer());
        const selectedAreaIds = selectedAreas?.map((area:any) => ({
            areaId: area.areaId,
            areaName: area?.areaName
        }))
        const effectiveDate = yield select(positionManagementSelector.getEffectiveDate());
        const request = {
            sourcePositionId: partyDetails?.from?.details?.staffPositionId,
            destinationPositionId: partyDetails?.to?.details?.staffPositionId,
            partyIds: selectedPartyIds,
            areaIds: selectedAreaIds,
            isAreaTransfer: selectedAreaIds?.length ? true : false,
            effectiveDate: effectiveDate || dayjs().utc().toISOString()
        }
        const url = API_PATH.positionManagement.tranferParties;
        const response = yield call(NetworkService.post, url, request)

        if (response?.status === HTTP_OK) {
            yield put(
                positionManagementStateActions.setTransferPositionResponse(request),
            );
        }else if(response?.status === ERROR){
            yield put(
                positionManagementStateActions.setTransferPositionResponse(response.data),
            );
        }
    } catch (error) { }
}

/**
 * Fetch Clone List Watcher
 */
 export function* fetchPositionCloningListWatcher() {
    yield takeLatest(fetchPositionCloneListCreatorTypeName, fetchPositionCloningListWorker);
}

export function* fetchPositionCloningListWorker(): any {
    try {
        const userDetails = yield select(appSelector.getUserInfo());
        const url = `${API_PATH.positionManagement.positionCloneList}?adminStaffPositionId=${userDetails?.staffPositionId}`;
        const response = yield call(NetworkService.get, url, {}, {})

        if (response?.status === HTTP_OK) {
            yield put(
                positionManagementStateActions.setPositionCloneListData(response?.data),
            );
            yield put(
                positionManagementStateActions.setPositionCloneListRows(response.data?.length),
            );
            yield put(
                positionManagementStateActions.setCloningListResponseUpdated(true),
            );
        }
    } catch (error) { }
}

/**
 * Fetch Clone List Watcher
 */
 export function* fetchPositionTransferListWatcher() {
    yield takeLatest(fetchPositionTransferListCreatorTypeName, fetchPositionTransferListWorker);
}

export function* fetchPositionTransferListWorker(): any {
    try {
        const userDetails = yield select(appSelector.getUserInfo());
        const url = `${API_PATH.positionManagement.positionTransferList}?adminStaffPositionId=${userDetails?.staffPositionId}`;
        const response = yield call(NetworkService.get, url, {}, {})

        if (response?.status === HTTP_OK) {
            yield put(
                positionManagementStateActions.setPositionTransferListData(response?.data),
            );
            yield put(
                positionManagementStateActions.setPositionTransferListRows(response.data?.length),
            );
            yield put(
                positionManagementStateActions.setTransferListResponseUpdated(true),
            );
        }
    } catch (error) { }
}

/**
 * Clone party Watcher
 */
 export function* clonePositionUpdateWatcher() {
    yield takeLatest(clonePositionUpdateCreatorTypeName, clonePositionUpdateWorker);
}

export function* clonePositionUpdateWorker(action: any): any {
    try {
        const partyDetails = yield select(positionManagementSelector.getPartyDetails());
        const request = {
            fromStaffPositionId : partyDetails?.from?.details?.staffPositionId,
            toStaffPositionId : partyDetails?.to?.details?.staffPositionId,
            durationId: partyDetails?.cloningPeriod
        }
        const url = API_PATH.positionManagement.clonePositon;
        const response = yield call(NetworkService.post, url, request)

        if (response?.status === HTTP_OK) {
            yield put(
                positionManagementStateActions.setClonePositionRespone(response.data),
            );
        } else if(response?.status === ERROR){
            yield put(
                positionManagementStateActions.setClonePositionRespone(response?.data),
            );  
        }  
    } catch (error) { }
}

/**
 * Validate Transfer party Watcher
 */
 export function* validatePartyTransferWatcher() {
    yield takeLatest(validatePartyTransferCreatorTypeName, validatePartyTransferWorker);
}

export function* validatePartyTransferWorker(action: any): any {
    try {
        const partyDetails = yield select(positionManagementSelector.getPartyDetails());
        const selectedParties = yield select(positionManagementSelector.getPartiesSelectedForTransfer());
        const selectedPartyIds = selectedParties?.map((party:any) => ({
            partyId: party.id,
            areaId: party?.areas[0]?.id,
            areaName: party?.areas[0]?.name
        }))
        const request = {
            sourcePositionId: partyDetails?.from?.details?.staffPositionId,
            destinationPositionId: partyDetails?.to?.details?.staffPositionId,
            partyIds: selectedPartyIds
        }
        const url = API_PATH.positionManagement.validateTranferParties;
        const response = yield call(NetworkService.post, url, request)

        if (response?.status === HTTP_OK) {
            yield put(
                positionManagementStateActions.setValidateTransferResponse(response.data),
            );
        }else if(response?.status === ERROR){
            yield put(
                positionManagementStateActions.setValidateTransferResponse(response.data),
            );
        }
    } catch (error) { }
}

/**
 * Area Allocation Watcher
 */
 export function* areaAllocationWatcher() {
    yield takeLatest(areaAllocationCreatorTypeName, areaAllocationWorker);
}

export function* areaAllocationWorker(action: any): any {
    try { 
        const url = API_PATH.positionManagement.areaAllocation;
        const payload = action?.payload?.body;
        const areas = action?.payload?.areasSelected?.map((area:any) => area?.areaName)?.toString();
        const response = yield call(NetworkService.post, url, payload)
        const formattedEffectiveDate = getFormatDate({
            date: action?.payload?.actionType === AREA_ACTIONS.CHANGE_STATUS
                ? dayjs(payload.effectiveDate).add(1, 'days')
                : payload?.effectiveDate,
            format: MONTH_NAME_FORMAT
        })
        if (response?.status === HTTP_OK) {
            if(response?.data) {
                const areaAllocationResponse = {
                    status: response.data,
                    isAreaAllocation: !(action?.payload?.actionType === AREA_ACTIONS.CHANGE_EFFECTIVE_FROM
                    || action?.payload?.actionType === AREA_ACTIONS.CHANGE_STATUS),
                    message:
                    action?.payload?.actionType === AREA_ACTIONS.CHANGE_EFFECTIVE_FROM
                    ? `Effective date for allocation of ${areas} changed to ${formattedEffectiveDate}`
                    : action?.payload?.actionType === AREA_ACTIONS.CHANGE_STATUS
                    ? `Status change of ${areas} to ${payload?.isActive ? 'Active' : 'Inactive'}
                        completed. Change will be effective from ${formattedEffectiveDate}.`
                    : `Area allocation request completed. Change will be effective from ${formattedEffectiveDate}`,
                };
                yield put(
                    positionManagementStateActions.setAreaAllocationResponse(areaAllocationResponse),
                );
            } else {
                const areaAllocationResponse = {
                    status: response.data,
                    message: 'Something went wrong.'
                };
                yield put(
                    positionManagementStateActions.setAreaAllocationResponse(areaAllocationResponse),
                );
            }
        } else {
            const areaAllocationResponse = {
                status: false,
                message: response?.data?.details?.[0]?.message || 'Something went wrong.'
            };
            yield put(
                positionManagementStateActions.setAreaAllocationResponse(areaAllocationResponse),
            );
        }
    } catch (error) { }
}
