import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'end',
    width: '70%',
    minHeight: 78,
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '100%',
    height: '35px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
  },
});
