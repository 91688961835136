import { createAction, createSlice } from "@reduxjs/toolkit";

interface InitialStateProps {
  hoUserList: {
    id: number;
    tabId: number;
    name: string;
  }[];
  // tabList: any;
  // columnMappingFields: {}[];
  hoUserCategory: any;
  hoUserDepartment: any;
  hoUserDivision: any;
  hoUserState: any;
  hoUserRegion: any;
}

const initialState: InitialStateProps = {
  hoUserCategory: null,
  hoUserDepartment: null,
  hoUserDivision: null,
  hoUserState: null,
  hoUserList: [],
  hoUserRegion: null,
};

export const hoUserCategoryCreator = createAction<any>(
  "DIRECTORY/HO_USER_CATEGORY"
);
export const hoUserCategoryTypeName = hoUserCategoryCreator(null).type;

export const hoUserDepartmentCreator = createAction<any>(
  "DIRECTORY/HO_USER_DEPARTMENT"
);
export const hoUserDepartmentTypeName = hoUserCategoryCreator(null).type;

export const hoUserDivisionCreator = createAction<any>(
  "DIRECTORY/HO_USER_DIVISION"
);
export const hoUserDivisionTypeName = hoUserDivisionCreator(null).type;

export const hoUserStateCreator = createAction<any>("DIRECTORY/HO_USER_STATE");
export const hoUserStateTypeName = hoUserStateCreator(null).type;

export const hoUserRegionCreator = createAction<any>(
  "DIRECTORY/HO_USER_REGION"
);
export const hoUserRegionTypeName = hoUserRegionCreator(null).type;

export const hoUserCreator = createAction<any>("DIRECTORY/HO_USER");
export const hoUserTypeName = hoUserCreator(null).type;

const hoUserSlice = createSlice({
  name: "HO_USER",
  initialState: initialState,
  reducers: {
    fetchHoUserCategory: (state, action) => {
      return {
        ...state,
        hoUserCategory: action.payload,
      };
    },
    fetchHoUserDepartment: (state, action) => {
      return {
        ...state,
        hoUserDepartment: action.payload,
      };
    },
    fetchHoUserDivision: (state, action) => {
      return {
        ...state,
        hoUserDivision: action.payload,
      };
    },
    fetchHoUserState: (state, action) => {
      return {
        ...state,
        hoUserState: action.payload,
      };
    },
    fetchHoUserRegion: (state, action) => {
      return {
        ...state,
        hoUserRegion: action.payload,
      };
    },
    fetchHoUserList: (state, action) => {
      return {
        ...state,
        hoUserList: action.payload,
      };
    },
    fetchHoUserNewList: (state, action) => {
      return {
        ...state,
        hoUserList: [...state.hoUserList, ...action.payload],
      };
    },
  },
});

export const hoUserActions = hoUserSlice.actions;
export const hoUserReducer = hoUserSlice.reducer;
