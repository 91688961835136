import {ACCESS_TOKEN} from '@app/common/constants';
import {resetPassword} from '@app/helper/login';
import {createSlice} from '@reduxjs/toolkit';

export const authInitialState = {
  userToken: null,
  logout: false,
  userState: null,
  isAuthorized: true,
};

const authLoginHandler = createSlice({
  name: 'LOGIN_HANDLER',
  initialState: authInitialState,
  reducers: {
    signIn: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        userToken: payload.userToken,
        userState: payload.userState,
        logout: false,
      };
    },
    signOut: prevState => {
      resetPassword(
        prevState.userToken
          ? prevState.userToken
          : localStorage.getItem(ACCESS_TOKEN),
      );
      return {
        ...prevState,
        userToken: null,
        logout: true,
      };
    },
    removeToken: prevState => {
      return {
        ...prevState,
        userToken: null,
      };
    },
    updateScreen: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        authScreen: payload.screen,
      };
    },
    setIsAuthorized: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isAuthorized: payload.isAuthorized,
      };
    },
  },
});

export const authTokenActions = authLoginHandler.actions;
export const authTokenReducer = authLoginHandler.reducer;
