import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  inventoryRolesActions,
  fetchItemCategoryName,
  fetchInventoryRolesName,
  fetchInventoryRolesCreator,
  fetchInventoryDeleteRolesName,
  fetchInventorySaveRolesName,
  fetchInventoryDivisionName,
  fetchInventoryRolesPage2Name,
  fetchInventoryRolesPage2Creator,
  fetchInventoryDeleteRolesPage2Name,
  fetchInventorySaveRolesPage2Name,
  fetchInventoryPartyTypeName,
  fetchItemCatogoryUserName,
  fetchInventoryRulesActiveInActiveName,
  fetchInventoryRulesActiveName,
  fetchInventoryRulesInActiveName,
} from './slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

export function* fetchItemCategoryWatcher() {
  yield takeLatest(fetchItemCategoryName, fetchItemCategoryWorker);
}
export function* fetchInventoryRolesWatcher() {
  yield takeLatest(fetchInventoryRolesName, fetchInventoryRolesWorker);
}

export function* fetchInventoryDeleteRolesWatcher() {
  yield takeLatest(
    fetchInventoryDeleteRolesName,
    fetchInventoryDeleteRolesWorker,
  );
}

export function* fetchInventorySaveRolesWatcher() {
  yield takeLatest(fetchInventorySaveRolesName, fetchInventorySaveRolesWorker);
}

export function* fetchInventoryDivisionWatcher() {
  yield takeLatest(fetchInventoryDivisionName, fetchInventoryDivisionWorker);
}

export function* fetchInventoryPartyTypeWatcher() {
  yield takeLatest(fetchInventoryPartyTypeName, fetchInventoryPartyTypeWorker);
}

export function* fetchInventoryRolesPage2Watcher() {
  yield takeLatest(
    fetchInventoryRolesPage2Name,
    fetchInventoryRolesPage2Worker,
  );
}

export function* fetchInventoryDeleteRolesPage2Watcher() {
  yield takeLatest(
    fetchInventoryDeleteRolesPage2Name,
    fetchInventoryDeleteRolesPage2Worker,
  );
}

export function* fetchInventorySaveRolesPage2Watcher() {
  yield takeLatest(
    fetchInventorySaveRolesPage2Name,
    fetchInventorySaveRolesPage2Worker,
  );
}
export function* fetchItemCategoryUserWatcher() {
  yield takeLatest(fetchItemCatogoryUserName, fetchItemCategoryUserWorker);
}

export function* fetchInventoryRulesActiveInActiveWatcher() {
  yield takeLatest(
    fetchInventoryRulesActiveInActiveName,
    fetchInventoryRulesActiveInActiveWorker,
  );
}
export function* fetchInventoryRulesActiveWatcher() {
  yield takeLatest(
    fetchInventoryRulesActiveName,
    fetchInventoryRulesActiveWorker,
  );
}
export function* fetchInventoryRulesInActiveWatcher() {
  yield takeLatest(
    fetchInventoryRulesInActiveName,
    fetchInventoryRulesInActiveWorker,
  );
}

export function* fetchInventoryPartyTypeWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryCustomerType}`;

    const partyTypeDropdown = yield call(NetworkService.get, url, {}, {});

    yield put(inventoryRolesActions.setPartyType(partyTypeDropdown?.data));
  } catch (error) {}
}

export function* fetchInventoryDivisionWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryDivision}`;

    const divisionDropdown = yield call(NetworkService.get, url, {}, {});

    yield put(inventoryRolesActions.setDivision(divisionDropdown?.data));
  } catch (error) {}
}

export function* fetchInventorySaveRolesWorker(action: any): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.createitemcategoryqualificationmap}`;
    const response = yield call(
      NetworkService.post,
      url,
      [...action.payload],
      {},
    );
    if (response?.status === 200) {
      ToasterService.showToaster(
        'Inventory rules added successfully!',
        ToastType.SUCCESS,
      );
      yield put(fetchInventoryRolesCreator());
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventorySaveRolesPage2Worker(action: any): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.createinventoryrule}`;
    const response = yield call(
      NetworkService.post,
      url,
      [...action.payload],
      {},
    );
    if (response?.status === 200) {
      ToasterService.showToaster(
        'Inventory rules added successfully!',
        ToastType.SUCCESS,
      );
      yield put(fetchInventoryRolesPage2Creator());
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventoryDeleteRolesWorker(action: any): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const itemID = action.payload;
    const url = `${API_PATH.inventoryModule.remmoveitemcategoryqualificationmap}/${itemID}`;
    const response = yield call(NetworkService.Delete, url, {}, {});
    if (response?.status === 200) {
      yield put(fetchInventoryRolesCreator());
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventoryDeleteRolesPage2Worker(action: any): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const itemID = action.payload;
    const url = `${API_PATH.inventoryModule.remmovinventoryrule}/${itemID}`;
    const response = yield call(NetworkService.Delete, url, {}, {});
    if (response?.status === 200) {
      yield put(fetchInventoryRolesPage2Creator());
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchItemCategoryWorker(): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.itemCategory}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200 && response?.data) {
      yield put(inventoryRolesActions.setItemCategory(response?.data));
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventoryRolesWorker(): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.itemcategoryqualificationmaps}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200 && response?.data) {
      yield put(inventoryRolesActions.setInventoryRolesList(response?.data));
    } else {
      yield put(inventoryRolesActions.setRolesError(response.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventoryRolesPage2Worker(): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.inventoryrules}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200 && response?.data) {
      yield put(inventoryRolesActions.setInventoryRolesList(response?.data));
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchItemCategoryUserWorker(action: any): any {
  const {isToggled} = action.payload;
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.itemCategoryUser}/${isToggled}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200 && response?.data) {
      yield put(inventoryRolesActions.setItemCategoryUser(response?.data));
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventoryRulesActiveInActiveWorker(action: any): any {
  const {isToggled} = action.payload;
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.inventoryRulesActiveInactive}/${isToggled}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200 && response?.data) {
      yield put(
        inventoryRolesActions.setInventoryRulesActiveInActive(response?.data),
      );
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventoryRulesActiveWorker(action: any): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.inventoryRulesActive}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200 && response?.data) {
      yield put(inventoryRolesActions.setInventoryRulesActive(response?.data));
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}

export function* fetchInventoryRulesInActiveWorker(action: any): any {
  try {
    yield put(inventoryRolesActions.setLoadingRoles());
    const url = `${API_PATH.inventoryModule.inventoryRulesInActive}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200 && response?.data) {
      yield put(
        inventoryRolesActions.setInventoryRulesInActive(response?.data),
      );
    } else {
      yield put(inventoryRolesActions.setRolesError(response?.description));
    }
  } catch (e) {
    yield put(inventoryRolesActions.setRolesError(e));
  }
}
