import {shallowEqual, useSelector} from 'react-redux';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {CircularProgress, Box, Stack} from '@mui/material';
import { strings } from '@app/common/strings';

export const tableRowKeys = [
  'id',
  'staffName',
  'divName',
  'designation',
  'hqName',
  'month',
  'distance',
  'taClaimed',
  'flmCclamied',
  'adminPassed',
  'difference',
];

export const tableFooterKeys = [
  'month',
  'distance',
  'taClaimed',
  'flmCclamied',
  'adminPassed',
  'difference',
];

const TASummaryReport = () => {
  const data = useSelector(
    expenseReportsStateSelector.getReportData(),
    shallowEqual,
  );
  const TALoading = useSelector(
    expenseReportsStateSelector.getTALoading(),
    shallowEqual,
  );
  const docHeader = [
    'S. No.',
    'Staff Name',
    'Division',
    'Designation',
    'HQ',
    'Month',
    'Distance',
    'TA Claimed',
    'Manager Passed',
    'Admin Passed',
    'Difference',
  ];
  const headerMonthly = [
    'Month',
    'Distance',
    'TA Claimed',
    'Manager Passed',
    'Admin Passed',
    'Difference',
  ];
  if (TALoading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }
  if (data?.row?.length === 0 || !data?.row) {
    return (
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        padding={30}>
      {strings.ModuleSpecificUtilitie.NoRecordsFound}
      </Stack>
    );
  }
  return (
    <div
      style={{
        fontFamily: 'Balsamiq-Sans, serif',
        letterSpacing: '0.01px',
        marginTop: 10,
        maxHeight: 450,
        overflow: 'auto',
      }}>
      <header>
        <h3 style={{textAlign: 'center', marginRight: 100}}>
          {strings.ModuleSpecificUtilitie.expenseReportPage.MONTHWISETACLAIMVSTAADMINPASS}
        </h3>
        <div style={{width: '100%'}}>
          <div>
            <span style={{fontWeight: 'bold', width: '100%'}}>Division: </span>
            <span>{data?.header?.divName}</span>
          </div>
          <div>
            <span style={{fontWeight: 'bold', width: '100%'}}>Period: </span>
            <span>{data?.header?.period?.slice(0, -1)}</span>
          </div>
        </div>
      </header>
      <div style={{width: '100%', marginTop: '2%'}}>
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              {docHeader?.map((val: string) => (
                <th
                  key={val}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    fontSize: 12,
                    width: 'fit-content',
                    fontWeight: 'bold',
                  }}>
                  {val}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.row?.map((tableRow: any, index: number) => (
              <tr key={tableRow}>
                {tableRowKeys.map((row: any) => (
                  <td
                    key={row}
                    style={{
                      border: '1px solid black',
                      borderCollapse: 'collapse',
                      fontSize: 12,
                      fontWeight:
                        tableRow.section !== 'Row' ? 'bold' : 'normal',
                      textAlign: 'center',
                    }}>
                    {(() => {
                      if (
                        row === 'id' &&
                        (tableRow.section === 'Total' ||
                          tableRow.section === 'G. Total')
                      ) {
                        return null;
                      } else if (row === 'month' && tableRow.month === null) {
                        return tableRow.section;
                      } else {
                        return tableRow[row];
                      }
                    })()}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{width: '100%', marginTop: '2%'}}>
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
            border: '1px solid black',
          }}>
          <thead>
            <tr>
              <th colSpan={5} style={{width: '445px'}}></th>
              {headerMonthly?.map((headerData: string) => (
                <th
                  key={headerData}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    fontSize: 12,
                    width: 'fit-content',
                    fontWeight: 'bold',
                  }}>
                  {headerData}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.footer?.map((tableRow: any, index: number) => (
              <tr key={tableRow}>
                <td
                  colSpan={5}
                  style={{
                    fontSize: 12,
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}>
                  {index === 0 ? 'MONTHLY SUMMARY' : ''}
                </td>
                {tableFooterKeys.map((row: any) => (
                  <td
                    key={row}
                    style={{
                      border: '1px solid black',
                      borderCollapse: 'collapse',
                      fontSize: 12,
                      textAlign: 'center',
                      fontWeight:
                        tableRow.section === 'G. Total' ? 'bold' : 'normal',
                    }}>
                    {row === 'month' && tableRow.month === null
                      ? tableRow.section
                      : tableRow[row]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TASummaryReport;
