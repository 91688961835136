import {API_PATH, HTTP_OK} from '@app/common/constants';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import NetworkService from '@app/services/network/NetworkService';
import {
  onboardNewDivisionStateActions,
  createDivisionCreatorTypeName,
  fetchDivisionsDataCreatorTypeName,
} from './index';

/**
 * Fetch Divisions Data
 */
export function* fetchDivisionsDataWatcher() {
  yield takeLatest(fetchDivisionsDataCreatorTypeName, fetchDivisionsDataWorker);
}

/**
 * Create New Division
 */
export function* createDivisionWatcher() {
  yield takeLatest(createDivisionCreatorTypeName, createDivisionWorker);
}

export function* fetchDivisionsDataWorker(): any {
  try {
    const url = API_PATH.onboardNewDivision.divisionConfig;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        onboardNewDivisionStateActions.setDivisionsData(response?.data),
      );
    }
  } catch (error) {}
}

export function* createDivisionWorker(action: any): any {
  try {
    const url = API_PATH.onboardNewDivision.saveDivision;
    const response = yield call(
      NetworkService.post,
      url,
      action?.payload?.body,
    );
    if (response?.status === HTTP_OK) {
      yield put(
        onboardNewDivisionStateActions.setOnboardNewDivisionResponse({
          response: response.data,
          divisionName: action?.payload?.divisionName,
        }),
      );
    } else {
      yield put(
        onboardNewDivisionStateActions.setOnboardNewDivisionResponse({
          response: false,
        }),
      );
    }
  } catch (error) {
    yield put(
      onboardNewDivisionStateActions.setOnboardNewDivisionResponse({
        response: false,
      }),
    );
  }
}
