import {Box, CircularProgress} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {FormFooter} from './sub-components/FormFooter';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import FiledActivity from './FiledActivity';
import moment from 'moment';
import {styles} from '../styles/customStyle';
export const DcrModal: any = () => {
  const dispatch = useDispatch();
  const onCloseClick = () => {
    dispatch(approveExpenseStateActions.setDcrReportPopupVisible(false));
  };
  const setDcrReportPopupVisible: boolean = useSelector(
    approveExpenseStateSelector.setDcrReportPopupVisible(),
  );
  const dailyExpenseDcrReportData: any = useSelector(
    approveExpenseStateSelector.getDailyExpenseDcrReportData(),
  );
  const getDailyExpenseDcrReportLoading = useSelector(
    approveExpenseStateSelector.getDailyExpenseDcrReportLoading(),
  );

  const DailyCallReportBoxFieldsValue = {
    StaffName: dailyExpenseDcrReportData?.staffName,
    SubmissionDateTime: dailyExpenseDcrReportData?.dcrSubmissionDateTime
      ? moment
          .utc(dailyExpenseDcrReportData?.dcrSubmissionDateTime)
          .local()
          .format('DD/MM/YYYY, h:mm a')
      : '',
    HQName: dailyExpenseDcrReportData?.headQuaterName,
    PlaceWorked: dailyExpenseDcrReportData?.placeWorkedName,
  };

  const DivisonBoxFieldsValue = {
    Designation: dailyExpenseDcrReportData?.designationName,
    DCRDate: moment(dailyExpenseDcrReportData?.dcrDate).format('DD/MM/YYYY'),
    DayStatus: dailyExpenseDcrReportData?.dcrStatus,
    Remarks: dailyExpenseDcrReportData?.dcrRemark,
  };
  return (
    <CustomModal
      fullWidth={true}
      maxWidth={'xl'}
      open={setDcrReportPopupVisible}
      onClose={onCloseClick}
      header={<Box display={'flex'} justifyContent="space-between"></Box>}
      footer={<FormFooter onCloseClick={onCloseClick} />}>
      <Box>
        {getDailyExpenseDcrReportLoading ? (
          <Box sx={styles.circularProgress}>
            <CircularProgress />
          </Box>
        ) : (
          <FiledActivity
            Data={dailyExpenseDcrReportData}
            DailyCallReportBoxFieldsValue={DailyCallReportBoxFieldsValue}
            DivisonBoxFieldsValue={DivisonBoxFieldsValue}
          />
        )}
      </Box>
    </CustomModal>
  );
};
