import {createSelector} from '@reduxjs/toolkit';

const dataCorrectionInitialState = (state: any) => state.dataCorrectionMaster;

const getCorrectionTypeSelector = createSelector(
  [dataCorrectionInitialState],
  data => {
    return data?.correctionType;
  },
);
  
const getPariesListSelector = createSelector(
  [dataCorrectionInitialState],
  data => data.partiesList
)

const getPartiesListCloneSelector = createSelector(
  [dataCorrectionInitialState],
  data => data.partiesListClone
)

const getListGridOptionSelector = createSelector(
  [dataCorrectionInitialState],
  data => data.gridOption
)

const getUpdatedStartDates = createSelector(
  [dataCorrectionInitialState],
  data => data.updatedStartDates
)

const getDataCorrectionResponse = createSelector([dataCorrectionInitialState],
  data => data?.dataCorrectionResponse)

export const dataCorrectionSelector = {
    getCorrectionType: () => getCorrectionTypeSelector,
    getPartiesList: () => getPariesListSelector,
    getPartiesListClone: () => getPartiesListCloneSelector,
    getListGridOption: () => getListGridOptionSelector,
    getDataCorrectionResponse: () => getDataCorrectionResponse,
    getUpdatedStartDates: () => getUpdatedStartDates
}