import {createAction, createSlice} from '@reduxjs/toolkit';
export const expectedUtilzationDateState = {
  expectedUtilizatonDateSkuAutoSuggestionLoading: true,
  expectedUtilizatonDateSkuAutoSuggestionData: [],
  expectedUtilizatonDateSkuAutoSuggestionError: '',

  expectedUtilizatonDaysLoading: true,
  expectedUtilizatonDaysData: [],
  expectedUtilizatonDaysError: '',

  newExpectedUtilizatonDaysLoading: true,
  newExpectedUtilizatonDaysData: [],
  newExpectedUtilizatonDaysError: '',
};

export const expectedUtilizatonDateSkuAutoSuggestionCreator = createAction<any>(
  'Expected_Utilization_Date_Sku',
);
export const expectedUtilizatonDateSkuAutoSuggestion =
  expectedUtilizatonDateSkuAutoSuggestionCreator({}).type;
export const expectedUtilizatonDaysCreator = createAction<any>(
  'Expected_Utilization_Days',
);
export const expectedUtilizatonDays = expectedUtilizatonDaysCreator({}).type;
export const newExpectedUtilizatonDaysCreator = createAction<any>(
  'New_Expected_Utilization_Date_Sku',
);
export const newExpectedUtilizatonDay = newExpectedUtilizatonDaysCreator(
  {},
).type;

export const expectedUtilzationDateHandler = createSlice({
  name: 'EXPECTED_UTILIZATON_DATE_HANDLER',
  initialState: expectedUtilzationDateState,
  reducers: {
    SetExpectedUtilizatonDateSkuAutoSuggestionLoading: (prevState: any) => {
      return {
        ...prevState,
        expectedUtilizatonDateSkuAutoSuggestionLoading: true,
        expectedUtilizatonDateSkuAutoSuggestionData: [],
        expectedUtilizatonDateSkuAutoSuggestionError: '',
      };
    },
    SetExpectedUtilizatonDateSkuAutoSuggestionData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expectedUtilizatonDateSkuAutoSuggestionLoading: false,
        expectedUtilizatonDateSkuAutoSuggestionData: payload,
        expectedUtilizatonDateSkuAutoSuggestionError: '',
      };
    },
    SetExpectedUtilizatonDateSkuAutoSuggestionError: prevState => {
      return {
        ...prevState,
        expectedUtilizatonDateSkuAutoSuggestionLoading: false,
        expectedUtilizatonDateSkuAutoSuggestionData: [],
        expectedUtilizatonDateSkuAutoSuggestionError: '',
      };
    },
    SetResetExpectedUtilizatonDateSkuAutoSuggestion: prevState => {
      return {
        ...prevState,
        expectedUtilizatonDateSkuAutoSuggestionLoading: false,
        expectedUtilizatonDateSkuAutoSuggestionData: [],
        expectedUtilizatonDateSkuAutoSuggestionError: '',
      };
    },
    setExpectedUtilizatonDaysLoading: (prevState: any) => {
      return {
        ...prevState,
        expectedUtilizatonDaysLoading: true,
        expectedUtilizatonDaysData: [],
        expectedUtilizatonDaysError: '',
      };
    },
    setExpectedUtilizatonDaysData: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        expectedUtilizatonDaysLoading: false,
        expectedUtilizatonDaysData: payload,
        expectedUtilizatonDaysError: '',
      };
    },
    setExpectedUtilizatonDaysError: (prevState: any) => {
      return {
        ...prevState,
        expectedUtilizatonDaysLoading: false,
        expectedUtilizatonDaysData: [],
        expectedUtilizatonDaysError: '',
      };
    },
    setNewExpectedUtilizatonDaysLoading: (prevState: any) => {
      return {
        ...prevState,
        newExpectedUtilizatonDaysLoading: true,
        newExpectedUtilizatonDaysData: [],
        newExpectedUtilizatonDaysError: '',
      };
    },
    setNewExpectedUtilizatonDaysData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        newExpectedUtilizatonDaysLoading: false,
        newExpectedUtilizatonDaysData: payload,
        newExpectedUtilizatonDaysError: '',
      };
    },
    setNewExpectedUtilizatonDaysError: (prevState: any) => {
      return {
        ...prevState,
        newExpectedUtilizatonDaysLoading: false,
        newExpectedUtilizatonDaysData: [],
        newExpectedUtilizatonDaysError: '',
      };
    },
  },
});

export const expectedUtilzationDateActions =
  expectedUtilzationDateHandler.actions;

export const expectedUtilzationDateReducers =
  expectedUtilzationDateHandler.reducer;
