import axios from 'axios';
import {API_TIMEOUT} from '@app/common/constants';
import env from '@app/env.json';

axios.defaults.headers = {
  common: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  delete: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  get: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  head: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  post: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  put: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  patch: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  options: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  purge: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  link: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
  unlink: {'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0'},
};

export const client = axios.create();
client.defaults.timeout = env.API_TIMEOUT || 60000;

export const setAPITimeout = (_timeout: any) => {
  client.defaults.timeout = _timeout || env.API_TIMEOUT || 60000;
};

/** pick and set last API timeout from local storage */
const lastError: any = localStorage.getItem(API_TIMEOUT);
const lastTimeout: any = lastError ? JSON.parse(lastError) : null;
const timeout = Number(lastTimeout);
setAPITimeout(timeout);
