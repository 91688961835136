
import { createSelector } from "@reduxjs/toolkit";

const socialMediaList = (state: any) => state.socialMediaState.socialMediaList;
const socialMediaCategory = (state: any) =>
  state.socialMediaState.socialMediaCategory;


const getSocialMediaList = createSelector(
  [socialMediaList],
  (_socialMediaList) => _socialMediaList
);

const getSocialMediaCategory = createSelector(
  [socialMediaCategory],
  (_socialMediaCategory) => _socialMediaCategory
);

export const socialMediaSelector = {
  getSocialMediaList: () => getSocialMediaList,
  getSocialMediaCategory: () => getSocialMediaCategory,
};
