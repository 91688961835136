import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Stack,
  InputLabel,
  Autocomplete,
  Switch as MuiSwitch,
} from "@mui/material";
import {
  directorySelector,
  directoryNavActionHandlerCreator,
  directoryNavSearchQueryCreator,
  directoryNavToggleCreator,
} from "@app/screens/directory-configuration/redux";
import { Search } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { ChangeEvent, useCallback } from "react";
import { directoryPageLists } from "@app/screens/directory-configuration/redux/slice";
import { columnManagementActions, columnManagemnetSelector } from "@app/screens/directory-configuration/pages/column-management/redux";
import { CustomButton, SearchTextField, StyledAutocomplete } from "@app/screens/directory-configuration/components/styledComponents";


const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  width: 36,
  height: 36,
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: '9px 0 0 1px',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 18,
    height: 18,
    backgroundColor: '#ACACAC',
  },
  '& .MuiSwitch-track': {
    height: 20,
    width: 36,
    borderRadius: 20,
    backgroundColor: '#9CA0C1',
    opacity: 1,
    '&.Mui-checked + &': {
      opacity: 1,
    },
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: 18,
  fontStyle: 'normal',
}));




const DirectoryNavBar = () => {
  const dispatch = useDispatch();

  const title = useSelector(directorySelector.getNavTitle());
  const action = useSelector(directorySelector.getNavAction());
  const search = useSelector(directorySelector.getNavSearch());
  const showToggle = useSelector(directorySelector.getShowNavToggle(), shallowEqual);
  const status = useSelector(directorySelector.getStatus, shallowEqual);
  const totalCount = useSelector(directorySelector.totalRecords, shallowEqual);
  const currentPage = useSelector(directorySelector.getCurrentPage, shallowEqual);
  const tabList = useSelector(columnManagemnetSelector.getTabList(), shallowEqual);

  const onActionHandler = useCallback(() => {
    dispatch(directoryNavActionHandlerCreator({ show: true, type: action.type }))
  }, [action])

  const onSearchHandler = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(directoryNavSearchQueryCreator(event.target.value));
  }, [])

  const onToggleHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(directoryNavToggleCreator(event.target.checked))
  }, [])

  const onTabChangeHandler = useCallback((_: any, val: any) => {
    dispatch(columnManagementActions.updateCurrentTabId(val.value));
  }, [])

  const renderDivisionDropdownInput = useCallback(
    params => <TextField {...params} placeholder="Select" required />,
    [],
  );
  return (
    <Grid container justifyContent={'space-between'} sx={{ minHeight: 110 }}>
      <Grid item xs={8}>
        <Box sx={{
          mb: 4,
          mt: 1,
        }}>
          <TitleTypography
            // variant="title"
            color={'primary.main'}

          >
            {title} {currentPage !== directoryPageLists.columnMapping ? `( ${totalCount} )` : null}
          </TitleTypography>
        </Box>
        {search ? <SearchTextField
          variant="outlined"
          placeholder={search.placeholder}
          type='search'
          fullWidth
          onChange={onSearchHandler}
          value={search.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        /> : null}
        {
          currentPage === directoryPageLists.columnMapping ? (
            <Box sx={{ maxWidth: 250, width: '100%', m: 4, mb: 1 }}>
              <InputLabel variant='standard'></InputLabel>
              <StyledAutocomplete
                options={tabList}
                freeSolo
                renderInput={renderDivisionDropdownInput}
                onChange={onTabChangeHandler}
              />
            </Box>
          ) : null
        }
      </Grid>
      <Grid item display={'flex'} direction='column' justifyContent={'space-between'}>
        <div>
          {action ? (
            <CustomButton variant="contained" onClick={onActionHandler}>{action.title}</CustomButton>
          ) : null}
        </div>
        {showToggle ? (
          <Stack direction="row" spacing={1} alignItems="center" alignSelf={'flex-end'}>
            <Typography
            // variant="switch"
            >Inactive</Typography>
            <StyledSwitch sx={{
              "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked": {
                transform: 'translateX(16px)',
              },
              "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                opacity: 1
              }
            }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} checked={status === 1} onChange={onToggleHandler} />
            <Typography
            //  variant="switch"
            >Active</Typography>
          </Stack>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default DirectoryNavBar;
