import { positionManagementMock } from '@app/mocks';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { PLAN_AND_MEET_PAGE_SIZE } from '@app/common/constants'

export const dataCorrectionInitialState = {
  correctionType: true,
  partiesList:[],
  partiesListClone:[],
  gridOption:{
    pageNo: 0,
    pageSize: PLAN_AND_MEET_PAGE_SIZE,
  },
  dataCorrectionResponse:null,
  updatedStartDates:[]
}

export const fetchDataCorrectionListCreator = createAction<any>(
  'DATA_CORRECTION_HANDLER/FETCH_DATA_CORRECTION_LIST',
);

export const fetchDataCorrectionListCreatorTypeName = fetchDataCorrectionListCreator({}).type;

export const updateDataCorrectionCreator = createAction<any>(
  'DATA_CORRECTION_HANDLER/UPDATE_DATA_CORRECTION',
);

export const updateDataCorrectionCreatorTypeName = updateDataCorrectionCreator({}).type;


const dataCorrectionStateHandler = createSlice({
  name: 'PLAN_AND_MEET_STATUS_HANDLER',
  initialState: dataCorrectionInitialState,
  reducers: {
    setCorrectionType: (prevState, action) => {
      return {
        ...prevState,
        correctionType: action.payload
      }
    },
    setPartiesList: (prevState,action) => {
      return {
        ...prevState,
        partiesList: action.payload
      }
    },
    setPartiesListClone: (prevState,action) => {
      return {
        ...prevState,
        partiesListClone: action.payload
      }
    },
    setDataCorrectionListGridOptions: (prevState,action) => {
      return {
        ...prevState,
        gridOption: action.payload
      }
    },
    setDataCorrectionResponse: (prevState,action) => {
      return {
        ...prevState,
        dataCorrectionResponse: action.payload
      }
    },
    setUpdatedStartDates: (prevState,action) => {
      return {
        ...prevState,
        updatedStartDates: action.payload
      }
    }
  }
});


export const dataCorrectionStateActions =
  dataCorrectionStateHandler.actions;
export const dataCorrectionStateReducer =
  dataCorrectionStateHandler.reducer;
