import {createSelector} from '@reduxjs/toolkit';

export const getexpenseTAMasterStateSelector = (stateName: string) => {
  return createSelector(
    [(state: any) => state.expenseTAMaster[stateName]],
    state => state,
  );
};
export const expenseTAMasterStateSelector = {
  getExpenseTAMasterGrid: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.expenseTAMasterGrid],
      expenseTAMasterGrid => expenseTAMasterGrid,
    );
  },
  getIsActiveState: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.isActiveState],
      isActiveState => isActiveState,
    );
  },
  getPage: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.page],
      page => page,
    );
  },
  getPageSize: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.pageSize],
      pageSize => pageSize,
    );
  },

  getIsExpenseTaMaster: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.isExpenseTaMaster],
      isExpenseTaMaster => isExpenseTaMaster,
    );
  },
  getExpenseTAMasterNewDataValidation: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.expenseTAMasterNewDataValidation],
      expenseTAMasterNewDataValidation => expenseTAMasterNewDataValidation,
    );
  },
  getAddPopupErrorMessage: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.addPopupErrorMessage],
      addPopupErrorMessage => addPopupErrorMessage,
    );
  },
  getSearchText: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.searchText],
      searchText => searchText,
    );
  },
  getExpenseTAMasterInAutoSuggestFilter: () => {
    return createSelector(
      [
        (state: any) =>
          state.expenseTAMaster.expenseTAMasterInAutoSuggestFilter,
      ],
      expenseTAMasterInAutoSuggestFilter => expenseTAMasterInAutoSuggestFilter,
    );
  },
  getExpenseTAMasterInAutoSuggest: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.expenseTAMasterInAutoSuggest],
      expenseTAMasterInAutoSuggest => expenseTAMasterInAutoSuggest,
    );
  },

  getAdminUsersLoading: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.adminUsersLoading],
      adminUsersLoading => adminUsersLoading,
    );
  },
  getaddexpenseTAMasterPopupVisible: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.addexpenseTAMasterPopupVisible],
      addexpenseTAMasterPopupVisible => addexpenseTAMasterPopupVisible,
    );
  },
  getEditExpenseTAMasterPopupVisible: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.editExpenseTAMasterPopupVisible],
      editExpenseTAMasterPopupVisible => editExpenseTAMasterPopupVisible,
    );
  },
  getEditExpenseTAMasterValue: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.editExpenseTAMasterValue],
      editExpenseTAMasterValue => editExpenseTAMasterValue,
    );
  },
  getResetNavbarVersion: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.resetNavbarVersion],
      resetNavbarVersion => resetNavbarVersion,
    );
  },
  getExpenseTAMasterLoading: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.expenseTAMasterLoading],
      expenseTAMasterLoading => expenseTAMasterLoading,
    );
  },
  getExpenseTAMasterSnaShot: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.expenseTAMasterSnaShot],
      expenseTAMasterSnaShot => expenseTAMasterSnaShot,
    );
  },
  getTotalCount: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.totalCount],
      totalCount => totalCount,
    );
  },
  getExpenseTAMasterAutoSuggestLoading: () => {
    return createSelector(
      [(state: any) => state.expenseTAMaster.expenseTAMasterAutoSuggestLoading],
      expenseTAMasterAutoSuggestLoading => expenseTAMasterAutoSuggestLoading,
    );
  },
};
