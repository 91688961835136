import {shallowEqual, useSelector} from 'react-redux';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {CircularProgress, Box, Stack} from '@mui/material';
import moment from 'moment';

export const monthNames = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

export const getTotal = (arr: any[]) => {
  return arr.reduce((acc, curr) => acc + curr.adminApprovedTotalAmount, 0) || 0;
};

export const getMonthlyData = (monthData: any) => {
  if (monthData === undefined) {
    return '';
  }
  if (monthData.expenseStatusId === 9 || monthData.expenseStatusId === 8) {
    return 0;
  } else if (
    monthData.expenseStatusId === 7 ||
    monthData.expenseStatusId === 0
  ) {
    return '-';
  } else {
    return monthData?.adminApprovedTotalAmount || 0;
  }
};

const YearlySummaryReport = () => {
  const data = useSelector(
    expenseReportsStateSelector.getYearlyReport(),
    shallowEqual,
  );
  const yearlyLoading = useSelector(
    expenseReportsStateSelector.getYearlyLoading(),
    shallowEqual,
  );
  const uniqueMonths: any[] = [
    ...new Set(
      data?.reduce((months: any, item: any) => {
        return months.concat(
          item.expenseMonthItems.map(
            (monthItem: any) => monthItem.expenseMonth,
          ),
        );
      }, []),
    ),
  ];
  

  const getTotalAll = (type: string) => {
    if (type === 'AVERAGE') {
      return data?.reduce(
        (acc: number, curr: any) => acc + curr.averageAmount,
        0,
      );
    }
    if (type === 'MONTHS') {
      return data?.reduce(
        (acc: number, curr: any) => acc + getTotal(curr.expenseMonthItems),
        0,
      );
    }
  };

  const getTotalMonth = (month: any) => {
    return data.reduce((total: number, employee: any) => {
      const monthData = employee.expenseMonthItems.find(
        (item: any) => item.expenseMonth === month,
      );
      return total + (monthData ? monthData.adminApprovedTotalAmount : 0);
    }, 0);
  };

  const monthAbbreviations: any[] = [
    ...new Set(
      data?.reduce((months: any, item: any) => {
        return months.concat(
          item.expenseMonthItems.map(
            (monthItem: any) => monthNames[monthItem.expenseMonth - 1]+'_'+monthItem.expenseYear,
          ),
        );
      }, []),
    ),
  ];
  // Define the existing tableRowKeys
  const tableRowKeys = [
    'staffCode',
    'staffName',
    'division',
    'designationName',
    'dateOfJoining',
    'sapCode',
    'hqName',
    ...uniqueMonths.map(month => `month${month}`),
    'TOTAL',
    'averageAmount',
  ];

  let header = [
    'S. No.',
    'Staff Code',
    'Staff Name',
    'Division',
    'Designation',
    'DOJ',
    'Employee Code', 
    'HQ',
    'TOTAL',
    'Average',
  ];
  header = [...header.slice(0, 8), ...monthAbbreviations, ...header.slice(8)];

  let totalRow = [
    'Total',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ...uniqueMonths.map(month => getTotalMonth(month)),
    getTotalAll('MONTHS'),
    getTotalAll('AVERAGE'),
  ];
  if (yearlyLoading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }
  if (data?.length === 0) {
    return (
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        padding={30}>
        No Records Found
      </Stack>
    );
  }
  const months =(expenseMonthItems:any,month:any)  => expenseMonthItems.find(
    (item: any) => item.expenseMonth === month,
  );

  return (
    <div
      style={{
        fontFamily: 'Balsamiq-Sans, serif',
        letterSpacing: '0.01px',
        marginTop: 10,
        maxHeight: 450,
        overflow: 'auto',
      }}>
      <header>
        <h3 style={{textAlign: 'center'}}>YEARLY DISBURSEMENT SUMMARY</h3>
        {/* <div style={{width: '100%'}}>
          <div>
            <span style={{fontWeight: 'bold', width: '100%'}}>Division</span>:
            <span> 'Cerebris' </span>
          </div>
        </div> */}
      </header>
      <div style={{width: '100%', marginTop: '2%'}}>
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              {header?.map((headerData: string) => (
                <th
                  key={headerData}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    fontSize: 12,
                    width: 'fit-content',
                    fontWeight: 'bold',
                  }}>
                  {headerData}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((tableRow: any, index: number) => (
              <tr key={tableRow}>
                <td
                  style={{
                    border: '1px solid black',
                    padding: '8px',
                    fontSize: 12,
                    textAlign: 'center',
                  }}>
                  {index + 1}
                </td>
                {tableRowKeys.map((rows: any, rowIndex) => (
                  <td
                    key={rows}
                    style={{
                      border: '1px solid black',
                      padding: '8px',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    {(() => {
                      if (rows === 'dateOfJoining') {
                        return moment(tableRow[rows])?.format('D-MMM-YY');
                      } else if (rows === 'staffName') {
                        return `${tableRow['staffFirstName']} ${
                          tableRow['staffMiddleName'] || ''
                        } ${tableRow['staffLastName']}`;
                      } else if (rows.startsWith('month')) {
                        const month = parseInt(rows.slice(5));
                        const monthData = months(tableRow.expenseMonthItems,month)
                        return getMonthlyData(monthData);
                      } else if (rows === 'TOTAL') {
                        return getTotal(tableRow.expenseMonthItems);
                      } else if (rows === 'averageAmount') {
                        return tableRow[rows];
                      } else {
                        return tableRow[rows];
                      }
                    })()}
                  </td>
                ))}
              </tr>
            ))}
            <tr>
              {totalRow.map((row: any) => (
                <td
                  key={row}
                  style={{
                    border: '1px solid black',
                    padding: '8px',
                    fontSize: 12,
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}>
                  {row}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YearlySummaryReport;
