import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';

import {useDispatch, useSelector} from 'react-redux';
import {configurationManagementStateActions} from '../redux';
import Stack from '@mui/material/Stack';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
  ConfigurationManagementSelector,
  getConfigManagementStateSelector,
} from '../redux/seletors';
import React, {useCallback, useState} from 'react';
import {editProRataMiscExpenseCreator} from '../redux/slice';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePickerStyle} from '@app/components/widgets/datepickerStyle/index';
import {expenseDAMasterStateActions} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';

import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {DialogProps} from '@app/App';
import {useStyles} from '@app/screens/Styles/style';
import {strings} from '@app/common/strings';
export const util: any = {
  onResetClick: null,
  ExpenseSettingStatusHandleChange: null,
  handleChange: null,
  OncloseSettingsAdd: null,
  expenseSettingHandleChangeExpenseType: null,
  expenseSettingHandleChangetaStartDate: null,
  expenseSettingHandleChangetaEndDate: null,
  onCloseDialog: null,
  closeSettingsAdd: null,
  triggeringButtonAdd: null,
  onSaveClick: null,
};

export const AddExpenseProRataMiscExpense = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [expenseSettingEndDates, setExpenseSettingEndDates] =
    useState<Date | null>(null);
  const [expenseSettingStartDates, setExpenseSettingStartDates] =
    useState<Date | null>(null);
  const [expenseSettingExpenseTypes, setExpenseSettingExpenseTypes] =
    useState<any>([]);
  const [expenseSettingStatus, setExpenseSettingStatus] = useState(true);
  const [expenseSettingValidate, setExpenseSettingValidate] = useState(true);
  const [reset, setReset] = useState(0);
  const proRataPopupType = useSelector(
    getConfigManagementStateSelector('proRataMiscExpensePopupType'),
  );
  const data = useSelector(
    ConfigurationManagementSelector.getProRataMiscExpenseEdit(),
  );

  const onResetClick = useCallback(() => {
    setExpenseSettingExpenseTypes('');
    setExpenseSettingStatus(true);
    setExpenseSettingEndDates(null);
    setExpenseSettingStartDates(null);
    setExpenseSettingValidate(true);
    setReset(reset + 1);
    dispatch(expenseDAMasterStateActions.setDesignationArr([]));
    dispatch(configurationManagementStateActions.setExpenseTypeSettingHq([]));
    dispatch(
      configurationManagementStateActions.setExpenseTypeSettingCreateValidation(
        [],
      ),
    );
  }, []);

  const onCloseDialog = () => {
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupVisible(
        false,
      ),
    );
  };
  const openDialogAddNew = useSelector(
    ConfigurationManagementSelector.getProRataMiscExpensePopupVisile(),
  );
  const expenseSettingHandleChangeExpenseType = useCallback(
    (event: any, value) => {
      setExpenseSettingExpenseTypes(value);
    },
    [expenseSettingExpenseTypes],
  );

  const ExpenseSettingStatusHandleChange = useCallback(
    (event: {
      target: {checked: boolean | ((prevState: boolean) => boolean)};
    }) => {
      setExpenseSettingStatus(event?.target?.checked);
    },
    [expenseSettingStatus],
  );

  const handleChange = useCallback(
    (event: {
      target: {checked: boolean | ((prevState: boolean) => boolean)};
    }) => {
      if (event?.target?.checked) {
        dispatch(
          configurationManagementStateActions.setProRataMiscExpenseEdit({
            ...data,
            startDate: new Date(new Date().setDate(1)),
            isProRataEnabled: event?.target?.checked,
          }),
        );
      } else if (!event?.target?.checked && data?.startDate) {
        let date = new Date();
        dispatch(
          configurationManagementStateActions.setProRataMiscExpenseEdit({
            ...data,
            endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
            isProRataEnabled: event?.target?.checked,
          }),
        );
      }
      setExpenseSettingValidate(false);
    },
    [data],
  );

  const expenseSettingHandleChangetaStartDate = useCallback(
    (event: any) => {
      setExpenseSettingStartDates(event);
    },
    [expenseSettingStartDates],
  );

  const expenseSettingHandleChangetaEndDate = useCallback(
    (event: any) => {
      setExpenseSettingEndDates(event);
    },
    [expenseSettingEndDates],
  );
  const onSaveClick = useCallback(() => {
    const request = {
      expMiscProRataId: data?.expenseProRataId,
      expMiscMasterId: data?.expenseMiscMasterId,
      isProRateEnabled: data?.isProRataEnabled,
      startDate: data?.startDate
        ? new Date(data?.startDate)?.toLocaleDateString('en-ca')
        : null,
      endDate: data?.endDate
        ? new Date(data?.endDate)?.toLocaleDateString('en-ca')
        : null,
    };
    dispatch(editProRataMiscExpenseCreator(request));
  }, [data]);
  const closeSettingsAdd = useCallback((reason: string) => {
    if (reason === 'backdropClick') return;
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupVisible(
        false,
      ),
    );
    onResetClick();
  }, []);
  const OncloseSettingsAdd = useCallback(() => {
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupVisible(
        false,
      ),
    );
    onResetClick();
  }, []);
  const triggeringButtonAdd = () => {
    onResetClick();
    onSaveClick();
    closeSettingsAdd('backdropClick');
    OncloseSettingsAdd();
    setExpenseSettingExpenseTypes(['23']);
    setExpenseSettingStatus(true);
    setExpenseSettingEndDates(new Date());
    setExpenseSettingStartDates(new Date());
    setExpenseSettingValidate(false);
    setExpenseSettingValidate(true);
    expenseSettingHandleChangeExpenseType({target: {value: '4'}}, []);
    ExpenseSettingStatusHandleChange({target: {checked: true}});
  };
  const preventDefaultHandle = useCallback(e => e.preventDefault(), []);
  const renderInput = useCallback(function (params: any) {
    return (
      <TextField
        size="small"
        {...params}
        sx={DatePickerStyle.datepick}
        helperText={null}
        onKeyDown={preventDefaultHandle}
        style={{maxwidth: 270, marginLeft: 5}}
      />
    );
  }, []);

  util.ExpenseSettingStatusHandleChange = ExpenseSettingStatusHandleChange;
  util.handleChange = handleChange;
  util.expenseSettingHandleChangeExpenseType =
    expenseSettingHandleChangeExpenseType;
  util.openDialogAddNew = openDialogAddNew;
  util.onCloseDialog = onCloseDialog;
  util.onResetClick = onResetClick;
  util.expenseSettingHandleChangetaStartDate =
    expenseSettingHandleChangetaStartDate;
  util.expenseSettingHandleChangetaEndDate =
    expenseSettingHandleChangetaEndDate;
  util.onSaveClick = onSaveClick;
  util.closeSettingsAdd = closeSettingsAdd;
  util.OncloseSettingsAdd = OncloseSettingsAdd;
  util.triggeringButtonAdd = triggeringButtonAdd;

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={openDialogAddNew}
        onClose={onCloseDialog}>
        <DialogTitle>
          <Box className={classes.justifyContentbtflex}>
            <Box>
              <Typography sx={popUpStyle.addcss}>{proRataPopupType}</Typography>
              <Typography sx={popUpStyle.headingcss}>
                {strings.proRataMiscExpense}
              </Typography>
            </Box>
            <Box>
              <Button onClick={OncloseSettingsAdd}>
                <ClearOutlinedIcon sx={popUpStyle.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <button
            data-testid="triggeringButton-add"
            className={'hiddenButton'}
            onClick={triggeringButtonAdd}
          />
          <div style={popUpStyle.dialogContent}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.division}
              </Typography>

              <TextField
                disabled={true}
                id="fullWidth"
                size="small"
                value={data?.division}
              />
            </FormControl>

            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.designation}
              </Typography>
              <TextField
                disabled={true}
                id="fullWidth"
                size="small"
                value={data?.designation}
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>{strings.hq}</Typography>

              <TextField
                disabled={true}
                id="fullWidth"
                size="small"
                value={data?.hq}
              />
            </FormControl>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.expenseTypeName}
              </Typography>
              <TextField
                disabled={true}
                id="fullWidth"
                size="small"
                value={data?.expenseTypeName}
              />
            </FormControl>
          </div>

          <div style={popUpStyle.twocolumnlayout}>
            <FormControl fullWidth sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.effectiveForm}
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  mask="__/__/____"
                  inputFormat="DD/MM/YYYY"
                  disabled={true}
                  value={data?.startDate}
                  PopperProps={DialogProps}
                  onChange={expenseSettingHandleChangetaStartDate}
                  label={strings.label.startDate}
                  renderInput={renderInput}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl
              fullWidth
              sx={[popUpStyle.marginBottom15, popUpStyle.paddingLeft5px]}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.validUpto}
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  mask="__/__/____"
                  inputFormat="DD/MM/YYYY"
                  value={data?.endDate}
                  disabled={true}
                  PopperProps={DialogProps}
                  onChange={expenseSettingHandleChangetaEndDate}
                  label={strings.label.endDate}
                  renderInput={renderInput}
                />
              </LocalizationProvider>
            </FormControl>
          </div>
          <div style={popUpStyle.twocolumnlayout}>
            <FormGroup sx={popUpStyle.marginBottom15}>
              <Typography sx={popUpStyle.labelSizing}>
                {strings.enableProRata}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                paddingLeft={1}
                spacing={2}>
                <Typography sx={popUpStyle.labelSizingAntSwitch}>
                  {strings.no}
                </Typography>
                <AntSwitch
                  checked={data?.isProRataEnabled}
                  disabled={!expenseSettingValidate}
                  inputProps={{'aria-label': 'ant design'}}
                  data-testid="expenseSettingHandleChangeAmount"
                  onChange={handleChange}
                  defaultChecked
                />
                <Typography sx={popUpStyle.labelSizingAntSwitch}>
                  {strings.yes}
                </Typography>
              </Stack>
            </FormGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Box display={'flex'} justifyContent="center" width={'100%'}>
            <Button
              data-testid="onResetClick"
              variant="contained"
              onClick={onCloseDialog}
              sx={popUpStyle.resetButton}>
              {strings.close}
            </Button>

            <Button
              data-testid="onSaveClick"
              variant="contained"
              sx={popUpStyle.saveButton}
              onClick={onSaveClick}
              disabled={expenseSettingValidate}>
              {strings.save}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
