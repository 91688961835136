import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IOnboardNewDivisionInitialState {
  divisionsData: any;
  onboardNewDivisionResponse: boolean | null;
}

export const onboardNewDivisionInitialState: IOnboardNewDivisionInitialState = {
  divisionsData: null,
  onboardNewDivisionResponse: null,
};

export const fetchDivisionsDataCreator = createAction(
  'ONBOARD_NEW_DIVISION_HANDLER/FETCH_DIVISIONS_DATA',
);
export const fetchDivisionsDataCreatorTypeName =
  fetchDivisionsDataCreator().type;

export const createDivisionCreator = createAction<any>(
  'ONBOARD_NEW_DIVISION_HANDLER/CREATE_DIVISION',
);
export const createDivisionCreatorTypeName = createDivisionCreator({}).type;

const onboardNewDivisionHandler = createSlice({
  name: 'ONBOARD_NEW_DIVISION_HANDLER',
  initialState: onboardNewDivisionInitialState,
  reducers: {
    resetOnboardNewDivisionState: () => {
      return {
        ...onboardNewDivisionInitialState,
      };
    },
    setDivisionsData: (prevState, action) => {
      return {
        ...prevState,
        divisionsData: action.payload,
      };
    },
    setOnboardNewDivisionResponse: (prevState, action) => {
      return {
        ...prevState,
        onboardNewDivisionResponse: action.payload,
      };
    },
  },
});

export const onboardNewDivisionStateActions = onboardNewDivisionHandler.actions;
export const onboardNewDivisionStateReducer = onboardNewDivisionHandler.reducer;
