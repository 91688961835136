import {createSelector} from '@reduxjs/toolkit';

const navData = (state: any) => state.holidayManagment.nav;

const getNavTitle = createSelector([navData], nav => nav.title);
const getModalVisibility = createSelector([navData], nav => nav.modalShow);
const getButtonVisible = createSelector([navData], nav => nav.buttonVisible);
const getStaffName = createSelector([navData], nav => nav.staffName);
const getHolidayAllowedCount = createSelector(
  [navData],
  nav => nav.holidayAllowedCount,
);
const getSelectedHoliday = createSelector(
  [navData],
  nav => nav.selectedHoliday,
);

const initCountrylist = (state: any) => state.holidayManagment.countrylist;
const getCountryListSelector = createSelector(
  [initCountrylist],
  _countrylist => _countrylist,
);
const initStateList = (state: any) => state.holidayManagment.statelist;
const getStateListSelector = createSelector(
  [initStateList],
  _stateList => _stateList,
);
const initHolidayByYearList = (state: any) =>
  state.holidayManagment.holidayByYearList;
const getHolidayByYearSelector = createSelector(
  [initHolidayByYearList],
  _holidayByYearList => _holidayByYearList,
);

const initHolidayDivision = (state: any) =>
  state.holidayManagment.holidayDivisionList;
const getHolidayDivisionSelector = createSelector(
  [initHolidayDivision],
  _holidayDivisionList => _holidayDivisionList,
);

const initLoading = (state: any) => state.holidayManagment.loading;
const getLoading = createSelector([initLoading], _loading => _loading);

const initCountryId = (state: any) => state.holidayManagment.countryId;
const getCountryId = createSelector([initCountryId], _countryId => _countryId);

const inityear = (state: any) => state.holidayManagment.year;
const getYears = createSelector([inityear], _year => _year);

const initHMyear = (state: any) => state.holidayManagment.HMYear;
const getHMYears = createSelector([initHMyear], _year => _year);

const initRefresh = (state: any) => state.holidayManagment.refresh;
const getRefresh = createSelector([initRefresh], _refresh => _refresh);

const initAllowedHolidayByCountryYearList = (state: any) =>
  state.holidayManagment.allowedHolidayByCountryYearList;
const getAllowedHolidayByCountryYearList = createSelector(
  [initAllowedHolidayByCountryYearList],
  _vale => _vale,
);

export const holidaySelector = {
  getNavTitle: () => getNavTitle,
  getModalVisibility: () => getModalVisibility,
  getButtonVisible: () => getButtonVisible,
  getCountryList: () => getCountryListSelector,
  getStateList: () => getStateListSelector,
  getHolidayByYear: () => getHolidayByYearSelector,
  getHolidayDivision: () => getHolidayDivisionSelector,
  getLoading: () => getLoading,
  getCountryId: () => getCountryId,
  getYears: () => getYears,
  getHMYears: () => getHMYears,
  getRefresh: () => getRefresh,
  getAllowedHolidayByCountryYearList: () => getAllowedHolidayByCountryYearList,
  getStaffName: () => getStaffName,
  getHolidayAllowedCount: () => getHolidayAllowedCount,
  getSelectedHoliday: () => getSelectedHoliday,
};
