import {createSelector} from '@reduxjs/toolkit';

export const inventoryPRSelector = {
  getStateDivisionLoader: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.stateDivisionLoader],
      stateDivisionLoader => stateDivisionLoader,
    );
  },
  getAllStates: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.allStates],
      allStates => allStates,
    );
  },
  getAllDivisions: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.allDivisions],
      allDivisions => allDivisions,
    );
  },
  getHeadquaterLoader: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.headquaterLoader],
      headquaterLoader => headquaterLoader,
    );
  },
  getAllHeadquaters: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.allHeadquaters],
      allHeadquaters => allHeadquaters,
    );
  },
  getSelectedState: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.selectedState],
      selectedState => selectedState,
    );
  },
  getSelectedDivision: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.selectedDivision],
      selectedDivision => selectedDivision,
    );
  },
  getSelectedHeadquater: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.selectedHeadquater],
      selectedHeadquater => selectedHeadquater,
    );
  },
  getPageNumber: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.pageNumber],
      pageNumber => pageNumber,
    );
  },
  getPendencyReportData: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.pendencyReportData],
      pendencyReportData => pendencyReportData,
    );
  },
  getDivisionStateData: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.divisionStateData],
      divisionStateData => divisionStateData,
    );
  },
  getItemSampleAutoSuggest: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.itemSampleAutoSuggest],
      itemSampleAutoSuggest => itemSampleAutoSuggest,
    );
  },
  getAdminData: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.adminData],
      adminData => adminData,
    );
  },
  getSubordinatesDetailsData: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.subordinatesDetailsData],
      subordinatesDetailsData => subordinatesDetailsData,
    );
  },
  getUserListData: () => {
    return createSelector(
      [(state: any) => state?.pendencyReport?.userListData],
      userListData => userListData,
    );
  },
  getSelectedStaff: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.selectedStaff],
      state => state,
    );
  },
  getSelectedSubordinate: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.selectedSubordinate],
      state => state,
    );
  }, //setActiveUsers
  getActiveUsers: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.activeUsers],
      state => state,
    );
  },
  getItemAndSample: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.itemAndSample],
      state => state,
    );
  },
  getSelectedItemAndSample: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.selectedItemAndSample],
      state => state,
    );
  },
  getPendencyExpectedUtilizationDate: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.pendencyExpectedUtilizationDate],
      state => state,
    );
  },
  getPendencyPayloadData: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.pendencyPayloadData],
      state => state,
    );
  },
  getPendencyReportExcelData: () => {
    return createSelector(
      [(state): any => state?.pendencyReport?.pendencyReportExcelData],
      state => state,
    );
  },
  getLoading :() =>{
    return createSelector(
      [(state): any => state?.pendencyReport?.loading],
      state => state,
    );
  }
};
