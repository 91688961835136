import {createSelector} from '@reduxjs/toolkit';

export const expenseReportsStateSelector = {
  getReportData: () => {
    return createSelector(
      [(state: any) => state.expenseReport.reportData],
      reportData => reportData,
    );
  },
  getReportBifurcate: () => {
    return createSelector(
      [(state: any) => state.expenseReport.reportDataBI],
      reportDataBI => reportDataBI,
    );
  },
  getTALoading: () => {
    return createSelector(
      [(state: any) => state.expenseReport.TALoading],
      TALoading => TALoading,
    );
  },
  getFiltersTASummary: () => {
    return createSelector(
      [(state: any) => state.expenseReport.filtersTA],
      filtersTA => filtersTA,
    );
  },

  getBILoading: () => {
    return createSelector(
      [(state: any) => state.expenseReport.bifurcateLoading],
      bifurcateLoading => bifurcateLoading,
    );
  },
  getFiltersBISummary: () => {
    return createSelector(
      [(state: any) => state.expenseReport.filtersBI],
      filtersBI => filtersBI,
    );
  },
  getSelectedStaffData: () => {
    return createSelector(
      [(state: any) => state.expenseReport.selectedStaff],
      selectedStaff => selectedStaff,
    );
  },
  getExpenseReportsPage: () => {
    return createSelector(
      [(state: any) => state.expenseReport.expenseReportsPage],
      expenseReportsPage => expenseReportsPage,
    );
  },
  getFiltersBifurcation: () => {
    return createSelector(
      [(state: any) => state.expenseReport.filtersBI],
      filtersBI => filtersBI,
    );
  },
  getBifurcationAutoSuggestStaffDetail: () => {
    return createSelector(
      [(state: any) => state.expenseReport.BifurcationAutoSuggestStaffDetails],
      BifurcationAutoSuggestStaffDetails => BifurcationAutoSuggestStaffDetails,
    );
  },
  getBifurcationAutoSuggestLoading: () => {
    return createSelector(
      [(state: any) => state.expenseReport.BifurcationAutoSuggestLoading],
      BifurcationAutoSuggestLoading => BifurcationAutoSuggestLoading,
    );
  },
  getBifurcationAction: () => {
    return createSelector(
      [(state: any) => state.expenseReport.BifurcationAction],
      BifurcationAction => BifurcationAction,
    );
  },
  getYearlyReport: () => {
    return createSelector(
      [(state: any) => state.expenseReport.reportDataYearly],
      reportDataYearly => reportDataYearly,
    );
  },
  getYearlyLoading: () => {
    return createSelector(
      [(state: any) => state.expenseReport.yearlyLoading],
      yearlyLoading => yearlyLoading,
    );
  },
  getBifurcationMonth: () => {
    return createSelector(
      [(state: any) => state.expenseReport.bifurcationMonth],
      bifurcationMonth => bifurcationMonth,
    );
  },
  getIsActiveTaSummary: () => {
    return createSelector(
      [(state: any) => state.expenseReport.isActiveTaSummary],
      isActiveTaSummary => isActiveTaSummary,
    );
  },
  getIsActiveBifurcation: () => {
    return createSelector(
      [(state: any) => state.expenseReport.isActiveBifurcation],
      isActiveBifurcation => isActiveBifurcation,
    );
  },
  getIsActiveYearly: () => {
    return createSelector(
      [(state: any) => state.expenseReport.isActiveYearly],
      isActiveYearly => isActiveYearly,
    );
  },
};
