import React, {useEffect} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {intransistChallanSelector} from './redux/selectors';
import {
  challanAuditReportCreator,
  challanReceiveAutoSuggestCreator,
  intransistChallanActions,
} from './redux/slice';
import {inTransitChallanTableStyle as styles} from './styles';
import _ from 'lodash';
import {getFormatDate} from '@app/utils/dateTimeHelper';
import {YEAR_DATE_FORMAT} from '@app/common/constants';
import {navbarStyles} from '@app/components/widgets/AntSwitch/AntSwitch';
import Search from '@mui/icons-material/Search';
export enum SearchType {
  userName = 'staffname',
  staffCode = 'staffcode',
  voucherNumber = 'challannumber',
  hqname = 'hqname',
}
const searchTypeLabel = {
  challannumber: 'Voucher Number',
  staffname: 'Employee Name',
  staffcode: 'SAP Code',
  hqname: 'HQ',
};

export const utility: any = {
  handleDDChange: null,
  debouncedSearch: null,
  calculatePage: null,
};

const ChallanTableModal = ({search = '', searchtype = '', total = 0}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [autocompleteValue, setAutocompleteValue] = React.useState<any>(null);
  const [searchText, setSearchText] = React.useState(search);
  const [searchType, setSearchType] = React.useState<string>(searchtype);
  const [reportChallanData, setReportChallanData] = React.useState<any>([]);

  const [totalRecords, setTotalRecords] = React.useState(total);
  const pageSize = 20;
  const challanAuditData = useSelector(
    intransistChallanSelector.getChallanAuditData(),
  );
  const challanPayload = useSelector(
    intransistChallanSelector.getChallanReceivePayload(),
  );
  const challanAutuSuggest = useSelector(
    intransistChallanSelector.getChallanReceiveAutoSuggest(),
  );
  const pageNumberData = useSelector(intransistChallanSelector.getPage());

  const dataSearchGrid = challanAuditData?.adminIntransitChallanMasterDtoLst
    ?.length
    ? challanAuditData?.adminIntransitChallanMasterDtoLst
    : [];

  const Loader = useSelector(
    intransistChallanSelector.getChallanClosingReportLoader(),
  );
  const dataGrid = challanAutuSuggest?.adminIntransitChallanMasterDtoLst?.length
    ? challanAutuSuggest?.adminIntransitChallanMasterDtoLst
    : dataSearchGrid;

  const handlePageChange = (event: any, page: any) => {
    dispatch(intransistChallanActions.setPage(page));
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchText(newSearchTerm);
  };

  const onGenerateButton = () => {
    const generateBody = {
      challanPayload,
      searchData: searchText,
      returnInBase64: false,
    };
    dispatch(challanAuditReportCreator(generateBody));
  };
  const handleDDChange = (e: SelectChangeEvent) => {
    setAutocompleteValue('');
    setSearchText('' as string);
    setSearchType(e.target.value as SearchType);
  };
  useEffect(() => {
    if (challanAuditData?.adminIntransitChallanMasterDtoLst) {
      setReportChallanData(challanAuditData?.adminIntransitChallanMasterDtoLst);
    }
    if (challanAuditData?.totalRecords) {
      setTotalRecords(parseInt(challanAuditData.totalRecords));
    }
  }, [challanAuditData]);
  useEffect(() => {
    dispatch(intransistChallanActions.setPage(pageNumberData));
  }, [pageNumberData]);

  const calculatePage = () => {
    return Math.ceil(totalRecords / pageSize);
  };
  const debouncedSearch = _.debounce(() => {
    const autosuggest = {
      _auditTrailsRequestDto: challanPayload,
      adminInTransitChallanMasterSearchRequestDto: {
        searchValue: searchText,
        searchType: searchType,
      },
    };
    dispatch(challanReceiveAutoSuggestCreator(autosuggest));
  }, 1000);

  utility.handleDDChange = handleDDChange;
  utility.handleTextChange = handleTextChange;
  utility.calculatePage = calculatePage;
  utility.debouncedSearch = debouncedSearch;
  useEffect(() => {
    if (searchText !== '' && searchType !== '') {
      debouncedSearch();
    } else {
      dispatch(intransistChallanActions.setChallanReceiveAutoSuggest([]));
    }
    return () => debouncedSearch.cancel();
  }, [searchText, pageNumberData, searchType]);
  return (
    <Box sx={{}}>
      <Box sx={styles.QtyformContainer}>
        <Box display={'inline-flex'} sx={{marginLeft: 50, width: '100%'}}>
          <FormControl sx={styles.QtyformControl} fullWidth>
            <InputLabel id="search-type-select-label">Search By</InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-search-type',
              }}
              variant="outlined"
              labelId="search-type-select-label"
              label="Search By"
              value={searchType}
              onChange={handleDDChange}>
              <MenuItem value={SearchType.voucherNumber}>
                {searchTypeLabel[SearchType.voucherNumber]}
              </MenuItem>
              <MenuItem value={SearchType.staffCode}>
                {searchTypeLabel[SearchType.staffCode]}
              </MenuItem>
              <MenuItem value={SearchType.userName}>
                {searchTypeLabel[SearchType.userName]}
              </MenuItem>
              <MenuItem value={SearchType.hqname}>
                {searchTypeLabel[SearchType.hqname]}
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={styles.serachFormControl}>
            <Autocomplete
              noOptionsText={''}
              freeSolo={true}
              data-testid="Searchfield"
              key={'key'}
              open={open}
              disabled={!searchType}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              value={autocompleteValue}
              renderInput={(params: any) => (
                <TextField
                  data-testid="test-texfield"
                  onChange={handleTextChange}
                  {...params}
                  placeholder={'Search by SAP Code, Name, Challan Number'}
                  sx={styles.searchbar}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={navbarStyles.padding5}>
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              onInputChange={(event, newValue) => {
                let searchTextValue = '';
                if (newValue) {
                  if (
                    searchType === SearchType.voucherNumber ||
                    SearchType.staffCode ||
                    SearchType.userName
                  ) {
                    searchTextValue = newValue;
                  }
                }
                setAutocompleteValue(newValue);
                setSearchText(searchTextValue);
              }}
              getOptionLabel={(option: any) => {
                if (
                  searchType === SearchType.voucherNumber ||
                  SearchType.staffCode ||
                  SearchType.userName
                ) {
                  return option;
                }
                return '';
              }}
              options={
                challanAutuSuggest.adminInTransitChallanMasterDtoLst || []
              }
            />
          </FormControl>
          <Box marginTop={-0.7}>
            <Button
              data-testid="Download-button"
              variant="contained"
              style={styles.reports}
              sx={{textTransform: 'none'}}
              onClick={onGenerateButton}>
              Download
            </Button>
          </Box>
        </Box>
      </Box>
      {Loader && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //width: '100%',
            //paddingTop: '10px',
            //paddingBottom: '10px',
            //height: 350,
          }}>
          <CircularProgress size={40} />
        </div>
      )}
      {!Loader && dataGrid?.length > 0 && (
        <TableContainer sx={{height: 350}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={styles.container}>Voucher Number</Typography>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography sx={styles.container}>SAP Code</Typography>
                    <Typography sx={styles.container}>Name</Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography sx={styles.container}>HQ</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={styles.container}>Voucher Date</Typography>
                </TableCell>

                <TableCell>
                  <Typography sx={styles.container}>GR Number</Typography>
                  <Typography sx={styles.container}>GR Date</Typography>
                </TableCell>

                <TableCell>
                  <Typography sx={styles.container}>
                    Transporter Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={styles.container}>Acknowledged By</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            {dataGrid?.map((data: any) => {
              return (
                <TableRow key={data.voucherNumber}>
                  <TableCell sx={styles.ChallanTableCell}>
                    {data.voucherNumber}
                  </TableCell>
                  <TableCell sx={styles.ChallanTableCell}>
                    <div>
                      <div>{data.staffCode}</div>
                      <div>{data.staffName}</div>
                    </div>
                  </TableCell>
                  <TableCell sx={styles.ChallanTableCell}>
                    {data.hqName}
                  </TableCell>
                  <TableCell sx={styles.ChallanTableCell}>
                    {getFormatDate({
                      date: data.voucherDate,
                      format: YEAR_DATE_FORMAT,
                    })}
                  </TableCell>
                  <TableCell sx={styles.ChallanTableCell}>
                    <div>{data.grNumber}</div>
                    <div>
                      {getFormatDate({
                        date: data.grDate,
                        format: YEAR_DATE_FORMAT,
                      })}
                    </div>
                  </TableCell>
                  <TableCell sx={styles.ChallanTableCell}>
                    {data.modeOfTransport}
                  </TableCell>
                  <TableCell>
                    <Typography sx={styles.cellStyle}>
                      {data.acknowledgeBy}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </TableContainer>
      )}
      {!Loader && reportChallanData?.length === 0 && (
        <Typography sx={{display: 'flex', justifyContent: 'center'}}>
          There are no records
        </Typography>
      )}
      <Box sx={{display: 'flex', flexDirection: 'row-reverse'}}>
        {totalRecords > 0 && (
          <Pagination
            data-testid="pagination"
            count={calculatePage()} // Total number of pages
            page={pageNumberData} // Current page
            onChange={handlePageChange} // Event handler for page change
            variant="outlined"
            shape="rounded"
          />
        )}
      </Box>
    </Box>
  );
};

export default ChallanTableModal;
