import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {useSelector, useDispatch} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {DataGrid, GridRenderCellParams} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import {dailyDataGrid} from '@app/screens/Styles/style';
import {renderDateCell} from '@app/screens/configuration-management/components/expense-type-setting-grid';
import theme from '@app/themes';
import {strings} from '@app/common/strings';
const columns: any[] = [
  {field: 'expenseRemark', headerName: 'Expense Remark', width: 180},
  {
    field: 'fromStationName',
    headerName: 'From Station Name',
    width: 150,
  },
  {
    field: 'toStationName',
    headerName: 'To Station Name',
    width: 150,
  },
  {
    field: 'merchantName',
    headerName: 'Vendor',
    width: 150,
  },
  {
    field: 'bookedDate',
    headerName: 'Booked Date',
    width: 110,
    renderCell: (params: GridRenderCellParams<any>) => {
      return renderDateCell(params?.row?.bookedDate);
    },
  },
  {
    field: 'claimedAmount',
    headerName: 'Claimed Amount',
    width: 110,
  },
];
export default function TravleDetail() {
  const PopupVisible: boolean = useSelector(
    approveExpenseStateSelector.getTravelDetailPopup(),
  );
  const data: any[] = useSelector(
    approveExpenseStateSelector.getTravelDetails(),
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(approveExpenseStateActions.setTravelDetailPopup(false));
  };
  return (
    <div>
      <Dialog
        open={PopupVisible}
        fullWidth={true}
        maxWidth={'md'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>
          <Box display={'flex'} justifyContent="space-between">
            <div>{strings.moduleSpecificUtilities.tarvelDetails}</div>
            <Box>
              <Button onClick={handleClose}>
                <CloseIcon sx={{color: theme.colors.blue[1400]}} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box sx={[dailyDataGrid(300, 75)]}>
            <DataGrid
              rows={data}
              columns={columns}
              getRowId={row => row.expenseRemark}
              rowsPerPageOptions={[10]}
              hideFooter={true}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleClose}>
            {strings.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
