import {TOKEN_EXPIRY_TIME, USER_ID, USER_INFO} from '@app/common/constants';
import env from '@app/env.json';

export const isAccessTokenValid = () => {
  const tokenExpiryTime: any = localStorage.getItem(TOKEN_EXPIRY_TIME);
  const currentTime = Date.now();
  const diff = currentTime - tokenExpiryTime * 1000;
  if (diff <= 0) {
    return true;
  } else {
    localStorage.clear();
    return false;
  }
};

export const revokeLogin = (userToken: any) => {
  try {
    const {oneLogin} = env;
    const {revokeUrlBase, web} = oneLogin;
    const {logoutRedirectURL} = web;
    if (userToken) {
      const url = `${revokeUrlBase}${window.location.origin}${logoutRedirectURL}&id_token_hint=${userToken}`;
      window.location.replace(url);
    }
    localStorage.removeItem(TOKEN_EXPIRY_TIME);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(USER_INFO);
  } catch (error) {}
};

export const isLocalHost = () =>
  ['localhost', '127.0.0.1', ''].includes(window.location.hostname);

export const formatFilters = (data: any, key: string) => {
  return Object.fromEntries([...new Set(data?.map((g: any) => g[key]))].map((g: any) => ([g, false ])).sort())
}