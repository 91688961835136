import { API_PATH } from "@app/common/constants";
import { directoryActions } from "@app/screens/directory-configuration/redux";
import { directoryPageLists } from "@app/screens/directory-configuration/redux/slice";
import { ToasterService } from "@app/services";
import NetworkService from "@app/services/network/NetworkService";
import { ToastType } from "@app/services/toaster";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fetchDivisionsCreatorTypeName,
  fetchPartyGroupsCreatorTypeName,
  tabManagementActions,
  updateTabsCreatorTypeName,
} from "./slice";

/**
 * Update Admin Nav watcher
 */
export function* updateTabsWatcher() {
  yield takeLatest(updateTabsCreatorTypeName, updateTabsWorker);
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateTabsWorker(action: any): any {
  const { index, isActive, query } = action.payload;
  yield put(
    directoryActions.updateLoading(API_PATH.directoryConfiguration.fetchTabs)
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.fetchTabs,
      { pageIndex: index, pageSize: 15, isActive, name: query || "" },
      {}
    );
    yield put(directoryActions.updateLoading(null));
    if (index === 1) {
      yield put(tabManagementActions.updateTabs(response?.data));
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.tabManagement,
          count: response?.data[0]?.totalRecords || 0,
          pageIndex: index,
        })
      );
    } else {
      yield put(tabManagementActions.updateNewTabs(response?.data));
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.tabManagement,
          pageIndex: index,
        })
      );
    }
    // yield put(tabManagementActions.updateTabs(response?.data));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}

/**
 * Fetch divisions wather
 */
export function* fetchDivisionsWatcher() {
  yield takeLatest(fetchDivisionsCreatorTypeName, fetchDivisionsWorker);
}

/**
 * Fetch divisions worker
 *
 * @param action
 */
function* fetchDivisionsWorker(): any {
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.fetchDivisions,
      {},
      {}
    );
    yield put(tabManagementActions.fetchDivisions(response.data));
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}

/**
 * Fetch Party groups wather
 */
export function* fetchPartyGroupsWatcher() {
  yield takeLatest(fetchPartyGroupsCreatorTypeName, fetchPartyGroupsWorker);
}

/**
 * Fetch party groups worker
 *
 * @param action
 */
function* fetchPartyGroupsWorker(action: any): any {
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.fetchPartyGroups,
      {},
      {}
    );
    yield put(tabManagementActions.fetchPartyGroups(response.data));
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}
