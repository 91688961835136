export const mapActivityTypes = (activities: any) => {
  let activityTypes: any = {};
  activities?.activityTypeList.forEach((activity: any) => {
    activityTypes[activity.id] = activity.name;
  });

  const activityList = activities?.activityList
    ?.map((activity: any) => ({
      ...activity,
      activityType: activityTypes?.[activity?.activityTypeId],
    }))
    ?.sort((a: any, b: any) => {
      if (a?.activityType === b?.activityType) {
        return a?.name?.localeCompare(b?.name);
      } else {
        return a?.activityType?.localeCompare(b?.activityType);
      }
    });
  return activityList;
};
