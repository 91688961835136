import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  inventoryModuleCnfActions,
  fetchInventoryAdminCnfName,
  fetchCNFStatesAndDivisionsName,
  fetchCNFHeadquartersName,
  fetchautoSuggestions,
  fetchFilteredDataCnf,
  fetchCnfReturnItemsFilterName,
  fetchApprovedItems,
  fetchRejectItems,
  fetchCnfAuditDivisionTypeName,
  fetchCnfReturnAudtilDetialsTypeName,
} from './slice';

export function* fetchInventoryCnfReturnWatcher() {
  yield takeLatest(fetchInventoryAdminCnfName, fetchInventoryCnfReturnWorker);
}

export function* fetchCNFStatesAndDivisionsWatcher() {
  yield takeLatest(
    fetchCNFStatesAndDivisionsName,
    fetchCNFStatesAndDivisionsWorker,
  );
}

export function* fetchCNFHeadquartersWatcher() {
  yield takeLatest(fetchCNFHeadquartersName, fetchCNFHeadquartersWorker);
}

export function* fetchCnfAutoSuggestionWatcher() {
  yield takeLatest(fetchautoSuggestions, fetchCnfAutoSuggestionsWorker);
}

export function* fetchFilteredDataWatcher() {
  yield takeLatest(fetchFilteredDataCnf, fetchFilteredDataWorker);
}

export function* fetchCnfReturnItemsFilterWatcher() {
  yield takeLatest(
    fetchCnfReturnItemsFilterName,
    fetchCnfReturnItemsFilterWorker,
  );
}

export function* fetchApprovedItemsWatcher() {
  yield takeLatest(fetchApprovedItems, fetchApprovedItemsWorker);
}
export function* fetchRejectItemsWatcher() {
  yield takeLatest(fetchRejectItems, fetchRejectItemsWorker);
}
export function* fetchCnfAuditDivisionWatcher() {
  yield takeLatest(fetchCnfAuditDivisionTypeName, fetchCnfAuditDivisionWorker);
}
export function* fetchCnfReturnAudtilDetialsWatcher() {
  yield takeLatest(
    fetchCnfReturnAudtilDetialsTypeName,
    fetchCnfReturnAudtilDetialsWorker,
  );
}

export function* fetchCnfAutoSuggestionsWorker(action: any): any {
  try {
    yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
    if (action && action.payload) {
      const {searchValue} = action.payload;
      const url = `${API_PATH.inventoryModule.cnfAutoSuggestionData}/${searchValue}`;
      const autoSuggestionData = yield call(
        NetworkService.post,
        url,
        {...action.payload},
        null,
      );
      if (autoSuggestionData?.status === 200) {
        yield put(
          inventoryModuleCnfActions.setCnfAutoSuggestionDataSuccess(
            autoSuggestionData?.data,
          ),
        );
      }
      yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
    }
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchFilteredDataWorker(action: any): any {
  try {
    yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
    if (action && action.payload) {
      const {searchText, offsetValue, pageNumber} = action.payload;
      const url = `${API_PATH.inventoryModule.cnfFilteredData}/${searchText}?pageNum=${pageNumber}`;
      const filteredData = yield call(NetworkService.post, url, null, null);
      if (filteredData?.status === 200) {
        yield put(
          inventoryModuleCnfActions.setCnfFilteredData(filteredData?.data),
        );
        yield put(inventoryModuleCnfActions.setPageNumber(1));
      }
    }
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchInventoryCnfReturnWorker(action: any): any {
  try {
    yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
    const url = `${API_PATH.inventoryModule.confirmReturnItems}?pageNumber=${action.payload}`;
    const result = yield call(NetworkService.get, url, {}, {});
    yield put(
      inventoryModuleCnfActions.setInventoryAdminCnfSuccess(result?.data),
    );
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchCNFStatesAndDivisionsWorker(action: any): any {
  yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  try {
    const urlStates = `${API_PATH.inventoryModule.cnfStates}`;
    const resStates = yield call(NetworkService.get, urlStates, null, null);
    yield put(inventoryModuleCnfActions.setStates(resStates?.data));
    yield put(inventoryModuleCnfActions.setDivisions(resStates?.data));
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchCNFHeadquartersWorker(action: any): any {
  yield put(inventoryModuleCnfActions.setCnfHeadquarterLoading(true));
  yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  try {
    const url = `${API_PATH.inventoryModule.cnfHeadquarters}`;
    const res = yield call(NetworkService.post, url, action.payload, null);
    yield put(inventoryModuleCnfActions.setHeadQuarters(res?.data));
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchCnfReturnItemsFilterWorker(action: any): any {
  yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  try {
    const url = `${API_PATH.inventoryModule.cnfReturnItemsFilter}`;
    const res = yield call(NetworkService.post, url, action.payload, null);
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfSuccess(res?.data));
    yield put(inventoryModuleCnfActions.setCnfRequestApiCalled(true));
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchApprovedItemsWorker(action: any): any {
  yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  try {
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.approvedItems}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );

      if (response.status === 200) {
        yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
        yield put(inventoryModuleCnfActions.setApprovedItems(response?.data));
      }
    }
    yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchRejectItemsWorker(action: any): any {
  yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  try {
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.rejectItems}`;
      const response = yield call(
        NetworkService.post,
        url,
        action?.payload,
        null,
      );

      if (response?.status === 200) {
        yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
        yield put(inventoryModuleCnfActions.setrejectItems(response?.data));
      }
    }
    yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchCnfAuditDivisionWorker(action: any): any {
  yield put(inventoryModuleCnfActions.setInventoryAdminisCnfLoading());
  try {
    const urlStates = `${API_PATH.inventoryModule.cnfDivisions}`;
    const resStates = yield call(NetworkService.get, urlStates, null, null);
    yield put(inventoryModuleCnfActions.setCnfAuditDivision(resStates?.data));
  } catch (e) {
    yield put(inventoryModuleCnfActions.setInventoryAdminCnfError());
  }
}

export function* fetchCnfReturnAudtilDetialsWorker(action: any): any {
  try {
    const url = API_PATH.inventoryModule.cnfAuditTrail;
    const response = yield call(
      NetworkService.post,
      url,
      action?.payload?.body,
    );
    if (response.status === HTTP_OK) {
      const fileName = 'audit_trail_data';
      const url = `data:text/csv;base64,${response.data}`;
      const outputFilename = `${fileName}.csv`;

      // Create a Blob with the CSV data
      const blob = new Blob([response.data], {type: 'text/csv'});

      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', outputFilename);

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
    // ...
  } catch (error) {}
}
