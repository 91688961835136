import {useCallback, useEffect, useState} from 'react';
import {Search} from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {accessGroupMasterStateActions} from '@app/screens/access-management/pages/access-group-master/redux';
import {accessGroupMasterStateSelector} from '@app/screens/access-management/pages/access-group-master/redux/selectors';
import {
  fetchAccessGroupMasterCreator,
  fetchAccessGroupsAutoSuggestCreator,
} from '@app/screens/access-management/pages/access-group-master/redux/slice';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_ACTIVE_BUTTON} from '@app/utils/analytics/constants';
import {
  AntSwitch,
  navbarStyles,
} from '@app/components/widgets/AntSwitch/AntSwitch';
import { searchBarStyle } from '../widgets/searchBarCss/searchBarCss';

export const SearchBarAccessGroup = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const isActiveState = useSelector(
    accessGroupMasterStateSelector.getIsActiveState(),
    shallowEqual,
  );
  const accessGroupsAutosuggestSearchText = useSelector(
    accessGroupMasterStateSelector.getAccessGroupsAutosuggestSearchText(),
    shallowEqual,
  );
  const accessGroupsAutosuggestLoading = useSelector(
    accessGroupMasterStateSelector.getAccessGroupsAutosuggestLoading(),
    shallowEqual,
  );
  const accessGroupsAutosuggestData = useSelector(
    accessGroupMasterStateSelector.getAccessGroupsAutosuggestData(),
    shallowEqual,
  );
  const selectedAccessGroupsInAutoSuggest = useSelector(
    accessGroupMasterStateSelector.getSelectedAccessGroupsInAutoSuggest(),
    shallowEqual,
  );

  const resetNavbarVersion = useSelector(
    accessGroupMasterStateSelector.getResetNavbarVersion(),
    shallowEqual,
  );

  useEffect(() => {
    accessGroupsAutosuggestSearchText.length > 0 &&
      dispatch(fetchAccessGroupsAutoSuggestCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessGroupsAutosuggestSearchText]);

  useEffect(() => {
    selectedAccessGroupsInAutoSuggest &&
      Object.keys(selectedAccessGroupsInAutoSuggest).length > 0 &&
      dispatch(
        fetchAccessGroupMasterCreator(selectedAccessGroupsInAutoSuggest),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccessGroupsInAutoSuggest]);
  const renderInput = useCallback(function (params4: any) {
    return(
      <TextField
      onChange={event => {
        dispatch(
          accessGroupMasterStateActions.setAccessGroupsAutosuggestSearchText(
            event.target.value,
          ),
        );
      }}
      {...params4}
      placeholder={'Search here...'}
      sx={navbarStyles.textField}
      InputProps={{
        ...params4.InputProps,
        startAdornment: (
          <InputAdornment position="start" sx={navbarStyles.padding5}>
            <Search />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {accessGroupsAutosuggestLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}
          </>
        ),
      }}
    />
    )
  },[])

  return (
    <div>
      <Toolbar sx={navbarStyles.toolbar}>
        <FormControl sx={searchBarStyle.width60}>
          <Autocomplete
            key={
              isActiveState
                ? `active ${resetNavbarVersion}`
                : `inactive ${resetNavbarVersion}`
            }
            open={open}
           
            onChange={(_event, value) => {
              if (!value) {
                dispatch(fetchAccessGroupMasterCreator(null));
              }

              dispatch(
                accessGroupMasterStateActions.setSelectedAccessGroupsInAutoSuggest(
                  value,
                ),
              );
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.accessGroup === value.accessGroup
            }
            getOptionLabel={(option: any) => option.accessGroup}
            options={
              accessGroupsAutosuggestData &&
              accessGroupsAutosuggestData.length > 0
                ? accessGroupsAutosuggestData
                : []
            }
            loading={accessGroupsAutosuggestLoading}
            renderInput={renderInput}
          />
        </FormControl>

        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontSize={'normal'}>In-Active</Typography>
            <AntSwitch
              onChange={() => {
                trackEvent(EVENT_NAME_ACTIVE_BUTTON, {});

                dispatch(
                  accessGroupMasterStateActions.setIsActiveState(
                    !isActiveState,
                  ),
                );
                dispatch(
                  accessGroupMasterStateActions.setSelectedAccessGroupsInAutoSuggest(
                    {},
                  ),
                );
                dispatch(
                  accessGroupMasterStateActions.setAccessGroupsAutosuggestData(
                    [],
                  ),
                );
                dispatch(accessGroupMasterStateActions.setUserSearchText(''));
                setTimeout(() => {
                  dispatch(fetchAccessGroupMasterCreator(null));
                }, 10);
              }}
              checked={isActiveState}
              inputProps={{'aria-label': 'ant design'}}
            />
            <Typography fontStyle={'normal'}>Active</Typography>
          </Stack>
        </FormGroup>
      </Toolbar>
    </div>
  );
};
