import {Label} from '@app/components/elements/Label';
import {
  leaveSelector,
  setApplyedFilterData,
} from '@app/screens/leave-management/redux';
import {
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material';
import {
  BottomNavigation,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Paper,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useStyles} from './styles';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

const LeaveFilter = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const getLeaveFilterDataSelector = useSelector(
    leaveSelector.getLeaveFilterDataSelector(),
    shallowEqual,
  );
  const [filterComponents, setFilterComponent] = useState<any>(<></>);
  const [filters, setFiltersState] = useState<any>({});
  const addFilterView = () => {
    setFilterComponent(getFilterLayout(filters));
  };
  useEffect(() => {
    filters && Object.keys(filters)?.length > 0
      ? addFilterView()
      : setFilterComponent(<></>);
  }, [filters]);

  useEffect(() => {
    getLeaveFilterDataSelector &&
    Object.keys(getLeaveFilterDataSelector)?.length > 0
      ? setFiltersState(getLeaveFilterDataSelector)
      : setFiltersState({});
  }, [getLeaveFilterDataSelector]);

  const clearAllFilter = () => {
    setFiltersState(getLeaveFilterDataSelector);
  };

  const alterOptionFilterAppliedState = (
    state: any,
    parentKey: any,
    childKey: any,
  ) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
    if (filtersCopy[parentKey] && filtersCopy[parentKey].filterData) {
      filtersCopy[parentKey].filterData = filtersCopy[
        parentKey
      ].filterData?.map((optionKey: any) => {
        if (optionKey.id === childKey.id) {
          return {
            ...optionKey,
            isChecked: state,
          };
        }
        return optionKey;
      });
      const uncheckedChild = filtersCopy[parentKey]?.filterData?.find(
        (child: any) => !child?.isChecked,
      );
      filtersCopy[parentKey]['isChecked'] = getIsCheckedValue(uncheckedChild);
    }
    setFiltersState(filtersCopy);
  };

  const alterAllAppliedState = (state: any, key: any) => {
    let updatedFilters = JSON.parse(JSON.stringify(filters ? filters : {}));
    const parentFilter = updatedFilters[key];
    // Toggle the isChecked state of the parent
    parentFilter.isChecked = state;
    // Mark all child filters as checked or unchecked
    parentFilter.filterData.forEach((filter: any) => {
      filter.isChecked = state;
    });
    const replaceUpdateObj = {
      ...filters,
      [key]: parentFilter,
    };
    setTimeout(() => {
      setFiltersState(replaceUpdateObj);
    }, 0);
  };

  const getIsCheckedValue = (value: any) => {
    return value ? false : true;
  };

  const handleChildCheckbox = (parentKeyName: string, childKey: any) => {
    alterOptionFilterAppliedState(
      !childKey?.isChecked,
      parentKeyName,
      childKey,
    );
  };

  const handleParentCheckbox = (parentKeyName: string, filter: any) => {
    alterAllAppliedState(!filter?.isChecked, parentKeyName);
  };

  const checkIfIndeterminate = (list: any, filter: any) => {
    if (filter.isChecked) return false;
    let isIndeterminate = list?.find((item: any) => item.isChecked);
    return isIndeterminate ? true : false;
  };
  const handleAppleyedFilterOutput = () => {
    // Initialize empty lists
    const statusList: any[] = [];
    const leaveTypeList: any[] = [];
    const stateList: any[] = [];
    const divisionList: any[] = [];
    const userStatusList: any[] = [];
    // Iterate over each filter entry
    Object.entries<any>(filters).forEach(([key, value]) => {
      // Skip "Country" filter
      if (key === 'Country') return;
      // Extract filterData array and construct list based on key
      const list = value.filterData
        .filter((item: any) => item.isChecked)
        .map((item: any) => {
          if (key === 'Approval status') {
            return {statusId: item.id};
          } else if (key === 'Leave Type') {
            return {leaveTypeId: item.id};
          } else if (key === 'State') {
            return {stateId: item.id};
          } else if (key === 'Division') {
            return {divisionId: item.id};
          } else if (key === 'User Status') {
            return {userStatus: item.id};
          }
        });
      // Push constructed list to the respective list
      if (key === 'Approval status') {
        statusList.push(...list);
      } else if (key === 'Leave Type') {
        leaveTypeList.push(...list);
      } else if (key === 'State') {
        stateList.push(...list);
      } else if (key === 'Division') {
        divisionList.push(...list);
      } else if (key === 'User Status') {
        userStatusList.push(...list);
      }
    });
    // Construct final output object
    const output: any = {
      statusList: statusList,
      leaveTypeList: leaveTypeList,
      stateList: stateList,
      divisionList: divisionList,
      userStatusList: userStatusList,
    };
    return output;
  };
  const applyFilters = () => {
    const output = handleAppleyedFilterOutput();
    // Check if statusList and User status is empty
    if (output?.statusList?.length === 0) {
      ToasterService.showToaster(
        'Please select at least one approval status type.',
        ToastType.ERROR,
      );
    } else if (output?.userStatusList?.length === 0) {
      ToasterService.showToaster(
        'Please select at least one user status.',
        ToastType.ERROR,
      );
    } else {
      dispatch(setApplyedFilterData(output));
    }
  };

  const getExpandIcon = (collapsed: boolean) => {
    if (collapsed) return <ExpandMore />;
    else return <ExpandLess />;
  };

  const listItemText = (text: string) => (
    <Label title={text} classes={classes.listItemText} />
  );

  const parentListItemText = (text: string) => (
    <Label title={text} classes={classes.parentListItemText} />
  );

  const getFilterLayout = (filtersObj: any) => {
    let filterItems: any = [];
    Object.keys(filtersObj).forEach(key => {
      filterItems.push(secondaryListItems(filtersObj[key], key));
    });
    return <Box paddingBottom={'100px'}>{filterItems}</Box>;
  };
  const handleParentListItemClick = (parentKeyName: string) => {
    let updatedZonesData = JSON.parse(JSON.stringify(filters ? filters : {}));
    updatedZonesData[parentKeyName].collapsed =
      !updatedZonesData[parentKeyName].collapsed;
    setFiltersState(updatedZonesData);
  };
  const secondaryListItems = (filter: any, parentKeyName: any) => {
    return (
      <>
        {filter ? (
          <Box marginBottom={'15px'} marginLeft={'10px'}>
            <ListItem
              disablePadding
              button
              key={parentKeyName}
              onClick={() => handleParentListItemClick(parentKeyName)}
              data-testid={`list_item_${parentKeyName}`}>
              <ListItemIcon>
                <Checkbox
                  className={classes.checkbox}
                  checked={filter?.isChecked || false}
                  indeterminate={checkIfIndeterminate(
                    filter?.filterData,
                    filter,
                  )}
                  disabled={filter?.isDisable || false}
                  indeterminateIcon={<IndeterminateIcon />}
                  onClick={event => {
                    handleParentCheckbox(parentKeyName, filter);
                  }}
                  data-testid={`parent_${parentKeyName}`}
                />
              </ListItemIcon>
              {parentListItemText(parentKeyName)}
              <Box paddingRight={'10px'}>
                {getExpandIcon(filter?.collapsed)}
              </Box>
            </ListItem>

            <Collapse in={!filter?.collapsed} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                className={classes.marginLeft15}>
                {filter?.filterData?.map((childKey: any) => {
                  return (
                    <>
                      <ListItem
                        disablePadding
                        button
                        key={`${parentKeyName}_${childKey?.id}`}
                        data-testid={`list_item_${childKey?.id}`}>
                        <ListItemIcon>
                          <Checkbox
                            className={classes.checkbox}
                            checked={childKey?.isChecked || false}
                            disabled={childKey?.isDisable || false}
                            indeterminateIcon={<IndeterminateIcon />}
                            onClick={event => {
                              handleChildCheckbox(parentKeyName, childKey);
                            }}
                            data-testid={`option_${parentKeyName}_${childKey?.id}`}
                          />
                        </ListItemIcon>
                        {listItemText(childKey?.value)}
                      </ListItem>
                    </>
                  );
                })}
              </List>
            </Collapse>
          </Box>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        margin={'20px'}
        marginTop={'10px'}
        borderRadius={'20px'}
        className={`${classes.backgroundWhite} hide-scrollbar`}
        height={'100vh'}
        overflow={'scroll'}>
        {filters && Object.keys(filters)?.length > 0 ? (
          <>
            <Box
              display={'flex'}
              justifyContent="space-between"
              marginBottom={'10px'}
              padding={'10px'}>
              <Chip
                label="Filter"
                size="small"
                className={classes.chipFilter}
              />
              <Chip
                label="Reset"
                size="small"
                clickable
                onClick={clearAllFilter}
                className={classes.chipClearAll}
                data-testid="clear-filters"
              />
            </Box>

            {filterComponents}

            <Paper className={`${classes.paper} height-100`} elevation={3}>
              <BottomNavigation showLabels value={0}>
                <Box className={classes.applyBox}>
                  <Button
                    variant="contained"
                    onClick={applyFilters}
                    className={classes.paperButton}
                    data-testid={'apply_filters'}>
                    Apply
                  </Button>
                </Box>
              </BottomNavigation>
            </Paper>
          </>
        ) : (
          <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>
    </>
  );
};

const IndeterminateIcon = () => {
  const classes = useStyles();

  return (
    <>
      <CheckBoxOutlineBlank />
      <div className={classes.indeterminateBox} />
    </>
  );
};

export default LeaveFilter;
