import {
  DataGrid,
  DataGridProps,
  GridCellParams,
  GridColDef,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid';
import {SetStateAction, useEffect, useState} from 'react';
import {Box, Button, TableCell} from '@mui/material';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import clsx from 'clsx';
import {dailyDataGrid} from '@app/screens/Styles/style';
import {DA_DATA} from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import {fetchBilDetailsCreator} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {strings} from '@app/common/strings';

export const util: any = {
  adminPassedDACalulations: null,
  hqTypeE_Selected: null,
  hqTypeO_Selected: null,
  hqTypeHQ_Selected: null,
  billPath: null,
  hqTypeAdmin: null,
  adminApprovedAmountConditionalRendering: null,
  processRowUpdate: null,
};

export const getadminApprovedAmount = (
  isDisable: boolean,
  adminApprovedAmountConditionalRendering: any,
  setSaveValidation: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
) => {
  return {
    field: strings.moduleSpecificUtilities.adminPassedAmount,
    headerName: strings.moduleSpecificUtilities.ADMINPASSEDDA,
    width: 120,
    editable: isDisable,
    valueParser: (value: any) => {
      return value
        ?.replace(/\D/g, '')
        .substring(0, value?.length - (value?.length - 6));
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      setSaveValidation(true);
      return {...params?.props};
    },
  };
};
export const getadminRemark = (
  isDisable: boolean,
  setAdminReason: {(value: SetStateAction<string>): void; (arg0: any): void},
  adminReason: string,
  setSaveValidation: {
    (value: SetStateAction<boolean>): void;
    (arg0: boolean): void;
  },
) => {
  return {
    field: strings.moduleSpecificUtilities.adminRemarks,
    headerName: strings.moduleSpecificUtilities.ADMINCHANGEREASONDA,
    width: 220,
    editable: isDisable,
    type: 'text',
    valueParser: (value: any) => {
      let res = value?.replace(/[^a-zA-Z0-9 ]/g, '')?.trimStart();
      if (res?.length <= 50) {
        setAdminReason(res);
        return res;
      } else {
        return adminReason;
      }
    },

    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const reason = params?.props?.value;
      setSaveValidation(true);
      const hqTypeAdminModified =
        params?.otherFieldsProps !== undefined
          ? params?.otherFieldsProps?.hqTypeAdmin?.value !=
              params?.row?.hqTypeAdmin ||
            params?.otherFieldsProps?.adminApprovedAmount?.value !=
              params?.row?.adminApprovedAmount
          : false;
      const validateReason =
        reason !== null && reason !== undefined ? reason?.length < 5 : true;
      const hasError2 = hqTypeAdminModified && validateReason;
      return {...params?.props, error: hasError2};
    },
  };
};
export const getHqTypeAdmin = (
  isDisable: any,
  setSaveValidation: any,
  setHqType_Value: any,
  setHqType_id: any,
) => {
  return {
    field: strings.moduleSpecificUtilities.hqTypeAdmin,
    headerName: strings.moduleSpecificUtilities.HQTYPEADMIN,
    width: 120,
    editable: isDisable,
    type: 'singleSelect',
    cellClassName: (params: GridCellParams<number>) =>
      clsx('super-app', {
        enable: params?.row?.isRuleEngineCalculated,
        disable: !params?.row?.isRuleEngineCalculated,
      }),
    valueOptions: () => {
      return [
        {value: 'O', label: strings.ModuleSpecificUtilitie.approveExpense.out},
        {value: 'H', label: strings.home},
        {value: 'E', label: strings.ModuleSpecificUtilitie.approveExpense.Ex},
      ];
    },
    renderCell: (params: any) => {
      if (
        params?.row?.hqTypeAdmin ===
          strings.ModuleSpecificUtilitie.approveExpense.out ||
        params?.row?.hqTypeAdmin ===
          strings.moduleSpecificUtilities.outStation ||
        params?.row?.hqTypeAdmin === strings.moduleSpecificUtilities.o
      ) {
        return (
          <TableCell>
            <Box>{strings.out}</Box>
          </TableCell>
        );
      }
      if (
        params?.row?.hqTypeAdmin ===
          strings.moduleSpecificUtilities.homeStation ||
        params?.row?.hqTypeAdmin === strings.home ||
        params?.row?.hqTypeAdmin ===
          strings.moduleSpecificUtilities.homeStation ||
        params?.row?.hqTypeAdmin ===
          strings.moduleSpecificUtilities.HOMESTATION ||
        params?.row?.hqTypeAdmin === strings.moduleSpecificUtilities.H
      ) {
        return (
          <TableCell>
            <Box>{strings.home}</Box>
          </TableCell>
        );
      }
      if (
        params?.row?.hqTypeAdmin ===
          strings.moduleSpecificUtilities.exStation ||
        params?.row?.hqTypeAdmin ===
          strings.ModuleSpecificUtilitie.approveExpense.Ex ||
        params?.row?.hqTypeAdmin === 'E'
      ) {
        return (
          <TableCell>
            <Box>{strings.ex}</Box>
          </TableCell>
        );
      }
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const updatedRow = {
        ...params?.row,
        hqTypeAdmin: params?.row?.hqTypeAdmin,
      };
      setSaveValidation(true);
      setHqType_Value(params?.props?.value);
      setHqType_id(params?.row?.id);
      return {...params?.props, row: updatedRow};
    },
  };
};
interface Props {
  gridDataDa: any;
  isDisable: boolean;
}
export default function DaDataGrid(props: Readonly<Props>) {
  const dispatch = useDispatch();
  const [hqType_Value, setHqType_Value] = useState('');
  const [hqType_id, setHqType_id] = useState<number>(0);
  const [adminReason, setAdminReason] = useState('');
  const [saveValidation, setSaveValidation] = useState(false);
  const daGrid = useSelector(approveExpenseStateSelector.getDaGrid());
  useEffect(() => {
    dispatch(approveExpenseStateActions.setDisableDaSave(saveValidation));
  }, [saveValidation]);

  const adminPassedDACalulations = (newRow: any, index: any) => {
    if (newRow?.hqTypeAdmin !== daGrid?.[index]?.hqTypeAdmin) {
      if (newRow?.hqTypeAdmin === 'E') {
        return hqTypeE_Selected(newRow);
      }
      if (newRow?.hqTypeAdmin === 'O') {
        return hqTypeO_Selected(newRow);
      }
      if (newRow?.hqTypeAdmin === 'H') {
        return hqTypeHQ_Selected(newRow);
      }
    }
  };
  const hqTypeE_Selected = (newRow: any) => {
    let value = 0;
    if (newRow?.daExAmount !== null) {
      value = newRow?.daExAmount;
    }
    return value;
  };
  const hqTypeO_Selected = (newRow: any) => {
    let value = 0;
    if (newRow?.daOutAmount !== null) {
      value = newRow?.daOutAmount;
    }
    return value;
  };
  const hqTypeHQ_Selected = (newRow: any) => {
    let value = 0;
    if (newRow?.dahqAmount !== null) {
      value = newRow?.dahqAmount;
    }
    return value;
  };

  const billPath = (params: any) => {
    let request = {
      id: params?.row?.id,
      page: strings.moduleSpecificUtilities.taDatPopup,
    };
    dispatch(fetchBilDetailsCreator(request));
    dispatch(
      approveExpenseStateActions.setBillPathArr(
        params?.row?.billpath?.split(','),
      ),
    );
    dispatch(approveExpenseStateActions.setViewBillPopupVisible(true));
  };
  const processRowUpdate: DataGridProps['processRowUpdate'] = (
    newRow: DA_DATA,
  ) => {
    const tempArrDa = (daGrid && [...daGrid]) ?? [];
    const index = tempArrDa?.findIndex(
      (element: {id: number}) => element?.id === newRow?.id,
    );
    setHqType_id(0);
    setHqType_Value('');
    setSaveValidation(false);
    tempArrDa[index] = {
      ...tempArrDa?.[index],
      adminApprovedAmount:
        daGrid?.[index]?.hqTypeAdmin != newRow?.hqTypeAdmin
          ? adminPassedDACalulations(newRow, index)
          : newRow?.adminApprovedAmount,
      hqTypeAdmin: hqTypeAdmin(newRow?.hqTypeAdmin),
      adminRemarks: newRow?.adminRemarks,
    };
    dispatch(approveExpenseStateActions.setDaGrid(tempArrDa));

    return newRow;
  };

  const hqTypeAdmin = (newHq: any) => {
    if (
      newHq == strings.home ||
      newHq == strings.moduleSpecificUtilities.homeStation ||
      newHq == strings.moduleSpecificUtilities.H ||
      newHq === strings.moduleSpecificUtilities.homeStation
    ) {
      return 'H';
    }
    if (
      newHq == strings.ModuleSpecificUtilitie.approveExpense.Ex ||
      newHq == strings.moduleSpecificUtilities.exStation ||
      newHq == 'E'
    ) {
      return 'E';
    }
    if (
      newHq == strings.ModuleSpecificUtilitie.approveExpense.out ||
      newHq == strings.moduleSpecificUtilities.outStation ||
      newHq == 'O'
    ) {
      return 'O';
    }
  };

  const adminApprovedAmountConditionalRendering = (params: any) => {
    if (hqType_Value && hqType_id === params?.row?.id) {
      if (hqType_Value == 'E' && params?.row?.daExAmount !== null)
        return params?.row?.daExAmount;
      if (hqType_Value == 'H' && params?.row?.dahqAmount !== null)
        return params?.row?.dahqAmount;
      if (hqType_Value == 'O' && params?.row?.daOutAmount !== null)
        return params?.row?.daOutAmount;
    } else {
      return params?.row?.adminApprovedAmount;
    }
  };

  const columns: GridColDef[] = [
    {
      field: strings.moduleSpecificUtilities.systemAmount,
      headerName: strings.da,
      width: 70,
      editable: false,
    },
    {
      field: strings.moduleSpecificUtilities.customAmount,
      headerName: strings.moduleSpecificUtilities.DACLAIMED,
      type: 'number',
      editable: false,
      width: 120,
    },
    {
      field: strings.moduleSpecificUtilities.REMARKS,
      headerName: strings.moduleSpecificUtilities.DACHANGEREASON,
      width: 160,
      editable: false,
      type: 'date',
    },
    {
      field: strings.moduleSpecificUtilities.managerApprovedAmount,
      width: 120,
      editable: false,
      headerName: strings.moduleSpecificUtilities.MANAGERPASSEDDA,
    },
    {
      field: strings.moduleSpecificUtilities.managerRemarks,
      headerName: strings.moduleSpecificUtilities.MANAGERREMARKS,
      editable: false,
      width: 120,
    },
    getHqTypeAdmin(
      props?.isDisable,
      setSaveValidation,
      setHqType_Value,
      setHqType_id,
    ),
    getadminRemark(
      props?.isDisable,
      setAdminReason,
      adminReason,
      setSaveValidation,
    ),
    getadminApprovedAmount(
      props?.isDisable,
      adminApprovedAmountConditionalRendering,
      setSaveValidation,
    ),
    {
      field: strings.moduleSpecificUtilities.billpath,
      headerName: strings.moduleSpecificUtilities.bill,
      editable: false,
      width: 100,
      renderCell: (params: any) => {
        if (params?.row?.isBillUploaded) {
          return (
            <Button
              sx={{fontSize: 11, lineHeight: 1.45}}
              size="small"
              variant="contained"
              onClick={() => billPath(params)}>
              {strings.view}
            </Button>
          );
        }
      },
    },
  ];

  util.adminPassedDACalulations = adminPassedDACalulations;
  util.hqTypeE_Selected = hqTypeE_Selected;
  util.hqTypeO_Selected = hqTypeO_Selected;
  util.hqTypeHQ_Selected = hqTypeHQ_Selected;
  util.hqTypeAdmin = hqTypeAdmin;
  util.adminApprovedAmountConditionalRendering =
    adminApprovedAmountConditionalRendering;
  util.processRowUpdate = processRowUpdate;
  util.billPath = billPath;

  const boxHeight =
    props?.gridDataDa?.length < 5 ? props?.gridDataDa?.length * 30 + 70 : 190;

  return (
    <Box sx={dailyDataGrid(boxHeight, 50)}>
      <DataGrid
        getRowId={row => row?.id}
        editMode="row"
        columnBuffer={10}
        experimentalFeatures={{newEditingApi: true}}
        rows={props?.gridDataDa || []}
        columns={columns}
        processRowUpdate={processRowUpdate}
        rowHeight={30}
        headerHeight={40}
        hideFooter={true}
      />
    </Box>
  );
}
