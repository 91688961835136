import {createAction, createSlice} from '@reduxjs/toolkit';
import {actionChannel} from 'redux-saga/effects';

export const inventoryModuleInitialState = {
  isOpenChallans: true,
  isCnfLoading: false,
  cnfData: [],
  inventoryAdminInTransitChallanError: '',
  cnfStates: [],
  cnfDivisions: [],
  cnfHeadQuarters: [],
  selectedCnfState: '',
  selectedCnfDivision: '',
  selectedCnfHeadQuarter: '',
  cnfRequestApiCalled: false,
  isLoadingCnfHeadQuarter: false,
  autoSuggestionData: [],
  filteredData: [],
  selectedCnfReturnItems: [],
  approvedItems: [],
  rejectItems: [],
  statesData: [],
  division: [],
  HQ: [],
  copySnapshotHQ: [],
  pageNumber: 1,
  cnfAuditDivision: [],
  cnfReturnAudtilDetials: [],
};

export const fetchInventoryCnfCreator = createAction('CNF_RETURN');
export const fetchCNFStatesAndDivisionsCreator = createAction(
  'CNF_STATES_DIVISIONS',
);
export const fetchCNFHeadquartersCreator =
  createAction<any>('CNF_HEAD_QUARTERS');
export const cnfSearchCreator = createAction<any>('CNF_SEARCH_AUTO');
export const cnfFilteredDataCreator = createAction<any>('FILTERED_DATA_CNF');
export const fetchCnfReturnItemsFilterCreator = createAction<any>(
  'CNF_RETURN_ITEMS_FILTER',
);
export const cnfApproveCreator = createAction<any>('CNF_APPROVED_ITEMS');
export const rejectItemsCreator = createAction<any>('CNF_REJECTS_ITEMS');

export const fetchInventoryAdminCnfName = fetchInventoryCnfCreator().type;
export const fetchCNFStatesAndDivisionsName =
  fetchCNFStatesAndDivisionsCreator().type;
export const fetchCNFHeadquartersName = fetchCNFHeadquartersCreator({}).type;
export const fetchautoSuggestions = cnfSearchCreator({}).type;
export const fetchFilteredDataCnf = cnfFilteredDataCreator({}).type;
export const fetchCnfReturnItemsFilterName = fetchCnfReturnItemsFilterCreator(
  {},
).type;
export const fetchApprovedItems = cnfApproveCreator({}).type;
export const fetchRejectItems = rejectItemsCreator({}).type;
export const fetchCnfAuditDivisionCreator = createAction('CnfAuditDivision');
export const fetchCnfAuditDivisionTypeName =
  fetchCnfAuditDivisionCreator().type;
export const fetchCnfReturnAudtilDetialsCreator = <any>(
  createAction('CnfReturnAudtilDetials')
);
export const fetchCnfReturnAudtilDetialsTypeName =
  fetchCnfReturnAudtilDetialsCreator({}).type;

const inventoryModuleCnfHandler = createSlice({
  name: 'INVENTORY_MODULE_CNF_HANDLER',
  initialState: inventoryModuleInitialState,
  reducers: {
    setIsOpenChallans: prevState => {
      return {
        ...prevState,
        isOpenChallans: !prevState.isOpenChallans,
      };
    },
    setInventoryAdminCnfSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        cnfData: payload,
        error: '',
      };
    },
    setInventoryAdminisCnfLoading: prevState => {
      return {
        ...prevState,
        isCnfLoading: false,
      };
    },
    setInventoryAdminCnfError: prevState => {
      return {
        ...prevState,
        isCnfLoading: false,
        data: [],
        error: '',
      };
    },
    setStates: (prevState, {payload}) => {
      return {
        ...prevState,
        cnfStates: payload,
      };
    },
    setDivisions: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        cnfDivisions: payload,
      };
    },
    setHeadQuarters: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        isLoadingCnfHeadQuarter: false,
        cnfHeadQuarters: payload,
      };
    },
    setSelectedCnfState: (prevState, {payload}) => {
      return {
        ...prevState,
        selectedCnfState: payload,
      };
    },
    setSelectedCnfDivison: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        selectedCnfDivision: payload,
      };
    },
    setSelectedCnfHeadquarter: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        selectedCnfHeadQuarter: payload,
      };
    },
    setCnfRequestApiCalled: (prevState, {payload}) => {
      return {
        ...prevState,
        cnfRequestApiCalled: payload,
      };
    },
    setCnfAutoSuggestionDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        autoSuggestionData: payload,
      };
    },
    setCnfFilteredData: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        filteredData: payload,
        cnfData: [],
      };
    },
    setSelectedCnfReturnItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        selectedCnfReturnItems: payload,
      };
    },
    setApprovedItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        approvedItems: payload,
      };
    },
    setrejectItems: (prevState, {payload}) => {
      return {
        ...prevState,
        isCnfLoading: false,
        rejectItems: payload,
      };
    },
    setStatesData: (prevState, {payload}) => {
      return {
        ...prevState,
        statesLoading: false,
        statesData: payload,
      };
    },
    setDivision: (prevState, {payload}) => {
      return {
        ...prevState,
        divisionLoading: false,
        division: payload,
      };
    },
    setHQ: (prevState, {payload}) => {
      return {
        ...prevState,
        HQLoading: false,
        HQ: payload,
      };
    },
    setCopySnapshotHQ: (prevState, {payload}) => {
      return {
        ...prevState,
        CopySnapshotHQLoading: false,
        copySnapshotHQ: payload,
      };
    },
    setCnfHeadquarterLoading: (prevState, {payload}) => {
      return {
        ...prevState,
        isLoadingCnfHeadQuarter: payload,
      };
    },
    setPageNumber: (prevState, {payload}) => {
      return {
        ...prevState,
        pageNumber: payload,
      };
    },
    setCnfAuditDivision: (prevState, {payload}) => {
      return {
        ...prevState,
        cnfAuditDivision: payload,
      };
    },
    setCnfReturnAudtilDetials: (prevState, {payload}) => {
      return {
        ...prevState,
        cnfReturnAudtilDetials: payload,
      };
    },
  },
});

export const inventoryModuleCnfActions = inventoryModuleCnfHandler.actions;
export const inventoryModuleCnfReducer = inventoryModuleCnfHandler.reducer;
