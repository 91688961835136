import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {takeLatest, put, call} from 'redux-saga/effects';
import {
  expenseReportsActions,
  expenseReportsReducer,
  fetchExpenseReportCreatorTypeName,
  fetchExpenseReportsPagesCreatorTypeName,
  fetchBifurcationAutoSuggestStaffCreatorTypeName,
  fetchExpenseReportCreatorYearlyTypeName,
  fetchExpenseReportCreatorBITypeName
} from './slice';
import { ToastType } from '@app/services/toaster';

export function* fetchExpenseReportPagesWatcher() {
  yield takeLatest (
    fetchExpenseReportsPagesCreatorTypeName,
    fetchExpenseReportPagesWorker,
  )
}

export function* fetchExpenseReportPagesWorker(): any {
  try{
    const url = `${API_PATH.expenseReports.getReportPages}`
    const res = yield call(NetworkService.get, url, {}, {});
    yield put(expenseReportsActions.setExpenseReportsPage(res.data));
  } catch(error){}
}

// Bifurcation report
export function* fetchExpenseBifurcateDataWatcher() {
  yield takeLatest(
    fetchExpenseReportCreatorBITypeName,
    fetchExpenseBifurcateWatcherWorker,
  );
}

export function* fetchExpenseBifurcateWatcherWorker(action: any): any {
  try {
    yield put(expenseReportsActions.setBifurLoading(true));
    let request = action.payload;
    const url = `${API_PATH.expenseReports.getReportBifurcate}`;
    const res = yield call(NetworkService.post, url, request);
    if (res.data) {
      yield put(expenseReportsActions.setBIData(res.data));
    } 
    yield put(expenseReportsActions.setBifurLoading(false));
  } catch (error) {}
}

export function* fetchExpenseReportsDataWatcher() {
  yield takeLatest(
    fetchExpenseReportCreatorTypeName,
    fetchExpenseReportDataWatcherWorker,
  );
}

export function* fetchExpenseReportDataWatcherWorker(action: any): any {
  try {
    yield put(expenseReportsActions.setTALoading(true));
    let request = action.payload;
    const url = `${API_PATH.expenseReports.getReportData}`;
    const res = yield call(NetworkService.post, url, request);
    if (res.data && !res?.data?.message ) {
      yield put(expenseReportsActions.setReportData(res.data));
    } else{
      yield put(expenseReportsActions.setReportData([]));
        ToasterService.showToaster(`${res.data.message}`, ToastType.ERROR);
    }
    yield put(expenseReportsActions.setTALoading(false));
  } catch (error) {}
}

export function* fetchExpenseReportsYearlyDataWatcher() {
  yield takeLatest(
    fetchExpenseReportCreatorYearlyTypeName,
    fetchExpenseReportYearlyDataWatcherWorker,
  );
}

export function* fetchExpenseReportYearlyDataWatcherWorker(action: any): any {
  try {
    yield put(expenseReportsActions.setYearlyLoading(true));
    let request = action.payload;
    const url = `${API_PATH.expenseReports.getReportYearly}`;
    const res = yield call(NetworkService.post, url, request);
    if (res.data) {
      yield put(expenseReportsActions.setYearlyReport(res.data));
    } 
    yield put(expenseReportsActions.setYearlyLoading(false));
  } catch (error) {}
}

// Expense Bifurcation

export function* fetchBifurcationAutoSuggestStaffWatcher() {
  yield takeLatest(
    fetchBifurcationAutoSuggestStaffCreatorTypeName,
    fetchBifurcationAutoSuggestStaffWorker
  );
}

export function* fetchBifurcationAutoSuggestStaffWorker(action: any): any {
  try {
    yield put(
      expenseReportsActions.setBifurcationAutoSuggestStaffDetail(
    []
    )
  );
    if (action.payload.searchText.length > 0) {
      yield put(expenseReportsActions.setBifurcationAutoSuggestLoading(true));
      const url = `${API_PATH.statusManager.autoSuggestStaff}`;
      const autoSuggestedStaff = yield call(NetworkService.post, url, {
        searchData: action.payload.searchText,
        divisionIds:action.payload.divisionId,
       
      });
      yield put(
        expenseReportsActions.setBifurcationAutoSuggestStaffDetail(
          autoSuggestedStaff?.data
        )
      );
      yield put(expenseReportsActions.setBifurcationAutoSuggestLoading(false));
    }
  } catch (error) {}
}

  

  
  
