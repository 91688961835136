import {ACCESS_TOKEN} from '@app/common/constants';
import {revokeLogin} from '@app/utils/util';

export const resetPassword = (userToken: any) => {
  revokeLogin(userToken);
  localStorage.removeItem(ACCESS_TOKEN);
};

export const saveAccessToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};
