import {createSelector} from '@reduxjs/toolkit';

export const authSelector = {
  getAuthScreen: () => {
    return createSelector(
      [(state: any) => state.authState.authScreen],
      screen => screen,
    );
  },
  getAuthToken: () => {
    return createSelector(
      [(state: any) => state.authState.userToken],
      token => token,
    );
  },
  getAuthLogoutStatus: () => {
    return createSelector(
      [(state: any) => state.authState.logout],
      logout => logout,
    );
  },
  getUserState: () => {
    return createSelector(
      [(state: any) => state.authState.userState],
      userState => userState,
    );
  },
  getIsAuthorized: () => {
    return createSelector(
      [(state: any) => state.authState.isAuthorized],
      isAuthorized => isAuthorized,
    );
  },
};
