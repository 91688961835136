import Colors from '@app/themes/colors';
import {makeStyles,DefaultTheme} from '@mui/styles';
import theme from '@app/themes';


interface CheckBoxObj {
    type: string[];
    checked: boolean;
  }

export const useStyles = makeStyles<DefaultTheme, CheckBoxObj>({
  navbarHeading:{
    display:'flex',
    flexDirection:'row',
    alignItems:'flex-end'
  },
  backIcon:{
    padding:0,
    marginRight:10    
  },
  navbarComponentHeading: {
    height: 24.7,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#1c1939',
  },
  searchContainer:{
    display:'flex',
    flexDirection:'row',
    alignItems:'flex-start'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '5px',
    width: 280,
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 12.7,
  },
  searchRadioBtns:{
    marginTop:theme.spacing(15)
  },
  formLabel:{
    marginRight:10,
    '& .MuiFormControlLabel-label':{
      fontSize:13
    }
  },
  optionRadio:{
    padding:5
  },
  dropdown: {
    backgroundColor: '#f2f2f2',
    height: 54,
    width: 150
  },
  searchBar:{
    flex:1,
  },
  typeDropdown:{
    marginRight:theme.spacing(10)
  },
  root: {
    width: '140px',
    height: '40px',
    padding: '2px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#fff',
      },
    },
  },
  thumb: type => {
    return {
      color: '#fff',
      backgroundColor: '#332b77',
      width: '80px',
      height: '36px',
      margin: '0px',
      borderRadius: 20,

      left: '9px',
      '&:after, &:before': {
        color: '#fff',
        fontSize: '14px',
        position: 'absolute',
        top: '8px',
      },
      '&:after': {
        content: type.checked
          ? `'${type?.type[1]}'`
          : `'${type?.type[0]}'`,
        left: '22px',
      },
    };
  },
  track: type => {
    return {
      borderRadius: '20px',
      color: '#fff',
      backgroundColor: '#fff!important',
      opacity: '1 !important',
      '&:after, &:before': {
        color: '#000',
        fontSize: '14px',
        position: 'absolute',
        top: '9px',
      },
      '&:after': {
        content: `'${type?.type[0]}'`,
        left: '20px',
      },
      '&:before': {
        content: `'${type?.type[1]}'`,
        right: '12px',
      },
    };
  },
  checked: {
    color: '#23bf58 !important',
    transform: 'translateX(58px) !important',
  },
  optionContainer:{
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between'
  }
})