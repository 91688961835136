import {useState, useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {useStyles} from './styles';
import {Close} from '@mui/icons-material';
import {
  DATE_TIME_SECOND_FORMAT,
  JUMP_DROPDOWN_VALUES,
  PRODUCT_LEVEL_DROPDOWN_VALUES,
} from '@app/common/constants';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {useDispatch, useSelector} from 'react-redux';
import {gspScheduleSelector} from '../../pages/gsp-schedule/redux';
import {
  IGspDivision,
  IGspScheduleListItem,
  saveConfigurationCreator,
} from '../../pages/gsp-schedule/redux/slice';
import dayjs from 'dayjs';
import {
  getCurrentTimeFormatDate,
  getMidnightTimeFormatDate,
} from '@app/utils/dateTimeHelper';
import {Label} from '@app/components/elements/Label';

const ADD_DIVISION_SUBTEXT =
  ' Other than these configurations we also need PDC data, sales data to be configured at backend to initiate GSP for division users';

const CreateGspDivConfig = ({
  open,
  onClose,
  isAddDivScreen,
  resetFields,
  setResetFields,
}: Readonly<{
  open: boolean;
  onClose: any;
  isAddDivScreen?: boolean;
  resetFields?: boolean;
  setResetFields?: any;
}>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [division, setDivision] = useState<any>('');
  const [financialYear, setFinancialYear] = useState<any>('');
  const [jump, setJump] = useState<any>('');
  const [productLevelSetting, setProductLevelSetting] = useState<any>('');
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [filteredDivisions, setFilteredDivisions] = useState<
    Array<IGspDivision>
  >([]);
  const gspDivisions = useSelector(gspScheduleSelector.getGspDivisions());
  const selectedFY = useSelector(gspScheduleSelector.getSelectedFY());
  const [startDateLimit, setStartDateLimit] = useState<any>(null);
  const [endDateLimit, setEndDateLimit] = useState<any>(null);
  const gspGridList = useSelector(
    gspScheduleSelector.getOriginalGspScheduleList(),
  );
  const gspYears = useSelector(gspScheduleSelector.getGspYears());

  useEffect(() => {
    const divisions = gspDivisions.filter(
      (item: IGspDivision) => !item.isGspConfigured,
    );
    const nonExistingDivisions = divisions?.filter((item: IGspDivision) => {
      if (
        !gspGridList.find(
          (row: IGspScheduleListItem) => row.divisionId === item.id,
        )
      )
        return item;
    });
    setFilteredDivisions(nonExistingDivisions);
  }, [gspDivisions]);

  useEffect(() => {
    if (resetFields) {
      resetFormFields();
      setResetFields(false);
    }
  }, [resetFields]);

  const resetFormFields = () => {
    setFinancialYear('');
    setDivision('');
    setJump('');
    setProductLevelSetting('');
    setStartDate(null);
    setEndDate(null);
    setStartDateLimit(null);
    setEndDateLimit(null);
  };

  const closeDialog = () => {
    resetFormFields();
    onClose();
  };

  const createNewDivConfig = () => {
    if (isAddDivScreen) {
      onClose({
        financialYear,
        jump,
        productLevelSetting,
        startDate,
        endDate,
      });
    } else {
      const apiData = {
        activeFY: selectedFY,
        gspConfig: [
          {
            divisionId: division,
            powerBrandLevel: productLevelSetting,
            planningLevel: jump,
            unlockedStartDate: startDate,
            unlockedEndDate: endDate,
          },
        ],
        isNew: true,
        divisionName: filteredDivisions.find(item => item.id === division)
          ?.name,
      };
      dispatch(saveConfigurationCreator(apiData));
      closeDialog();
    }
  };

  const isApplyDisabled = () => {
    return (isAddDivScreen ? financialYear : division) &&
      jump &&
      productLevelSetting &&
      startDate &&
      endDate &&
      startDate < endDate &&
      !isAddDivScreen
      ? startDate > startDateLimit && endDate < endDateLimit
      : true;
  };

  const handleDivisionChange = (val: any) => {
    setDivision(val);
    const division = filteredDivisions?.find(div => div.id === val);
    setStartDateLimit(division?.currentYearStartDateLimit);
    setEndDateLimit(division?.currentYearEndDateLimit);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paper: classes.dialog}}
      scroll="paper">
      <Box className={classes.boxtitle}>
        <DialogTitle className={classes.title}>Adding Division to GSP Schedule</DialogTitle>
        <IconButton
          color="primary"
          aria-label="add division close"
          component="span"
          id="close-add-modal"
          onClick={closeDialog}
          data-testid={'close-add-modal'}>
          <Close />
        </IconButton>
      </Box>
      <Label
        title={ADD_DIVISION_SUBTEXT}
        classes={classes.subText}
        hideToolTip
      />

      <DialogContent>
        {isAddDivScreen ? (
          <FormControl size="medium" fullWidth variant="standard" sx={{marginBottom: 2}}>
            <InputLabel id="financial-year">Select Financial Year</InputLabel>
            <Select
              labelId="financial-year"
              id="financial-year"
              defaultValue=''
              value={financialYear}
              label="Select Financial Year"
              onChange={e => setFinancialYear(e.target.value)}
              data-testid="financial-year-dropdown">
              {gspYears?.length ? (
                gspYears?.map((item: any) => {
                  if (item > dayjs().year())
                    return (
                      <MenuItem
                        key={item}
                        value={item}
                        data-testid={`menu_item_${item}`}>
                        {item - 1}-{item}
                      </MenuItem>
                    );
                })
              ) : (
                <MenuItem
                  key={null}
                  disabled={true}
                  data-testid={`menu_item_empty_fy`}>
                  No financial year to select
                </MenuItem>
              )}
            </Select>
          </FormControl>
        ) : (
          <FormControl size="medium" fullWidth variant="standard" sx={{marginBottom: 2}}>
            <InputLabel id="division">Select Division</InputLabel>
            <Select
              labelId="division"
              id="division"
              defaultValue=''
              value={division}
              label="Select Division"
              onChange={e => handleDivisionChange(e.target.value)}
              data-testid="divisions-dropdown">
              {filteredDivisions?.length ? (
                filteredDivisions?.map((item: IGspDivision) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    data-testid={`menu_item_${item.id}`}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem
                  key={null}
                  disabled={true}
                  data-testid={`menu_item_empty`}>
                  No divisions to select
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <FormControl size="medium" fullWidth variant="standard" sx={{marginBottom: 2}}>
          <InputLabel id="jump">Select Jump</InputLabel>
          <Select
            labelId="jump"
            id="jump"
            label="Select Jump"
            defaultValue=''
            value={jump}
            onChange={e => setJump(e.target.value)}
            data-testid="jump-dropdown">
            {JUMP_DROPDOWN_VALUES?.map(item => (
              <MenuItem
                key={item}
                value={item}
                data-testid={`menu_item_${item}`}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="medium" fullWidth variant="standard" sx={{marginBottom: 1}}>
          <InputLabel id="product-level-setting">
            Select Product Level Setting
          </InputLabel>
          <Select
            labelId="product-level-setting"
            id="product-level-setting"
            defaultValue=''
            value={productLevelSetting}
            label="Select Product Level Setting"
            onChange={e => setProductLevelSetting(e.target.value)}
            data-testid="product-level-setting-dropdown">
            {PRODUCT_LEVEL_DROPDOWN_VALUES?.map(item => (
              <MenuItem
                key={item}
                value={item}
                data-testid={`menu_item_${item}`}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={startDate}
            minDate={
              startDateLimit
                ? dayjs(startDateLimit).add(1, 'days')?.toDate()
                : dayjs()
            }
            maxDate={
              endDate
                ? dayjs(endDate).toDate()
                : dayjs(endDateLimit).subtract(1, 'days')?.toDate()
            }
            inputFormat="dd-MM-yyyy"
            onChange={newValue => {
              const formattedDate = getCurrentTimeFormatDate({
                date: newValue,
                format: DATE_TIME_SECOND_FORMAT,
              });
              setStartDate(formattedDate);
            }}
            InputProps={{
              classes: {root: classes.root},
            }}
            renderInput={param => (
              <TextField
                {...param}
                className={classes.datePicker}
                inputProps={{
                  ...param.inputProps,
                  placeholder: 'Start Date',
                }}
              />
            )}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            value={endDate}
            minDate={
              startDate
                ? dayjs(startDate).add(1, 'days')?.toDate()
                : startDateLimit
                ? dayjs(startDateLimit).add(1, 'days')?.toDate()
                : dayjs()
            }
            maxDate={dayjs(endDateLimit).subtract(1, 'days')?.toDate()}
            inputFormat="dd-MM-yyyy"
            onChange={newValue => {
              const formattedDate = getMidnightTimeFormatDate({
                date: newValue,
                format: DATE_TIME_SECOND_FORMAT,
              });
              setEndDate(formattedDate);
            }}
            InputProps={{
              classes: {root: classes.root},
            }}
            renderInput={param => (
              <TextField
                {...param}
                className={classes.datePicker}
                inputProps={{
                  ...param.inputProps,
                  placeholder: 'End Date',
                }}
              />
            )}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Box className={classes.box}>
          <Button
            data-testid="button-cancel"
            variant="contained"
            className={classes.paperButton}
            onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}
            disabled={!isApplyDisabled()}
            onClick={createNewDivConfig}>
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGspDivConfig;
