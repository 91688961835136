import {DataGrid, GridRenderCellParams} from '@mui/x-data-grid';
import {
  TableCell,
  Box,
  Stack,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';
import {
  activeInactiveExpenseTypeSettingCreator,
  configurationManagementStateActions,
  createProRataMiscExpenseCreator,
} from '../redux/slice';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Gridstyle} from '@app/components/widgets/grid/grid';

import {ConfigurationManagementSelector} from '../redux/seletors';
import moment from 'moment';

import {useCallback, useEffect, useState} from 'react';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import {EditIcon, useStyles} from '@app/screens/Styles/style';

import {popUpStyle as style} from '@app/components/widgets/popupCss/popUpStyles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {appSelector} from '@app/store/selectors';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_USER_MANAGEMENT_DEACTIVATED_BUTTON} from '@app/utils/analytics/constants';
import {Filter} from '@app/components/widgets/filters';
import {
  fetchDesignationsDropdownCreator,
  fetchHQDropdownCreator,
} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {fetchDivisionDataCreator} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import theme from '@app/themes';
import {strings} from '@app/common/strings';

export const utils: any = {
  OnDelete: null,
  getIconColor: null,
  onDialogClose: null,
  onOpenDialogEdit: null,
  renderActionsCell: null,
  renderProRataCell: null,
  getRowIdHandler: null,
  PageSizeChange: null,
  PageChange: null,
  renderCell: null,
  noRowsOverlayComponent: null,
};

export const renderDateCell = (date: any) => {
  return <Box>{date ? moment(date)?.format('DD/MM/YYYY') : ''}</Box>;
};

export const ProRataMiscExpenseGrid = ({
  expenseTypeSetting,
}: {
  expenseTypeSetting: any;
}) => {
  let row: any;
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const {isWriteAccess = false} = useSelector(
    appSelector.getUserInfo(),
    shallowEqual,
  );

  const getIsActiveState: any = useSelector(
    ConfigurationManagementSelector.getIsActiveState(),
    shallowEqual,
  );

  const OnDelete = useCallback(() => {
    trackEvent(EVENT_NAME_USER_MANAGEMENT_DEACTIVATED_BUTTON, {});
    dispatch(activeInactiveExpenseTypeSettingCreator(row));
  }, []);

  const getIconColor = (readOnly: boolean) => {
    if (!isWriteAccess || readOnly) {
      return theme.colors.greyish;
    } else if (getIsActiveState) {
      return theme.colors.LIGHT_NAVY_BLUE;
    } else {
      return theme.colors.redish;
    }
  };

  const onDialogClose = () => {
    setIsDialogOpen(false);
  };

  const classes = useStyles();
  useEffect(() => {
    dispatch(filterStateActions.setFilterState({}));
    dispatch(fetchDivisionDataCreator());
    dispatch(fetchDesignationsDropdownCreator());
    dispatch(fetchHQDropdownCreator());
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(filterStateActions.setFilterApplied(false));
      dispatch(configurationManagementStateActions.setExpenseTypeSetting([]));
    };
  }, [dispatch]);

  const pageNumber = useSelector(
    ConfigurationManagementSelector.getPageNo(),
    shallowEqual,
  );
  const totalCount = useSelector(
    ConfigurationManagementSelector.getTotalCount(),
    shallowEqual,
  );

  const pageRow = useSelector(
    ConfigurationManagementSelector.getRowPerPage(),
    shallowEqual,
  );
  const Loading = useSelector(
    ConfigurationManagementSelector.getExpenseTypeLoading()
  );
  const request = useSelector(filterSelector.getFilterOptions());

  const [page, setPage] = useState(pageNumber);
  const [pageSize, setPageSize] = useState(pageRow);

  const onOpenDialogEdit = (e: any) => {
    dispatch(configurationManagementStateActions.setProRataMiscExpenseEdit(e));
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupVisible(
        true,
      ),
    );
    dispatch(
      configurationManagementStateActions.setProRataMiscExpensePopupType(
        'Edit',
      ),
    );
  };

  useEffect(() => {
    setPage(pageNumber);
    setPageSize(pageRow);
  }, [pageNumber, pageRow]);

  useEffect(() => {
    dispatch(configurationManagementStateActions.setPageNo(page));
    dispatch(configurationManagementStateActions.setRowPerPage(pageSize));
  }, [dispatch, page, pageSize]);

  const renderActionsCell = (params: GridRenderCellParams) => {
    if (params?.row) {
      return (
        <TableCell>
          <Button
            data-testid={`IconButton1`}
            sx={Gridstyle.IconButton}
            onClick={() => onOpenDialogEdit(params?.row)}
            disabled={params?.row?.isReadOnly}>
            <EditIcon
              fontSize="small"
              style={{
                color: getIconColor(params?.row?.isReadOnly),
              }}
              sx={{marginLeft: 2}}
            />
          </Button>
        </TableCell>
      );
    }
  };

  const renderProRataCell = (params: GridRenderCellParams) => {
    return (
      <TableCell>
        <Box>{params?.row?.isProRataEnabled ? strings.yes : strings.no}</Box>
      </TableCell>
    );
  };
  const getRowIdHandler = useCallback(function (row: any) {
    return row?.id;
  }, []);

  const PageSizeChange = useCallback(function (newPageSize: any) {
    return setPageSize(newPageSize);
  }, []);
  const PageChange = useCallback(function (newPage: any) {
    return setPage(newPage);
  }, []);

  const renderCell = (params: GridRenderCellParams<any>, field: keyof any) => (
    <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
      {params?.row[field] || ''}
    </p>
  );

  const columnsMap: Record<
    string,
    {
      headerName: string;
      width: number;
      renderCell?: (params: GridRenderCellParams<any>) => JSX.Element;
    }
  > = {
    division: {
      headerName: strings.division,
      width: 120,
      renderCell: params => renderCell(params, 'division'),
    },
    designation: {
      headerName: strings.designation,
      width: 150,
      renderCell: params => renderCell(params, 'designation'),
    },
    hq: {
      headerName: strings.hq,
      width: 120,
      renderCell: params => renderCell(params, 'hq'),
    },
    expenseTypeName: {
      headerName: strings.expenseTypeName,
      width: 180,
      renderCell: params => renderCell(params, 'expenseTypeName'),
    },
    amount: {headerName: strings.amount, width: 120},
    isProRataEnabled: {
      headerName: strings.proRataEnable,
      width: 150,
      renderCell: params => renderProRataCell(params),
    },

    startDate: {
      headerName: strings.effectiveForm,
      width: 150,
      renderCell: params => renderDateCell(params?.row?.startDate),
    },
    endDate: {
      headerName: strings.validUpto,
      width: 120,
      renderCell: params => renderDateCell(params?.row?.endDate),
    },
    actions: {
      headerName: strings.actions,
      width: 120,
      renderCell: params => renderActionsCell(params) ?? <></>,
    },
  };

  const dataColumns = Object.entries(columnsMap)?.map(([field, config]) => ({
    field,
    ...config,
    editable: false,
  }));

  useEffect(() => {
    dispatch(configurationManagementStateActions.setPageNo(page));
    dispatch(configurationManagementStateActions.setRowPerPage(pageSize));
    if (Number(page) * Number(pageSize) > totalCount) {
      dispatch(configurationManagementStateActions.setPageNo(0));
      dispatch(
        filterStateActions.setFilterOptions({
          ...request,
          pageNumber: 0,
          pageLimit: pageSize,
        }),
      );
    } else {
      dispatch(
        filterStateActions.setFilterOptions({
          ...request,
          pageNumber: page,
          pageLimit: pageSize,
        }),
      );
    }

    dispatch(createProRataMiscExpenseCreator(null));
  }, [page, pageSize]);
  const noRowsOverlayComponent: any = useCallback(
    () => (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {strings.noRecordFound}
      </Stack>
    ),
    [],
  );

  utils.OnDelete = OnDelete;
  utils.getIconColor = getIconColor;
  utils.onDialogClose = onDialogClose;
  utils.onOpenDialogEdit = onOpenDialogEdit;
  utils.renderActionsCell = renderActionsCell;
  utils.renderProRataCell = renderProRataCell;
  utils.getRowIdHandler = getRowIdHandler;
  utils.PageSizeChange = PageSizeChange;
  utils.PageChange = PageChange;
  utils.renderCell = renderCell;
  utils.noRowsOverlayComponent = noRowsOverlayComponent;

  return (
    <>
      {' '}
      <Dialog
        open={isDialogOpen}
        onClose={onDialogClose}
        fullWidth={true}
        maxWidth={'sm'}>
        <DialogTitle>
          <Box display={'flex'} justifyContent="space-between">
            <Typography sx={style.headingcss}>{strings.confirm}</Typography>
            <Box>
              <Button onClick={onDialogClose} data-testid="closeButton">
                <ClearOutlinedIcon sx={style.closeIcon} />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <div style={style.dialogContent}>
            <Typography sx={style.labelSizing}>
              {strings.continueMsg}
            </Typography>
          </div>
        </DialogContent>

        <DialogActions>
          <Box className={classes.justifyContentcenterflex}>
            <Button
              data-testid="delete-btn"
              variant="contained"
              sx={style.resetButton}
              onClick={OnDelete}>
              {strings.ok}
            </Button>
            <Button
              variant="contained"
              sx={style.saveButton}
              onClick={onDialogClose}>
              {strings.cancel}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Filter />
      <Box sx={Gridstyle.gridcss}>
        <DataGrid
          columnBuffer={9}
          page={page}
          rowsPerPageOptions={[10, 20, 50, 100]}
          onPageChange={PageChange}
          pagination
          paginationMode="server"
          loading={Loading}
          pageSize={pageSize}
          rows={expenseTypeSetting ?? []}
          rowCount={
            Number(page) * Number(pageSize) > totalCount
              ? Number(page) * Number(pageSize)
              : totalCount
          }
          getRowId={getRowIdHandler}
          columns={dataColumns}
          onPageSizeChange={PageSizeChange}
          components={{
            NoRowsOverlay: noRowsOverlayComponent,
          }}
        />
      </Box>
    </>
  );
};
