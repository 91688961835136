import {createSelector} from '@reduxjs/toolkit';

export const intransistChallanSelector = {
  getIsOpenChallans: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.isOpenChallans],
      state => state,
    );
  },
  getIntransitChallanloading: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.intransitChallanloading],
      state => state,
    );
  },
  getIntransistChallanData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.challanData],
      state => state,
    );
  },
  getAutoSuggestionData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.autoSuggestionData],
      state => state,
    );
  },
  getFilteredData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.filteredData],
      state => state,
    );
  },
  getFilteredDownloadData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.filteredDownloadData],
      state => state,
    );
  },
  getSelectedIntransitChallas: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.selectedChallans],
      state => state,
    );
  },
  getVoucherNumberData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.voucherNumber],
      state => state,
    );
  },
  getRecievedChallanData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.receivedChallans],
      state => state,
    );
  },
  getSearchType: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.searchType],
      state => state,
    );
  },
  getSearchValue: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.searchValue],
      state => state,
    );
  },
  getTotalIntransitChallans: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.totalIntransitChallans],
      state => state,
    );
  },
  getIntransitChallanDivisionData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.divisionData],
      state => state,
    );
  },
  getIntransitChallanDivisionLoader: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.divisionLoading],
      state => state,
    );
  },
  getIntransitChallanDivisionError: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.divisionError],
      state => state,
    );
  },
  getChallanAuditData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.challanAuditData],
      state => state,
    );
  },
  getChallanAuditReport: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.challanAuditReport],
      state => state,
    );
  },
  getChallanPage: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.ChallanPage],
      state => state,
    );
  },
  getChallanReceivesearchData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.challanReceivesearchData],
      state => state,
    );
  },
  getChallanReceivePayload: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.challanReceivePayload],
      state => state,
    );
  },
  getChallanReceiveAutoSuggest: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.challanReceiveAutoSuggest],
      state => state,
    );
  },
  getPage: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.page],
      state => state,
    );
  },
  getChallanClosingReportLoader: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.reportDataLoading],
      state => state,
    );
  },
  getIsActive: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.isActive],
      state => state,
    );
  },
  getSearchTypeAndSearchValue: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan?.searchType],

      state => state,
    );
  },
  getExcelData: () => {
    return createSelector(
      [(state: any) => state?.intransistChallan.excelData],
      state => state,
    );
  },
  getExcelAutoData:() =>{
    return createSelector(
      [(state:any) => state?.intransistChallan.excelAutoData],
      state => state,
    )
  }
};
