import Typography from '@mui/material/Typography';
import {Box} from '@mui/material';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  antSwitch: {
    marginTop: '4px !important',
  },
};

export const ExpectedUtilizationDateNavBar = () => {
  return (
    <Box sx={styles.container}>
      <Typography
        variant="h6"
        noWrap
        component="div"
        color={'#1c1939'}
        fontStyle={'normal'}>
        Expected utilization Date
      </Typography>
    </Box>
  );
};
