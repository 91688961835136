import {filterStateActions} from '@app/components/right-sidebar/redux';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ExpenseDatesGrid} from '../expense-dates-grid/expensedategrid';
import {fetchExpenseTypeDateGridCreator} from '../redux/slice';
import {ConfigurationManagementSelector} from '../redux/seletors';

export const ExpenseDates = () => {
  const dispatch = useDispatch();
  const getExpenseDateData = useSelector(
    ConfigurationManagementSelector.getExpenseTypeDate(),
  );
  useEffect(() => {
    dispatch(fetchExpenseTypeDateGridCreator());
    return () => {
      dispatch(filterStateActions.setFilterState({}));
    };
  }, [dispatch]);

  return (
    <div>
      <ExpenseDatesGrid expdata={getExpenseDateData?.data ?? []} />
    </div>
  );
};
