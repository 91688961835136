import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useEffect, useState} from 'react';
import {CustomModal} from '../widgets/CustomModal';
import {Box} from '@mui/system';
import {useDispatch, useSelector} from 'react-redux';
import GeneralReportTable from './GeneralReportTable';
import {getDifferenceBetweenDates, isAfter} from '@app/utils/dateTimeHelper';
import {challanShortQtyApprovalSelector} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/selectors';
import {
  challanShortQtyApprovalActions,
  challanShortQtyAuditDivisionCreator,
} from '@app/screens/inventory-management/pages/ChallanShortQtyApproval/redux/slice';
import {FilterModalStyles as styles} from './style';
import {YEAR_DATE_FORMAT} from '@app/common/constants';
import dayjs from 'dayjs';

interface Option {
  label: string;
  value: string;
}

export const utility: any = {
  handleDivisionChange: null,
  handleChangeStartDate: null,
  handleChangeEndDate: null,
  disableBeforeStartDate: null,
  handleDeleteselectedChip: null,
};
const GeneralReportFilterModal = ({
  reportModal,
  setReportModal,
  disableButton = true,
  selectedValue = [],
  modalOpen = false,
  start = null,
  end = null,
}: any) => {
  const [startDate, setStartDate] = useState<Date | null>(start);
  const [endDate, setEndDate] = useState<Date | null>(end);
  const [dates, setDates] = useState({startDate: '', endDate: ''});
  const [approveReturnDivisionData, setApproveReturnDivisionData] = useState<
    Array<{label: string; value: string}>
  >([]);
  const [approveReturnDivision, setApproveReturnDivision] =
    useState<Array<{label: string; value: string}>>(selectedValue);
  const [disable, setDisable] = useState(disableButton);
  const [tableModal, setTableModal] = useState(modalOpen);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const shortQuantityAuditDivision = useSelector(
    challanShortQtyApprovalSelector.getChallanSotQTYAuditDivision(),
  );
  const onClose = () => {
    setStartDate(null);
    setEndDate(null);
    setApproveReturnDivision([]);
    setReportModal(false);
  };
  useEffect(() => {
    dispatch(challanShortQtyAuditDivisionCreator());
    return () => {
      dispatch(
        challanShortQtyApprovalActions.setchallanShortQtyAuditDivision([]),
      );
    };
  }, []);

  useEffect(() => {
    if (shortQuantityAuditDivision.length > 0) {
      setApproveReturnDivisionData(shortQuantityAuditDivision);
    }
  });

  const onCloseTable = () => {
    setTableModal(false);
  };

  const handleDivisionChange = (value: any) => {
    setApproveReturnDivision(value);
    setOpen(false);
  };

  const handleChangeStartDate = (newValue: Date | null) => {
    const newValueUTC = newValue ? dayjs.utc(newValue) : null;
    const newDate = newValueUTC?.add(1, 'day')
    // Ensure that you set the startDate state after updating dates
    setStartDate(newValue);
  
    setDates((prevState) => {
      if (newDate) {
        return {...prevState, startDate: newDate?.format(YEAR_DATE_FORMAT)};
      } else {

        return {...prevState, startDate: ''}
      }
    });
    // It seems you want to set the endDate state to null after updating startDate
    setEndDate(null);
  };

  const handleChangeEndDate = (newValue: Date | null) => {
    const newValueUTC = newValue ? dayjs.utc(newValue) : null;
    const newDate = newValueUTC?.add(1, 'day')

    setEndDate(newValue);

    setDates((prevState) => {
      if (newDate) {
        return {...prevState, endDate: newDate?.format(YEAR_DATE_FORMAT)};
      } else {

        return {...prevState, endDate: ''}
      }
    });
  };
  
  useEffect(() => {
    if (
      approveReturnDivision.length < 1 ||
      startDate === null ||
      endDate === null
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [approveReturnDivision, startDate, endDate]);

  const getReports = () => {
    const divisionIds: string[] = [];
    approveReturnDivision.length > 0 &&
      approveReturnDivision.forEach(item => {
        divisionIds.push(item?.value);
      });
    const params: {divisionIds: any[]; startDate: any; endDate: any} = {
      divisionIds: divisionIds,
      startDate: dates.startDate,
      endDate: dates.endDate,
    };

    dispatch(
      challanShortQtyApprovalActions.setChallanShortQtyAuditTrailPayload(
        params,
      ),
    );
    
    setTableModal(true);
  };
  const disableBeforeStartDate = (date: Date) => {
    const diff = getDifferenceBetweenDates(startDate, date);
    if (diff > 90) {
      return true;
    } else {
      return isAfter(startDate, date);
    }
  };

  const handleDeleteselectedChip = (value: string) => {
    const updatedValues = approveReturnDivision.filter(item =>
      typeof item === 'string' ? item !== value : item.value !== value,
    );
    handleDivisionChange(updatedValues);
  };

  utility.disableBeforeStartDate = disableBeforeStartDate;
  utility.handleChangeEndDate = handleChangeEndDate;
  utility.handleChangeStartDate = handleChangeStartDate;
  utility.handleDivisionChange = handleDivisionChange;
  utility.handleDeleteselectedChip = handleDeleteselectedChip;

  const allOption: Option = {
    label: 'Select All',
    value: 'select-all',
  };
  return (
    <Box sx={styles.container}>
      <CustomModal
        header={<Box>View Reports</Box>}
        maxWidth={'md'}
        backdropClick={true}
        open={reportModal}
        fullWidth={true}
        footer={
          <Box display={'flex'} justifyContent="center" sx={styles.Button}>
            <Button
              sx={styles.ViewButton}
              data-testid="Report-Button"
              variant="contained"
              fullWidth
              onClick={() => getReports()}
              disabled={disable}>
              View Report
            </Button>
          </Box>
        }
        footerClass={styles.footer}
        onClose={onClose}>
        <Box sx={styles.container}>
          <Box paddingBottom={2}>
            {approveReturnDivision.length > 0 &&
              approveReturnDivision.map((option, index) => (
                <Chip
                  sx={{
                    margin: 0.5,
                    borderColor: '#322b7c',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    color: '#322b7c',
                  }}
                  style={{
                    backgroundColor: 'white',
                  }}
                  size={'small'}
                  key={option.value}
                  label={option.label}
                  deleteIcon={
                    <IconButton
                      size="small"
                      sx={{'&:hover': {backgroundColor: 'transparent'}}}>
                      <CloseIcon style={{color: '#322b7c'}} />
                    </IconButton>
                  }
                  onDelete={() => handleDeleteselectedChip(option.value)}
                />
              ))}
            <Box flexDirection={'row'} display={'flex'}>
              <Typography color={'#000000de'} fontWeight={'normal'}>
                Division
              </Typography>
              <Typography color={'#FE5B38'}>*</Typography>
            </Box>
            <FormControl sx={styles.formControl} fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                options={[allOption, ...approveReturnDivisionData]}
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.label
                }
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={(_, newValue) => {
                  if (
                    newValue.some(option => option.value === allOption.value)
                  ) {
                    if (
                      approveReturnDivision.length !==
                      approveReturnDivisionData.length
                    ) {
                      handleDivisionChange([...approveReturnDivisionData]);
                    } else {
                      handleDivisionChange([]);
                    }
                  } else {
                    handleDivisionChange(newValue);
                  }
                }}
                data-testid="division-select-dropdown"
                value={approveReturnDivision}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Select Division"
                    variant="outlined"
                    fullWidth
                  />
                )}
                renderTags={() => null}
                isOptionEqualToValue={(option: Option, value: Option) =>
                  option.value === value.value
                }
              />
            </FormControl>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            paddingTop={3}>
            <div>
              <span>
                Start Date<span style={{color: 'red'}}>*</span>
              </span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  data-testid="start-Date"
                  inputFormat={'YYYY/MM/DD'}
                  value={startDate}
                  //inputFormat="yyyy/MM/dd"
                  onChange={handleChangeStartDate}
                  maxDate={dayjs().startOf('day').toDate()}
                  renderInput={params => (
                    <TextField
                      data-testid="EffectiveFromDate"
                      {...params}
                      sx={{width: 390}}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div>
              <span>
                End Date<span style={{color: 'red'}}>*</span>
              </span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={endDate}
                  inputFormat={YEAR_DATE_FORMAT}
                  //label={'End Date'}
                  shouldDisableDate={date => disableBeforeStartDate(date)}
                  onChange={handleChangeEndDate}
                  renderInput={params => (
                    <TextField
                      data-testid="EffectiveFromDate"
                      {...params}
                      sx={{
                        width: 434,
                        '& .MuiInput-root': {
                          position: 'relative', // Ensure relative positioning
                        },
                        '& .MuiPopover-paper': {
                          left: '0 !important',
                          right: 'auto !important',
                          marginLeft: '0 !important', // Adjust this value as needed
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </Box>
        </Box>
      </CustomModal>
      {tableModal && (
        <GeneralReportTable
          onCloseTable={onCloseTable}
          tableModalopen={tableModal}
        />
      )}
    </Box>
  );
};

export default GeneralReportFilterModal;
