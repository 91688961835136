import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Fragment, useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
  CircularProgress,
} from '@mui/material';
import {TabPanelProps} from '../../module-specific-utilities.types';
import {MODULE_SPECIFIC_UTILITIES} from '@app/common/constants';
import ApproveExpenseMiscEditableGrid from './ApproveExpenseMiscEditableGrid';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {popUpStyle} from '@app/components/widgets/popupCss/popUpStyles';
import {useStyles} from '@app/screens/Styles/style';
import {
  deductDaAmountCreator,
  fetchDailyExpenseApprovalsDataCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {saveAs} from 'file-saver';
import moment from 'moment';
import ApproveExpenseDailyGrid from './ApproveExpenseDailyGrid';
import Excel from 'exceljs';
import {ApproveExpenseExceptionSummaryGrid} from './ApproveExpenseExceptionSummaryGrid';
import {ApproveExpenseTabViewDisplayFooter} from './ApproveExpenseTabViewDisplayFooter';
import jsPDF from 'jspdf';
import theme from '@app/themes';
import {styles} from './styles/customStyle';
import {strings} from '@app/common/strings';

export const bgColourFontBold = (worksheet: any) => {
  worksheet.getRow(1).font = {bold: true};
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {argb: theme.colors.silver},
  };
};

export const getTotal = (key: string, expenseApprovalsDaily: any) => {
  const total = expenseApprovalsDaily?.reduce((acc: any, curr: any) => {
    if (!isNaN(parseFloat(curr[key]))) {
      return Number(acc) + Number(curr[key]);
    } else {
      return Number(acc);
    }
  }, 0);

  return parseFloat(total?.toFixed(2));
};

export const fitToColumnExcelJs = (
  worksheet: any,
  data: any,
  divisor?: any,
) => {
  let index = 1;
  for (const property in data[0]) {
    const maxColumnWidth = Math.max(
      property ? property?.toString().length * 12 : 0,
      ...(data?.map((obj: any) =>
        obj[property] ? obj[property]?.toString()?.length * 12 : 0,
      ) || [0]),
    );
    if (property == strings.moduleSpecificUtilities.travelItems) {
      worksheet.getColumn(index).width = 120;
      worksheet.getColumn(index).alignment = {wrapText: true};
    } else {
      worksheet.getColumn(index).width = maxColumnWidth / (divisor || 7);
    }
    index++;
  }
};

function TabPanel(props: Readonly<TabPanelProps>) {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{paddingTop: 2}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const columns = {
  date: 'Date',
  expenseType: 'ExpenseType',
  standard: 'Standard',
  claimed: 'Claimed',
  remarks: 'Remarks',
  managerApprovedAmount: 'Manager Approved Amount',
  ManagerRemarks: 'Manager Remarks',
};
const columnKeys = Object.keys(columns);
const columnValues = Object.values(columns);

const resNestObj = (exceptionSummary: any) => {
  return exceptionSummary?.map((tableObj: any) =>
    columnKeys?.map((key: any) => tableObj[key]),
  );
};

const generatePDF = (
  exceptionSummaryData: any,
  staffName: string,
  sapcode: string,
) => {
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: 'a3',
    putOnlyUsedFonts: true,
  });
  const header = columnValues;
  const data = resNestObj(exceptionSummaryData);
  const marginHeight = 48;
  const name = staffName;
  const StaffSapcode = sapcode;

  const options = {
    margin: {top: marginHeight, left: 10},
    didDrawPage: () => {
      const lineSpacing = 10;
      doc?.text(
        strings.moduleSpecificUtilities.ExceptionSummaryReport,
        120,
        12,
      );
      doc?.setFontSize(10);
      doc?.text(
        `${strings.moduleSpecificUtilities.staffName}  ${name} `,

        10,
        20 + lineSpacing,
      );

      doc?.text(
        `${strings.ModuleSpecificUtilitie.reverseExpensepage.SAP_CODE} : ${StaffSapcode}`,

        10,
        20 + 2 * lineSpacing,
      );
    },
  };
  const columnWidths = header?.map((_, colIndex) => {
    const maxContentWidth = Math.max(
      doc?.getStringUnitWidth(header[colIndex]) * 1.3,
      ...(data ??
        [].map((row: any) => {
          const cellContent = String(row[colIndex]);
          return doc?.getStringUnitWidth(cellContent) * 2;
        })),
    );
    return maxContentWidth;
  });
  const pageCount = (doc as any).internal.getNumberOfPages();

  (doc as any).autoTable({
    head: [columnValues],
    body: data,
    theme: 'grid',
    headStyles: {
      lineWidth: 0.5,
      lineColor: [0, 0, 0, 0.1],
      fillColor: [255, 255, 255],
      textColor: [1, 1, 1, 1],
      fontSize: 7,
      fontStyle: 'bold',
      fontWeight: 'normal',
      halign: 'center',
      padding: '8px',
      border: '1px solid black',
    },
    ...options,
    didParseCell: function (data: any) {
      let rows = data?.table?.body;
      if (data?.row?.index === rows?.length - 1) {
        data.cell.styles.fontStyle = 'bold';
      }
    },
    styles: {
      fontSize: 7,
      halign: 'center',
      textColor: [1, 1, 1, 1],
    },
    columnWidths,
  });

  for (let i = 1; i <= pageCount; i++) {
    doc?.setPage(i);
    doc?.setFontSize(8);
    doc?.text(
      `Page ${i} of ${pageCount}`,
      264,
      doc?.internal?.pageSize?.height - 10,
    );
  }
  doc.save(strings.moduleSpecificUtilities.ExceptionSummarypdf);
};
export const util: any = {
  TabPanel: TabPanel,
  handleClose: null,
  handleChange: null,
  handleExpenseApprovedDialog: null,
  handleExpenseApprovedDialogClose: null,
  handleApproveExpenseDialogOk: null,
  handleChangeDADeductAmount: null,
  handleApproveExpenseDialogCancel: null,
  generatePDF: null,
  downloadExcel: null,
};

export const ApproveExpenseDetailedTabVIew = () => {
  const SelectedExpenseApprovalsUser = useSelector(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);
  const [validation, setValidation] = useState<boolean>(false);
  const setErrorPopupVisible: boolean = useSelector(
    approveExpenseStateSelector?.setErrorPopupVisible(),
  );
  const exceptionSummaryRows = useSelector(
    approveExpenseStateSelector.getExceptionSummaryData(),
  );
  const DADeductModal = useSelector(
    approveExpenseStateSelector?.setDeductTheAmountPopup(),
  );

  const DaDeductAmount = useSelector(
    approveExpenseStateSelector?.getdaAmountToDeduct(),
  );
  const status = useSelector(
    approveExpenseStateSelector.getApproveExpensestatus(),
  );

  const expenseApprovalsDaily: any[] =
    useSelector(approveExpenseStateSelector?.getDailyExpenseApprovalsData()) ??
    [];

  const expenseApprovalsMisc: any[] =
    useSelector(approveExpenseStateSelector?.getMiscExpenseApprovalsData()) ??
    [];

  const expenseApprovalExceptionSummary: any[] =
    useSelector(approveExpenseStateSelector?.getExceptionSummaryData()) ?? [];

  const isDailyGridSaveLoading = useSelector(
    approveExpenseStateSelector.getisSaveExpenseDetailsLoading(),
  );
  const isMiscGridSaveLoading = useSelector(
    approveExpenseStateSelector?.getisSaveMiscDetailsLoading(),
  );
  const isFooterLoading = useSelector(
    approveExpenseStateSelector.getFooterLoading(),
  );
  const isBillSaving = useSelector(
    approveExpenseStateSelector.getBillIsLoading(),
  );
  const dailyExpenseApprovalsLoading = useSelector(
    approveExpenseStateSelector.getDailyExpenseApprovalsLoading(),
  );

  const handleExpenseApprovedDialog = () => {
    dispatch(approveExpenseStateActions?.setDeductTheAmountPopup(true));
    dispatch(approveExpenseStateActions?.setDaAmountToDeduct(0));
  };

  const handleExpenseApprovedDialogClose = () => {
    dispatch(approveExpenseStateActions?.setDeductTheAmountPopup(false));
  };

  const handleApproveExpenseDialogOk = () => {
    if (Number(DaDeductAmount) !== 0) {
      const sum: any = expenseApprovalsDaily?.reduce(
        (accumulator: any, object: any) => {
          return Number(accumulator) + Number(object?.adminPassedDA);
        },
        0,
      );

      let DeductableAmount = Number(DaDeductAmount);
      if (sum < DeductableAmount) {
        dispatch(approveExpenseStateActions?.setErrorPopupVisible(true));
      } else {
        dispatch(deductDaAmountCreator(DeductableAmount));
        dispatch(approveExpenseStateActions?.setDeductTheAmountPopup(false));
      }
    } else {
      setValidation(true);
    }
  };

  const handleClose = () => {
    dispatch(approveExpenseStateActions?.setErrorPopupVisible(false));
  };
  const filteredData = useSelector(approveExpenseStateSelector.getFilterData());

  const handleChange = (_event: any, newValue: number): void => {
    setValue(newValue);
    if (filteredData && SelectedExpenseApprovalsUser && newValue == 0) {
      const requestBody = {
        month: SelectedExpenseApprovalsUser?.expenseMonth,
        year: SelectedExpenseApprovalsUser?.expenseYear,
        staffPositionId: SelectedExpenseApprovalsUser?.staffPositionId,
        userId: SelectedExpenseApprovalsUser?.staffUserId,
      };
      dispatch(fetchDailyExpenseApprovalsDataCreator(requestBody));
    }
  };
  const handleChangeDADeductAmount = (event: any) => {
    dispatch(
      approveExpenseStateActions?.setDaAmountToDeduct(
        event?.target?.value?.replace(/\D/g, ''),
      ),
    );
  };

  const handleApproveExpenseDialogCancel = () => {
    dispatch(approveExpenseStateActions?.setDeductTheAmountPopup(false));
    dispatch(approveExpenseStateActions?.setDaAmountToDeduct(0));
  };

  const getTotalMisc = (key: string) => {
    return expenseApprovalsMisc?.reduce((acc, curr) => {
      if (!isNaN(parseFloat(curr[key]))) {
        return acc + curr[key];
      } else {
        return acc;
      }
    }, 0);
  };

  const getTotalException = (key: string) => {
    return expenseApprovalExceptionSummary?.reduce((acc, curr) => {
      if (!isNaN(parseFloat(curr[key]))) {
        return acc + curr[key];
      } else {
        return acc;
      }
    }, 0);
  };
  const totals = {
    ExpenseDay: '',
    Activity: '',
    FromStation: '',
    ToStation: '',
    HqType: strings.ModuleSpecificUtilitie.approveExpense.Total,
    Distance: getTotal(
      strings.moduleSpecificUtilities.distance,
      expenseApprovalsDaily ?? 0,
    ),
    Ta: getTotal(strings.TA, expenseApprovalsDaily),
    TaClaimed: getTotal(
      strings.moduleSpecificUtilities.taClaimed,
      expenseApprovalsDaily,
    ),
    TaChangeReason: '',
    ManagerPassedTA: getTotal(
      strings.moduleSpecificUtilities.managerPassedTA,
      expenseApprovalsDaily,
    ),
    ManagerChangeReasonTA: '',
    AdminPassedDistance: getTotal(
      strings.moduleSpecificUtilities.adminPassedDistance,
      expenseApprovalsDaily,
    ),
    AdminChangeReasonTA: '',
    AdminPassedTA: getTotal(
      strings.moduleSpecificUtilities.adminPassedTA,
      expenseApprovalsDaily,
    ),
    Da: getTotal(strings.DA, expenseApprovalsDaily),
    DAclaimed: getTotal(
      strings.moduleSpecificUtilities.dAclaimed,
      expenseApprovalsDaily,
    ),
    DAchangeReason: '',
    ManagerPassedDA: getTotal(
      strings.moduleSpecificUtilities.managerPassedDA,
      expenseApprovalsDaily,
    ),
    ManagerPassedReasonDA: '',
    HqTypeAdmin: '',
    AdminChangeReasonDA: '',
    AdminPassedDA: getTotal(
      strings.moduleSpecificUtilities.adminPassedDA,
      expenseApprovalsDaily,
    ),
    WorkWith: '',
    NoOfDoctors: getTotal(
      strings.moduleSpecificUtilities.noOfDoctors,
      expenseApprovalsDaily,
    ),
    NoOfChemists: getTotal(
      strings.moduleSpecificUtilities.noofChemists,
      expenseApprovalsDaily,
    ),
    DcrRemarks: '',
    Remarks: '',
    TaRate: '',
    expenseDate: '',
    travelItems: '',
  };

  const totalMisc = {
    expenseMonth: '',
    expenseYear: '',
    expenseType: strings.ModuleSpecificUtilitie.approveExpense.Total,
    amount: getTotalMisc(strings.amount1),
    amountClaimed: getTotalMisc(strings.amountClaimed),
    changeReason: '',
    amountManagerPassed: getTotalMisc(strings.amountManagerPassed),
    managerChangeReason: '',
    amountAdminPassed: getTotalMisc(strings.amountAdminPassed),
    adminChangeReason: '',
  };

  const totalExceptionSummary = {
    date: '',
    expenseType: strings.ModuleSpecificUtilitie.approveExpense.Total,
    standard: getTotalException(strings.standard),
    claimed: getTotalException(strings.claimed),
    remarks: '',
    ManagerApprovedAmount: getTotalException(
      strings.moduleSpecificUtilities.managerApprovedAmount,
    ),
    ExpenseCategory: '',
  };

  const downloadExcel = () => {
    const workbook = new Excel.Workbook();
    const expenseApprovalsDailyData = expenseApprovalsDaily?.map(
      ({
        staffPositionId,
        id,
        billPath,
        dcrDayCode,
        dcrId,
        expenseDetailId,
        isHoliday,
        isWeekOff,
        staffUserId,
        ...rest
      }) => ({
        ...{
          ExpenseDay: rest?.expenseDay,
          Activity: rest?.activityName,
          FromStation: rest?.fromStation,
          ToStation: rest?.toStation,
          HqType: rest?.hqType,
          Distance: rest?.distance,
          Ta: rest?.ta,
          TaClaimed: rest?.taClaimed,
          TaChangeReason: rest?.taChangeReason,
          ManagerPassedTA: rest?.managerPassedTA,
          ManagerChangeReasonTA: rest?.managerChangeReasonTA,
          AdminPassedDistance: rest?.adminPassedDistance,
          AdminChangeReasonTA: rest?.adminChangeReasonTA,
          AdminPassedTA: rest?.adminPassedTA,
          Da: rest?.da,
          DAclaimed: rest?.dAclaimed,
          DAchangeReason: rest?.dAchangeReason,
          ManagerPassedDA: rest?.managerPassedDA,
          ManagerPassedReasonDA: rest?.managerPassedReasonDA,
          HqTypeAdmin: rest?.hqTypeAdmin,
          AdminChangeReasonDA: rest?.adminChangeReasonDA,
          AdminPassedDA: rest?.adminPassedDA,
          WorkWith: rest?.workWith,
          NoOfDoctors: rest?.noOfDoctors,
          NoOfChemists: rest?.noofChemists,
          ExpenseRemarks: rest?.mainRemark,
          SubRemarks: rest?.wsfaRemarks,
          Remarks: rest?.remarks,
          TaRate: rest?.taRate,
          expenseDate: moment(rest?.expenseDate).format('DD/MM/YY'),
          travelItems:
            rest?.travelItems?.length > 0
              ? JSON.stringify(rest?.travelItems)
              : '',
        },
      }),
    );
    const wsBooks = workbook?.addWorksheet(
      strings.moduleSpecificUtilities.ExpenseApprovalDaily,
    );

    const header = [
      'Expense Day',
      'Activity',
      'From Station',
      'To Station',
      'HQ Type',
      'Distance',
      'TA',
      'TA Claimed',
      'TA Change Reason',
      'Manager Passed TA',
      'Manager Change Reason TA',
      'Admin Passed Distance',
      'Admin Change Reason TA',
      'Admin Passed TA',
      'DA',
      'DA Claimed',
      'DA Change Reason',
      'Manager Passed DA',
      'Manager Passed Reason DA',
      'HQ Type Admin',
      'Admin Change Reason DA',
      'Admin Passed DA',
      'Work With',
      'No Of Doctors',
      'No Of Chemists',
      'Expense Remarks',
      'Sub Remarks',
      'Remarks',
      'TA Rate',
      'Expense Date',
      'Travel Details',
    ];
    wsBooks.addRow(header);
    expenseApprovalsDailyData?.forEach(rowData => {
      wsBooks?.addRow(Object.values(rowData));
    });
    wsBooks?.addRow(Object.values(totals));
    wsBooks.getRow(wsBooks.actualRowCount).font = {bold: true};
    bgColourFontBold(wsBooks);
    fitToColumnExcelJs(wsBooks, [...expenseApprovalsDailyData, totals]);

    // Process the second set of data
    const expenseApprovalsMiscData = expenseApprovalsMisc?.map(
      ({
        dcrExpCode,
        expMiscHistoyId,
        isBusinessExpenditure,
        miscBillPath,
        staffPositionId,
        userId,
        staffWSFACode,
        expenseStatus,
        ...rest
      }) => ({
        ...{
          expenseMonth: rest?.expenseMonth,
          expenseYear: rest?.expenseYear,
          expenseType: rest?.expenseType,
          amount: rest?.amount,
          amountClaimed: rest?.amountClaimed,
          changeReason: rest?.changeReason,
          amountManagerPassed: rest?.amountManagerPassed,
          managerChangeReason: rest?.managerChangeReason,
          amountAdminPassed: rest?.amountAdminPassed,
          adminChangeReason: rest?.adminChangeReason,
        },
      }),
    );

    const wsPersonDetails = workbook?.addWorksheet(
      strings.moduleSpecificUtilities.ExpenseApprovalMisc,
    );

    if (expenseApprovalsMiscData?.length > 0) {
      const header1 = [
        'Expense Month',
        'Expense Year',
        'Expense Type',
        'Amount',
        'Amount Claimed',
        'Change Reason',
        'Amount Manager Passed',
        'Manager Change Reason',
        'Amount Admin Passed',
        'Admin Change Reason',
      ];
      wsPersonDetails?.addRow(header1);

      // Add data
      expenseApprovalsMiscData.forEach(rowData => {
        wsPersonDetails?.addRow(Object.values(rowData));
      });
      wsPersonDetails?.addRow(Object.values(totalMisc));
      wsPersonDetails.getRow(wsPersonDetails?.actualRowCount).font = {
        bold: true,
      };

      bgColourFontBold(wsPersonDetails);
      fitToColumnExcelJs(wsPersonDetails, [
        ...expenseApprovalsMiscData,
        totalMisc,
      ]);
    }
    // exception summary
    const wsExceptionSummary = workbook?.addWorksheet(
      strings.moduleSpecificUtilities.ExceptionSummary,
    );
    if (expenseApprovalExceptionSummary?.length > 0) {
      const expenseApprovalException = expenseApprovalExceptionSummary?.map(
        ({...rest}) => ({
          ...{
            date: moment(rest?.date).format('DD/MM/YY'),
            expenseType: rest?.expenseCategory,
            standard: rest?.standard,
            claimed: rest?.claimed,
            remarks: rest?.expenseType,
            ManagerApprovedAmount: rest?.managerApprovedAmount,
            ManagerRemarks: rest?.managerReason,
          },
        }),
      );
      const header3 = [
        'Date',
        'Expense Type',
        'Standard',
        'Claimed',
        'Remarks',
        'Manager Approved Amount',
        'Manager Remarks',
      ];

      wsExceptionSummary.addRow(header3);
      expenseApprovalException?.forEach(rowData => {
        wsExceptionSummary?.addRow(Object.values(rowData));
      });
      wsExceptionSummary?.addRow(Object.values(totalExceptionSummary));
      wsExceptionSummary.getRow(wsExceptionSummary?.actualRowCount).font = {
        bold: true,
      };

      bgColourFontBold(wsExceptionSummary);
      fitToColumnExcelJs(wsExceptionSummary, [
        ...expenseApprovalException,
        totalExceptionSummary,
      ]);
    }
    // Save the workbook
    workbook?.xlsx
      ?.writeBuffer()
      ?.then(buffer => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const stafffileName = SelectedExpenseApprovalsUser?.staffName;
        const employeesapcode = SelectedExpenseApprovalsUser?.sapCode;

        const fileName = `${stafffileName}_${employeesapcode}_ApproveExpenses.xlsx`;

        saveAs(blob, fileName);
      })
      .catch(error => {
        console.error(error);
      });
  };
  const withTotal = {
    date: null,
    expenseType: strings.ModuleSpecificUtilitie.approveExpense.Total,
    standard: ` ${getTotal(strings.standard, exceptionSummaryRows)}`,
    claimed: ` ${getTotal(strings.claimed, exceptionSummaryRows)}`,
    Remarks: null,
    managerApprovedAmount: `${getTotal(
      strings.moduleSpecificUtilities.managerApprovedAmount,
      exceptionSummaryRows,
    )}`,
    wsfaRemarks: null,
  };
  let data = expenseApprovalExceptionSummary?.map(({...rest}) => ({
    ...{
      date: moment(rest?.date).format('DD/MM/YY'),
      expenseType: rest?.expenseCategory,
      standard: rest?.standard,
      claimed: rest?.claimed,
      remarks: rest?.expenseType,
      managerApprovedAmount: rest?.managerApprovedAmount,
      ManagerRemarks: rest?.managerReason,
    },
  }));
  let exceptionSummaryRowsWithSum = [...data, withTotal];
  util.TabPanel = TabPanel;
  util.handleClose = handleClose;
  util.handleChange = handleChange;
  util.handleExpenseApprovedDialog = handleExpenseApprovedDialog;
  util.handleExpenseApprovedDialogClose = handleExpenseApprovedDialogClose;
  util.handleApproveExpenseDialogOk = handleApproveExpenseDialogOk;
  util.handleChangeDADeductAmount = handleChangeDADeductAmount;
  util.handleApproveExpenseDialogCancel = handleApproveExpenseDialogCancel;
  util.downloadExcel = downloadExcel;
  util.generatePDF = generatePDF;

  return (
    <>
      <Box sx={{position: 'absolute', top: '-60px', width: '100%'}}>
        <div className={classes.topHeader}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="approve expense tabs"
              className={classes.headerTab}>
              <Tab
                className={classes.tabs}
                label={
                  MODULE_SPECIFIC_UTILITIES?.APPROVE_EXPENSE?.exceptionSummary
                }
              />
              <Tab
                className={classes.tabs}
                label={MODULE_SPECIFIC_UTILITIES?.APPROVE_EXPENSE?.dailyExpense}
              />
              <Tab
                className={classes.tabs}
                label={
                  MODULE_SPECIFIC_UTILITIES?.APPROVE_EXPENSE?.miscellaneous
                }
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={1}>
            <Box
              className={classes.dailogapproveExpenseTab}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}>
              <Button
                onClick={handleExpenseApprovedDialog}
                size="small"
                variant="contained"
                disabled={status?.expenseStatusId !== 9}>
                {strings.moduleSpecificUtilities.DeductDAAmount}
              </Button>
              <Button
                data-testid="downloadBills-button"
                variant="contained"
                sx={{m: 3}}
                onClick={downloadExcel}>
                {strings.DownloadExcel}
              </Button>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={0}>
            <Box sx={styles.flexStartCenter}>
              {value === 0 && exceptionSummaryRows?.length !== 0 ? (
                <>
                  <Button
                    data-testid="generate-pdf"
                    variant="contained"
                    sx={{m: 3}}
                    onClick={() => {
                      generatePDF(
                        exceptionSummaryRowsWithSum,
                        SelectedExpenseApprovalsUser?.staffName,
                        SelectedExpenseApprovalsUser?.sapCode,
                      );
                    }}>
                    {strings.ModuleSpecificUtilitie.DownloadPdf}
                  </Button>
                  <Button
                    data-testid="downloadBills-button"
                    variant="contained"
                    sx={{m: 3}}
                    onClick={downloadExcel}>
                    {strings.DownloadExcel}
                  </Button>
                </>
              ) : (
                <></>
              )}
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box sx={styles.flexStartCenter}>
              <Button
                data-testid="downloadBills-button"
                variant="contained"
                sx={{m: 3}}
                onClick={downloadExcel}>
                {strings.DownloadExcel}
              </Button>
            </Box>
          </TabPanel>
        </div>
        <TabPanel value={value} index={0}>
          <ApproveExpenseExceptionSummaryGrid />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {isDailyGridSaveLoading ||
          isFooterLoading ||
          dailyExpenseApprovalsLoading ? (
            <Box className={classes.justifyContentcenterflex}>
              <CircularProgress />
            </Box>
          ) : (
            <Fragment>
              <ApproveExpenseDailyGrid />
              <ApproveExpenseTabViewDisplayFooter />
            </Fragment>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {(isMiscGridSaveLoading || isFooterLoading) &&
          isBillSaving === null ? (
            <Box className={classes.justifyContentcenterflex}>
              <CircularProgress />
            </Box>
          ) : (
            <Fragment>
              <ApproveExpenseMiscEditableGrid />
              <ApproveExpenseTabViewDisplayFooter />
            </Fragment>
          )}
        </TabPanel>
      </Box>
      <Dialog
        open={DADeductModal}
        onClose={handleExpenseApprovedDialogClose}
        maxWidth="sm"
        fullWidth>
        <DialogTitle>
          {strings.moduleSpecificUtilities.DeductDAAmount}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="expense-approve-admin"
            label="Enter Amount"
            fullWidth
            value={DaDeductAmount ?? ''}
            variant="filled"
            onChange={handleChangeDADeductAmount}
          />
          {validation && (
            <span style={styles.invalidAmount}>{strings.InvalidAmount}</span>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            sx={popUpStyle.saveButton}
            onClick={handleApproveExpenseDialogOk}>
            {strings.ok}
          </Button>
          <Button
            sx={popUpStyle.saveButton}
            onClick={handleApproveExpenseDialogCancel}>
            {strings.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={setErrorPopupVisible}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText
            className={classes.balckClolor}
            mt={1}
            mb={2}
            id="alert-dialog-description">
            {strings.moduleSpecificUtilities.insufficientFund}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleClose}>
            {strings.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
