import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Typography,
  FormControl,
  TextField,
  Button,
  Stack,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import {ApproveExpenseModal} from './sub-components/ApproveExpenseModal';
import {RejectExpenseModal} from './sub-components/RejectExpenseModal';
import {useState} from 'react';
import {styles} from '@app/screens/Styles/style';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import {styles as customStyle} from './styles/customStyle';
import {strings} from '@app/common/strings';
import theme from '@app/themes';

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: 'transparent',
  ...theme.typography.body2,
  textAlign: 'left',
  boxShadow: 'none',
  color: theme.palette.text.secondary,
  justifyContent: 'space-between',
  display: 'flex',
}));
export const util: any = {
  handleApproveOpen: null,
  handleRejectOpen: null,
};
export const ApproveExpenseTabViewDisplayFooter = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  // selected row from staff dropdown
  const adminExpenseSelectedRow = useSelector(
    approveExpenseStateSelector?.getSelectedExpenseApprovalsUser(),
  );

  const footer = useSelector(approveExpenseStateSelector?.getFooterDatas());

  const dailyEditedRowColorIndex =
    useSelector(approveExpenseStateSelector?.setDailyEditedRowColorIndex()) ??
    [];
  const dailyEditedRowColorIndexArr1 =
    useSelector(
      approveExpenseStateSelector?.getDailyEditedRowColorIndexArr1(),
    ) ?? [];
  //   daily api count data
  const dailys = useSelector(approveExpenseStateSelector?.getDailyCountData());

  const miscEditedRowColorIndex =
    useSelector(approveExpenseStateSelector?.getMiscEditedRowColorIndex()) ??
    [];
  const handleApproveOpen = () => {
    if (
      dailyEditedRowColorIndex?.length > 0 ||
      dailyEditedRowColorIndexArr1?.length > 0 ||
      miscEditedRowColorIndex?.length > 0
    ) {
      setOpen(true);
    } else {
      dispatch(approveExpenseStateActions?.setOpenApproveExpenseModal(true));
    }
  };

  const handleRejectOpen = () => {
    dispatch(approveExpenseStateActions?.setOpenRejectExpenseModal(true));
  };

  const status = useSelector(
    approveExpenseStateSelector?.getApproveExpensestatus(),
  );
  const handleClose = () => {
    setOpen(false);
  };

  const dailyE = () => {
    if (dailys?.Ex) {
      return dailys?.E ? dailys?.E + dailys?.Ex : dailys?.Ex;
    } else {
      return dailys?.E;
    }
  };
  const dailyO = () => {
    if (dailys?.Out) {
      return dailys?.O ? dailys?.O + dailys?.Out : dailys?.Out;
    } else {
      return dailys?.O;
    }
  };
  const dailyH = () => {
    if (dailys?.Home) {
      return dailys?.H ? dailys?.H + dailys?.Home : dailys?.Home;
    } else {
      return dailys?.H;
    }
  };

  const totalDistance = () => {
    if (dailys?.adminPassedDistance)
      return dailys?.adminPassedDistance?.toFixed(0);
    else {
      return adminExpenseSelectedRow?.totalDistance?.toFixed(0);
    }
  };
  const ApproveAndRejectButtonEnableFunction = () => {
    if (status?.expenseStatusId == 9) return false;
    else {
      return true;
    }
  };
  util.handleApproveOpen = handleApproveOpen;
  util.handleRejectOpen = handleRejectOpen;

  return (
    <Box sx={{mb: 1, pr: 1, pl: 1, marginTop: 3}}>
      <Grid container spacing={1}>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {strings.ModuleSpecificUtilitie.approveExpense.TotalTA}
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles?.textFieldReadOnly}
                  value={footer?.tasystemTotalAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalTAPassedManager
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.managerApprovedTaAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {strings.ModuleSpecificUtilitie.approveExpense.TotalDA}
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.dasystemTotalAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalDAPassedManager
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.managerApprovedDaAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {strings.ModuleSpecificUtilitie.approveExpense.MiscAmt}
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.miscSystemTotalAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalMiscAmtPassedManager
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.managerApprovedMiscAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={[styles.labelStyles, styles.boldLabel]}>
                {strings.ModuleSpecificUtilitie.approveExpense.Total}
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnlyBold}
                  value={footer?.totalSystemAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={[styles.labelStyles, styles.boldLabel]}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalAmtPassedManager
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnlyBold}
                  value={footer?.managerApprovedTotalAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {strings.ModuleSpecificUtilitie.approveExpense.TotalTAClaim}
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.tacustomTotalAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalTAPassedAdmin
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  name="adminApprovedTaAmount"
                  value={footer?.adminApprovedTaAmount?.toFixed(0)}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnlyYellow}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {strings.ModuleSpecificUtilitie.approveExpense.TotalDAClaim}
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.dacustomTotalAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalDAPassedAdmin
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  name="adminApprovedDaAmount"
                  value={footer?.adminApprovedDaAmount?.toFixed(0)}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnlyYellow}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {strings.ModuleSpecificUtilitie.approveExpense.MiscAmtClaimed}
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnly}
                  value={footer?.miscCustomTotalAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={styles.labelStyles}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalMiscPassedAdmin
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  name="adminApprovedMiscAmount"
                  value={footer?.adminApprovedMiscAmount?.toFixed(0)}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnlyYellow}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.25}>
          <Stack spacing={1}>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={[styles.labelStyles, styles.boldLabel]}>
                {strings.ModuleSpecificUtilitie.approveExpense.TotalAmtClaimed}
              </Typography>
              <FormControl>
                <TextField
                  data-testId="footer1"
                  variant="outlined"
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnlyBold}
                  value={footer?.totalClaimedAmount?.toFixed(0)}
                />
              </FormControl>
            </Box>
            <Box sx={styles.textFieldContainerStyles}>
              <Typography sx={[styles.labelStyles, styles.boldLabel]}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .TotalAmtPassedAdmin
                }
              </Typography>
              <FormControl>
                <TextField
                  variant="outlined"
                  size="small"
                  name="adminApprovedTotalAmount"
                  value={footer?.adminApprovedTotalAmount?.toFixed(0)}
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={styles.textFieldReadOnlyBoldYellow}
                />
              </FormControl>
            </Box>
          </Stack>
        </Grid>
        <Grid item md={1.8}>
          <Stack spacing={1}>
            <Box sx={styles.fieldSetStyles}>
              <fieldset>
                <legend style={{fontSize: '12px'}}>
                  {strings.ModuleSpecificUtilitie.approveExpense.PassedByAdmin}
                </legend>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div>
                    <Item>
                      {strings.hq} : {dailys?.H ? dailyH() : 0}
                    </Item>
                    <Item>
                      {strings.ModuleSpecificUtilitie.approveExpense.Ex} :{' '}
                      {dailys?.E ? dailyE() : 0}
                    </Item>
                    <Item>
                      {strings.ModuleSpecificUtilitie.approveExpense.out} :{' '}
                      {dailys?.O ? dailyO() : 0}
                    </Item>
                    <Item
                      sx={{
                        fontWeight: 'bolder',
                        color: theme.colors.fontcolor,
                      }}>
                      {
                        strings.ModuleSpecificUtilitie.approveExpense
                          .TotalDistance
                      }
                      : {totalDistance()}
                    </Item>
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      top: '17px',
                      width: '100%',
                      right: '19px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-end',
                    }}>
                    <Item>
                      <Button
                        data-testId="button"
                        sx={[{marginBottom: 0.8}, styles.btn]}
                        variant="contained"
                        disabled={ApproveAndRejectButtonEnableFunction()}
                        onClick={handleApproveOpen}>
                        {strings.ModuleSpecificUtilitie.approveExpense.Approve}
                      </Button>
                      <ApproveExpenseModal />
                    </Item>
                    <Item>
                      <Button
                        variant="contained"
                        sx={styles.btn}
                        disabled={ApproveAndRejectButtonEnableFunction()}
                        onClick={handleRejectOpen}>
                        {strings.ModuleSpecificUtilitie.approveExpense.Reject}
                      </Button>
                      <RejectExpenseModal />
                    </Item>
                  </div>
                </div>
              </fieldset>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle sx={customStyle.errorDialogBox}>
          <ErrorIcon sx={customStyle.errorIcon}></ErrorIcon>
        </DialogTitle>
        <CloseIcon
          aria-label="close"
          onClick={handleClose}
          sx={[
            {
              right: 8,
              top: 8,
            },
            customStyle.closeIcon,
          ]}
        />

        <DialogContent dividers>
          {dailyEditedRowColorIndex?.length > 0 && (
            <>
              <Typography sx={customStyle.adminChangeTaReason}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .ADMINCHANGEREASONTA
                }
              </Typography>
              <Typography sx={customStyle.font12}>
                {strings.ModuleSpecificUtilitie.approveExpense.Missingreason}{' '}
                {dailyEditedRowColorIndex?.toString()}
              </Typography>
            </>
          )}
          {dailyEditedRowColorIndexArr1?.length > 0 && (
            <>
              <Typography sx={customStyle.adminChangeReason}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .ADMINCHANGEREASONDA
                }
              </Typography>
              <Typography sx={customStyle.font12}>
                {strings.ModuleSpecificUtilitie.approveExpense.Missingreason}
                {dailyEditedRowColorIndexArr1?.toString()}
              </Typography>
            </>
          )}
          {miscEditedRowColorIndex?.length > 0 && (
            <>
              <Typography sx={customStyle.adminChangeReason}>
                {
                  strings.ModuleSpecificUtilitie.approveExpense
                    .CHANGEREASONBYADMIN
                }
              </Typography>
              <Typography sx={customStyle.font12}>
                {strings.ModuleSpecificUtilitie.approveExpense.msg}
              </Typography>
            </>
          )}
          <Typography sx={customStyle.remarksText}>
            {strings.ModuleSpecificUtilitie.approveExpense.msg1}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {strings.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
