import {Provider} from 'react-redux';
import {getStore} from '@app/store';
import {AppRouter} from '@app/router/AppRouter';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ErrorBoundary} from './ErrorBoundary';


export const store = getStore();

export const themeOptions: any = {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 800,
          color: '#322b7c',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontWeight: '600',
          fontStyle: 'normal',
          letterSpacing: 0,
          textAlign: 'center',
          fontSize: 12.7,
        },
      },
    },
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
    fontSize: 13,
    leftSideBar: {
      color: '#534e91',
    },
    rightSideBar: {
      color: '#534e91',
    },
  },
  backgroundColors: {
    leftSidebar: '#f5f8f9',
  },
  palette: {
    primary: {
      main: '#322b7c',
    },
    title: {main: '#322b7c'},
    white: {
      main: '#ffffff',
    },

  },
 
};
export const DialogProps = { 
  sx: {
 marginLeft:'22px !important'
  },

};

const theme = createTheme(themeOptions);
function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
