import {createSelector} from '@reduxjs/toolkit';

export const userManagementStateSelector = {
  getAdminUsers: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsers],
      adminUsers => adminUsers,
    );
  },
  getAdminUsersLoading: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersLoading],
      adminUsersLoading => adminUsersLoading,
    );
  },
  getAdminUsersError: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersError],
      adminUsersError => adminUsersError,
    );
  },
  getAdminUsersSuccess: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersSuccess],
      adminUsersSuccess => adminUsersSuccess,
    );
  },
  getAdminUsersCount: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersCount],
      adminUsersCount => adminUsersCount,
    );
  },
  getAdminUsersErrorMessage: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersErrorMessage],
      adminUsersErrorMessage => adminUsersErrorMessage,
    );
  },
  getIsActiveState: () => {
    return createSelector(
      [(state: any) => state.userManagement.isActiveState],
      isActiveState => isActiveState,
    );
  },
  getUserSearchText: () => {
    return createSelector(
      [(state: any) => state.userManagement.userSearchText],
      userSearchText => userSearchText,
    );
  },
  getDivisionDropDown: () => {
    return createSelector(
      [(state: any) => state.userManagement.divisionDropDown],
      divisionDropDown => divisionDropDown,
    );
  },
  getStateDropDown: () => {
    return createSelector(
      [(state: any) => state.userManagement.stateDropDown],
      stateDropDown => stateDropDown,
    );
  },
  getAccessGroupDropDown: () => {
    return createSelector(
      [(state: any) => state.userManagement.accessGroupDropDown],
      accessGroupDropDown => accessGroupDropDown,
    );
  },
  getEditUserSuccess: () => {
    return createSelector(
      [(state: any) => state.userManagement.editUserSuccess],
      editUserSuccess => editUserSuccess,
    );
  },
  getEditUserError: () => {
    return createSelector(
      [(state: any) => state.userManagement.editUserError],
      editUserError => editUserError,
    );
  },
  getEditUserErrorMessage: () => {
    return createSelector(
      [(state: any) => state.userManagement.editUserErrorMessage],
      editUserErrorMessage => editUserErrorMessage,
    );
  },
  getEditUserLoading: () => {
    return createSelector(
      [(state: any) => state.userManagement.editUserLoading],
      editUserLoading => editUserLoading,
    );
  },
  getAddUserSuccess: () => {
    return createSelector(
      [(state: any) => state.userManagement.addUserSuccess],
      addUserSuccess => addUserSuccess,
    );
  },
  getAddUserError: () => {
    return createSelector(
      [(state: any) => state.userManagement.addUserError],
      addUserError => addUserError,
    );
  },
  getAddUserErrorMessage: () => {
    return createSelector(
      [(state: any) => state.userManagement.addUserErrorMessage],
      addUserErrorMessage => addUserErrorMessage,
    );
  },
  getAddUserLoading: () => {
    return createSelector(
      [(state: any) => state.userManagement.addUserLoading],
      addUserLoading => addUserLoading,
    );
  },
  getDisableUserSuccess: () => {
    return createSelector(
      [(state: any) => state.userManagement.disableUserSuccess],
      disableUserSuccess => disableUserSuccess,
    );
  },
  getDisableUserError: () => {
    return createSelector(
      [(state: any) => state.userManagement.disableUserError],
      disableUserError => disableUserError,
    );
  },
  getDisableUserErrorMessage: () => {
    return createSelector(
      [(state: any) => state.userManagement.disableUserErrorMessage],
      disableUserErrorMessage => disableUserErrorMessage,
    );
  },
  getDisableUserLoading: () => {
    return createSelector(
      [(state: any) => state.userManagement.disableUserLoading],
      disableUserLoading => disableUserLoading,
    );
  },
  getEditUserPopupVisible: () => {
    return createSelector(
      [(state: any) => state.userManagement.editUserPopupVisible],
      editUserPopupVisible => editUserPopupVisible,
    );
  },
  getEditUserValue: () => {
    return createSelector(
      [(state: any) => state.userManagement.editUserValue],
      editUserValue => editUserValue,
    );
  },
  getAddUserPopupVisible: () => {
    return createSelector(
      [(state: any) => state.userManagement.addUserPopupVisible],
      addUserPopupVisible => addUserPopupVisible,
    );
  },
  getAddUserValue: () => {
    return createSelector(
      [(state: any) => state.userManagement.addUserValue],
      addUserValue => addUserValue,
    );
  },
  getAdminUsersAutosuggestData: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersAutosuggestData],
      adminUsersAutosuggestData => adminUsersAutosuggestData,
    );
  },
  getAdminUsersAutosuggestLoading: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersAutosuggestLoading],
      adminUsersAutosuggestLoading => adminUsersAutosuggestLoading,
    );
  },
  getAdminUsersAutosuggestError: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersAutosuggestError],
      adminUsersAutosuggestError => adminUsersAutosuggestError,
    );
  },
  getAdminUsersAutosuggestErrorMessage: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersAutosuggestErrorMessage],
      adminUsersAutosuggestErrorMessage => adminUsersAutosuggestErrorMessage,
    );
  },
  getAdminUsersAutosuggestSuccess: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersAutosuggestSuccess],
      adminUsersAutosuggestSuccess => adminUsersAutosuggestSuccess,
    );
  },
  getSelectedAdminUserInAutoSuggest: () => {
    return createSelector(
      [(state: any) => state.userManagement.selectedAdminUserInAutoSuggest],
      selectedAdminUserInAutoSuggest => selectedAdminUserInAutoSuggest,
    );
  },

  getAdminAddUsersAutosuggestData: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminAddUsersAutosuggestData],
      adminAddUsersAutosuggestData => adminAddUsersAutosuggestData,
    );
  },
  getAdminAddUsersAutosuggestLoading: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminAddUsersAutosuggestLoading],
      adminAddUsersAutosuggestLoading => adminAddUsersAutosuggestLoading,
    );
  },
  getAdminAddUsersAutosuggestError: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminAddUsersAutosuggestError],
      adminAddUsersAutosuggestError => adminAddUsersAutosuggestError,
    );
  },
  getAdminAddUsersAutosuggestErrorMessage: () => {
    return createSelector(
      [
        (state: any) =>
          state.userManagement.adminAddUsersAutosuggestErrorMessage,
      ],
      adminAddUsersAutosuggestErrorMessage =>
        adminAddUsersAutosuggestErrorMessage,
    );
  },
  getAdminAddUsersAutosuggestSuccess: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminAddUsersAutosuggestSuccess],
      adminAddUsersAutosuggestSuccess => adminAddUsersAutosuggestSuccess,
    );
  },
  getSelectedAdminAddUserInAutoSuggest: () => {
    return createSelector(
      [(state: any) => state.userManagement.selectedAdminAddUserInAutoSuggest],
      selectedAdminAddUserInAutoSuggest => selectedAdminAddUserInAutoSuggest,
    );
  },
  getAdminUsersAutosuggestSearchText: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersAutosuggestSearchText],
      adminUsersAutosuggestSearchText => adminUsersAutosuggestSearchText,
    );
  },

  getAdminUsersSnaShot: () => {
    return createSelector(
      [(state: any) => state.userManagement.adminUsersSnaShot],
      adminUsersSnaShot => adminUsersSnaShot,
    );
  },
  getResetNavbarVersion: () => {
    return createSelector(
      [(state: any) => state.userManagement.resetNavbarVersion],
      resetNavbarVersion => resetNavbarVersion,
    );
  },
};
