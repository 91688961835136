import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DialogContent from '@mui/material/DialogContent';
import {
  List,
  ListItem,
  Collapse,
  IconButton,
  Typography,
  Link,
  Box,
  CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import CollapsibleContent from './CollapsibleContent';
import {
  deleteBillCreator,
  fetchDailyExpenseApprovalsDataCreator,
  fetchDailyExpensePopupReadOnlyData,
  fetchDailyExpensePopupTADAData,
  fetchMiscExpenseApprovalsDataCreator,
  footerDataCreator,
  saveSupportingDocCreator,
  submitBillStatusCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {fileDownload} from './PreviewDialog';
import {styles} from '../BillPopup/billsStyles';
import {strings} from '@app/common/strings';
import theme from '@app/themes';
import {ConfirmationPopup} from '@app/components/confirmationPopup/confirmationPopup';

export const util: any = {
  onCloseClick: null,
  getFileTypeWeb: null,
  base64DownloaderWeb: null,
  handleDelete: null,
  handleDownload: null,
  handleAccordionClick: null,
  onSubmitBtn: null,
  changeHandler: null,
  handlePreview: null,
  closeDialog: null,
  handelDeleteClick: null,
  renderSuppDocs: null,
  miscBills: null,
  isvalueChanged: null,
  checkAndRender: null,
};

export const previewInNewWindow = (
  fileType: string,
  base: string,
  fileName: string,
  isAddNewBill?: boolean,
) => {
  const fileBase = isAddNewBill ? base : `data:application/pdf;base64,${base}`;
  const newWindow = window.open('', '_blank', 'width=850,height=600');
  if (newWindow) {
    const isPDF = fileType === 'application/pdf';
    const newWindowContent = `
    <html>
      <head>
        <title>Preview Bill</title>
      </head>
      <body style="margin:0; padding:0; text-align: center;">
        <div style="margin-top: 20px;">
          <h3 style="width: 70%; margin: 0 auto 10px; word-wrap: break-word;">File Name: ${fileName}</h3>
          ${
            isPDF
              ? `<iframe src="${fileBase}" style="width:95vw; height:87%; object-fit: contain;" alt="Preview"></iframe>`
              : `<img src="${fileBase}" style="width:95vw; height:87%; object-fit: contain;" alt="Preview" />`
          }
        </div>
      </body>
    </html>`;
    newWindow?.document.write(newWindowContent);
  }
};

export const ViewBillPopup: React.FC<{
  open?: boolean;
  onClose?: () => void;
  isComingFromDailyGrid?: boolean;
  expenseId?: boolean | string;
  setExpenseId?: (id: string | number | null) => void;
}> = ({open, onClose, isComingFromDailyGrid, expenseId, setExpenseId}) => {
  const billStyles = styles();
  const dispatch = useDispatch();
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] =
    React.useState(false);
  const [currentSelectedBill, setCurrentSelectedBill] = React.useState(null);
  const [uploadedFileData, setUploadedFileData] = useState<any>([]);
  const [currentOpenedBill, setCurrentOpenedBill] = useState<any>(null);

  const isViewBillPopupVisible = useSelector(
    approveExpenseStateSelector.getViewBillPopupVisible(),
  );
  const isLoading = useSelector(approveExpenseStateSelector.getBillIsLoading());

  const billDetails: any = useSelector(
    approveExpenseStateSelector.getBillDetails(),
  );

  const status = useSelector(
    approveExpenseStateSelector.getApproveExpensestatus(),
  );

  const isBillDeleted = useSelector(
    approveExpenseStateSelector.getIsBillDeleted(),
  );
  const isSupDocSubmitted = useSelector(
    approveExpenseStateSelector.getIsSupportingDocSubmitted(),
  );
  const taDaPopupVisible = useSelector(
    approveExpenseStateSelector.getTaDaPopupVisible(),
  );
  const selectedExpenseApprovalsUser = useSelector(
    approveExpenseStateSelector.getSelectedExpenseApprovalsUser(),
  );

  const handleAccordionClick = (index: number, icon = false) => {
    setCurrentOpenedBill(index);
    isBillDeleted &&
      dispatch(approveExpenseStateActions.setIsBillDeleted(false));
    const temp = uploadedFileData?.slice();
    if (icon && !temp[index]?.expanded) return;
    temp[index].expanded = !temp[index]?.expanded;
    setUploadedFileData(temp);
    dispatch(approveExpenseStateActions.setInvoiceStatus(null));
  };

  const isvalueChanged = (
    invoiceNum?: boolean,
    vendorName?: boolean,
    amount?: boolean,
  ) => {
    return (
      uploadedFileData?.length > 0 &&
      uploadedFileData?.every((obj1: any, i: number) => {
        if (invoiceNum) {
          return obj1?.invoiceNumber === billDetails?.bills[i]?.invoiceNumber;
        } else if (vendorName) {
          return obj1?.vendor === billDetails?.bills[i]?.vendor;
        } else if (amount) {
          return obj1?.billAmount === billDetails?.bills[i]?.billAmount;
        }
      })
    );
  };

  React.useEffect(() => {
    dispatch(approveExpenseStateActions.setIsBillDeleted(false));
    const billsArray = billDetails?.bills?.map((item: any, i: number) => ({
      ...item,
      expanded: false,
    }));
    setUploadedFileData(billsArray);
    return () => {
      dispatch(approveExpenseStateActions.setexpenseApprovalsViewBillData([]));
    };
  }, [
    isViewBillPopupVisible,
    isBillDeleted,
    isSupDocSubmitted,
    ...(billDetails?.bills?.map((bill: any) => bill?.supportingBills?.length) ||
      []),
    isvalueChanged(true),
    isvalueChanged(false, true),
    isvalueChanged(false, false, true),
  ]);

  const getPageAndRequestId = (taDaMiscId: any) => {
    const requestId =
      isComingFromDailyGrid && !taDaPopupVisible ? expenseId : taDaMiscId;
    const page = isComingFromDailyGrid && !taDaPopupVisible ? 'daily' : 'misc';
    return {requestId, page};
  };

  const handelDeleteClick = (
    billIds: any,
    supportingDocId?: number | boolean,
  ) => {
    const {requestId, page} = getPageAndRequestId(billIds?.tadaMiscId);
    let body;
    let request = {
      id: requestId,
      page,
    };
    if (!supportingDocId) {
      body = {
        tadaMiscId: billIds?.tadaMiscId,
        billId: billIds?.id,
        supportingBillsId: null,
      };
    } else {
      body = {
        tadaMiscId: billIds?.tadaMiscId,
        billId: billIds?.id,
        supportingBillsId: [supportingDocId],
      };
    }
    dispatch(deleteBillCreator({body, request}));
    setIsDeleteConfirmPopupOpen(false);
  };

  const onSubmitBtn = (
    updatedBill: any,
    id: number,
    date: any,
    fileName: any,
    tadaMiscId: any,
    remarks: any,
  ) => {
    const {
      invoiceNumber,
      vendor,
      invoiceDate,
      invoiceAmt,
      isBillVisible,
      isAdminUploaded,
    } = updatedBill;
    changeHandler(
      invoiceDate,
      vendor,
      invoiceAmt,
      invoiceNumber,
      false,
      null,
      fileName,
      id,
      isBillVisible,
      isAdminUploaded,
      tadaMiscId,
      remarks,
    );
  };

  const changeHandler = (
    date: any,
    vendorName: string,
    billAmt: string,
    invoiceNumber: any,
    isSupportingDoc?: boolean,
    supportingDoc?: any,
    ...rest: any
  ) => {
    const [
      fileName,
      id,
      isBillVisible,
      isAdminUploaded,
      tadaMiscId,
      remarks = '',
    ] = rest;
    const formattedDate = new Date(date);
    const formData = new FormData();
    if (isSupportingDoc) {
      formData.append('BillId', id);
      formData.append('File', supportingDoc);
      formData.append('FileName', fileName);
      formData.append('FilesBase64String', 'test');
      formData.append('IsBase64StringContent', 'false');
      if (supportingDoc) {
        dispatch(saveSupportingDocCreator(formData));
      }
      return;
    }
    formData.append('id', id);
    formData.append('TADAMiscId', tadaMiscId);
    formData.append(
      'Date',
      `${formattedDate?.getFullYear()}-${
        formattedDate?.getMonth() + 1
      }-${formattedDate?.getDate()}`,
    );
    formData.append('InvoiceNumber', invoiceNumber);
    formData.append('Vendor', vendorName);
    formData.append('BillAmount', billAmt);
    formData.append('IsBillVisible', isBillVisible ? 'true' : 'false');
    formData.append('IsAdminUploaded', isAdminUploaded ? 'true' : 'false');
    formData.append('FileName', fileName);
    formData.append('IsAdminModified', 'true');
    formData.append('IsBase64StringContent', 'false');
    formData.append('FilesBase64String', 'null');
    remarks.length > 1 && formData.append('Remarks', remarks);
    const {requestId, page} = getPageAndRequestId(tadaMiscId);
    if (fileName) {
      let request = {
        id: requestId,
        page,
      };
      dispatch(submitBillStatusCreator({formData, request}));
    }
  };

  const getFileTypeWeb = (fileName: any) => {
    if (fileName?.includes('pdf') || fileName?.includes('PDF')) {
      return 'application/pdf';
    } else if (fileName?.includes('png') || fileName?.includes('PNG')) {
      return 'image/png';
    } else if (fileName?.includes('jpg') || fileName?.includes('JPG')) {
      return 'image/jpg';
    } else if (fileName?.includes('jpeg') || fileName?.includes('JPEG')) {
      return 'image/jpg';
    } else {
      return '';
    }
  };

  const base64DownloaderWeb = (base64String: any, file: any) => {
    const linkSource = `data:${getFileTypeWeb(file)};base64,${base64String}`;
    const downloadLink = document.createElement('a');
    const fileName = file;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink?.click();
  };

  const handleDownload = (file: any) => {
    isBillDeleted &&
      dispatch(approveExpenseStateActions.setIsBillDeleted(false));
    void (async () => {
      try {
        const response = await fileDownload(
          `expense/bill/tablet/download?blobName=${file}`,
          file,
        );
        if (response?.data?.error) {
          throw new Error(response?.error);
        }
        base64DownloaderWeb(response?.data, file);
      } catch (err) {}
    })();
  };

  const handlePreview = (file: any) => {
    void (async () => {
      try {
        const fileName = encodeURIComponent(file);
        const response = await fileDownload(
          `expense/bill/tablet/download?blobName=${fileName}`,
          fileName,
        );

        if (response?.data?.error) {
          throw new Error(response?.error);
        }

        previewInNewWindow(
          getFileTypeWeb(fileName),
          response?.data,
          fileName,
          false,
        );
      } catch (err) {}
    })();
  };

  const closeDialog = () => {
    const request = {
      month: selectedExpenseApprovalsUser?.expenseMonth,
      year: selectedExpenseApprovalsUser?.expenseYear,
      staffPositionId: selectedExpenseApprovalsUser?.staffPositionId,
      userId: selectedExpenseApprovalsUser?.staffUserId,
    };
    if (isComingFromDailyGrid && taDaPopupVisible) {
      dispatch(fetchDailyExpensePopupTADAData(expenseId));
      dispatch(fetchDailyExpensePopupReadOnlyData(expenseId));
    } else if (isComingFromDailyGrid) {
      dispatch(fetchDailyExpenseApprovalsDataCreator(request));
      dispatch(footerDataCreator(request));
    } else {
      dispatch(fetchMiscExpenseApprovalsDataCreator(request));
      dispatch(footerDataCreator(request));
    }

    dispatch(approveExpenseStateActions.setSubmittedBillStatus({}));
    dispatch(approveExpenseStateActions.setViewBillPopupVisible(false));
    dispatch(
      approveExpenseStateActions.setBillDetails({
        bills: [],
      }),
    );
    const mappedBill = uploadedFileData?.map((bill: any) => {
      if (bill?.expanded) {
        return {...bill, expanded: !bill?.expanded};
      }
      return bill;
    });
    setUploadedFileData(mappedBill);
    if (!taDaPopupVisible) {
      setExpenseId?.(null);
    }
  };

  const openDeletePopup = (currentBill: any) => {
    setIsDeleteConfirmPopupOpen(true);
    setCurrentSelectedBill(currentBill);
  };

  const closeDeletePopup = () => {
    setIsDeleteConfirmPopupOpen(false);
  };

  const renderSuppDocs = (bills: any) => {
    return bills?.map(
      (doc: any, i: number) =>
        !!doc && (
          <Box
            display={'flex'}
            flexDirection={'row'}
            key={doc?.id}
            maxWidth={'100%'}>
            <Link
              m={2}
              className={billStyles.viewSupDocPrev}
              width={'90%'}
              onClick={() => handlePreview(doc)}>
              {doc}
            </Link>
            <IconButton onClick={() => handleDownload(doc)} color="primary">
              <DownloadIcon />
            </IconButton>
          </Box>
        ),
    );
  };

  const allBills = () => {
    return (
      <React.Fragment>
        {isBillDeleted && (
          <Typography className={billStyles.viewBillTypo}>
            {strings.moduleSpecificUtilities.billDeleteSuccess}
          </Typography>
        )}
        {isLoading && !uploadedFileData?.length ? (
          <Box width={'100%'} display={'flex'} justifyContent={'center'}>
            <CircularProgress />
          </Box>
        ) : (
          <div
            data-testid="FileDownloadOutlinedIcon1"
            className={billStyles.viewBillContainer}>
            <span className={billStyles.uploadBillText}>
              {strings.moduleSpecificUtilities.uploadedBill}
            </span>
            <List>
              {uploadedFileData?.length ? (
                uploadedFileData?.map((item: any, index: any) => (
                  <div key={item} className={billStyles.billContainer}>
                    {currentOpenedBill === index && isLoading && (
                      <Box
                        height={'30px'}
                        width={'100%'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <Typography color={theme.colors.green[100]}>
                          {strings.moduleSpecificUtilities.billIsSaving}
                        </Typography>
                      </Box>
                    )}
                    <ListItem
                      style={{
                        width: '555px',
                        justifyContent: 'space-between',
                      }}>
                      <div
                        style={{
                          width: '232%',
                        }}>
                        <Typography
                          component="h5"
                          className={billStyles.billNameText}>{`Bill ${
                          index + 1
                        } - `}</Typography>

                        <Link
                          onClick={() => handlePreview(item?.fileName)}
                          className={billStyles.viewBillPreview}>
                          {`${item.fileName}`}
                        </Link>
                      </div>
                      <div style={{width: '100%'}}>
                        {status?.expenseStatusId === 9 && (
                          <IconButton
                            color="primary"
                            onClick={() => handleAccordionClick(index)}>
                            <ModeEditIcon />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => handleDownload(item?.fileName)}
                          color="primary">
                          <DownloadIcon />
                        </IconButton>
                        {status?.expenseStatusId === 9 && (
                          <IconButton
                            onClick={() => openDeletePopup(item)}
                            style={{color: '#cc0000'}}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => handleAccordionClick(index, false)}>
                          {item?.expanded ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </div>
                    </ListItem>
                    <Collapse in={item?.expanded} timeout="auto" unmountOnExit>
                      <CollapsibleContent
                        item={item}
                        isDisable={false}
                        onSubmitBtn={onSubmitBtn}
                        handlePreview={handlePreview}
                        handleDownload={handleDownload}
                        handelDeleteClick={handelDeleteClick}
                        isHideable={status?.expenseStatusId === 9}
                      />
                    </Collapse>
                  </div>
                ))
              ) : (
                <div
                  data-testid="noBills"
                  className={billStyles.noBillsContainer}>
                  {strings.moduleSpecificUtilities.noBillFound}
                </div>
              )}
            </List>
          </div>
        )}
      </React.Fragment>
    );
  };

  const checkAndRender = (bills: any) =>
    !!bills?.length && renderSuppDocs(bills);

  util.getFileTypeWeb = getFileTypeWeb;
  util.base64DownloaderWeb = base64DownloaderWeb;

  util.handleDownload = handleDownload;
  util.handleAccordionClick = handleAccordionClick;
  util.onSubmitBtn = onSubmitBtn;
  util.changeHandler = changeHandler;
  util.handlePreview = handlePreview;
  util.closeDialog = closeDialog;
  util.handelDeleteClick = handelDeleteClick;
  util.renderSuppDocs = renderSuppDocs;
  util.isvalueChanged = isvalueChanged;
  util.checkAndRender = checkAndRender;
  return (
    <Dialog
      sx={{width: '100%'}}
      open={isViewBillPopupVisible}
      onClose={() => closeDialog()}>
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        {strings.bill}
        <Button onClick={() => closeDialog()}>
          <CloseOutlinedIcon />
        </Button>
      </DialogTitle>
      <DialogContent className={billStyles.viewBillDialogContent}>
        {allBills()}
      </DialogContent>
      <ConfirmationPopup
        open={isDeleteConfirmPopupOpen}
        title={strings.confirmMsg}
        message={strings.billDeleteMsg}
        cancel={closeDeletePopup}
        confirm={() => handelDeleteClick(currentSelectedBill)}
      />
    </Dialog>
  );
};
