import {Box, Button} from '@mui/material';
import {styles} from '../../styles/customStyle';
import {strings} from '@app/common/strings';

export const FormFooter = ({onCloseClick}: {onCloseClick: () => void}) => {
  return (
    <Box display={'flex'} justifyContent="center" width={'100%'}>
      <Button
        data-testid="reset-button"
        variant="contained"
        sx={styles.footerButton}
        onClick={onCloseClick}>
        {strings.close}
      </Button>
    </Box>
  );
};
