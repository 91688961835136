import {initializeApp} from '@firebase/app';
import {
  getAnalytics,
  logEvent,
  setCurrentScreen,
  setUserProperties,
  setUserId,
  Item,
} from '@firebase/analytics';
import {trackInsightPageView, trackInsightEvent} from '../applicationInsight';

import env from '../../env.json';
// Initialize Firebase
const app = initializeApp(env.firebaseConfig);
const analytics = getAnalytics(app);

export const trackScreenView = async (screenName: string) => {
  trackInsightPageView(screenName);
  setCurrentScreen(analytics, screenName);
  return logEvent(analytics, 'screen_view', {
    firebase_screen: screenName,
    firebase_screen_class: screenName,
  });
};

export const trackEvent = (
  eventName: string,
  params:
    | {
        [key: string]: any;
        coupon?: string;
        currency?: string;
        items?: Item[];
        payment_type?: string;
        value?: number;
      }
    | undefined,
) => {
  trackInsightEvent(eventName, params);
  return logEvent(analytics, eventName, params);
};

export const setUserProperty = async (
  propertyName: string | number,
  propertyValue: any,
) => {
  const properties: any = {};
  properties[propertyName] = propertyValue;
  return setUserProperties(analytics, properties);
};

export const setUserIdData = (propertyValue: string) => {
  return setUserId(analytics, propertyValue);
};
