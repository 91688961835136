import {DataGrid, GridRenderCellParams} from '@mui/x-data-grid';
import {Box, Stack, TableCell} from '@mui/material';
import moment from 'moment';
import {Gridstyle} from '@app/components/widgets/grid/grid';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {generateReportStateSelector} from '@app/screens/module-specific-utilities/pages/generateReport/redux/selectors';
import {useCallback} from 'react';
import {generateReportStateActions} from '@app/screens/module-specific-utilities/pages/generateReport/redux/index';
import {getMonthInitial} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseTable';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {strings} from '@app/common/strings';
import {fetchGenerateReportCreator} from '../../pages/generateReport/redux/slice';
import {GenerateReportPayload} from './generate-report-type';

export const util: any = {
  renderPassedOnDateCell: null,
  noRowsOverlayComponent: null,
};

export const GenerateReportGrid = ({
  generateReportgridData,
  payload,
  totalCount,
}: {
  generateReportgridData: any;
  payload: GenerateReportPayload;
  totalCount: number;
}) => {
  const dispatch = useDispatch();
  const generateReportError = useSelector(
    generateReportStateSelector.getIsGenerateError(),
  );
  const pageNo = useSelector(
    generateReportStateSelector.getGenerateReportPageNo(),
    shallowEqual,
  );

  const renderPassedOnDateCell = (params: GridRenderCellParams) => {
    return (
      <TableCell sx={{ textAlign: 'right', padding:0}}>
        <Box sx={{ fontSize: '12px'}}>
          {params?.row?.passedOnDate
            ? moment(params?.row?.passedOnDate).format('DD/MM/YYYY')
            : '-'}
        </Box>
      </TableCell>
    );
  };

  const columns = [
    {
      field: 'divisionName',
      headerName: 'Division',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {params.row.divisionName}
          </p>
        );
      },
    },
    {
      field: 'stateName',
      headerName: 'State',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {params.row.stateName}
          </p>
        );
      },
    },

    {
      field: 'staffSAPCode',
      headerName: 'Sap code',
      width: 120,
      editable: false,
    },
    {
      field: 'staffName',
      headerName: 'STAFF NAME',
      width: 130,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {params.row.staffName}
          </p>
        );
      },
    },
    {
      field: 'staffCode',
      headerName: 'STAFF CODE',
      width: 130,
      editable: false,
    },
    {
      field: 'staffDesignationName',
      headerName: 'DESIGNATION NAME',
      width: 200,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {params.row.staffDesignationName}
          </p>
        );
      },
    },
    {
      field: 'hqName',
      headerName: 'HQ',
      width: 100,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {params.row.hqName}
          </p>
        );
      },
    },
    {
      field: 'expenseMonth',
      headerName: 'EXP MONTH',
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {`${getMonthInitial(params?.row?.expenseMonth, monthMock())}
            ${params?.row?.expenseYear}`}
          </p>
        );
      },
    },
    {
      field: 'totalDistance',
      headerName: 'KM',
      width: 100,
      editable: false,
    },
    {
      field: 'adminApprovedTAAmount',
      headerName: 'tA amount',
      width: 140,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return params?.row?.adminApprovedTAAmount?.toFixed(0);
      },
    },
    {
      field: 'adminApprovedDAAmount',
      headerName: 'da amount',
      width: 140,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return params?.row?.adminApprovedDAAmount?.toFixed(0);
      },
    },
    {
      field: 'expenseDADays',
      headerName: 'DA Days',
      width: 100,
      editable: false,
    },
    {
      field: 'passedOnDate',
      headerName: 'PASSED ON',
      width: 130,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderPassedOnDateCell(params);
      },
    },
    {
      field: 'passedByName',
      headerName: 'PASSED BY',
      width: 130,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{maxWidth: '100%', wordBreak: 'break-word'}}>
            {params.row.passedByName}
          </p>
        );
      },
    },
  ];
  const getRowId = useCallback(function (row: any) {
    return row?.id;
  }, []);

  const noRowsOverlayComponent = useCallback(() => {
    const message = generateReportError ? '' : strings.noRecordFound;

    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {message}
      </Stack>
    );
  }, [generateReportError, generateReportgridData?.length]);

  const pageChange = useCallback(
    function (currentPage: number) {
      if (payload) {
        const requestBody = {
          ...payload,
          pageNumber: currentPage,
        };
        dispatch(fetchGenerateReportCreator(requestBody));
      }
      dispatch(generateReportStateActions.setGenerateReportPageNo(currentPage));
    },
    [pageNo],
  );

  util.renderPassedOnDateCell = renderPassedOnDateCell;
  util.noRowsOverlayComponent = noRowsOverlayComponent;

  return (
    <Box sx={Gridstyle.gridcss}>
      <DataGrid
        columnBuffer={columns.length}
        page={pageNo}
        onPageChange={pageChange}
        rows={generateReportgridData ?? []}
        getRowId={getRowId}
        pageSize={100}
        rowCount={totalCount}
        columns={columns}
        paginationMode="server"
        rowsPerPageOptions={[100]}
        components={{
          NoRowsOverlay: noRowsOverlayComponent,
        }}
      />
    </Box>
  );
};
