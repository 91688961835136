import {Fragment, useEffect, useState} from 'react';
import {
  BottomNavigation,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {useStyles} from './styles';
import {
  ANNUAL_LIMIT_KEY,
  DIVISIONSKEY,
  FILTERS_LABEL,
  PARTY_TYPE_KEY,
  STATUS_KEY,
} from '@app/common/constants';
import {Label} from '@app/components/elements/Label';
import {
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
  Search,
} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {filterSelector, filterStateActions} from '../redux';
import {
  fetchInventoryListCreator,
  inventoryComplianceSelector,
  inventoryComplianceStateActions,
} from '@app/screens/configuration-management/pages/inventory-compliance/redux';
import {
  createFilterObject,
  isFilterEmpty,
} from '@app/helper/inventory-compliance';
import Colors from '@app/themes/colors';

const numRegex = /^[1-9]\d*$/;

const InventoryComplianceFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector(filterSelector.getFilterState());
  const [minLimit, setMinLimit] = useState<string>('');
  const [maxLimit, setMaxLimit] = useState<string>('');
  const [skuStatus, setSkuStatus] = useState<boolean>(true);
  const [filteredPartyTypes, setFilteredPartyTypes] = useState<any[] | null>(
    null,
  );
  const [filteredDivisions, setFilteredDivisions] = useState<any[] | null>(
    null,
  );
  const applyButtonDisabled = useSelector(
    filterSelector.getApplyDisableStatus(),
  );
  const gridOptions = useSelector(inventoryComplianceSelector.getGridOptions());

  useEffect(() => {
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(filterStateActions.setApplyDisableStatus(true));
    };
  }, []);

  const getExpandIcon = (collapsed: boolean) => {
    if (collapsed) return <ExpandMore />;
    else return <ExpandLess />;
  };

  const listItemText = (text: string) => (
    <Label title={text} classes={classes.listItemText} hideToolTip />
  );

  const parentListItemText = (text: string) => (
    <Label title={text} classes={classes.parentListItemText} hideToolTip />
  );

  const radioButtonLabel = (text: string) => (
    <Box marginLeft={2}>{listItemText(text)}</Box>
  );

  const getOrder = (parentKeyName: string, index: number) => {
    return parentKeyName === PARTY_TYPE_KEY ? 0 : index + 1;
  };

  const clearAllFilter = () => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
    filtersCopy &&
      Object.keys(filtersCopy).forEach(key => {
        if (filtersCopy && filtersCopy[key]) {
          filtersCopy[key].isChecked = false;
          if (filtersCopy[key] && filtersCopy[key].filterData) {
            Object.keys(filtersCopy[key].filterData).forEach(optionKey => {
              filtersCopy[key].filterData[optionKey]['isChecked'] = false;
            });
          }
        }
      });
    setMinLimit('');
    setMaxLimit('');
    setSkuStatus(true);
    setFilteredPartyTypes(null);
    setFilteredDivisions(null);
    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
    dispatch(
      inventoryComplianceStateActions.setAppliedFilters({skuStatus: true}),
    );
  };

  const handleParentCheckbox = (key: string, filter: any) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));
    if (filtersCopy && filtersCopy[key]) {
      filtersCopy[key].isChecked = !filter?.isChecked;
      if (filtersCopy[key] && filtersCopy[key].filterData) {
        Object.keys(filtersCopy[key].filterData).forEach(optionKey => {
          filtersCopy[key].filterData[optionKey]['isChecked'] =
            !filter?.isChecked;
        });
      }
      dispatch(filterStateActions.setFilterState(filtersCopy));
      dispatch(filterStateActions.setApplyDisableStatus(false));
    }
  };

  const handleChildCheckbox = (parentKey: string, childKey: any) => {
    let filtersCopy = JSON.parse(JSON.stringify(filters ? filters : {}));

    if (filtersCopy[parentKey] && filtersCopy[parentKey]?.filterData) {
      filtersCopy[parentKey].filterData = filtersCopy[
        parentKey
      ].filterData?.map((optionKey: any) => {
        if (optionKey.id === childKey.id) {
          return {
            ...optionKey,
            isChecked: !childKey?.isChecked,
          };
        }
        return optionKey;
      });

      const uncheckedChild = filtersCopy[parentKey]?.filterData?.find(
        (child: any) => !child?.isChecked,
      );
      filtersCopy[parentKey]['isChecked'] = !Boolean(uncheckedChild);
    }

    dispatch(filterStateActions.setFilterState(filtersCopy));
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };

  const checkIfIndeterminate = (filter: any, parentKeyName: string) => {
    if (filter.isChecked) return false;
    if (parentKeyName === STATUS_KEY) return true;
    if (parentKeyName === ANNUAL_LIMIT_KEY && (minLimit || maxLimit))
      return true;
    let isIndeterminate = filter?.filterData?.find(
      (item: any) => item.isChecked,
    );
    return Boolean(isIndeterminate);
  };

  const handleMinValue = (e: any) => {
    const val = e?.target?.value;
    if (numRegex.test(val) || val === '') {
      setMinLimit(val);
      dispatch(filterStateActions.setApplyDisableStatus(false));
    }
  };

  const handleMaxValue = (e: any) => {
    const val = e?.target?.value;
    if (numRegex.test(val) || val === '') {
      setMaxLimit(e?.target?.value);
      dispatch(filterStateActions.setApplyDisableStatus(false));
    }
  };

  const handleSkuStatus = (e: any) => {
    setSkuStatus(e?.target?.value);
    dispatch(filterStateActions.setApplyDisableStatus(false));
  };

  const applyFilters = () => {
    const appliedFilters = createFilterObject(
      filters,
      minLimit,
      maxLimit,
      skuStatus,
    );
    if (isFilterEmpty(appliedFilters)) {
      dispatch(filterStateActions.setApplyDisableStatus(true));
    }
    setFilteredPartyTypes(null);
    setFilteredDivisions(null);
    dispatch(
      inventoryComplianceStateActions.setInventoryListGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );
    dispatch(inventoryComplianceStateActions.setAppliedFilters(appliedFilters));
    dispatch(filterStateActions.setApplyDisableStatus(true));
    dispatch(fetchInventoryListCreator());
  };
  const handleSearch = (searchText: string, filterKey: string) => {
    const filteredFilters = filters[filterKey]?.filterData
      ?.filter((div: any) => div?.value?.toLowerCase()?.includes(searchText))
      ?.map((div: any) => div?.id);
    if (filterKey === DIVISIONSKEY) {
      setFilteredDivisions(filteredFilters);
    } else if (filterKey === PARTY_TYPE_KEY) {
      setFilteredPartyTypes(filteredFilters);
    }
  };

  const showFilterItem = (parentKey: string, childKey: any) => {
    if (parentKey === DIVISIONSKEY) {
      if (!filteredDivisions) return true;
      else return filteredDivisions?.includes(childKey.id);
    } else if (parentKey === PARTY_TYPE_KEY) {
      if (!filteredPartyTypes) return true;
      else return filteredPartyTypes?.includes(childKey.id);
    } else {
      return true;
    }
  };

  const secondaryListItems = (
    filter: any,
    parentKeyName: any,
    index: number,
  ) => (
    <Fragment key={parentKeyName}>
      {filter ? (
        <Box
          marginBottom={'15px'}
          marginLeft={'10px'}
          order={getOrder(parentKeyName, index)}>
          <ListItem
            disablePadding
            key={parentKeyName}
            onClick={() => {
              dispatch(
                filterStateActions.setFilterCollapsedState({
                  key: parentKeyName,
                  value: !filter?.collapsed,
                }),
              );
            }}
            data-testid={`list_item_${parentKeyName}`}>
            <ListItemIcon>
              <Checkbox
                className={classes.checkbox}
                checked={filter?.isChecked || false}
                indeterminate={checkIfIndeterminate(filter, parentKeyName)}
                indeterminateIcon={<IndeterminateIcon />}
                onClick={event => {
                  event.stopPropagation();
                  handleParentCheckbox(parentKeyName, filter);
                }}
                data-testid={`parent_${parentKeyName}`}
                disabled={!filter?.filterData?.length}
              />
            </ListItemIcon>
            {parentListItemText(FILTERS_LABEL[parentKeyName as keyof object])}
            <Box paddingRight={'10px'}>{getExpandIcon(filter?.collapsed)}</Box>
          </ListItem>

          <Collapse in={!filter?.collapsed} timeout="auto" unmountOnExit>
            {parentKeyName === ANNUAL_LIMIT_KEY ? (
              annualLimitFilters()
            ) : parentKeyName === STATUS_KEY ? (
              statusFilter()
            ) : (
              <List
                component="div"
                disablePadding
                className={classes.marginLeft15}>
                {(parentKeyName === DIVISIONSKEY ||
                  parentKeyName === PARTY_TYPE_KEY) && (
                  <SearchComponent
                    filterKey={parentKeyName}
                    handleSearch={handleSearch}
                  />
                )}
                {filter?.filterData?.length > 0 ? (
                  filter?.filterData?.map((childKey: any) =>
                    showFilterItem(parentKeyName, childKey) ? (
                      <ListItem
                        disablePadding
                        key={`${parentKeyName}_${childKey?.id}`}
                        data-testid={`list_item_${childKey?.id}`}>
                        <ListItemIcon>
                          <Checkbox
                            className={classes.checkbox}
                            checked={childKey?.isChecked || false}
                            onClick={() => {
                              handleChildCheckbox(parentKeyName, childKey);
                            }}
                            data-testid={`option_${parentKeyName}_${childKey?.id}`}
                          />
                        </ListItemIcon>
                        {listItemText(
                          parentKeyName === PARTY_TYPE_KEY
                            ? `${childKey?.value}, ${childKey?.wsfaCode}`
                            : `${childKey?.value}`,
                        )}
                      </ListItem>
                    ) : (
                      <></>
                    ),
                  )
                ) : (
                  <ListItem>{listItemText('No Filters Available')}</ListItem>
                )}
              </List>
            )}
          </Collapse>
        </Box>
      ) : (
        <></>
      )}
    </Fragment>
  );

  const annualLimitFilters = () => {
    return (
      <List component="div" disablePadding className={classes.marginLeft15}>
        {listItemText('Minimum value')}
        <TextField
          size="small"
          className={classes.limitField}
          value={minLimit}
          onChange={handleMinValue}
        />

        {listItemText('Maximum value')}
        <TextField
          size="small"
          className={classes.limitField}
          value={maxLimit}
          onChange={handleMaxValue}
        />
      </List>
    );
  };

  const statusFilter = () => {
    return (
      <List component="div" disablePadding className={classes.marginLeft25}>
        <RadioGroup
          defaultValue={true}
          value={skuStatus}
          name="status-radio"
          onChange={handleSkuStatus}>
          <FormControlLabel
            value={true}
            control={<Radio size="small" />}
            label={radioButtonLabel('Active')}
          />
          <FormControlLabel
            value={false}
            control={<Radio size="small" />}
            label={radioButtonLabel('Inactive')}
          />
        </RadioGroup>
      </List>
    );
  };

  return (
    <Box
      margin={'20px'}
      marginTop={'10px'}
      borderRadius={'20px'}
      className={`${classes.backgroundWhite} hide-scrollbar`}
      height={'100vh'}
      overflow={'scroll'}>
      {filters && Object.keys(filters)?.length > 0 ? (
        <>
          <Box
            display={'flex'}
            justifyContent="space-between"
            marginBottom={'10px'}
            padding={'10px'}>
            <Chip label="Filter" size="small" className={classes.chipFilter} />
            <Chip
              label="Clear All"
              size="small"
              clickable
              onClick={clearAllFilter}
              className={classes.chipClearAll}
              data-testid="clear-filters"
            />
          </Box>

          <Box paddingBottom={'100px'} display="flex" flexDirection="column">
            {Object.keys(filters)?.map((key, index) =>
              secondaryListItems(filters[key as keyof object], key, index),
            )}
          </Box>

          <Paper className={`${classes.paper} height-100`} elevation={3}>
            <BottomNavigation showLabels value={0}>
              <Box className={classes.applyBox}>
                <Button
                  variant="contained"
                  onClick={applyFilters}
                  className={`${classes.paperButton} ${
                    applyButtonDisabled && classes.paperButtonDisabled
                  }`}
                  disabled={applyButtonDisabled}
                  data-testid={'apply_filters'}>
                  Apply
                </Button>
              </Box>
            </BottomNavigation>
          </Paper>
        </>
      ) : (
        <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
          <CircularProgress size={20} />
        </Box>
      )}
    </Box>
  );
};

const IndeterminateIcon = () => {
  const classes = useStyles();

  return (
    <>
      <CheckBoxOutlineBlank />
      <div className={classes.indeterminateBox} />
    </>
  );
};

const SearchComponent = ({
  filterKey,
  handleSearch,
}: {
  filterKey: string;
  handleSearch: any;
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const handleSearchChange = (e: any) => {
    setSearchText(e?.target?.value);
  };

  const keyPress = (e: any) => {
    if (e.keyCode == 13) {
      const searchVal = searchText?.toLowerCase();
      handleSearch(searchVal, filterKey);
    }
  };

  return (
    <TextField
      placeholder={`Search ${FILTERS_LABEL[filterKey as keyof object]}`}
      size="small"
      onKeyDown={keyPress}
      value={searchText}
      onChange={handleSearchChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      sx={{backgroundColor: Colors.grey[1700]}}
    />
  );
};

export default InventoryComplianceFilters;
