import { API_PATH } from "@app/common/constants";
import { directoryActions } from "@app/screens/directory-configuration/redux";
import { directoryPageLists } from "@app/screens/directory-configuration/redux/slice";
import { ToasterService } from "@app/services";
import NetworkService from "@app/services/network/NetworkService";
import { ToastType } from "@app/services/toaster";
import { call, put, take, takeLatest } from "redux-saga/effects";
import {
  departmentManagementTypeName,
  departmentManagementActions,
} from "./slice";

/**
 * Fetch Department Management List watcher
 */

export function* fetchDepartmentListWatcher() {
  yield takeLatest(departmentManagementTypeName, fetchDepartmentListWorker);
}

/**
 * Fetch Department Management List Worker
 */

function* fetchDepartmentListWorker(action: any): any {
  const { index, isActive, query } = action.payload;
  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.departmentList
    )
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.departmentList,
      { pageIndex: index, pageSize: 15, isActive, name: query || "" },
      {}
    );
    yield put(directoryActions.updateLoading(null));
    if (index === 1) {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.departmentManagement,
          count: response?.data[0]?.totalRecords || 0,
          pageIndex: index,
        })
      );
      yield put(departmentManagementActions.fetchDepartmentList(response.data));
    } else {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.departmentManagement,
          pageIndex: index,
        })
      );
      yield put(departmentManagementActions.fetchDepartmentNewList(response.data));
    }
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}
