import {strings} from '@app/common/strings';
import theme from '@app/themes';
import {Typography, Grid, Card, CardContent} from '@mui/material';
export const SummaryBox = ({
  heading,
  SummaryKey_FirstBox,
  SummaryKey_SecondBox,
  Key_Values,
}: {
  heading: any;
  SummaryKey_FirstBox: any;
  SummaryKey_SecondBox: any;
  Key_Values: any;
}) => {
  const Summary_SecondtBox_Values = {
    'Total calls today:': Key_Values?.totalCallsTodayCount,
    'GP calls today:': Key_Values?.gpCallsTodayCount,
    'Consultants calls today:': Key_Values?.consultantCallsTodayCount,
    'Top GP:': Key_Values?.topGPName,
    'Top Consultants:': Key_Values?.topConsultantName,
  };

  const Summary_FirstBox_Values = {
    'KYC calls today:': Key_Values?.kycCallsTodayCount,
    'Calls today:': Key_Values?.callsTodayCount,
    'Cumulative calls(MTD):': Key_Values?.cumulativeCallsCount,
    'Gross total:': Key_Values?.grossTotalCount,
    'Total Samples given:': Key_Values?.totalSampleGivenCount,
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{textAlign: 'center', mb: 2}}>{heading}</Typography>
        </Grid>
      </Grid>

      <Grid container display={'flex'} spacing={2}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={5} direction="column">
                  {SummaryKey_FirstBox?.map((field: any) => {
                    return (
                      <Typography
                        mt={1}
                        sx={{color: theme.colors.black}}
                        key={field}>
                        {field}
                      </Typography>
                    );
                  })}
                </Grid>
                <Grid item xs={7} direction="column">
                  {Object.values(Summary_FirstBox_Values)?.map((value: any) => (
                    <Typography
                      mt={1}
                      sx={{color: theme.colors.black}}
                      key={value}>
                      {value === ''
                        ? strings.moduleSpecificUtilities.noData
                        : value}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={6} direction="column">
                  {SummaryKey_SecondBox?.map((field: any) => {
                    return (
                      <Typography
                        mt={1}
                        pr={1}
                        sx={{color: theme.colors.black}}
                        key={field}>
                        {field}
                      </Typography>
                    );
                  })}
                </Grid>
                <Grid item xs={6} direction="column">
                  {Object.values(Summary_SecondtBox_Values)?.map(
                    (value: any) => (
                      <Typography
                        mt={1}
                        sx={{color: theme.colors.black}}
                        key={value}>
                        {value === ''
                          ? strings.moduleSpecificUtilities.noData
                          : value}
                      </Typography>
                    ),
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
