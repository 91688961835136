import { API_PATH } from "@app/common/constants";
import { directoryActions } from "@app/screens/directory-configuration/redux";
import { ToasterService } from "@app/services";
import NetworkService from "@app/services/network/NetworkService";
import { ToastType } from "@app/services/toaster";
import { call, put, takeLatest } from "redux-saga/effects";
import { directoryPageLists } from "@app/screens/directory-configuration/redux/slice";
import {
  hoUserCategoryTypeName,
  hoUserTypeName,
  hoUserActions,
  hoUserRegionTypeName,
  hoUserDivisionTypeName,
} from "./slice";

/**
 * Fetch Ho User watcher
 */

export function* fetchHoUserCategoryWatcher() {
  yield takeLatest(hoUserCategoryTypeName, fetchHoUserCategoryWorker);
}

/**
 * Fetch Ho User Worker
 */

function* fetchHoUserCategoryWorker(): any {
  yield put(
    directoryActions.updateLoading(API_PATH.directoryConfiguration.hoUser)
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.hoUser,
      {},
      {}
    );
    yield put(directoryActions.updateLoading(null));
    yield put(hoUserActions.fetchHoUserCategory(response.data));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}

/**
 * Fetch Ho User Department Watcher
 */

export function* fetchHoUserDepartmentWatcher() {
  yield takeLatest(hoUserCategoryTypeName, fetchHoUserDepartmentWorker);
}

/**
 * Fetch Ho User Department Worker
 */

function* fetchHoUserDepartmentWorker(): any {
  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.hoUserDepartment
    )
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.hoUserDepartment,
      {},
      {}
    );
    yield put(directoryActions.updateLoading(null));
    yield put(hoUserActions.fetchHoUserDepartment(response.data));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}

/**
 * Fetch Ho User Division Watcher
 */

export function* fetchHoUserDivisionWatcher() {
  yield takeLatest(hoUserDivisionTypeName, fetchHoUserDivisionWorker);
}

/**
 * Fetch Ho User Division Worker
 */

function* fetchHoUserDivisionWorker(action: any): any {
  const { id } = action.payload;

  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.hoUserDivision
    )
  );
  try {
    const response = yield call(
      NetworkService.get,
      `${API_PATH.directoryConfiguration.hoUserDivision}?AdminStaffPositionMasterId=${id}`,
      {},
      {}
    );
    yield put(directoryActions.updateLoading(null));
    yield put(hoUserActions.fetchHoUserDivision(response.data));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}

/**
 * Fetch Ho User State Watcher
 */

export function* fetchHoUserStateWatcher() {
  yield takeLatest(hoUserCategoryTypeName, fetchHoUserStateWorker);
}

/**
 * Fetch Ho User State Worker
 */

function* fetchHoUserStateWorker(): any {
  yield put(
    directoryActions.updateLoading(API_PATH.directoryConfiguration.hoUserState)
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.hoUserState,
      {},
      {}
    );
    yield put(directoryActions.updateLoading(null));
    yield put(hoUserActions.fetchHoUserState(response.data));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}

/**
 * Fetch Ho User Region Watcher
 */

export function* fetchHoUserRegionWatcher() {
  yield takeLatest(hoUserRegionTypeName, fetchHoUserRegionWorker);
}

/**
 * Fetch Ho User Region Worker
 */

function* fetchHoUserRegionWorker(action: any): any {
  const { statesId } = action.payload;
  yield put(
    directoryActions.updateLoading(API_PATH.directoryConfiguration.hoUserRegion)
  );
  try {
    const response = yield call(
      NetworkService.post,
      `${API_PATH.directoryConfiguration.hoUserRegion}`,
      statesId,
      {}
    );

    let res = response?.data?.map((ele: any) => {
      return {
        ...ele,
        label: ele.name,
        value: ele.id,
      };
    });
    yield put(hoUserActions.fetchHoUserRegion(res));
    yield put(directoryActions.updateLoading(null));
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}

/**
 * Fetch Ho User List Watcher
 */

export function* fetchHoUserListWatcher() {
  yield takeLatest(hoUserTypeName, fetchHoUserListWorker);
}

/**
 * Fetch Ho User List Worker
 */

function* fetchHoUserListWorker(action: any): any {
  const { index, isActive, query } = action.payload;

  yield put(
    directoryActions.updateLoading(API_PATH.directoryConfiguration.hoUserList)
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.hoUserList,
      { pageIndex: index, pageSize: 20, isActive, name: query || "" },
      {}
    );
    yield put(directoryActions.updateLoading(null));
    if (index === 1) {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.hoUser,
          count: response?.data[0]?.totalRecords || 0,
          pageIndex: index,
        })
      );
      yield put(hoUserActions.fetchHoUserList(response?.data));
    } else {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.hoUser,
          pageIndex: index,
        })
      );
      yield put(hoUserActions.fetchHoUserNewList(response?.data));
    }
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}
