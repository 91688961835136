import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  inTransistAccessActions,
  fetchInTransitAccessDivisionState,
  fetchInTransitAccessUser,
  fetchInTransitAccessDivisionLocked,
  fetchInTransitAccessStateLocked,
  fetchInTransitAccessUserLocked,
  fetchInTransitAccessUserStateDivisionRole,
  inTransitAccessUserCreator,
} from './slice';

export function* fetchInTransitAccessDivisionStateWatcher() {
  yield takeLatest(
    fetchInTransitAccessDivisionState,
    fetchInTransitAccessDivisionStateWorker,
  );
}

export function* fetchInTransitAccessUserStateDivisionRoleWatcher() {
  yield takeLatest(
    fetchInTransitAccessUserStateDivisionRole,
    fetchInTransitAccessUserStateDivisionRoleWorker,
  );
}

export function* fetchInTransitAccessUserWatcher() {
  yield takeLatest(fetchInTransitAccessUser, fetchInTransitAccessUsereWorker);
}

export function* fetchInTransitAccessDivisionLockedWatcher() {
  yield takeLatest(
    fetchInTransitAccessDivisionLocked,
    fetchInTransitAccessDivisionLockedWorker,
  );
}

export function* fetchInTransitAccessStateLockedWatcher() {
  yield takeLatest(
    fetchInTransitAccessStateLocked,
    fetchInTransitAccessStateLockedWorker,
  );
}

export function* fetchInTransitAccessUserLockedWatcher() {
  yield takeLatest(
    fetchInTransitAccessUserLocked,
    fetchInTransitAccessUsereLockedWorker,
  );
}

const getParseData = (data: any) => {
  let groupedState = Object.values(
    data?.reduce((acc: any, obj: any) => {
      let stateId = obj.stateId;
      if (!acc[stateId]) {
        acc[stateId] = {
          name: obj.stateName,
          id: obj.stateId,
          expanded: false,
          isLocked: true,
          group: [],
        };
      }
      acc[stateId].group.push({
        ...obj,
        name: obj.divisionName,
        id: obj.divisionId,
      });
      acc[stateId].isLocked = acc[stateId].isLocked
        ? obj.isLocked
          ? true
          : false
        : false;
      return acc;
    }, {}),
  );

  let groupedDivision = Object.values(
    data?.reduce((acc: any, obj: any) => {
      let divisionId = obj.divisionId;
      if (!acc[divisionId]) {
        acc[divisionId] = {
          name: obj.divisionName,
          id: obj.divisionId,
          expanded: false,
          isLocked: false,
          group: [],
        };
      }
      acc[divisionId].group.push({
        ...obj,
        name: obj.stateName,
        id: obj.stateId,
      });
      acc[divisionId].isLocked = acc[divisionId].isLocked
        ? obj.isLocked
          ? true
          : false
        : false;
      return acc;
    }, {}),
  );

  groupedDivision = groupedDivision.map((item: any) => ({
    ...item,
    isLocked: item.group.every((obj: any) => obj.isLocked === true),
  }));
  groupedState = groupedState.map((item: any) => ({
    ...item,
    isLocked: item.group.every((obj: any) => obj.isLocked === true),
  }));

  return {
    groupedState: Object.values(groupedState),
    groupedDivision: Object.values(groupedDivision),
  };
};

export function* fetchInTransitAccessDivisionStateWorker(): any {
  try {
    yield put(inTransistAccessActions.setLoading(true));
    const url = `${API_PATH.inventoryModule.admingeointransitstatedivisionlockdata}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200) {
      yield put(
        inTransistAccessActions.setInTransistAccesssSuccess(
          getParseData(response?.data),
        ),
      );
    }
    yield put(inTransistAccessActions.setLoading(false));
  } catch (e) {
    yield put(inTransistAccessActions.setLoading(false));
  }
}

export function* fetchInTransitAccessUserStateDivisionRoleWorker(): any {
  try {
    const url = `${API_PATH.inventoryModule.getadminuserstatedivisionroledata}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === 200) {
      yield put(inTransistAccessActions.setAdminFilterSuccess(response?.data));
    }
  } catch (e) {}
}

export function* fetchInTransitAccessUsereWorker(action: any): any {
  try {
    let request = {
      ...action.payload,
      pageNumber: parseInt(action.payload.pageNumber) + 1,
    };
    yield put(inTransistAccessActions.setLoading(true));
    if (action?.payload) {
      const url = `${API_PATH.inventoryModule.admingeointransituserlockdata}`;
      const response = yield call(NetworkService.post, url, request, {});

      if (response?.status === 200) {
        yield put(
          inTransistAccessActions.setInTransistAccessUser(response?.data),
        );
      }
    }
    yield put(inTransistAccessActions.setLoading(false));
  } catch (e) {
    yield put(inTransistAccessActions.setLoading(false));
  }
}

export function* fetchInTransitAccessDivisionLockedWorker(action: any): any {
  try {
    yield put(inTransistAccessActions.setLoading(true));

    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.createadmindivisionintransitlock}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );
      if (response?.status === 200) {
        yield put(inTransistAccessActions.setAccessAdminFlag(response?.data));
        yield put(inTransistAccessActions.setLoading(false));
      } else {
        yield put(inTransistAccessActions.setLoading(false));
      }
    }
    yield put(inTransistAccessActions.setLoading(false));
  } catch (e) {
    yield put(inTransistAccessActions.setLoading(false));
  }
}
export function* fetchInTransitAccessStateLockedWorker(action: any): any {
  try {
    yield put(inTransistAccessActions.setLoading(true));
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.createadminstateintransitlock}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );
      if (response?.status === 200) {
        yield put(inTransistAccessActions.setLoading(false));
      } else {
        yield put(inTransistAccessActions.setLoading(false));
      }
    }
    yield put(inTransistAccessActions.setLoading(false));
  } catch (e) {
    yield put(inTransistAccessActions.setLoading(false));
  }
}
export function* fetchInTransitAccessUsereLockedWorker(action: any): any {
  const {userLocked, userRequest} = action.payload;
  try {
    yield put(inTransistAccessActions.setLoading(true));

    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.createadminuserintransitlock}`;
      const response = yield call(NetworkService.post, url, userLocked, null);
      if (response?.status === 200) {
        yield put(inTransistAccessActions.setLoading(false));
        yield put(inTransitAccessUserCreator(userRequest));
      } else {
        yield put(inTransistAccessActions.setLoading(false));
      }
    }
    yield put(inTransistAccessActions.setLoading(false));
  } catch (e) {
    yield put(inTransistAccessActions.setLoading(false));
  }
}
