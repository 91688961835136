import {routeSelector} from '@app/router/redux';
import {useEffect} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const DirectoryConfiguration = () => {
  const navigate = useNavigate();

  const appRoutes = useSelector(routeSelector.getAppRoutes(), shallowEqual);

  useEffect(() => {
    let selectedRoute: any = {};
    appRoutes.map((route: any) => {
      if (route.webPath === '/directory-configuration') {
        selectedRoute = route;
      }
      return null;
    });
    if (selectedRoute?.firstLevel?.length > 0) {
      const firstRoute: any = selectedRoute.firstLevel[0];
      navigate(firstRoute.webPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRoutes]);

  return <div></div>;
};

export default DirectoryConfiguration;
