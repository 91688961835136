import {API_PATH} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {takeLatest, put, call, select} from 'redux-saga/effects';
import {
  fetchGetExpenseStatusActionCreatorTypeName,
  fetchStatusManagerAutoSuggestStaffCreatorTypeName,
  saveExpenseStatusActionCreatorTypeName,
  statusManagerStateActions,
} from './slice';
import {statusManagerStateSelector} from './selectors';

export function* fetchStatusManagerAutoSuggestStaffWatcher() {
  yield takeLatest(
    fetchStatusManagerAutoSuggestStaffCreatorTypeName,
    fetchStatusManagerAutoSuggestStaffWorker,
  );
}

export function* fetchStatusManagerAutoSuggestStaffWorker(action: any): any {
  try {
    yield put(
      statusManagerStateActions.setStatusManagerAutoSuggestStaffDetail([]),
    );
    const status = yield select(statusManagerStateSelector.getActiveStatus());
    if (action.payload.searchText.length > 0) {
      yield put(
        statusManagerStateActions.setStatusManagerAutoSuggestLoading(true),
      );
      const url = `${API_PATH.statusManager.autoSuggestStaff}`;
      const autoSuggestedStaff = yield call(NetworkService.post, url, {
        searchData: action.payload.searchText,
        divisionIds: action.payload.divisionId,
        InActive: !status,
      });
      yield put(
        statusManagerStateActions.setStatusManagerAutoSuggestStaffDetail(
          autoSuggestedStaff?.data,
        ),
      );
      yield put(
        statusManagerStateActions.setStatusManagerAutoSuggestLoading(false),
      );
    }
  } catch (error) {}
}

export function* fetchGetExpenseStatusActionsWatcher() {
  yield takeLatest(
    fetchGetExpenseStatusActionCreatorTypeName,
    fetchGetExpenseStatusActionWorker,
  );
}

export function* fetchGetExpenseStatusActionWorker(action: any): any {
  try {
    const tempParams = action.payload;

    if (tempParams) {
      const url = `${API_PATH.statusManager.getexpensestatusandactions}`;
      const expenseAction = yield call(NetworkService.post, url, {
        staffPositionId: tempParams.staffPositionId,
        staffUserId: tempParams.staffUserId,
        expenseMonth: tempParams.expenseMonth,
        expenseYear: tempParams.expenseYear,
      });

      yield put(
        statusManagerStateActions.setStatusManagerAction(expenseAction?.data),
      );
    }
  } catch (error) {}
}

export function* saveExpenseStatusActionsWatcher() {
  yield takeLatest(
    saveExpenseStatusActionCreatorTypeName,
    saveExpenseStatusActionWorker,
  );
}

export function* saveExpenseStatusActionWorker(action: any): any {
  try {
    const tempParams = action.payload;
    if (tempParams) {
      yield put(statusManagerStateActions.setIsLoading(true));

      const url = `${API_PATH.statusManager.saveExpenseStatusAndActions}`;
      const expenseAction = yield call(NetworkService.post, url, {
        staffPositionId: tempParams.staffPositionId,
        staffUserId: tempParams.staffUserId,
        expenseMonth: tempParams.expenseMonth,
        expenseYear: tempParams.expenseYear,
        expenseActionId: tempParams.expenseActionId.actionId,
      });
      if (expenseAction) {
        if (expenseAction.data.error) {
          yield put(statusManagerStateActions.setIsLoading(false));
          ToasterService.showToaster(expenseAction.data.error, ToastType.ERROR);
        } else if (expenseAction.status == 200) {
          yield put(statusManagerStateActions.setIsLoading(false));
          ToasterService.showToaster(
            tempParams.expenseActionId.actionName != 'Force Expense to Admin'
              ? 'Expense Status And Actions Saved Sucessfully'
              : 'Expense is forcefully submitted for the staff. Please check in expense approval screen',
            ToastType.SUCCESS,
          );
        }
      }
    }
  } catch (error) {}
}
