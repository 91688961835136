import {API_PATH} from '@app/common/constants';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {accessGroupMasterStateSelector} from './selectors';

import {
  accessGroupMasterStateActions,
  accessGroupMenuItemsCreatorTypeName,
  addAccessGroupCreatorTypeName,
  disableAccessGroupCreatorTypeName,
  editAccessGroupCreatorTypeName,
  fetchAccessGroupMasterCreatorTypeName,
  fetchAccessGroupsAutoSuggestCreatorTypeName,
} from './slice';

/**
 * Employee Details Watcher
 */
export function* fetchAccessGroupMasterWatcher() {
  yield takeLatest(
    fetchAccessGroupMasterCreatorTypeName,
    fetchAccessGroupMasterWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchAccessGroupMasterWatcherWorker(action: any): any {
  try {
    yield put(accessGroupMasterStateActions.setAccessGroupsLoading(true));
    const isUserActive = yield select(
      accessGroupMasterStateSelector.getIsActiveState(),
    );

    if (
      action &&
      action.payload &&
      Object.keys(action.payload).length > 0 &&
      typeof action.payload.id !== 'undefined'
    ) {
      const url = `${API_PATH.accessGroups.fetchAccessGroup}/${action.payload.id}`;
      const accessGroups = yield call(NetworkService.get, url, {}, {});
      yield put(
        accessGroupMasterStateActions.setAccessGroups(
          accessGroups?.data?.menuItems ? [accessGroups?.data] : [],
        ),
      );
    } else {
      const url = `${API_PATH.accessGroups.fetchAccessGroups}`;
      const accessGroups = yield call(NetworkService.post, url, {
        isActive: isUserActive,
        searchData: '',
      });
      yield put(
        accessGroupMasterStateActions.setAccessGroups(accessGroups?.data),
      );
    }

    yield put(accessGroupMasterStateActions.setAccessGroupsLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching access groups!',
      ToastType.ERROR,
    );
    yield put(accessGroupMasterStateActions.setAccessGroupsLoading(false));
    yield put(accessGroupMasterStateActions.setAccessGroupsError(true));
    yield put(accessGroupMasterStateActions.setAccessGroupsErrorMessage(error));
    setTimeout(function* () {
      yield put(accessGroupMasterStateActions.clearError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchAccessGroupsAutoSuggestDataWatcher() {
  yield takeLatest(
    fetchAccessGroupsAutoSuggestCreatorTypeName,
    fetchAccessGroupsAutoSuggestDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchAccessGroupsAutoSuggestDataWatcherWorker(): any {
  try {
    yield put(
      accessGroupMasterStateActions.setAccessGroupsAutosuggestLoading(true),
    );
    const isUserActive = yield select(
      accessGroupMasterStateSelector.getIsActiveState(),
    );
    const accessGroupsAutosuggestSearchText = yield select(
      accessGroupMasterStateSelector.getAccessGroupsAutosuggestSearchText(),
    );

    if (accessGroupsAutosuggestSearchText.length > 0) {
      const url = `${API_PATH.accessGroups.accessGroupsAutoSuggest}`;
      const adminUsersAutosuggestData = yield call(NetworkService.post, url, {
        isActive: isUserActive,
        searchData: accessGroupsAutosuggestSearchText,
      });
      yield put(
        accessGroupMasterStateActions.setAccessGroupsAutosuggestData(
          adminUsersAutosuggestData?.data,
        ),
      );
    }

    yield put(
      accessGroupMasterStateActions.setAccessGroupsAutosuggestLoading(false),
    );
  } catch (error) {
    yield put(
      accessGroupMasterStateActions.setAccessGroupsAutosuggestError(true),
    );
    yield put(
      accessGroupMasterStateActions.setAccessGroupsAutosuggestErrorMessage(
        error,
      ),
    );
    setTimeout(function* () {
      yield put(
        accessGroupMasterStateActions.clearAccessGroupsAutosuggestError(),
      );
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* editAccessGroupWatcher() {
  yield takeLatest(
    editAccessGroupCreatorTypeName,
    editAccessGroupWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* editAccessGroupWatcherWorker(action: any): any {
  try {
    yield put(accessGroupMasterStateActions.setEditAccessGroupLoading(true));
    const url = `${API_PATH.accessGroups.editAccessGroup}`;
    const selectedIDS: any = [];
    action.payload.menuItems.map((menuitem: any) => {
      if (menuitem.isChecked === true) {
        selectedIDS.push(menuitem.id);
      }
      menuitem.firstLevel.map((menu: any) => {
        if (menu.isChecked === true) {
          selectedIDS.push(menu.id);
        }
        return null;
      });

      menuitem.firstLevel.map((firstLevel: any) => {
        firstLevel?.secondLevel?.map((secondLevel: any) => {
          if (secondLevel.isChecked === true) {
            selectedIDS.push(secondLevel.id);
          }
          return null;
        });
        return null;
      });
      return null;
    });
    const request = {
      accessGroupId: action.payload.accessGroupId,
      selectedScreenIds: selectedIDS,
    };

    const editAccessGroup = yield call(NetworkService.post, url, request);
    yield put(accessGroupMasterStateActions.setEditAccessGroupLoading(false));
    yield put(
      accessGroupMasterStateActions.setEditAccessGroupPopupVisible(false),
    );
    yield put({
      type: fetchAccessGroupMasterCreatorTypeName,
      payload: {},
    });
    if (editAccessGroup.data) {
      ToasterService.showToaster(
        'Access Group updated successfully!',
        ToastType.SUCCESS,
      );
    }
    if (editAccessGroup.data.error) {
      ToasterService.showToaster(editAccessGroup.data.error, ToastType.ERROR);
    }
  } catch (error) {
    yield put(accessGroupMasterStateActions.setEditAccessGroupError(true));
    yield put(
      accessGroupMasterStateActions.setEditAccessGroupErrorMessage(error),
    );
    yield put(
      accessGroupMasterStateActions.setEditAccessGroupPopupVisible(false),
    );
    ToasterService.showToaster(
      'Something went wrong while editing access group!',
      ToastType.ERROR,
    );
    setTimeout(function* () {
      yield put(accessGroupMasterStateActions.clearEditAccessGroupError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* disableAccessGroupWatcher() {
  yield takeLatest(
    disableAccessGroupCreatorTypeName,
    disableAccessGroupWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* disableAccessGroupWatcherWorker(action: any): any {
  try {
    yield put(accessGroupMasterStateActions.setDisableAccessGroupLoading(true));
    const isUserActive = yield select(
      accessGroupMasterStateSelector.getIsActiveState(),
    );

    const url = `${API_PATH.accessGroups.disableAccessGroup}`;

    const disableUser: any = yield call(NetworkService.post, url, {
      AccessGroupId: action.payload.accessGroupId,
    });
    if (disableUser.data) {
      isUserActive &&
        ToasterService.showToaster('Group is In-Active', ToastType.SUCCESS);
      !isUserActive &&
        ToasterService.showToaster('Group is Active', ToastType.SUCCESS);

      yield put({
        type: fetchAccessGroupMasterCreatorTypeName,
        payload: {},
      });

      yield put(
        accessGroupMasterStateActions.setDisableAccessGroupLoading(false),
      );
    } else {
      yield put(
        accessGroupMasterStateActions.setDisableAccessGroupLoading(false),
      );
      throw new Error();
    }
  } catch (error) {
    yield put(accessGroupMasterStateActions.setDisableAccessGroupError(true));
    yield put(
      accessGroupMasterStateActions.setDisableAccessGroupErrorMessage(error),
    );
    ToasterService.showToaster(
      'Something went wrong while disabling access group!',
      ToastType.ERROR,
    );
    setTimeout(function* () {
      yield put(accessGroupMasterStateActions.clearDisableAccessGroupError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* addAccessGroupWatcher() {
  yield takeLatest(addAccessGroupCreatorTypeName, addAccessGroupWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* addAccessGroupWatcherWorker(action: any): any {
  try {
    yield put(accessGroupMasterStateActions.setAddAccessGroupLoading(true));
    const accessGroup = action.payload.accessGroup;
    const accessGroupName = action.payload.accessGroupName;
    const url = `${API_PATH.accessGroups.addAccessGroup}`;
    const selectedIDS: any = [];
    accessGroup.menuItems.map((menuitem: any) => {
      if (menuitem.isChecked === true) {
        selectedIDS.push(menuitem.id);
      }
      menuitem.firstLevel.map((_menu: any) => {
        if (_menu.isChecked === true) {
          selectedIDS.push(_menu.id);
        }
        return null;
      });

      menuitem.firstLevel.map((_firstLevel: any) => {
        _firstLevel?.secondLevel?.map((_secondLevel: any) => {
          if (_secondLevel.isChecked === true) {
            selectedIDS.push(_secondLevel.id);
          }
          return null;
        });
        return null;
      });
      return null;
    });
    const request = {
      accessGroup: accessGroupName,
      menuItems: selectedIDS,
    };
    const addUser = yield call(NetworkService.post, url, request);
    yield put(accessGroupMasterStateActions.setAddAccessGroupLoading(false));
    yield put(
      accessGroupMasterStateActions.setAddAccessGroupPopupVisible(false),
    );
    yield put({type: fetchAccessGroupMasterCreatorTypeName, payload: {}});

    if (
      addUser.data &&
      addUser.data.errors &&
      Object.keys(addUser.data.errors).length > 0
    ) {
      ToasterService.showToaster(addUser.data.title, ToastType.ERROR);
    } else if (addUser.data && addUser.data.error) {
      ToasterService.showToaster(addUser.data.error, ToastType.ERROR);
    } else {
      ToasterService.showToaster(addUser.data.message, ToastType.SUCCESS);
    }
  } catch (error) {
    yield put(accessGroupMasterStateActions.setAddAccessGroupError(true));
    yield put(
      accessGroupMasterStateActions.setAddAccessGroupErrorMessage(error),
    );
    yield put(
      accessGroupMasterStateActions.setAddAccessGroupPopupVisible(false),
    );
    ToasterService.showToaster(
      'Something went wrong while adding access group!',
      ToastType.ERROR,
    );
    setTimeout(function* () {
      yield put(accessGroupMasterStateActions.clearAddAccessGroupError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* accessGroupMenuItemsWatcher() {
  yield takeLatest(
    accessGroupMenuItemsCreatorTypeName,
    accessGroupMenuItemsWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* accessGroupMenuItemsWatcherWorker(): any {
  try {
    yield put(accessGroupMasterStateActions.setAddAccessGroupLoading(true));
    const url = `${API_PATH.accessGroups.menuItems}`;
    const menuItems = yield call(NetworkService.get, url, {}, {});
    yield put(
      accessGroupMasterStateActions.setAccessGroupMasterStateKeyValue({
        key: 'addAccessGroupMenuItems',
        value: menuItems.data,
      }),
    );
    yield put(accessGroupMasterStateActions.setAddAccessGroupLoading(false));
  } catch (error) {
    yield put(accessGroupMasterStateActions.setAddAccessGroupLoading(false));
    ToasterService.showToaster(
      'Something went wrong while adding access group!',
      ToastType.ERROR,
    );
    setTimeout(function* () {
      yield put(accessGroupMasterStateActions.clearAddAccessGroupError());
    }, 5000);
  }
}
