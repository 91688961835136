import {createAction, createSlice} from '@reduxjs/toolkit';
export const generateReportInitialState = {
  generateReportData: [],
  generateReportLoading: null,
  isGenerateReport: false,
  generateReportSnaShot: [],
  generateReportPageNo: 0,
  isGenerateError: false,
  yearDropdown: [],
  monthDropdown: [],
  selectedGenerateReprotData: [],
  hqDropDownForGenerateReport: [],
};
export const fetchGenerateReportCreator = createAction<any>(
  'GENERATE_REPORT_HANDLER/FETCH_GENERATE_REPORT',
);
export const fetchGenerateReportCreatorTypeName = fetchGenerateReportCreator(
  {},
).type;
export const fetchHqForGenerateReportCreator = createAction(
  'GENERATE_REPORT_HANDLER/HQ_DROPDOWN',
);

export const fetchHqForGenerateReportCreatorTypeName =
  fetchHqForGenerateReportCreator().type;
const generateReportStateHandler = createSlice({
  name: 'GENERATE_REPORT_HANDLER',
  initialState: generateReportInitialState,
  reducers: {
    setGenerateReport: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportData: payload,
      };
    },
    setGenerateReportPageNo: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportPageNo: payload,
      };
    },
    setGenerateReportSnaShot: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportSnaShot: payload,
      };
    },
    setIsGenerateReport: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isGenerateReport: payload,
      };
    },
    setGenerateReportLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        generateReportLoading: payload,
      };
    },
    setYearDropdown: (prevState, action) => {
      return {
        ...prevState,
        yearDropdown: action.payload,
      };
    },
    setMonthDropdown: (prevState, action) => {
      return {
        ...prevState,
        monthDropdown: action.payload,
      };
    },
    selectedGenerateReprot: (prevState, action) => {
      return {
        ...prevState,
        selectedGenerateReprotData: action.payload,
      };
    },
    setIsGenerateError: (prevState, action) => {
      return {
        ...prevState,
        isGenerateError: action.payload,
      };
    },
    setGenerateReportHqDropDown: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        hqDropDownForGenerateReport: payload,
      };
    },
  },
});

export const generateReportStateActions = generateReportStateHandler.actions;
export const generateReportStateReducer = generateReportStateHandler.reducer;
