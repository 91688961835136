import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  approvalWorkflowStateActions,
  fetchApprovalWorkflowListCreatorTypeName,
  fetchMasterConfigDataCreatorTypeName,
  createApprovalResponseCreatorTypeName,
  updateMinApproverConfigCreator,
} from './slice';

/**
 * Fetch Approval Workflow List
 */
export function* fetchApprovalWorkflowListWatcher() {
  yield takeLatest(
    fetchApprovalWorkflowListCreatorTypeName,
    fetchApprovalWorkflowListWorker,
  );
}

/**
 * Fetch Approval Workflow List
 */
export function* fetchMasterConfigDataWatcher() {
  yield takeLatest(
    fetchMasterConfigDataCreatorTypeName,
    fetchMasterConfigDataWorker,
  );
}

/**
 * Create Approval API
 */
export function* createApprovalResponseWatcher() {
  yield takeLatest(
    createApprovalResponseCreatorTypeName,
    createApprovalResponseWorker,
  );
}

/**
 * Update Min Approver Configuration
 */
export function* updateMinApproverConfigWatcher() {
  yield takeLatest(
    updateMinApproverConfigCreator,
    updateMinApproverConfigWorker,
  );
}

export function* fetchApprovalWorkflowListWorker(): any {
  try {
    const url = API_PATH.approvalWorkflow.approvalWorkflowList;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        approvalWorkflowStateActions.setApprovalWorkflowList(response.data),
      );
      yield put(
        approvalWorkflowStateActions.setCloneApprovalWorkflowList(
          response.data,
        ),
      );
      yield put(
        approvalWorkflowStateActions.setTotalRowsInGrid(response.data?.length),
      );
    }
  } catch (error) {}
}

export function* fetchMasterConfigDataWorker(): any {
  try {
    const url = API_PATH.approvalWorkflow.masterConfigData;
    const response = yield call(NetworkService.get, url, {}, {});

    if (response?.status === HTTP_OK) {
      yield put(approvalWorkflowStateActions.setMasterConifData(response.data));
    }
  } catch (error) {}
}

export function* createApprovalResponseWorker(action: any): any {
  try {
    const obj = action.payload;
    const url = API_PATH.approvalWorkflow.createApproval;
    const response = yield call(NetworkService.post, url, obj);

    yield put(
      approvalWorkflowStateActions.setCreateApprovalResponse(response.data),
    );
  } catch (error) {}
}

export function* updateMinApproverConfigWorker(action: any): any {
  try {
    const obj = action.payload;
    const url = API_PATH.approvalWorkflow.updateMinApproverConfig;
    const response = yield call(NetworkService.post, url, obj);
    yield put(
      approvalWorkflowStateActions.setMinApproverResponse(response.data),
    );
  } catch (error) {}
}
