import {useEffect} from 'react';
import {routeSelector} from '@app/router/redux';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { handleRoute } from '../routeHandle';
export const ConfigurationManagement = () => {
  const appRoutes = useSelector(routeSelector.getAppRoutes());
  const navigate = useNavigate();
  useEffect(() => {
    const route = handleRoute(appRoutes, '/configuration-management');
    if (route) {
      navigate(route);
    }
  }, [appRoutes]);


  return <div></div>;
};
