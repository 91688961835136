import {createAction, createSlice} from '@reduxjs/toolkit';

export interface IInventoryComplianceInitialState {
  inventoryMasterData: any;
  inventoryComplianceList: any;
  searchText: string;
  gridOptions: any;
  totalRows: number;
  filters: any;
  editableData: any;
  selectedFY: any;
  deactivateInventoryResponse: any;
  saveInventoryResponse: any;
  downloadExcelError: any;
  bulkUploadInventoryResponse: any;

}

export const inventoryComplianceInitialState: IInventoryComplianceInitialState =
  {
    inventoryMasterData: null,
    inventoryComplianceList: [],
    searchText: '',
    gridOptions: {},
    totalRows: 0,
    filters: {skuStatus: true},
    editableData: null,
    selectedFY: null,
    deactivateInventoryResponse: null,
    saveInventoryResponse: null,
    downloadExcelError: null,
    bulkUploadInventoryResponse: null,
  };

export const fetchInventoryMasterDataCreator = createAction(
  'INVENTORY_COMPLIANCE_HANDLER/FETCH_INVENTORY_MASTER_DATA',
);
export const fetchInventoryMasterDataCreatorTypeName =
  fetchInventoryMasterDataCreator().type;

export const fetchInventoryListCreator = createAction(
  'INVENTORY_COMPLIANCE_HANDLER/FETCH_INVENTORY_LIST',
);
export const fetchInventoryListCreatorTypeName =
  fetchInventoryListCreator().type;

export const getInventoryRuleCreator = createAction<any>(
  'INVENTORY_COMPLIANCE_HANDLER/GET_INVENTORY_RULE',
);
export const getInventoryRuleCreatorTypeName = getInventoryRuleCreator({}).type;

export const saveInventoryRuleCreator = createAction<any>(
  'INVENTORY_COMPLIANCE_HANDLER/SAVE_INVENTORY_RULE',
);
export const saveInventoryRuleCreatorTypeName = saveInventoryRuleCreator(
  {},
).type;
export const deactivateInventoryRuleCreator = createAction<any>(
  'INVENTORY_COMPLIANCE_HANDLER/DEACTIVATE_INVENTORY_RULE',
);
export const deactivateInventoryRuleCreatorTypeName =
  deactivateInventoryRuleCreator({}).type;

export const downloadInventoryRuleCreator = createAction<any>(
  'INVENTORY_COMPLIANCE_HANDLER/DOWNLOAD_INVENTORY_RULE',
);
export const downloadInventoryRuleCreatorTypeName = downloadInventoryRuleCreator(
  {},
).type;

export const downloadSampleBulkUploadCreator = createAction(
  'INVENTORY_COMPLIANCE_HANDLER/DOWNLOAD_SAMPLE_BULK_UPLOAD',
);
export const downloadSampleBulkUploadCreatorTypeName = downloadSampleBulkUploadCreator().type;

export const bulkUplodInventoryRuleCreator = createAction<any>(
  'INVENTORY_COMPLIANCE_HANDLER/BULK_UPLOAD_INVENTORY_RULE',
);
export const bulkUplodInventoryRuleCreatorTypeName = bulkUplodInventoryRuleCreator(
  {},
).type;

const inventoryComplianceHandler = createSlice({
  name: 'INVENTORY_COMPLIANCE_HANDLER',
  initialState: inventoryComplianceInitialState,
  reducers: {
    resetInventoryComplianceState: () => {
      return {
        ...inventoryComplianceInitialState,
      };
    },
    setInventoryMasterData: (prevState, action) => {
      return {
        ...prevState,
        inventoryMasterData: action.payload,
      };
    },
    setInventoryComplianceList: (prevState, action) => {
      return {
        ...prevState,
        inventoryComplianceList: action.payload,
      };
    },
    setInventoryListGridOptions: (prevState, action) => {
      return {
        ...prevState,
        gridOptions: action.payload,
      };
    },
    setTotalRowsInGrid: (prevState, action) => {
      return {
        ...prevState,
        totalRows: action.payload,
      };
    },
    setSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action.payload,
      };
    },
    setAppliedFilters: (prevState, action) => {
      return {
        ...prevState,
        filters: action.payload,
      };
    },
    setEditableData: (prevState, action) => {
      return {
        ...prevState,
        editableData: action.payload,
      };
    },
    setSelectedFY: (prevState, action) => {
      return {
        ...prevState,
        selectedFY: action.payload,
      };
    },
    setDeactivateInventoryResponse: (prevState, action) => {
      return {
        ...prevState,
        deactivateInventoryResponse: action.payload,
      };
    },
    setSaveInventoryResponse: (prevState, action) => {
      return {
        ...prevState,
        saveInventoryResponse: action.payload,
      };
    },
    setDownloadExcelError: (prevState, action) => {
      return {
        ...prevState,
        downloadExcelError: action.payload
      }
    },
    setBulkUploadInventoryResponse: (prevState, action) => {
      return {
        ...prevState,
        bulkUploadInventoryResponse: action.payload
      }
    },
  },
});

export const inventoryComplianceStateActions =
  inventoryComplianceHandler.actions;
export const inventoryComplianceStateReducer =
  inventoryComplianceHandler.reducer;
