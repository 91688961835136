import { createAction, createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  socialMediaList: any;
  socialMediaCategory: any;
}
const initialState: initialStateProps = {
  socialMediaList: null,
  socialMediaCategory: null,
};

export const updateSocialMediaListCreator = createAction<any>(
  "DIRECTORY/SOCIAL_MEDIA_LIST"
);
export const updateSocialmediaListTypeName =
  updateSocialMediaListCreator(null).type;

export const getSocialMediaCategoryCreator = createAction<any>(
  "DIRECTORY/SOCIAL_MEDIA_CATEGORY"
);
export const getSocialMediaCategoryTypeName =
  getSocialMediaCategoryCreator(null).type;

const socialMediaSlice = createSlice({
  name: "SOCIAL_MEDIA",
  initialState: initialState,
  reducers: {
    fetchSocialMediaList: (state, action) => {
      return {
        ...state,
        socialMediaList: action.payload,
      };
    },
    fetchSocialMediaNewList: (state, action) => {
      return {
        ...state,
        socialMediaList: [...state.socialMediaList, ...action.payload],
      };
    },
    fetchSocialMediaCategory: (state, action) => {
      return {
        ...state,
        socialMediaCategory: action.payload,
      };
    },
  },
});

export const socialMediaActions = socialMediaSlice.actions;
export const socialMediaReducer = socialMediaSlice.reducer;
