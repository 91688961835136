import {ChangeEvent, useState} from 'react';
import {
  Close,
  FileDownloadOutlined,
  FileUploadOutlined,
  CancelOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import {useDispatch, useSelector} from 'react-redux';
import {
  bulkUplodInventoryRuleCreator,
  downloadSampleBulkUploadCreator,
  inventoryComplianceSelector,
} from '../../pages/inventory-compliance/redux';
import { ToasterService } from '@app/services';
import { ToastType } from '@app/services/toaster';

const BulkUploadInventoryModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: any;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState<string>('');
  const [file, setFile] = useState<any>(null);
  const selectedFY = useSelector(inventoryComplianceSelector.getSelectedFY());

  const closeDialog = () => {
    setFile(null);
    setFileName('');
    onClose();
  };

  const isApplyDisabled = () => {
    if (fileName === '') {
      return false;
    }
    return true;
  };

  const downloadSampleCsv = () => {
    dispatch(downloadSampleBulkUploadCreator());
  };
  const hanldeCancelButton = () => {
    setFile(null);
    setFileName('');
  }
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const fileData = e?.target?.files;
    if (fileData?.length) {
      const file = fileData[0];
      const {name, size} = file;
      const fileSize = size / (1024 * 1024);
      if (fileSize > 5) {
        ToasterService.showToaster('Size of file is greater than 5mb, please reduce the file size and try again', ToastType.ERROR);
        e.target.value = ''
        return;
      }
      setFileName(name);
      setFile(file);
      e.target.value = ''
    }
  };

  const handleBulkUpload = () => {
    dispatch(bulkUplodInventoryRuleCreator({selectedFY, file}));
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paper: classes.dialog}}
      scroll="paper">
      <Box className={classes.dialogHeader}>
        <Box className={classes.headerTitle}>
          <DialogTitle className={classes.dialogTitle}>Bulk Upload</DialogTitle>
          <Label title={'Browse files to upload.'} size={14} hideToolTip />
        </Box>
        <IconButton
          color="primary"
          aria-label="add division close"
          component="span"
          id="close-add-modal"
          onClick={closeDialog}
          data-testid={'close-add-modal'}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Label
            title={"Don't have a tempalate? Download sample .csv file here"}
            size={12}
            hideToolTip
          />
          <IconButton
            color="primary"
            aria-label="sample download"
            component="span"
            id="download-sample"
            onClick={downloadSampleCsv}
            data-testid={'download-sample'}>
            <FileDownloadOutlined fontSize="large" />
          </IconButton>
        </Box>
        <Box className={classes.uploadBox}>
          {file !== null &&
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Label title={fileName} size={12} hideToolTip />
            <IconButton
              color="primary"
              aria-label="sample download"
              component="span"
              id='upload-image'
              onClick={hanldeCancelButton}
              data-testid={'upload-image'}>
              <CancelOutlined color="success" fontSize="small" />
            </IconButton>
          </Box>}
          <Button
            component="label"
            variant="contained"
            sx={{width: 220, height: 40}}
            disabled={isApplyDisabled()}
            startIcon={<FileUploadOutlined />}>
            BROWSE FILES
            <input
              id="bulk-upload"
              type="file"
              hidden
              accept=".csv, .xlsx"
              onChange={handleFileUpload}
            />
          </Button>
          <Label
            title={'Maximum file size allowed 5 MB'}
            size={12}
            hideToolTip
          />
          <Label
            title={'You can upload .csv or .xlsx file'}
            size={12}
            hideToolTip
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box className={classes.buttonContainer}>
          <Button
            data-testid="button-cancel"
            variant="contained"
            className={classes.paperButton}
            onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}
            disabled={!isApplyDisabled()}
            onClick={handleBulkUpload}>
            Upload
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUploadInventoryModal;
