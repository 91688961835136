import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { statusManagerStateSelector } from "@app/screens/module-specific-utilities/pages/status-manager/redux/selectors";
import { useCallback, useEffect, useState } from "react";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { saveExpenseStatusActionCreator } from "@app/screens/module-specific-utilities/pages/status-manager/redux/slice";
import { strings } from "@app/common/strings";

export const util: any = {
  handleOnClickSave :null,
  handleChange :null,
  saveAction :null,
}
const style = {
  closeIcon: {
    color: "#1c1939",
  },
};

export const StatusMangerCard = (props: any) => {
  const dispatch = useDispatch();
  let { open, selectedValue, onClose } = props;
  const [action, setAction] = useState("");
  const [expenseStatusName, setExpenseStatusName] = useState("");
  const getAction = useSelector(
    statusManagerStateSelector.getStatusManagerAction()
  );
  useEffect(() => {
    setExpenseStatusName(
      getAction?.expenseStatusName == null ? "" : getAction?.expenseStatusName
    );
  }, [setExpenseStatusName, getAction?.expenseStatusName]);
  const handleOnClickSave = useCallback(() => {
    const saveAction = {
      staffPositionId: selectedValue.staffPositionId,
      staffUserId: selectedValue.staffUserId,
      expenseMonth: selectedValue.expenseMonth,
      expenseYear: selectedValue.expenseYear,
      expenseActionId: action,
    };
    dispatch(saveExpenseStatusActionCreator(saveAction));
    onClose(false);
  },[selectedValue,action]);
  const handleClose = useCallback(() => {
    onClose(false);
  },[]);
  const handleChange = useCallback((event: { target: { value: any } }) => {
    setAction(
      event.target.value
    );
  
  }, [action]);

  util.handleOnClickSave=handleOnClickSave;
  util.handleChange=handleChange ;


  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        sx={{
          borderRadius: 13.3,
          backgroundColor: "white",
          height: '110%',
        }}
      >
        <DialogTitle display={"flex"} justifyContent="space-between">
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 18.7,
                fontWeight: "500",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: "#1c1939",
              }}
            >
              Expense
            </Typography>
          </Box>
          <Box>
            <Button onClick={handleClose} data-testid="button-handleClose">
              <ClearOutlinedIcon sx={style.closeIcon} />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{overflow: 'hidden'}}>
          <Box sx={{mt:1}}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: 12.7,
                fontWeight: '600',
                fontStyle: 'normal',
                letterSpacing: 0,
                textAlign: 'left',
                color: '#1c1939',
              }}
            >
             {strings.ModuleSpecificUtilitie.reverseExpensepage.Employee_Name}
            </Typography>
          </Box>

        <Box sx={{mt:1}}>
          <TextField
            fullWidth
            disabled={true}
            value={props.employeeName}
            sx={{
              backgroundColor: '#f4f4f4',
              borderColor: 'transparent',
            }}
          />
        </Box>

       
          <Box sx={{mt:1}}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: 12.7,
                fontWeight: '600',
                fontStyle: 'normal',
                letterSpacing: 0,
                textAlign: 'left',
                color: '#1c1939',
              }}
            >
              {strings.hq}
            </Typography>
          </Box>
        
     <Box sx={{mt:1}}>
          <TextField
            fullWidth
            disabled={true}
             value={props?.hq}
            sx={{
              backgroundColor: '#f4f4f4',
              borderColor: 'transparent',
            }}
          />
        </Box>

       
          <Box sx={{mt:1}}> 
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 12.7,
                fontWeight: "600",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: "#1c1939",
              }}
            >
             {strings.ModuleSpecificUtilitie.reverseExpensepage.Expense_Status}
            </Typography>
          </Box>
       
       <Box sx={{mt:1}}>
          <TextField
            fullWidth
            disabled={true}
            value={expenseStatusName}
            sx={{
              backgroundColor: "#f4f4f4",
              borderColor: "transparent",
            }}
          />
        </Box>

          <Box >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: 12.7,
                fontWeight: "600",
                fontStyle: "normal",
                letterSpacing: 0,
                textAlign: "left",
                color: "#1c1939",
                marginBottom: 2,
              }}
            >
              Actions
            </Typography>
          </Box>
          <FormControl fullWidth>
            <Select
              inputProps={{
                'data-testid': 'accessType-dropdown',
              }}
              value={action}
              onChange={handleChange}
            >
              {getAction?.actions?.map((option: any) => (
                <MenuItem key={option.actionId} value={option}>
                  {option.actionName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginBottom: 3,
            marginRight: 5,
            marginLeft: 5,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              width: 165.3,
              height: 42.7,
              borderRadius: 1.5,
              borderStyle: "solid",
              borderWidth: 1.3,
              borderColor: "#322b7c",
            }}
            onClick={handleClose}
          >
            
            {strings.cancel}
          </Button>
          <Button
            data-testid="button-handleOnClickSave"
            variant="contained"
            disabled= {!action}
            sx={{
              width: 165.3,
              height: 42.7,
              borderRadius: 1.5,
              backgroundColor: "#322b7c",
            }}
            onClick={handleOnClickSave}
          >
            {strings.save}
          </Button>
        </Box>
      </Dialog>
    </div>
  );
};
