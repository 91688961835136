import {
  ANNUAL_LIMIT_KEY,
  INVENTORY_FILTER_KEYS,
  STATUS_KEY,
} from '@app/common/constants';

export const getInventoryRequestObject = (state: any) => {
  return {
    pageNo: state?.gridOptions?.pageNo + 1 || 1,
    pageSize: state?.gridOptions?.pageSize || 10,
    searchValue: state?.searchText,
    fyEnd: state?.selectedFY,
    filters: state?.filters,
    sortExpression: state?.gridOptions?.sortColumn ?? 'startDate',
    sortDirection: state?.gridOptions?.sortType ?? 'desc',
  };
};

export const createFilterObject = (
  filters: any,
  minLimit: string,
  maxLimit: string,
  skuStatus: boolean,
) => {
  let appliedFilters: any = {
    minLimit,
    maxLimit,
    skuStatus,
  };

  Object.keys(filters).forEach((filterKey: string) => {
    if (filters[filterKey]?.filterData?.length) {
      const res = filters[filterKey]?.filterData
        ?.filter((item: any) => item?.isChecked)
        ?.map((item: any) => item.id);
      appliedFilters[filterKey] = res?.length ? res : null;
    }
  });
  return appliedFilters;
};

export const transformFilters = (filters: any, appliedFilters?: any) => {
  const updatedFilters: any = {
    [ANNUAL_LIMIT_KEY]: {
      isChecked: false,
      collapsed: true,
      filterdata: [],
    },
    [STATUS_KEY]: {
      isChecked: false,
      collapsed: true,
      filterdata: [],
    },
  };

  Object.keys(filters).forEach((filterKey, index) => {
    const newFilterKey = INVENTORY_FILTER_KEYS[filterKey as keyof object];
    updatedFilters[newFilterKey] = {
      isChecked: false,
      collapsed: true,
      filterData: filters[filterKey]
        ?.map((item: any) => ({
          ...item,
          isChecked:
            appliedFilters?.[newFilterKey]?.includes(item?.id) || false,
        }))
        .sort((prev: any, next: any) => {
          if (prev?.isChecked === next?.isChecked) {
            return 0;
          }
          return prev?.isChecked ? -1 : 1;
        }),
    };
  });

  return updatedFilters;
};

export const isFilterEmpty = (filters: any) => {
  return Object.values(filters).every((x: any) => x === null);
};
