import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {routeStateActions, fetchAppRoutesCreatorTypeName} from './routeHandler';

/**
 * Employee Details Watcher
 */
export function* fetchRoutesDataWatcher() {
  yield takeLatest(fetchAppRoutesCreatorTypeName, fetchRoutesDataWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* fetchRoutesDataWatcherWorker(): any {
  try {
    yield put(routeStateActions.setRoutesLoading(true));
    const url = `${API_PATH.main.leftmenu}`;
    const routes = yield call(NetworkService.post, url, {});
    yield put(routeStateActions.setAppRoutes(routes?.data[0]?.leftMenu || []));
    yield put(routeStateActions.setRoutesLoading(false));
  } catch (error) {
    yield put(routeStateActions.setRoutesRenderingError(true));
    yield put(routeStateActions.setRoutesRenderingErrorMessage(error));
    setTimeout(function* () {
      yield put(routeStateActions.clearError());
    }, 5000);
  }
}
