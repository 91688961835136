import {createSelector} from '@reduxjs/toolkit';

const getOnboardNewDivisionState = (state: any) =>
  state.onboardNewDivisionMaster;

const getDivisionsDataSelector = createSelector(
  [getOnboardNewDivisionState],
  data => data?.divisionsData,
);

const getOnboardNewDivisionResponseSelector = createSelector(
  [getOnboardNewDivisionState],
  data => data?.onboardNewDivisionResponse,
);

export const onboardNewDivisionSelector = {
  getDivisionsData: () => getDivisionsDataSelector,
  getOnboardNewDivisionResponse: () => getOnboardNewDivisionResponseSelector,
};
