import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {useStyle} from '@app/screens/Styles/style';
import theme from '@app/themes';

export const ExpenseNavBar = () => {
  const classes = useStyle();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={theme.colors.LIGHT_NAVY_BLUE}
          fontWeight={theme.sizing.weight800}
          display={'flex'}>
          {navbarComponentHeading}
        </Typography>
      </div>
    </div>
  );
};
