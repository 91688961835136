import {API_PATH} from '@app/common/constants';
import {ToasterService} from '@app/services';
import NetworkService from '@app/services/network/NetworkService';
import {ToastType} from '@app/services/toaster';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {select} from 'redux-saga/effects';
import {userManagementStateSelector} from './selectors';

import {
  userManagementStateActions,
  fetchAdminUsersCreatorTypeName,
  fetchStateDropdownCreatorTypeName,
  fetchDivisionDropdownCreatorTypeName,
  fetchAccessGroupDropDownCreatorTypeName,
  editAdminUserCreatorTypeName,
  addAdminUserCreatorTypeName,
  disableAdminUserCreatorTypeName,
  fetchAdminUsersAutoSuggestCreatorTypeName,
  fetchAdminAddUsersAutoSuggestCreatorTypeName,
} from './slice';

/**
 * Employee Details Watcher
 */
export function* fetchAdminUsersDataWatcher() {
  yield takeLatest(
    fetchAdminUsersCreatorTypeName,
    fetchAdminUsersDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchAdminUsersDataWatcherWorker(action: any): any {
  try {
    yield put(userManagementStateActions.setAdminUsersLoading(true));

    const isUserActive = yield select(
      userManagementStateSelector.getIsActiveState(),
    );
    if (action && action.payload && Object.keys(action.payload).length > 0) {
      const url = `${API_PATH.adminUsers.user}/${action.payload.id}`;
      const adminUsers = yield call(NetworkService.get, url, {}, {});
      yield put(userManagementStateActions.setAdminUsers(adminUsers?.data));
    } else {
      const url = `${API_PATH.adminUsers.users}`;
      const adminUsers = yield call(NetworkService.post, url, {
        isActive: isUserActive,
        searchData: '',
      });
      yield put(userManagementStateActions.setAdminUsers(adminUsers?.data));
      yield put(
        userManagementStateActions.setAdminUsersSnaShot(adminUsers?.data),
      );
    }

    yield put(userManagementStateActions.setAdminUsersLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      'Something went wrong while fetching admin data!',
      ToastType.ERROR,
    );
    yield put(userManagementStateActions.setAdminUsersError(true));
    yield put(userManagementStateActions.setAdminUsersErrorMessage(error));
    setTimeout(function* () {
      yield put(userManagementStateActions.clearAdminUsersError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchAdminUsersAutoSuggestDataWatcher() {
  yield takeLatest(
    fetchAdminUsersAutoSuggestCreatorTypeName,
    fetchAdminUsersAutoSuggestDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchAdminUsersAutoSuggestDataWatcherWorker(): any {
  try {
    yield put(userManagementStateActions.setAdminUsersAutosuggestLoading(true));

    const isUserActive = yield select(
      userManagementStateSelector.getIsActiveState(),
    );

    const userSearchText = yield select(
      userManagementStateSelector.getUserSearchText(),
    );

    if (userSearchText.length > 0) {
      const url = `${API_PATH.adminUsers.autosuggest}`;
      const adminUsersAutosuggestData = yield call(NetworkService.post, url, {
        isActive: isUserActive,
        searchData: userSearchText,
      });
      yield put(
        userManagementStateActions.setAdminUsersAutosuggestData(
          adminUsersAutosuggestData?.data,
        ),
      );
    }

    yield put(
      userManagementStateActions.setAdminUsersAutosuggestLoading(false),
    );
  } catch (error) {
    yield put(userManagementStateActions.setAdminUsersAutosuggestError(true));
    yield put(
      userManagementStateActions.setAdminUsersAutosuggestErrorMessage(error),
    );
    setTimeout(function* () {
      yield put(userManagementStateActions.clearAdminUsersAutosuggestError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchAdminAddUsersAutoSuggestDataWatcher() {
  yield takeLatest(
    fetchAdminAddUsersAutoSuggestCreatorTypeName,
    fetchAdminAddUsersAutoSuggestDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchAdminAddUsersAutoSuggestDataWatcherWorker(): any {
  try {
    yield put(
      userManagementStateActions.setAdminAddUsersAutosuggestLoading(true),
    );

    const adminUsersAutosuggestSearchText = yield select(
      userManagementStateSelector.getAdminUsersAutosuggestSearchText(),
    );

    if (adminUsersAutosuggestSearchText.length > 0) {
      const url = `${API_PATH.adminUsers.usersAutoSuggest}`;
      const adminAddUsersAutosuggestData = yield call(
        NetworkService.post,
        url,
        {
          searchData: adminUsersAutosuggestSearchText,
        },
      );
      yield put(
        userManagementStateActions.setAdminAddUsersAutosuggestData(
          adminAddUsersAutosuggestData?.data,
        ),
      );
    }

    yield put(
      userManagementStateActions.setAdminAddUsersAutosuggestLoading(false),
    );
  } catch (error) {
    yield put(
      userManagementStateActions.setAdminAddUsersAutosuggestError(true),
    );
    yield put(
      userManagementStateActions.setAdminAddUsersAutosuggestErrorMessage(error),
    );
    setTimeout(function* () {
      yield put(
        userManagementStateActions.clearAdminAddUsersAutosuggestError(),
      );
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchStateDropdownWatcher() {
  yield takeLatest(
    fetchStateDropdownCreatorTypeName,
    fetchStateDropdownWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchStateDropdownWatcherWorker(): any {
  try {
    const url = `${API_PATH.adminUsers.dropdowns.states}`;
    const stateDropdown = yield call(NetworkService.get, url, {}, {});
    yield put(userManagementStateActions.setStateDropDown(stateDropdown?.data));
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchDivisionDropdownWatcher() {
  yield takeLatest(
    fetchDivisionDropdownCreatorTypeName,
    fetchDivisionDropdownWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchDivisionDropdownWatcherWorker(): any {
  try {
    const url = `${API_PATH.adminUsers.dropdowns.divisions}`;
    const divisionDropdown = yield call(NetworkService.get, url, {}, {});
    yield put(
      userManagementStateActions.setDivisionDropDown(divisionDropdown?.data),
    );
  } catch (error) {}
}



/**
 * Employee Details Watcher
 */
export function* fetchAccessGroupDropDownWatcher() {
  yield takeLatest(
    fetchAccessGroupDropDownCreatorTypeName,
    fetchAccessGroupDropDownWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchAccessGroupDropDownWatcherWorker(): any {
  try {
    const url = `${API_PATH.adminUsers.dropdowns.accessgroups}`;
    const accessGroupDropDown = yield call(NetworkService.get, url, {}, {});
    yield put(
      userManagementStateActions.setAccessGroupDropDown(
        accessGroupDropDown?.data,
      ),
    );
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* editAdminUserWatcher() {
  yield takeLatest(editAdminUserCreatorTypeName, editAdminUserWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* editAdminUserWatcherWorker(action: any): any {
  try {
    yield put(userManagementStateActions.setEditUserLoading(true));
    const url = `${API_PATH.adminUsers.editUser}`;
    const editUser = yield call(NetworkService.post, url, {
      ...action.payload,
      adminStaffPositionMasterId: action.payload.adminUserId,
    });
    yield put(userManagementStateActions.setEditUserLoading(false));
    yield put(userManagementStateActions.setEditUserPopupVisible(false));
    yield put({type: fetchAdminUsersCreatorTypeName, payload: {}});
    if (editUser.data.isCreated) {
      ToasterService.showToaster(editUser.data.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(editUser.data.message, ToastType.ERROR);
    }
  } catch (error) {
    yield put(userManagementStateActions.setEditUserError(true));
    yield put(userManagementStateActions.setEditUserErrorMessage(error));
    yield put(userManagementStateActions.setEditUserPopupVisible(false));
    ToasterService.showToaster(
      'Something went wrong while editing admin!',
      ToastType.ERROR,
    );
    setTimeout(function* () {
      yield put(userManagementStateActions.clearEditUserError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* addAdminUserWatcher() {
  yield takeLatest(addAdminUserCreatorTypeName, addAdminUserWatcherWorker);
}

/**
 * Employee Details Worker
 */
export function* addAdminUserWatcherWorker(action: any): any {
  try {
    yield put(userManagementStateActions.setAddUserLoading(true));
    const url = `${API_PATH.adminUsers.addUser}`;
    const addUser = yield call(NetworkService.post, url, action.payload);
    yield put(userManagementStateActions.setAddUserLoading(false));
    yield put(userManagementStateActions.setAddUserPopupVisible(false));
    yield put({type: fetchAdminUsersCreatorTypeName, payload: {}});
    if (addUser.data.isCreated) {
      ToasterService.showToaster(addUser.data.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(addUser.data.message, ToastType.ERROR);
    }
  } catch (error) {
    yield put(userManagementStateActions.setAddUserError(true));
    yield put(userManagementStateActions.setAddUserErrorMessage(error));
    yield put(userManagementStateActions.setAddUserPopupVisible(false));
    ToasterService.showToaster(
      'Something went wrong while adding admin!',
      ToastType.ERROR,
    );
    setTimeout(function* () {
      yield put(userManagementStateActions.clearAddUserError());
    }, 5000);
  }
}

/**
 * Employee Details Watcher
 */
export function* disableAdminUserWatcher() {
  yield takeLatest(
    disableAdminUserCreatorTypeName,
    disableAdminUserWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* disableAdminUserWatcherWorker(action: any): any {
  try {
    yield put(userManagementStateActions.setDisableUserLoading(true));
    const isUserActive = yield select(
      userManagementStateSelector.getIsActiveState(),
    );
    const url = `${API_PATH.adminUsers.disableUser}`;
    const disableUser = yield call(NetworkService.post, url, {
      adminStaffPositionMasterId: action.payload.id,
    });
    if (disableUser.data === true) {
      !isUserActive &&
        ToasterService.showToaster('User is Active', ToastType.SUCCESS);
      isUserActive &&
        ToasterService.showToaster('User is In-Active', ToastType.SUCCESS);

      yield put({type: fetchAdminUsersCreatorTypeName, payload: {}});

      yield put(userManagementStateActions.setDisableUserLoading(false));
    } else {
      yield put(userManagementStateActions.setDisableUserLoading(false));
      throw new Error();
    }
  } catch (error) {
    yield put(userManagementStateActions.setDisableUserError(true));
    yield put(userManagementStateActions.setDisableUserErrorMessage(error));
    ToasterService.showToaster(
      'Something went wrong while disabling admin!',
      ToastType.ERROR,
    );
    setTimeout(function* () {
      yield put(userManagementStateActions.clearDisableUserError());
    }, 5000);
  }
}
