import { createAction, createSlice } from "@reduxjs/toolkit";

export const actions = {
  addTab: "addTab",
  addColumn: "addColumn",
  addSocialMedia: "addSocialMedia",
};

export const directoryPageLists = {
  tabManagement: "tabManagement",
  columnManagement: "columnManagement",
  departmentManagement: "departmentManagement",
  hoContactCategory: "hoContactCategory",
  hoUser: "hoUser",
  socialMedia: "socialMedia",
  socialMediaCategory: "socialMediaCategory",
  columnMapping: "columnMapping",
};

interface initialStateProps {
  nav: Record<string, any>;
  pages: Record<string, Record<string, any>>;
  modal: {};
  loading: null | number | boolean;
  currentPage: string | null;
}

const initialState: initialStateProps = {
  nav: {
    title: null,
    action: {
      title: null,
      type: null,
    },
    search: {
      placeholder: "Search here",
      query: "",
    },
    status: {
      show: false,
      value: true,
    },
  },
  pages: {
    tabManagement: {
      count: 0,
      pageIndex: 1,
    },
  },
  modal: {
    show: null,
    type: null,
  },
  loading: null,
  currentPage: null,
};

export const updateDirectoryNavDataCreator = createAction<any>(
  "DIRECTORY_CONFIGURATION/UPDATE_DIRECTORY_NAV_DATA"
);
export const updateDirectoryNavDataCreatorTypeName =
  updateDirectoryNavDataCreator(null).type;

export const directoryNavActionHandlerCreator = createAction<any>(
  "DIRECTORY_CONFIGURATION/UPDATE_DIRECTORY_NAV_ACTION_HANDLR"
);

export const directoryNavActionHandlerCreatorTypeName =
  directoryNavActionHandlerCreator(null).type;

export const directoryNavToggleCreator = createAction<any>(
  "DIRECTORY_CONFIGURATION/UPDATE_DIRECTORY_NAV_TOGGLE"
);

export const directoryNavToggleCreatorTypeName =
  directoryNavToggleCreator(null).type;

export const directoryLoadingHandlerCreator = createAction<any>(
  "DIRECTORY_CONFIGURATION/LOADING_HANDLER"
);

export const directoryLoadingHandlerCreatorTypeName =
  directoryLoadingHandlerCreator(null).type;

export const directoryNavSearchQueryCreator = createAction<any>(
  "DIRECTORY_CONFIGURATION/directoryNavSearchQueryCreator"
);

export const directoryNavSearchQueryCreatorTypeName =
  directoryNavSearchQueryCreator(null).type;

export const directoryUpdateCurrentPageCreator = createAction<any>(
  "DIRECTORY_CONFIGURATION/directoryUpdateCurrentPageCreator"
);

export const directoryUpdateCurrentPageCreatorTypeName =
  directoryUpdateCurrentPageCreator(null).type;

const directorySlice = createSlice({
  name: "DIRECTORY_CONFIGURATION",
  initialState: initialState,
  reducers: {
    updateNavData: (state, action) => {
      return {
        ...state,
        nav: action.payload,
      };
    },
    updateModalHandler: (state, action) => {
      return {
        ...state,
        modal: action.payload,
      };
    },
    updateLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateTotalRecords: (state, action) => {
      const { listing, ...props }: { listing: string; count: number } =
        action.payload;
      return {
        ...state,
        pages: {
          ...state.pages,
          [listing]: {
            ...state.pages[listing],
            ...props,
          },
        },
      };
    },
    updateNavSearchQuery: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          search: {
            ...state.nav.search,
            query: action.payload,
          },
        },
      };
    },
    updateCurrentPage: (state, action) => {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    updateNavToggle: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          status: {
            ...state.nav.status,
            value: action.payload,
          },
        },
      };
    },
  },
});

export const directoryActions = directorySlice.actions;
export const directoryReducer = directorySlice.reducer;
