import {API_PATH, HTTP_OK, INCORRECT_FORMAT, INTERNAL_SERVER_ERROR} from '@app/common/constants';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import NetworkService from '@app/services/network/NetworkService';
import {select} from 'redux-saga/effects';
import {
  fetchInventoryListCreatorTypeName,
  fetchInventoryMasterDataCreatorTypeName,
  getInventoryRuleCreatorTypeName,
  inventoryComplianceSelector,
  inventoryComplianceStateActions,
  saveInventoryRuleCreatorTypeName,
  downloadInventoryRuleCreatorTypeName,
  deactivateInventoryRuleCreatorTypeName,
  downloadSampleBulkUploadCreatorTypeName,
  bulkUplodInventoryRuleCreatorTypeName,
} from './index';
import {
  getInventoryRequestObject,
  transformFilters,
} from '@app/helper/inventory-compliance';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import dayjs from 'dayjs';

/**
 * Fetch Inventory Data
 */
export function* fetchInventoryMasterDataWatcher() {
  yield takeLatest(
    fetchInventoryMasterDataCreatorTypeName,
    fetchInventoryMasterDataWorker,
  );
}

export function* fetchInventoryMasterDataWorker(): any {
  try {
    const url = API_PATH.inventoryCompliance.getInventoryMasterData;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        inventoryComplianceStateActions.setInventoryMasterData(response?.data),
      );
      yield put(
        inventoryComplianceStateActions.setSelectedFY(
          response?.data?.financialYear[0],
        ),
      );
    }
  } catch (error) {}
}

/**
 * Fetch Inventory Data
 */
export function* fetchInventoryListWatcher() {
  yield takeLatest(fetchInventoryListCreatorTypeName, fetchInventoryListWorker);
}

export function* fetchInventoryListWorker(): any {
  try {
    const obj = yield select(
      inventoryComplianceSelector.getInventoryComplianceState(),
    );
    const request = getInventoryRequestObject(obj);
    const url = API_PATH.inventoryCompliance.inventoryList;
    const response = yield call(NetworkService.post, url, request);
    if (response?.status === HTTP_OK) {
      yield put(
        inventoryComplianceStateActions.setInventoryComplianceList(
          response?.data?.inventoryGridData,
        ),
      );
      yield put(
        inventoryComplianceStateActions.setTotalRowsInGrid(
          response.data?.totalRecordCount,
        ),
      );
      const updatedFilters = transformFilters(
        response?.data?.inventoryFiltersData,
        obj?.filters,
      );
      yield put(filterStateActions.setFilterState(updatedFilters));
    }
  } catch (error) {}
}

/**
 * Get Inventory Compliance Rule
 */
export function* getInventoryRuleWatcher() {
  yield takeLatest(getInventoryRuleCreatorTypeName, getInventoryRuleWorker);
}

export function* getInventoryRuleWorker(action: any): any {
  try {
    const obj = yield select(
      inventoryComplianceSelector.getInventoryComplianceState(),
    );
    const url = `${API_PATH.inventoryCompliance.getInventoryRule}${action.payload}`;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response?.status === HTTP_OK) {
      yield put(
        inventoryComplianceStateActions.setEditableData(response?.data?.[0]),
      );
    }
  } catch (error) {}
}

/**
 * Save Inventory Compliance Rule
 */
export function* saveInventoryRuleWatcher() {
  yield takeLatest(saveInventoryRuleCreatorTypeName, saveInventoryRuleWorker);
}

export function* saveInventoryRuleWorker(action: any): any {
  try {
    const url = API_PATH.inventoryCompliance.saveInventoryRule;
    const response = yield call(NetworkService.post, url, action.payload);
    if (response?.status === HTTP_OK) {
      yield put(
        inventoryComplianceStateActions.setSaveInventoryResponse({
          response: response?.data,
          isEdit: action?.payload?.isUpdate,
        }),
      );
    }
  } catch (error) {}
}

/**
 * Download Inventory Compliance Rule
 */
export function* downloadInventoryRuleWatcher() {
  yield takeLatest(downloadInventoryRuleCreatorTypeName, downloadInventoryRuleWorker);
}

export function* downloadInventoryRuleWorker(action: any): any {
  try {    
    const url = API_PATH.inventoryCompliance.downloadInventoryExcelRule;
    const response = yield call(NetworkService.post, url, action.payload);
    if (response.status == HTTP_OK) {
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
      const outputFilename = `inventory_${dayjs().format('DD-MM-YYYY-HH:mm:ss')}.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      yield put(
        inventoryComplianceStateActions.setDownloadExcelError(
          {message: 'Something went wrong. Please try again.'},
        ),
      );
    }
  } catch (error) {}
}
/**
 * Deactivate Inventory Compliance Rule
 */
export function* deactivateInventoryRuleWatcher() {
  yield takeLatest(deactivateInventoryRuleCreatorTypeName, deactivateInventoryRuleWorker);
}

export function* deactivateInventoryRuleWorker(action: any): any {
  try {
    const {remarks, inventoryId, endDate} = action.payload;
    const url = `${API_PATH.inventoryCompliance.disableInventoryRule}${inventoryId}`;
    const response = yield call(NetworkService.post, url, {remarks, endDate});
    if (response?.status === HTTP_OK) {
      yield put(
        inventoryComplianceStateActions.setDeactivateInventoryResponse(response?.data),
      );
    }
  } catch (error) {}
}
/**
 * Download Sample Bulk Upload Inventory Compliance Rule
 */
export function* downloadSampleBulkUploadWatcher() {
  yield takeLatest(downloadSampleBulkUploadCreatorTypeName, downloadSampleBulkUploadWorker);
}

export function* downloadSampleBulkUploadWorker(): any {
  try {
    const url = API_PATH.inventoryCompliance.downloadSampleBulkUploadFile;
    const response = yield call(NetworkService.get, url, {}, {});
    if (response.status == HTTP_OK) {
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data}`;
      const outputFilename = `sample-inventory-upload.xlsx`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    }
  } catch (error) {}
}
/**
 * Upload Bulk Upload Inventory Compliance Rule
 */
export function* bulkUploadInventoryRuleWatcher() {
  yield takeLatest(bulkUplodInventoryRuleCreatorTypeName, bulkUploadInventoryRuleWorker);
}

export function* bulkUploadInventoryRuleWorker(action: any): any {
  try {
    const {selectedFY, file } = action.payload;
    const url = `${API_PATH.inventoryCompliance.bulkUploadInventoryFile}${selectedFY}`;
    const data = new FormData();
    data?.append('file', file);
    const response = yield call(NetworkService.post, url, data);
    if (response.status == HTTP_OK) {
      yield put(
        inventoryComplianceStateActions.setBulkUploadInventoryResponse(response?.data),
      );
      if(response?.data?.status === 1) {
        const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${response.data?.errorResponse}`;
        const outputFilename = `inventory_error_report_${dayjs().format('DD-MM-YYYY-HH:mm:ss')}.xlsx`;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      }
    }
    else{
      yield put(
        inventoryComplianceStateActions.setBulkUploadInventoryResponse(response?.data),
      );
    }
  } catch (error) {}
}
