import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import '@app/screens/module-specific-utilities/components/approve-expenses/styles/templateStyle.css';
import {expenseReportsStateSelector} from '@app/screens/module-specific-utilities/pages/expense-reports/redux/selectors';
import {Box, Stack} from '@mui/system';
import {CircularProgress} from '@mui/material';
import moment from 'moment';
import {useEffect} from 'react';
import {expenseReportsActions} from '@app/screens/module-specific-utilities/pages/expense-reports/redux';
import { strings } from '@app/common/strings';

export const tableRowKeys = [
  'sapCode',
  'staffFullName',
  'designationName',
  'dateOfJoining',
  'headQuaterName',
  'stateName',
  'expenseMonth',
  'conveyanceExpenseAmount',
  'taAmount',
  'taTollTaxAmount',
  'taFlightTrainAmount',
  'taTaxiFareAmount',
  'taRailwayPassAmount',
  'travellingExpenseAmount',
  'telephoneAndInternetAmount',
  'courierAndPostalAmount',
  'transTransportAmount',
  'transRickshawAmount',
  'freightAndCartageAmount',
  'stationaryAmount',
  'xeroxAmount',
  'printingAndStationaryAmount',
  'miscExpenseAmount',
  'memberSubscriptionAmount',
  'trainingAndSalesAmount',
  'businessPromoExpAmount',
  'moiE_194_C_Amount',
  'moiE_194_J_Amount',
  'moiE_194_I_Amount',
  'moiE_194_Q_Amount',
  'moiE_194_H_Amount',
  'moiE_194_NO_TDS_Amount',
  'totalAmount',
];
export const parentHeader = [
  'Conveyance_Expenses',
  'Travelling_Expense',
  'Freight & Cartage',
  'Printing & Stationary',
];
export const ExpenseBifurcationReport = () => {
  const dispatch = useDispatch();
  const data = useSelector(
    expenseReportsStateSelector.getReportBifurcate(),
    shallowEqual,
  );

  let montths = data?.items?.map((key: any, i: number) => {
    const month = moment(key?.expenseMonth, 'M').format('MMM');
    const year = key?.expenseYear;
    return `${month}'${year}`;
  });

  useEffect(() => {
    if (data?.items) {
      dispatch(expenseReportsActions.setBifurcationMonth(montths));
    }
  }, [data]);
  const BILoading = useSelector(
    expenseReportsStateSelector.getBILoading(),
    shallowEqual,
  );

  const header = [
    'S.No',
    'Emp. Code',
    'Name',
    'Designation',
    'D.O.J',
    'Head Qtr',
    'State',
    'Month',
    'Conveyance_Expenses',
    'T.A',
    'Toll Tax',
    'Flight/Train Fare',
    'Taxi Fare',
    'Railway Pass',
    'Travelling_Expense',
    'Telephone And Net',
    'Postage & Courier',
    'Trans.',
    'Rikshaw',
    'Freight & Cartage',
    'Stationary',
    'Xerox',
    'Printing & Stationary',
    'Misc. Expense',
    'Member & Subscription',
    'Training_Sales',
    'Busi. Promo Exp.',
    'MOIE 194C',
    'MOIE 194J',
    'MOIE 194I',
    'MOIE 194Q',
    'MOIE 194H',
    'MOIE 194 NO TDS',
    'Amount',
  ];

  if (BILoading) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <CircularProgress />
      </Box>
    );
  }
  if (data?.items?.length === 0) {
    return (
      <Stack
        height="100%"
        alignItems="center"
        justifyContent="center"
        padding={30}>
        {strings.ModuleSpecificUtilitie.NoRecordsFound}
      </Stack>
    );
  }
  return (
    
    <div
      style={{
        fontFamily: 'Balsamiq-Sans, serif',
        letterSpacing: '0.01px',
        overflow: 'auto',
        marginTop: 10,
        maxHeight: 450,
      }}>
        { data?.items?.length > 0 &&
      (<><header>
        <h3 style={{ textAlign: 'center', fontSize: 18 }}>{strings.ModuleSpecificUtilitie.expenseReportPage.Expense_Bifurcation}</h3>
        <div
          style={{
            width: '100%',
            fontWeight: 'bold',
            marginLeft: 4,
            fontSize: 16,
          }}>
          <div>
            <span>Division:</span>
            <span>{data?.header?.divisionName}</span>
          </div>
        </div>
      </header><div style={{ width: '160%', marginTop: '2%' }}>
          <table style={{ width: '160%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                {header.map((headerData: string) => (
                  <th
                    key={headerData}
                    style={{
                      border: '1px solid black',
                      textAlign: 'center',
                      fontSize: 12,
                      width: 'fit-content',
                      fontWeight: 'bold',
                      backgroundColor: parentHeader.includes(headerData)
                        ? 'yellow'
                        : 'white',
                    }}>
                    {headerData}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>

              {data?.items?.map((tableRow: any, index: number) => (
                <tr key={tableRow}>
                  <td
                    style={{
                      border: '1px solid black',
                      padding: '8px',
                      fontSize: 12,
                      textAlign: 'center',
                    }}>
                    {index + 1}
                  </td>
                  {tableRowKeys?.map((key: any) => (
                    <td
                      key={key}
                      style={{
                        border: '1px solid black',
                        borderCollapse: 'collapse',
                        fontSize: 12,
                        fontWeight: tableRow.section !== 'Row' ? 'normal' : 'bold',
                        textAlign: 'center',
                      }}>
                      {(() => {
                        if (key === 'expenseMonth') {
                          const month = moment(tableRow[key], 'M').format('MMM');
                          const year = tableRow['expenseYear'];
                          return `${month}'${year}`;
                        } else if (key === 'dateOfJoining') {
                          return moment(tableRow[key])?.format('D-MMM-YY');
                        } else if (key === 'totalAmount') {
                          return <strong>{tableRow[key]}</strong>;
                        } else {
                          return tableRow[key];
                        }
                      })()}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                {[0, 1, 2, 3, 4, 5, 6, 7].map(value => {
                  if (value === 7) {
                    return (
                      <td
                        key={value}
                        style={{
                          border: '1px solid black',
                          padding: '8px',
                          fontSize: 12,
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}>
                        {'Total'}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={value}
                        style={{
                          border: '1px solid black',
                          padding: '8px',
                          fontSize: 12,
                          textAlign: 'center',
                        }}>
                        {''}
                      </td>
                    );
                  }
                })}
                {Object?.values(data?.summary ?? {})?.map((value: any) => (
                  <td
                    key={value}
                    style={{
                      border: '1px solid black',
                      padding: '8px',
                      fontSize: 12,
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}>
                    {value}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div></>)}
    </div>
  );
};
