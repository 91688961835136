import {Grid, Typography} from '@mui/material';
import {ModelTable} from './sub-components/ModelTable';
import {DailyCallReportBox} from './sub-components/DailyCallReportBox';
import {SummaryBox} from './sub-components/SummaryBox';
import {
  DailyCallReportBoxFields,
  SummaryKeyFirstBox,
  SummaryKeySecondBox,
  DivisonBoxFields,
} from './sub-components/DcrReportFileds';
import {strings} from '@app/common/strings';
interface Props {
  Data: any;
  DailyCallReportBoxFieldsValue: any;
  DivisonBoxFieldsValue: any;
}
export default function FiledActivity(props: Readonly<Props>) {
  return (
    <>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={6}>
          <Typography sx={{textAlign: 'center', mb: 2}}>
            {strings.moduleSpecificUtilities.dailyCallReport}
          </Typography>
          <DailyCallReportBox
            Key_Fields={DailyCallReportBoxFields}
            Key_Values={props?.DailyCallReportBoxFieldsValue}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{textAlign: 'center', mb: 2}}>
            {props?.Data?.divisionName != null
              ? props?.Data?.divisionName
              : strings.moduleSpecificUtilities.noData}
          </Typography>
          <DailyCallReportBox
            Key_Fields={DivisonBoxFields}
            Key_Values={props?.DivisonBoxFieldsValue}
          />
        </Grid>
      </Grid>
      {props?.Data?.fieldActivities?.length > 0 ||
      props?.Data?.fieldActivityItems?.length > 0 ||
      props?.Data?.nonFieldActivities?.length > 0 ? (
        <ModelTable Data={props?.Data} />
      ) : (
        <></>
      )}
      {props?.Data && Object.keys(props?.Data)?.length > 0 && (
        <Grid container spacing={2} mb={5} justifyContent={'space-between'}>
          <SummaryBox
            heading={strings.moduleSpecificUtilities.summary}
            SummaryKey_FirstBox={SummaryKeyFirstBox}
            SummaryKey_SecondBox={SummaryKeySecondBox}
            Key_Values={props?.Data?.summary}
          />
        </Grid>
      )}
    </>
  );
}
