import {strings} from '@app/common/strings';
import '../../approve-expenses/styles/templateStyle.css';

export const DailyReport = (props: any) => {
  const {header, tableData, selectedUser, getMonthInitial, monthData} = props;
  return (
    <div
      style={{
        width: '100%',
        fontFamily: 'Balsamiq-Sans, serif',
        letterSpacing: '0.01px',
      }}>
      <header>
        <h3 style={{textAlign: 'center'}}>
          {strings.moduleSpecificUtilities.dailyExpenseReport}
        </h3>
        <div style={{width: '100%'}}>
          <span style={{fontWeight: 'bold', width: '100%'}}>
            {strings.moduleSpecificUtilities.staffName}{' '}
          </span>
          <span>
            {selectedUser?.staffName}
            <b> {strings.moduleSpecificUtilities.sapCode} </b>
            {selectedUser?.sapCode}
          </span>
        </div>
        <div style={{width: '100%'}}>
          <span style={{fontWeight: 'bold', width: '100%'}}>
            {strings.moduleSpecificUtilities.designation}{' '}
          </span>
          <span>{selectedUser?.designationName}</span>
        </div>
        <div style={{width: '100%'}}>
          <span style={{fontWeight: 'bold', width: '100%'}}>
            {strings.moduleSpecificUtilities.division}{' '}
          </span>
          <span>{selectedUser?.divisionName}</span>
        </div>
        <div>
          <span style={{fontWeight: 'bold', width: '100%'}}>
            {strings.moduleSpecificUtilities.hq}{' '}
          </span>
          <span>{selectedUser?.hqName}</span>
        </div>
        <div>
          <span style={{fontWeight: 'bold', width: '100%'}}>
            {strings.moduleSpecificUtilities.month}{' '}
          </span>
          <span>
            {' '}
            {getMonthInitial?.(selectedUser?.expenseMonth, monthData)}-
            {selectedUser?.expenseYear}
          </span>
        </div>
      </header>
      <div style={{width: '100%', marginTop: '2%'}}>
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              {header?.map((headerData: string) => (
                <th
                  key={headerData}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    fontSize: 9,
                    width: '100px',
                    fontWeight: 'bold',
                  }}>
                  {headerData}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((tableRow: any) => (
              <tr key={tableRow}>
                {tableRow?.map((rowData: any, index: any) => (
                  <td
                    key={
                      rowData?.toString() +
                      index?.toString() +
                      rowData?.toString()
                    }
                    style={{
                      border: '1px solid black',
                      borderCollapse: 'collapse',
                      fontSize: 9,
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}>
                    {rowData}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
