import Typography from '@mui/material/Typography';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Button} from '@mui/material';
import {routeSelector} from '@app/router/redux';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER} from '@app/utils/analytics/constants';
import {expenseTAMasterStateActions} from '@app/screens/master-data-management/pages/expense-ta-master/redux';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {expenseDAMasterStateActions} from '@app/screens/master-data-management/pages/expense-da-master/redux';
import {SearchBarExpenseDAMaster} from './search-bar-DA-Master/searchForDA';
import {SearchBarExpenseTAMaster} from './search-bar-TA-Master/searchbar';
import {expenseTAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-ta-master/redux/selectors';
import {appSelector} from '@app/store/selectors/appSelector';
import {navbarCss} from '@app/components/widgets/navbar/navbarCss';
import {useCallback, useEffect} from 'react';
import {ExportButtons} from '@app/components/widgets/ExportButton/export-button';
import {fetchExportFileCreator} from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import {filterStateActions} from '@app/components/right-sidebar/redux';
import theme from '@app/themes';
import {strings} from '@app/common/strings';

export const MasterDataManagementBar = () => {
  const dispatch = useDispatch();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );

  const IsExpenseDaMaster = useSelector(
    expenseDAMasterStateSelector.getIsExpenseDaMaster(),
  );
  const IsExpenseTaMaster = useSelector(
    expenseTAMasterStateSelector.getIsExpenseTaMaster(),
  );
  const isExcelDownloading = useSelector(
    expenseDAMasterStateSelector.getIsExcelDownloading(),
  );

  const {isWriteAccess = false} = useSelector(
    appSelector.getUserInfo(),
    shallowEqual,
  );
  const manageAddNewPopupTA = useCallback(() => {
    dispatch(
      expenseTAMasterStateActions.setaddExpenseTAMasterPopupVisible(true),
    );
  }, []);
  const manageAddNewPopup = useCallback(() => {
    trackEvent(EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER, {});
    dispatch(
      expenseDAMasterStateActions.setaddExpenseDAMasterPopupVisible(true),
    );
  }, []);
  const exportButton = (e: any) => {
    dispatch(fetchExportFileCreator(e.target.name));
  };

  useEffect(() => {
    return () => {
      dispatch(
        filterStateActions.setFilterOptions({
          isActive: true,
          searchText: '',
          pageNumber: 0,
          pageLimit: 100,
          divisionIds: [],
          designationIds: [],
          hqIds: [],
        }),
      );
    };
  }, []);
  return (
    <div style={navbarCss.containercss}>
      <div style={navbarCss.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={theme.colors.LIGHT_NAVY_BLUE}
          fontWeight={theme.sizing.weight800}>
          {navbarComponentHeading}
        </Typography>
        <div style={navbarCss.flexSpaceBetween}>
          {IsExpenseDaMaster && (
            <>
              <ExportButtons
                onClick={exportButton}
                isWriteAccess={isWriteAccess}
                name={'expensedamasters'}
                isExcelDownloading={isExcelDownloading}
              />
              <Button
                variant="contained"
                disabled={!isWriteAccess}
                sx={navbarCss.button}
                data-testid="ExpenseDaMaster"
                onClick={manageAddNewPopup}>
                {strings.AddNew}
              </Button>
            </>
          )}
          {IsExpenseTaMaster && (
            <>
              <ExportButtons
                onClick={exportButton}
                isWriteAccess={isWriteAccess}
                name={'expensetamasters'}
                isExcelDownloading={isExcelDownloading}
              />
              <Button
                variant="contained"
                disabled={!isWriteAccess}
                sx={navbarCss.button}
                data-testid="ExpenseTaMaster"
                onClick={manageAddNewPopupTA}>
                {strings.AddNew}
              </Button>
            </>
          )}
        </div>
      </div>
      {IsExpenseDaMaster && <SearchBarExpenseDAMaster />}
      {IsExpenseTaMaster && <SearchBarExpenseTAMaster />}
    </div>
  );
};
