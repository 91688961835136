import {Box, Button, Container, Link} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useDispatch, useSelector} from 'react-redux';
import {dailyDataGrid, useStyles} from '@app/screens/Styles/style';
import {
  downloadExpenseFileNumberTempCreator,
  expenseStateActions,
  fetchExpenseFileNumberCreator,
} from '@app/screens/module-specific-utilities/pages/expense/redux/slice';
import {expenseStateSelector} from '@app/screens/module-specific-utilities/pages/expense/redux/selectors';
import saveAs from 'file-saver';
import {
  bgColourFontBold,
  fitToColumnExcelJs,
} from '@app/screens/module-specific-utilities/components/approve-expenses/ApproveExpenseDetailedTabVIew';
import Excel from 'exceljs';
import { strings } from '@app/common/strings';

export const util: any = {downloadExcel: null};
const styles = {
  reportNames:{
    
  }}
export const ExpenseFileNumberGenerate = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [csvFile, setCsvFile] = useState<any>({name: ''});
  const [csvArray, setCsvArray] = useState<
    {StaffCode: string; ExpNo: string}[] | []
  >([]);

  const errorres: any[] = useSelector(expenseStateSelector.getSaveRes()) ?? [];
  const processCSV = (str: any, delim = ',') => {
    setCsvArray([]);
    const headers = str
      .slice(0, str.indexOf('\n'))
      .split(delim)
      .map((header: any) => header.trim());
    const rows = str.slice(str.indexOf('\n') + 1).split('\n');
    const newArray = rows.map((row: any) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj: any, header: any, i: any) => {
        obj[header] = values[i]?.replace(/\r$/, '');
        return obj;
      }, {});
      const isEmptyRow = values.every((value: any) => value === '');
      return isEmptyRow ? null : eachObject;
    });

    const filteredArray = newArray.filter(
      (element: any) => element?.StaffCode && Boolean,
    );
    let result = filteredArray?.filter(
      (record: {StaffCode: any; ExpNo: any}, index: any) =>
        index ===
          filteredArray.findIndex(
            (other: {StaffCode: any; ExpNo: any}) =>
              record?.StaffCode === other?.StaffCode,
          ) && Boolean,
    );
    if (
      filteredArray?.length !== 0 &&
      result?.length == filteredArray?.length
    ) {
      setCsvArray(result);
    } else if (filteredArray?.length === 0) {
      ToasterService.showToaster(
        strings.ModuleSpecificUtilitie.expenseFileNumberPage.errmsg,
        ToastType.ERROR,
      );
    } else {
      ToasterService.showToaster(
        strings.ModuleSpecificUtilitie.expenseFileNumberPage.duplicatecheck,
        ToastType.ERROR,
      );
    }
  };

  const getkeyValuePairsForXlsx = (values: any, keys: any) =>
    values.map((row: any) =>
      keys.reduce((acc: any, key: any, index: any) => {
        acc[key] = row[index];
        return acc;
      }, {}),
    );

  const getFilteredValForXlsx = (values: any) =>
    values.filter((item: any) => item);

  const processXlsx = (text: any) => {
    const data = new Uint8Array(text as ArrayBuffer);
    const workbook = new Excel.Workbook();
    workbook.xlsx.load(data.buffer).then(() => {
      const worksheet = workbook.getWorksheet(1);
      if (worksheet) {
        const dataObj: any = [];
        worksheet.eachRow({includeEmpty: false}, row => {
          const values: any = row.values;
          const filteredVal = getFilteredValForXlsx(values);
          dataObj.push(filteredVal);
        });
        const [excelKeys, ...values]: any = dataObj;
        const keyValuePairs = getkeyValuePairsForXlsx(values, excelKeys);
        setCsvArray(keyValuePairs);
      }
    });
  };

  const submit = (e: any) => {
    const file = csvFile;
    const reader = new FileReader();
    const name = csvFile?.name.split('.')[0];
    const validName = strings.ModuleSpecificUtilitie.expenseFileNumberPage.validNameTemp;
    if (!name.includes(validName)) {
      ToasterService.showToaster(
        strings.ModuleSpecificUtilitie.expenseFileNumberPage.invalidFile,
        ToastType.ERROR,
      );
    } else {
      reader.onload = function (e) {
        const text = e.target?.result;

        if (csvFile?.name.split('.')[1] === 'csv') {
          processCSV(text);
        } else if (csvFile?.name.split('.')[1] === 'xlsx') {
          processXlsx(text);
        }
      };
      if (file) {
        if (csvFile?.name.split('.')[1] === 'csv') {
          reader.readAsText(file);
        } else if (csvFile?.name.split('.')[1] === 'xlsx') {
          reader.readAsArrayBuffer(file);
        } else {
          ToasterService.showToaster(
           strings.ModuleSpecificUtilitie.expenseFileNumberPage.uploadFile,
            ToastType.ERROR,
          );
        }
      }
    }
  };

  const downloadExcel = () => {
    const wb = new Excel.Workbook();
    let data = errorres.map((rest: any) => ({
      ...{
        employeeCode: rest.employeeCode,
        expNumber: rest.expNumber,
        status: rest.status,
      },
    }));
    const ws = wb.addWorksheet(strings.ModuleSpecificUtilitie.expenseFileNumberPage.expenseFileNumber);
    let headers = ['Employee Code', 'Expense Number', 'Status Code'];
    ws.addRow(headers);
    data.forEach((rowData: any) => {
      ws.addRow(Object.values(rowData));
    });
    bgColourFontBold(ws);
    fitToColumnExcelJs(ws, data, 5);
    wb.xlsx
      .writeBuffer()
      .then((buffer: any) => {
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const fileName = strings.ModuleSpecificUtilitie.expenseFileNumberPage.fileName;
        saveAs(blob, fileName);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  const save = () => {
    const options: any = {};
    csvArray?.map((item: any) => {
      options[item.StaffCode] = item.ExpNo;
      return null;
    });

    dispatch(fetchExpenseFileNumberCreator(options));
  };
  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.commonheader,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeadingEmpty}>
         {strings.ModuleSpecificUtilitie.expenseFileNumberPage.expenseFileNumber}
        </div>,
      ),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'StaffCode',
      headerName: 'Employee Code',
      width: 200,
      editable: false,
    },
    {
      field: 'ExpNo',
      headerName: 'Expense Number',
      width: 200,
      editable: false,
    },
  ];

  const columns1: GridColDef[] = [
    {
      field: 'employeeCode',
      headerName: 'Employee Code',
      width: 200,
      editable: false,
    },
    {
      field: 'expNumber',
      headerName: 'Expense Number',
      width: 200,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 198,
      editable: false,
    },
  ];

  const downloadBase = () => {
    dispatch(downloadExpenseFileNumberTempCreator(null));
  };
  util.downloadExcel = downloadExcel;
  return (
    <div>
      <Container maxWidth={false}>
        <Box
          display={'flex'}
          width={'100%'}
          justifyContent={'center'}
          flexDirection={'row'}
          fontSize={'2rem'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0px',
              width: '600px',
              padding: '20px',
              border: '1px solid #eeebeb',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                alignSelf: 'flex-start',
                width: '100%',
              }}>
              <div>
                <label htmlFor="contained-button-file">
                  <input
                    hidden
                    data-testid="goButton-input"
                    accept=".csv,.xlsx"
                    id="contained-button-file"
                    type="file"
                    onChange={(e: any) => {
                      setCsvArray([]);
                      dispatch(expenseStateActions.setSaveRes([]));
                      setCsvFile(e.target.files[0]);
                      e.target.value = null;
                    }}
                  />
                  <Button
                    data-testid="goButton"
                    variant="contained"
                    component="span">
                    {strings.ModuleSpecificUtilitie.expenseFileNumberPage.browseFileName}
                  </Button>
                </label>
              </div>
              <div
                style={{
                  marginTop: 20,
                  userSelect: 'none',
                  fontSize: '12px',
                  margin: 10,
                }}>
                {csvFile?.name}
              </div>
            </div>
            <Box sx={{justifyContent: 'space-between', width: '100%'}}>
              <div className={classes.downloadtemp}>
                <Link
                  className={'toLink'}
                  onClick={downloadBase}
                  data-testid="download">
                 {strings.ModuleSpecificUtilitie.expenseFileNumberPage.downloadTemplate}
                </Link>
              </div>
            </Box>
            <Button
              data-testid="submit-button-go"
              variant="outlined"
              sx={{m: 1, alignSelf: 'flex-end'}}
              disabled={csvFile?.name === ''}
              onClick={submit}>
             {strings.get}
            </Button>
            <div
              style={{
                width: '100%',
                height: '50vh',
                fontSize: 2,
                borderWidth: 3,
                backgroundColor: 'rgb(245, 245, 255)',
              }}>
              {csvArray.length > 0 && csvArray[0].StaffCode ? (
                <DataGrid
                  columns={columns}
                  rows={csvArray}
                  getRowId={(row: any) => row.StaffCode}
                  hideFooterPagination
                  hideFooter
                />
              ) : (
                <></>
              )}
            </div>
            <div>
              <Button
                variant="contained"
                data-testid="save-button"
                sx={{margin: 'auto', marginRight: '2px'}}
                onClick={save}
                disabled={!(csvArray.length > 0 && !!csvArray[0].StaffCode)}>
                {strings.save}
              </Button>{' '}
              <span style={{fontSize: '0.9rem'}}>
                Rows in File:{' '}
                {csvArray.length > 0 && !!csvArray[0].StaffCode
                  ? csvArray.length
                  : 0}
              </span>
            </div>
          </div>
        </Box>
        {errorres.length > 0 && (
          <Box
            display={'flex'}
            width={'100%'}
            justifyContent={'center'}
            flexDirection={'row'}
            marginTop={'5px'}>
            <div>
              <div style={{textAlign: 'right', cursor: 'pointer'}}>
                <Link className={'toLink'} onClick={downloadExcel}>
                {strings.ModuleSpecificUtilitie.expenseFileNumberPage.downloadReport}
                </Link>
              </div>
              <Box
                sx={[
                  dailyDataGrid(400, 75),
                  {width: '600px', marginTop: '5px'},
                ]}>
                <DataGrid
                  rows={errorres}
                  columns={columns1}
                  getRowId={row => row.employeeCode + row.expNumber}
                  hideFooterPagination
                  hideFooter
                />
              </Box>
            </div>
          </Box>
        )}
      </Container>
    </div>
  );
};
