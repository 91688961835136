import {strings} from '@app/common/strings';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface ConfirmationPopupProps {
  open: boolean;
  title: string;
  message: string;
  confirm: () => void;
  cancel: () => void;
}

export const ConfirmationPopup = (props: ConfirmationPopupProps) => {
  const {open, title, message, confirm, cancel} = props;
  return (
    <Dialog sx={{width: '100%'}} open={open} onClose={() => cancel()}>
      <DialogTitle
        color={'black'}
        fontSize={12}
        textAlign={'center'}
        paddingBottom={'2px !important'}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" fontSize={14}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          paddingBottom: '32px',
          paddingInline: '50px',
        }}>
        <Button variant="outlined" onClick={cancel}>
          {strings.no}
        </Button>
        <Button variant="contained" onClick={confirm}>
          {strings.yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
