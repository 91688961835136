import { API_PATH } from "@app/common/constants";
import { directoryActions } from "@app/screens/directory-configuration/redux";
import { ToasterService } from "@app/services";
import NetworkService from "@app/services/network/NetworkService";
import { ToastType } from "@app/services/toaster";
import { call, put, takeLatest } from "redux-saga/effects";
import { hoContactCategoryTypeName, hoContactCategoryActions } from "./slice";
import { directoryPageLists } from "@app/screens/directory-configuration/redux/slice";

/**
 * Fetch Ho Contact Category watcher
 */
export function* fetchHoContactCategoryWatcher() {
  yield takeLatest(hoContactCategoryTypeName, fetchHoContactCategoryWorker);
}

/**
 * Fetch Ho Contact Category worker
 *
 * @param action
 */
function* fetchHoContactCategoryWorker(action: any): any {
  const { index, isActive, query } = action.payload;

  yield put(
    directoryActions.updateLoading(
      API_PATH.directoryConfiguration.hoContactCategory
    )
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.directoryConfiguration.hoContactCategory,
      { pageIndex: index, pageSize: 20, isActive, name: query || "" },
      {}
    );
    yield put(directoryActions.updateLoading(null));
    if (index === 1) {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.hoContactCategory,
          count: response?.data[0]?.totalRecords || 0,
          pageIndex: index,
        })
      );
      yield put(hoContactCategoryActions.fetchHoContactCategory(response.data));
    } else {
      yield put(
        directoryActions.updateTotalRecords({
          listing: directoryPageLists.hoContactCategory,
          pageIndex: index,
        })
      );
      yield put(
        hoContactCategoryActions.fetchHoContactNewCategory(response?.data)
      );
    }
  } catch (error) {
    yield put(directoryActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}
