import {createSelector} from '@reduxjs/toolkit';

export const getexpenseDAMasterStateSelector = (stateName: string) => {
  return createSelector(
    [(state: any) => state.expenseDAMaster[stateName]],
    state => state,
  );
};
export const expenseDAMasterStateSelector = {
  getExpenseDAMasterGrid: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.expenseDAMasterGrid],
      expenseDAMasterGrid => expenseDAMasterGrid,
    );
  },
  getExpenseDAMasterTotalCount: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.totalCount],
      totalCount => totalCount,
    );
  },
  getExpenseDAMasterSnaShot: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.expenseDAMasterSnaShot],
      expenseDAMasterSnaShot => expenseDAMasterSnaShot,
    );
  },
  getDesignationArr: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.designationArr],
      designationArr => designationArr,
    );
  },
  getHqType: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.hqType],
      hqType => hqType,
    );
  },
  getPageNo: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.pageNo],
      pageNo => pageNo,
    );
  },
  getRowPerPage: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.rowPerPage],
      rowPerPage => rowPerPage,
    );
  },
  getIsActiveState: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.isActiveState],
      isActiveState => isActiveState,
    );
  },
  getExpenseDAMasterLoading: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.expenseDAMasterLoading],
      expenseDAMasterLoading => expenseDAMasterLoading,
    );
  },
  getSearchText: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.searchText],
      searchText => searchText,
    );
  },
  getExpensDAMasterInAutoSuggestFilter: () => {
    return createSelector(
      [
        (state: any) =>
          state.expenseDAMaster.expenseDAMasterInAutoSuggestFilter,
      ],
      expenseDAMasterInAutoSuggestFilter => expenseDAMasterInAutoSuggestFilter,
    );
  },
  getExpenseDAMasterInAutoSuggest: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.expenseDAMasterInAutoSuggest],
      expenseDAMasterInAutoSuggest => expenseDAMasterInAutoSuggest,
    );
  },
  getIsExpenseDaMaster: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.isExpenseDaMaster],
      isExpenseDaMaster => isExpenseDaMaster,
    );
  },
  getAdminUsersLoading: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.adminUsersLoading],
      adminUsersLoading => adminUsersLoading,
    );
  },
  getaddExpenseDAMasterPopupVisible: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.addExpenseDAMasterPopupVisible],
      addExpenseDAMasterPopupVisible => addExpenseDAMasterPopupVisible,
    );
  },
  getEditExpenseDAMasterPopupVisible: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.editExpenseDAMasterPopupVisible],
      editExpenseDAMasterPopupVisible => editExpenseDAMasterPopupVisible,
    );
  },
  getEditExpenseDAMasterValue: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.editExpenseDAMasterValue],
      editExpenseDAMasterValue => editExpenseDAMasterValue,
    );
  },
  getExpenseDAMasterNewData: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.expenseDAMasterNewData],
      expenseDAMasterNewData => expenseDAMasterNewData,
    );
  },
  getExpenseDAMasterExistingData: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.expenseDAMasterExistingData],
      expenseDAMasterExistingData => expenseDAMasterExistingData,
    );
  },
  getHQDropdown: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.HQDropdown],
      HQDropdown => HQDropdown,
    );
  },
  getHqByDivisionDesignation: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.hqByDivisionDesignation],
      hqByDivisionDesignation => hqByDivisionDesignation,
    );
  },
  getDesignationsDropdown: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.designationsDropdown],
      designationsDropdown => designationsDropdown,
    );
  },
  getResetNavbarVersion: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.resetNavbarVersion],
      resetNavbarVersion => resetNavbarVersion,
    );
  },
  getExpenseDAMasterAutoSuggestLoading: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.expenseDAMasterAutoSuggestLoading],
      expenseDAMasterAutoSuggestLoading => expenseDAMasterAutoSuggestLoading,
    );
  },
  getIsExcelDownloading: () => {
    return createSelector(
      [(state: any) => state.expenseDAMaster.isExcelDownloading],
      isExcelDownloading => isExcelDownloading,
    );
  },
};
