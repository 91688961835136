import {strings} from '@app/common/strings';
import {Typography} from '@mui/material';
import moment from 'moment';
import React from 'react';

const MonthlyReport = (props: any) => {
  const {
    data,
    header,
    tableData,
    selectedUser,
    expenseNumber,
    getMonthInitial,
    monthData,
  } = props;
  const date = new Date();
  const minutes = date.getMinutes();
  const dateStr = `${date.toLocaleDateString()} ${date.getHours()}:${
    minutes < 10 ? `0${minutes}` : minutes
  }`;
  const passedBy = data[0]?.footer?.passedBy || '';
  const approvedBy = data[0]?.footer?.approvedBy || '';

  const approvedOnDate = data[0]?.footer?.approvedOn
    ? moment
        .utc(data[0]?.footer?.approvedOn)
        .local()
        .format('DD/MM/YYYY, h:mm a')
    : '';

  const passOnDate = data[0]?.footer?.passedOn
    ? moment.utc(data[0]?.footer?.passedOn).local().format('DD/MM/YYYY, h:mm a')
    : '';

  const isRejectedOrUnapproved = (status: number, isApprovedBy: boolean) => {
    if (status === 7 || status === 9) {
      return '';
    }
    return isApprovedBy ? approvedBy : approvedOnDate;
  };

  return (
    <div
      style={{
        width: '100%',
        fontFamily: 'Balsamiq-Sans, serif',
        letterSpacing: '0.01px',
      }}>
      <header>
        <h3 style={{textAlign: 'center'}}>
          {strings.moduleSpecificUtilities.monthlyExpenseReport}
        </h3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>
              <div style={{width: '100%'}}>
                <span style={{fontWeight: 'bold', width: '100%'}}>
                  {strings.moduleSpecificUtilities.staffName}
                </span>
              </div>
              <div style={{width: '100%'}}>
                <span style={{fontWeight: 'bold', width: '100%'}}>
                  {strings.moduleSpecificUtilities.designation}
                </span>
              </div>
              <div>
                <span style={{fontWeight: 'bold', width: '100%'}}>
                  {strings.moduleSpecificUtilities.division}
                </span>
              </div>
              <div>
                <span style={{fontWeight: 'bold', width: '100%'}}>
                  {strings.moduleSpecificUtilities.hq}{' '}
                </span>
              </div>
              <span style={{fontWeight: 'bold', width: '100%'}}>
                {strings.moduleSpecificUtilities.month}{' '}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                marginLeft: 20,
              }}>
              <span>
                {selectedUser?.staffName}{' '}
                <b>
                  {strings.ModuleSpecificUtilitie.reverseExpensepage.SAP_CODE}
                </b>{' '}
                -{selectedUser?.sapCode}
              </span>
              <span>{selectedUser?.designationName}</span>
              <span>{selectedUser?.divisionName}</span>
              <span> {selectedUser?.hqName}</span>
              <span>
                {getMonthInitial?.(selectedUser?.expenseMonth, monthData)}-
                {selectedUser?.expenseYear}
              </span>
            </div>
          </div>
          <h1
            style={{
              textAlign: 'end',
              flexGrow: 1.5,
              bottom: 75,
              right: 84,
              position: 'relative',
            }}>
            {strings.moduleSpecificUtilities.expNum} {expenseNumber}
          </h1>
        </div>
      </header>

      <div style={{width: '100%', marginTop: '2%'}}>
        <table style={{width: '100%', borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              {header?.map((headerData: string) => (
                <th
                  key={headerData}
                  style={{
                    border: '1px solid black',
                    textAlign: 'center',
                    fontSize: 15,
                    width: '100px',
                    fontWeight: 'normal',
                  }}>
                  {headerData}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.length ? (
              tableData?.map((tableRow: any, index: number) => {
                const isBold =
                  index === tableData?.length - 5 ||
                  index === tableData?.length - 1 ||
                  index === tableData?.length - 2;
                const lastThreeRows =
                  index >= tableData?.length - 4 &&
                  index <= tableData?.length - 1;
                const isGrandTotal = tableData[index][0] === 'Grand Total';
                return (
                  <tr
                    key={tableRow?.id}
                    style={{
                      fontWeight: isBold ? 800 : 'unset',
                    }}>
                    {tableRow?.map((rowData: any, index: number) => (
                      <td
                        key={
                          rowData?.toString() +
                          index?.toString() +
                          rowData?.toString()
                        }
                        style={{
                          border: lastThreeRows ? 'none' : '1px solid black',
                          borderTop:
                            !isGrandTotal && lastThreeRows
                              ? 'none'
                              : '1px solid black',
                          borderBottom:
                            !isGrandTotal && lastThreeRows
                              ? 'none'
                              : '1px solid black',
                          borderCollapse: 'collapse',
                          fontSize: 15,
                          textAlign: 'center',
                        }}>
                        {rowData}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <Typography align="center" sx={{width: '800%'}}>
                {strings.noRecordFound}
              </Typography>
            )}
          </tbody>
        </table>
        {data[0]?.monthlyExpense?.length && (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 20,
              }}>
              <p>
                {' '}
                <b> {strings.moduleSpecificUtilities.passedBy}</b> - {passedBy}
              </p>
              <p>
                {' '}
                <b> {strings.moduleSpecificUtilities.passedOn} </b> -{' '}
                {passOnDate}
              </p>
              <p>
                {' '}
                <b> {strings.moduleSpecificUtilities.approvedBy} </b> -
                {isRejectedOrUnapproved(selectedUser?.expenseStatusId, true)}
              </p>
              <p>
                {' '}
                <b> {strings.moduleSpecificUtilities.approvedOn} </b> -
                {isRejectedOrUnapproved(selectedUser?.expenseStatusId, false)}
              </p>
            </div>
            <div>
              <p>{dateStr}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(MonthlyReport);
