import {useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
} from '@mui/material';
import {useStyles} from './styles';
import {Close} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';
import {Label} from '@app/components/elements/Label';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {
  dcrDownloadExcelCreator,
  dcrUnlockRequestSelector,
} from '../../pages/dcr-unlock-request/redux';
import dayjs from 'dayjs';
import Colors from '@app/themes/colors';

export interface IProps {
  open: boolean;
  onClose: () => void;
  data?: any;
}

const DownloadExcelDialog = (props: IProps) => {
  const dialogTitle = `Download DCR Request Excel`;
  const classes = useStyles();
  const dispatch = useDispatch();
  const dcrFilters = useSelector(dcrUnlockRequestSelector.getAppliedFilters());

  const [startOpen, setStartOpen] = useState<boolean>(false);
  const [endOpen, setEndOpen] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(
    dayjs().subtract(1, 'year').add(1, 'day').toDate(),
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const startDateLimit = dayjs()
    .subtract(10, 'year')
    .set('date', 1)
    .set('month', 3)
    .toDate();

  const downloadExcel = () => {
    const payload = {
      fromDate: startDate,
      toDate: endDate,
    };
    dispatch(dcrDownloadExcelCreator(payload));
    props.onClose();
  };

  const isDivisionSelected = () =>
    dcrFilters?.divisions?.length > 0 && dcrFilters?.divisions?.length <= 10;

  const isMoreThanYear = () =>
    dayjs(endDate).diff(dayjs(startDate), 'month') >= 12;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      classes={{paper: classes.dcrDialog}}
      scroll="paper">
      <Box className={classes.dcrBoxtitle}>
        <Box className={classes.dcrTitleContainer}>
          <DialogTitle className={classes.dcrTitle}>{dialogTitle}</DialogTitle>
          {isDivisionSelected() && (
            <Label title="Downloaded report will contain the name of approvers who have approved the DCR" />
          )}
        </Box>
        {!isDivisionSelected() && (
          <IconButton
            color="primary"
            aria-label="dcr close"
            component="span"
            id="dcr_close"
            onClick={props.onClose}
            data-testid={'dcr_close'}>
            <Close />
          </IconButton>
        )}
      </Box>
      {isDivisionSelected() ? (
        <>
          <Box className={classes.container}>
            <Box className={classes.datePickerContainer}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={startOpen}
                  onOpen={() => setStartOpen(true)}
                  onClose={() => setStartOpen(false)}
                  label="Start Date"
                  value={startDate}
                  minDate={startDateLimit}
                  maxDate={endDate || new Date()}
                  inputFormat="dd-MM-yyyy"
                  onChange={newValue => {
                    setStartDate(newValue);
                  }}
                  InputProps={{
                    classes: {root: classes.root},
                  }}
                  renderInput={param => (
                    <TextField
                      {...param}
                      className={classes.datePicker}
                      onClick={() => setStartOpen(true)}
                      inputProps={{
                        ...param.inputProps,
                        placeholder: 'Start Date',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  open={endOpen}
                  onOpen={() => setEndOpen(true)}
                  onClose={() => setEndOpen(false)}
                  label="End Date"
                  value={endDate}
                  minDate={startDate ? new Date(startDate) : startDateLimit}
                  maxDate={new Date()}
                  inputFormat="dd-MM-yyyy"
                  onChange={newValue => {
                    setEndDate(newValue);
                  }}
                  InputProps={{
                    classes: {root: classes.root},
                  }}
                  renderInput={param => (
                    <TextField
                      {...param}
                      className={classes.datePicker}
                      onClick={() => setEndOpen(true)}
                      inputProps={{
                        ...param.inputProps,
                        placeholder: 'End Date',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            {isMoreThanYear() && (
              <Label
                title={'*Maximum 1 year time period can be selected'}
                textColor={Colors.red[100]}
                classes={classes.errorLabel}
                hideToolTip
              />
            )}
          </Box>
          <DialogActions>
            <Box className={classes.buttonContainer}>
              <Button
                data-testid="button-clear"
                variant="contained"
                className={classes.paperButton}
                onClick={props.onClose}>
                Cancel
              </Button>
              <Button
                data-testid="button-apply"
                variant="contained"
                className={classes.applyButton}
                disabled={
                  !startDate ||
                  !endDate ||
                  startDate > endDate ||
                  isMoreThanYear()
                }
                onClick={downloadExcel}>
                Download
              </Button>
            </Box>
          </DialogActions>
        </>
      ) : (
        <Box className={classes.container}>
          <Label
            title={
              dcrFilters?.divisions?.length >= 10
                ? 'It seems you have selected more than 10 divisions, please select at least 1 or maximum 10 divisions.'
                : 'Please select at least 1 division & maximum 10 divisions can be selected.'
            }
            classes={classes.wrapText}
            size={16}
            hideToolTip
          />
        </Box>
      )}
    </Dialog>
  );
};

export default DownloadExcelDialog;
