import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

interface NavState {
  title: string | null;
}

interface InitialStateProps {
  nav: NavState;
  loading: boolean;
  allReporters: {
    id: number;
    firstName: string;
    lastName: string;
    staffPositionId: number;
  }[];
  balanceLeave: {};
  leaveHistory: [];
  userRole: [];
  leaveFilterData: {};
  applyedFilterData: {};
  totalRowCount: number;
}
const initialState: InitialStateProps = {
  nav: {
    title: null,
  },
  loading: false,
  allReporters: [],
  balanceLeave: {},
  leaveHistory: [],
  userRole: [],
  leaveFilterData: {},
  applyedFilterData: {},
  totalRowCount: 0,
};

export const updateLeaveHolidayNavDataCreator = createAction<any>(
  'LEAVE_MANAGAMENT/UPDATE_LEAVE_NAV_DATA',
);
export const updateLeaveHolidayNavDataCreatorTypeName =
  updateLeaveHolidayNavDataCreator(null).type;

export const fetchReportersCreator = createAction<any>(
  'LEAVE_MANAGAMENT/GET_ALL_REPORTERS',
);
export const fetchReportersApiTypeName = fetchReportersCreator(null).type;

export const allBalanceLeaveCreator = createAction<any>(
  'LEAVE_MANAGAMENT/ALL_BALANCE_LEAVE',
);
export const allBalanceLeaveApiTypeName = allBalanceLeaveCreator(null).type;

export const fetchLeaveHistoryListCreator = createAction<any>(
  'LEAVE_MANAGAMENT/FETCH_LEAVE_HISTORY_LIST',
);
export const fetchLeaveHistoryApiTypeName =
  fetchLeaveHistoryListCreator(null).type;
export const fetchUserRoleDataCreator = createAction<any>(
  'LEAVE_MANAGAMENT/FETCH_USER_ROLE_FOR_REPORT',
);
export const fetchUserRoleDataCreatorTypeName =
  fetchUserRoleDataCreator(null).type;

export const fetchLeaveFilterListCreator = createAction<any>(
  'LEAVE_MANAGAMENT/FETCH_LEAVE_FILTER_LIST',
);
export const fetchLeaveApiTypesName = fetchLeaveFilterListCreator(null).type;

const leaveSlice = createSlice({
  name: 'LEAVE_CONFIGURATION',
  initialState: initialState,
  reducers: {
    updateNavData: (state, action: PayloadAction<Partial<NavState>>) => {
      state.nav = {...state.nav, ...action.payload};
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setReporters: (state, action) => {
      return {
        ...state,
        allReporters: action.payload,
      };
    },
    setBalanceLeave: (state, action) => {
      return {
        ...state,
        balanceLeave: action.payload,
      };
    },
    setLeaveHistory: (state, action) => {
      return {
        ...state,
        leaveHistory: action.payload,
      };
    },
    setUserRole: (state, action) => {
      return {
        ...state,
        userRole: action.payload,
      };
    },
    setLeaveFilterData: (state, action) => {
      return {
        ...state,
        leaveFilterData: action.payload,
      };
    },
    setApplyedFilterData: (state, action) => {
      return {
        ...state,
        applyedFilterData: action.payload,
      };
    },
    setTotalRowCount: (state, action) => {
      return {
        ...state,
        totalRowCount: action.payload,
      };
    },
  },
});

export const {
  updateNavData,
  setLoading,
  setReporters,
  setBalanceLeave,
  setLeaveHistory,
  setUserRole,
  setLeaveFilterData,
  setApplyedFilterData,
  setTotalRowCount,
} = leaveSlice.actions;

export const leaveManagmentReducer = leaveSlice.reducer;
