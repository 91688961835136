import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'end',
    gap: 50,
    marginTop: 10,
  },
  dropdown: {
    backgroundColor: '#f2f2f2',
    height: 54,
    width: 150,
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: 160,
    height: '48px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    cursor: 'pointer',
    marginBottom: 5,
    '&:hover': {
      background: Colors.primary,
    },
  },
  fileAction: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '500',
    gap: 5,
    cursor: 'pointer',
  },
  alertContainer: {
    width: '100%',
    whiteSpace: 'pre-line',
    borderRadius: '5px',
  },
  alertBox: {
    overflowY: 'scroll',
    maxHeight: '85vh',
  },
});
