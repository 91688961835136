import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

export const ConfirmationDialog = ({
  open,
  message,
  onClose,
}: {
  open: boolean;
  message?: string;
  onClose: any;
}) => {

  const handleClose = (event: any, reason: string) => {
    if (reason && reason == "backdropClick") 
      return;
    onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {message
            ? message
            : 'Changes made earlier will be discarded. Do you want to proceed?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="button-cancel" onClick={() => onClose(false)}>
          No
        </Button>
        <Button data-testid="button-ok" onClick={() => onClose(true)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
)}
  