import React from 'react';
import {useSelector} from 'react-redux';
import {StatusMangerHeader} from './StatusMangerHeader';
import {statusManagerStateSelector} from '../../pages/status-manager/redux/selectors';
import {CircularProgress} from '@mui/material';
import {Box} from '@mui/system';

export const StatusManagerContent = () => {
  const isLoading = useSelector(statusManagerStateSelector.getIsLoading());

  return (
    <React.Fragment>
      <StatusMangerHeader />
      {isLoading && (
        <Box display={'flex'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
};
