import {Box, Button} from '@mui/material';

export const Footer = ({
  onResetClick,
  onSaveClick,
  isDisable,
  hide
}: {
  onResetClick: () => void;
  onSaveClick: () => void;
  isDisable?:boolean;
  hide?: boolean

}) => {
  return (
    <Box display={'flex'} justifyContent="center" width={'100%'}>
      <Button
        data-testid="reset-button"
        variant="contained"
      
        sx={{
          backgroundColor: '#ffffff',
          color: '#322b7c',
          border: '2px solid #322b7c',
          borderRadius: '5px',
          width: '150px',
          boxShadow: 'none',
          marginRight: '10px',
          fontWeight: 'bold',
          display:hide ? 'none':'block',
          '&:hover': {
            background: 'none',
          },
        }}
        onClick={onResetClick}
      >
        Reset
      </Button>

      <Button
       disabled={isDisable}
        data-testid="save-button"
        variant="contained"

        sx={{
          color: '#322b7c',
          borderRadius: '5px',
          border: '2px solid #322b7c',
          width: '150px',
          backgroundColor: '#ffffff',
          boxShadow: 'none',
          marginRight: '10px',
          fontWeight: 'bold',
          '&:hover': {
            background: 'none',
          },
        }}
        onClick={onSaveClick}
      >
        Save
      </Button>
    </Box>
  );
};
