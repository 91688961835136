export const DatePickerStyle = {
  datepick: {
    '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      padding: '10px',
      fontSize: '12px',
    },
    '& .css-1loy7j4-MuiInputBase-root-MuiOutlinedInput-root': {
      background: '#fff',
      zIndex: 1,
      marginLeft: '5px !important'
    },
    '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
      fontSize: '12px',
      fontStyle: 'normal',
      borderColor: '#322b7c',
      borderRadius: '4px',
    },
    "& .MuiPaper-root-MuiPickersPopper-paper":{
      marginLeft:"25px !important"
    },
    '& .MuiFormControl-root-MuiTextField-root':{
      marginLeft: '5px !important'
    }
  },
};
