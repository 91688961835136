import { routeStateActions } from '@app/router/redux';
import { navbarComponentName } from '@app/router/redux/routeHandler';
import { Container} from '@mui/material';
import { makeStyles } from "@mui/styles";
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ExpenseDates } from '../../components/expense-dates';
const useStyles = makeStyles({
    navbarComponentHeading: {
      width: 194,
      height: 70.7,
      fontFamily: "Poppins",
      fontSize: 18,
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: 0,
      textAlign: "left",
      color: "#1c1939",

    },
  });

export const ExpenseDate = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    
    useEffect(() => {
        dispatch(
            routeStateActions.setNavbarComponentName(navbarComponentName.commonheader)
          );
          dispatch(
            routeStateActions.setNavbarComponentHeading(
              <div className={classes.navbarComponentHeading}>Expense Dates</div>
            )
          );
        
        return () => {
          dispatch(routeStateActions.setNavbarComponentName(null));
         
        };
      }, [classes.navbarComponentHeading, dispatch]);
  return (
    <div>
      <Container maxWidth={false}>
      <ExpenseDates />
      </Container>
    </div>
  );
};
