import { Autocomplete, Button, TableCell, TextField, styled, InputLabel, Tabs, Tab, Checkbox, FormControlLabel } from "@mui/material";



export const StyledTableCell = styled(TableCell)`
  border: 0;
  font-family: 'Poppins', sans-serif;
  word-break: break-word;
  
  &.MuiTableCell-head {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12.7px;
    color: #1c1939;
    white-space: nowrap;
  }

  &.MuiTableCell-body {
    font-weight: 500;
    font-size: 12px;
    color: #322b7c;
  }

  &.MuiTableCell-body-active {
    color: #34b53a;
  }

  &.MuiTableCell-body-inactive {
    color: #acacac;
  }
`;


export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiAutocomplete-inputRoot': {
      padding: '5.5px 14px',
    },
    '& .MuiAutocomplete-input': {
      '&::placeholder': {
        color: theme.palette.grey.A400,
      },
      fontFamily: 'Poppins, sans-serif',
      fontSize: 12.7,
      fontWeight: 'normal',
    },
  }));


  export const CustomButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontSize: 12.7,
    padding: '11px 16px',
    minWidth: 165,
    fontWeight: 600,
    cursor: 'pointer',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    '&.MuiButton-containedPrimary': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&.MuiButton-outlinedPrimary': {
      color: theme.palette.primary.main,
      outline: `2px solid ${theme.palette.primary.main}`,
      border: 0,
      ':hover': {
        border: 0,
      },
    },
  }));


  export const SearchTextField = styled(TextField)(({ theme }) => ({
    '& input': {
      padding: '12px 30px 12px 0',
      color: theme.palette.primary.main,
      '&::placeholder': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: 12.7,
      fontWeight: '600',
      fontFamily: 'Poppins, sans-serif',
      color: theme.palette.primary.dark,
      textTransform: 'capitalize',
      marginBottom: 10,
    },
    '& .MuiOutlinedInput-input': {
      '&::placeholder': {
        color: theme.palette.grey.A400,
      },
      fontFamily: 'Poppins, sans-serif',
      fontSize: 12.7,
      fontWeight: 'normal',
      padding: '12px 20px',
    },
  }));


  export const StyleInputLabel = styled(InputLabel)(({ theme }) => ({
    fontSize: 12.7,
    fontWeight: 600,
    fontFamily: 'Poppins, sans-serif',
    color: '#1c1939',
    textTransform: 'capitalize',
    marginBottom: 10,
  }));


   export const StyledTabs = styled(Tabs)(({ theme }) => ({
    '& .MuiTabs-indicator': {
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100px',
      top: '0.2px',
      zIndex: 1,
      marginLeft: '1px',
      marginRight: '1px',
    },
    minHeight: 'unset',
  }));
  
  export const StyledTab = styled(Tab)(({ theme }) => ({
    color: theme.palette.primary.dark,
    zIndex: 3,
    padding: '7px 20px',
    minWidth: 'unset',
    minHeight: 'unset',
    '&.Mui-selected': {
      color: theme.palette.common.white,
    },
  }));


  export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    root: {
      padding: 0,
    },
  }));

  export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    margin: 0,
    alignItems: 'flex-start',
    '& .MuiFormControlLabel-label': {
      fontWeight: 600,
      fontSize: 12.7,
      color: theme.palette.primary.dark,
      textTransform: 'capitalize',
      marginBottom: 8, 
    },
    '& .MuiCheckbox-root': {
      padding: 0, 
      alignSelf: 'flex-start',
    },
  }));

