import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {Box} from '@mui/system';

interface Option {
  label: string;
  value: string;
}
interface MultiSelectDropdownProps {
  selectedValues: Option[];
  onChange: (selectedValues: Option[]) => void;
  options: readonly Option[];
  open: boolean;
  setOpen: any;
}
export const testUtil: any = {handleDeleteChip: null};
const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  selectedValues,
  onChange,
  open,
  setOpen,
}) => {
  const handleDeleteChip = (value: string) => {
    const updatedValues =
      selectedValues.length > 0 &&
      selectedValues.filter(item =>
        typeof item === 'string' ? item !== value : item.value !== value,
      );
    onChange(updatedValues as Option[]);
  };
  const allOption: Option = {
    label: 'Select All',
    value: 'select-all',
  };
  testUtil.handleDeleteChip = handleDeleteChip;
  return (
    <div>
      <Box paddingBottom={1}>
        {selectedValues.length > 0 &&
          selectedValues.map((option, index) => (
            <Chip
              sx={{
                margin: 0.5,
                borderColor: '#322b7c',
                borderWidth: '2px',
                borderStyle: 'solid',
                color: '#322b7c',
              }}
              style={{
                backgroundColor: 'white',
              }}
              size={'small'}
              key={option.value}
              label={typeof option === 'string' ? option : option.label}
              onDelete={() =>
                handleDeleteChip(
                  typeof option === 'string' ? option : option.value!,
                )
              }
              deleteIcon={
                <IconButton
                  size="small"
                  sx={{'&:hover': {backgroundColor: 'transparent'}}}>
                  <CloseIcon style={{color: '#322b7c'}} />
                </IconButton>
              }
            />
          ))}
      </Box>
      <Autocomplete
        multiple
        disableCloseOnSelect
        data-testid="multi-select-dropdown"
        options={[allOption, ...(options || [])]}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.label
        }
        onChange={(_, newValue) => {
          if (newValue.some(option => option.value === allOption.value)) {
            onChange([...options]);
          } else {
            onChange(newValue);
          }
        }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        open={open}
        value={selectedValues}
        renderInput={params => (
          <TextField
            {...params}
            label="Select Division"
            variant="outlined"
            fullWidth
          />
        )}
        renderTags={() => null}
        isOptionEqualToValue={(option: Option, value: Option) =>
          option.value === value.value
        }
      />
    </div>
  );
};

export default MultiSelectDropdown;
