export const DailyCallReportBoxFields = [
  'Staff Name:',
  'Submission Date & Time:',
  'HQ Name:',
  'Place Worked:',
];
export const DivisonBoxFields = [
  'Designation:',
  'DCR Date:',
  'Day Status:',
  'Remarks:',
];

export const SummaryKeyFirstBox = [
  'KYC calls today:',
  'Calls today:',
  'Cumulative calls(MTD):',
  'Gross total:',
  'Total samples given:',
];
export const SummaryKeySecondBox = [
  'Total calls today:',
  'GP calls today:',
  'Consultants calls today:',
  'Top GP:',
  'Top consultants:',
];
