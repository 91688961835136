export const EVENT_NAME_ADD_NEW_BUTTON = 'MANKIND_ADMIN_ADD_NEW_BUTTON';
export const EVENT_NAME_LOG_OUT_BUTTON = 'MANKIND_ADMIN_LOG_OUT_BUTTON';
export const EVENT_NAME_APPLY_BUTTON = 'MANKIND_ADMIN_APPLY_BUTTON';
export const EVENT_NAME_USER_M_EDIT_ADMIN_ACCESS =
  'MANKIND_ADMIN_USER_M_EDIT_ADMIN_ACCESS';
export const EVENT_NAME_USER_M_RESET = 'MANKIND_ADMIN_USER_M_RESET_BUTTON';
export const EVENT_NAME_USER_M_SAVE = 'MANKIND_ADMIN_USER_M_SAVE_BUTTON';
export const EVENT_NAME_ACCESS_GROUP_MASTER_EDIT_ADMIN_ACCESS =
  'MANKIND_ADMIN_ACCESS_GROUP_MASTER_EDIT_ADMIN_ACCESS';
export const EVENT_NAME_ACCESS_GROUP_MASTER_SAVE_BUTTON =
  'MANKIND_ADMIN_ACCESS_GROUP_MASTER_SAVE_BUTTON';
export const EVENT_NAME_ACCESS_GROUP_MASTER_RESET_BUTTON =
  'MANKIND_ADMIN_ACCESS_GROUP_MASTER_RESET_BUTTON';
export const EVENT_NAME_ACCESS_GROUP_MASTER_DEACTIVATED_BUTTON =
  'MANKIND_ADMIN_ACCESS_GROUP_MASTER_DEACTIVATED_BUTTON';
export const EVENT_NAME_USER_MANAGEMENT_DEACTIVATED_BUTTON =
  'MANKIND_ADMIN_USER_MANAGEMENT_DEACTIVATED_BUTTON';
export const EVENT_NAME_INACTIVE_BUTTON = 'MANKIND_ADMIN_INACTIVE_BUTTON';
export const EVENT_NAME_ACTIVE_BUTTON = 'MANKIND_ADMIN_ACTIVE_INACTIVE_BUTTON';
export const EVENT_NAME_USER_M_ASSIGN_DIVISION_DROPDWOWN =
  'MANKIND_ADMIN_USER_M_ASSIGN_DIVISION_DROPDOWN';
export const EVENT_NAME_USER_M_ASSIGN_STATE_DROPDOWN =
  'MANKIND_ADMIN_USER_M_ASSIGN_STATE_DROPDOWN';
export const EVENT_NAME_USER_M_ASSIGN_ACCESS_GROUP_DROPDOWN =
  'MANKIND_ADMIN_USER_M_ASSIGN_ACCESS_GROUP_DROPDOWN';
export const EVENT_NAME_USER_M_ACCESS_TYPE_DROPDOWN =
  'MANKIND_ADMIN_USER_M_ACCESS_TYPE_DROPDOWN';
export const EVENT_NAME_SEARCH_BAR = 'MANKIND_ADMIN_SEARCH_BAR';
export const EVENT_NAME_DRAWER_CLOSE = 'MANKIND_ADMIN_DRAWER_CLOSE';
export const EVENT_NAME_CLEAR_ALL = 'MANKIND_ADMIN_USER_M_CLEAR_ALL_FILTER';
export const EVENT_NAME_ADD_NEW =
  'MANKIND_ADMIN_USER_GROUP_MASTER_ADD_NEW_BUTTON';
export const EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER =
  'MANKIND_ADMIN_USER_GROUP_MASTER_ADD_NEW_BUTTON';
