import { createSelector } from "@reduxjs/toolkit";

const getDepartmentLists = (state: any) =>
  state.departmentManagementState.departmentManagementList;
const getDepartmentList = createSelector(
  [getDepartmentLists],
  (_getDepartmentLists) => _getDepartmentLists
);

export const departmentManagementSelector = {
  getdepartmentlist: () => getDepartmentList,
};
