import theme from '@app/themes';
import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

const drawerWidth = 240;

export const useStyles = makeStyles({
  checkbox: {
    color: `${theme.colors.primary} !important`,
    transform: 'scale(.8)',
  },

  marginLeft15: {
    marginLeft: 15,
  },

  backgroundWhite: {
    backgroundColor: '#ffffff',
  },

  chipFilter: {
    color: '#534e91',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
  },
  chipClearAll: {
    color: '#534e91',
    fontWeight: 'bold',
    backgroundColor: '#ffffff',
  },

  paper: {
    width: drawerWidth - 40,
    backgroundColor: '#ffffff',
    position: 'fixed',
    bottom: 20,
    right: 20,
    boxShadow: 'none',
    borderRadius: '4px',
    zIndex: 100,
    padding: '0px 15px',
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '100%',
    height: '35px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
  },
  indeterminateBox: {
    height: 7.5,
    width: 7.5,
    backgroundColor: Colors.primary,
    position: 'absolute',
  },
  applyBox: {
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
  },

  parentListItemText: {
    color: theme.colors.primary,
    fontSize: 12,
    fontWeight: 700,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  listItemText: {
    color: theme.colors.primary,
    fontSize: 12,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
});
