import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {Label} from '@app/components/elements/Label';
import {
  holidaySelector,
  updateModelShow,
} from '@app/screens/holiday-management/redux';
import {
  setCountryId,
  setHMYears,
  setRefresh,
  setYears,
} from '@app/screens/holiday-management/redux/slice';
import theme from '@app/themes';
import {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {holidayLeaveYearData} from '@app/common/constants';

let isShow = false;
let isRefresh = false;
let year = new Date().getFullYear();
export const HolidayNavBar = () => {
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState([]);
  const [seletedCountry, setSeletedCountry] = useState<any>({
    label: '',
    value: '',
  });
  const [hmYears, setHmYears] = useState<any>(year);
  const [hmYears2, setHmYears2] = useState<any>(year);

  const title = useSelector(holidaySelector.getNavTitle());
  const staffName = useSelector(holidaySelector.getStaffName());
  const getHolidayAllowedCount = useSelector(
    holidaySelector.getHolidayAllowedCount(),
  );
  const getSelectedHoliday = useSelector(holidaySelector.getSelectedHoliday());
  const countryListSelector = useSelector(
    holidaySelector.getCountryList(),
    shallowEqual,
  );
  useEffect(() => {
    const newCountryList =
      countryListSelector?.map((value: any) => ({
        label: value.countryName,
        value: value.id,
      })) ?? [];
    setCountryList(newCountryList);
    const indiaCountry = newCountryList.find(
      (value: any) => value.label.toLowerCase() === 'india',
    );
    setSeletedCountry(indiaCountry);
    /**
     * Once page gets mounted
     */
    dispatch(setYears(year));
    dispatch(setCountryId(indiaCountry?.value));
  }, [countryListSelector]);
  useEffect(() => {
    return () => {
      setHmYears(year);
      setHmYears2(year);
    };
  }, [title]);

  const onClickPopup = () => {
    isShow = !isShow;
    dispatch(updateModelShow(true));
  };

  const onClickRefresh = () => {
    isRefresh = !isRefresh;
    dispatch(setRefresh(isRefresh));
  };

  const renderDivisionDropdownInput = useCallback(
    params => (
      <TextField
        {...params}
        placeholder="Select"
        required
        InputProps={{
          ...params.InputProps,
        }}
      />
    ),
    [],
  );
  const onChangeCountry = useCallback((_, val: any) => {
    setSeletedCountry(val);
    dispatch(setCountryId(val?.value));
  }, []);
  const handleChange2 = (event: SelectChangeEvent) => {
    setHmYears2(event.target.value);
    dispatch(setYears(event.target.value));
  };
  const handleChange = (event: SelectChangeEvent) => {
    setHmYears(event.target.value);
    dispatch(setHMYears(event.target.value));
  };
  let buttonLabel;
  if (title === 'Holiday Master') {
    buttonLabel = 'Add Holiday';
  } else if (title === 'Staff Holiday Master') {
    buttonLabel = 'Filter';
  } else {
    buttonLabel = 'Add Staff Holiday Allowed';
  }
  return (
    <Grid container justifyContent={'space-between'} sx={{minHeight: 100}}>
      <Grid item xs={8}>
        <Grid container justifyContent={'space-between'}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            color={'#1c1939'}
            fontStyle={'normal'}>
            {title}
          </Typography>

          <Grid style={{position: 'absolute', right: 10}}>
            <Button variant="contained" onClick={onClickPopup}>
              {buttonLabel}
            </Button>
          </Grid>
        </Grid>
        {title === 'Staff Holiday Allowed' && (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              position: 'absolute',
              left: 6,
              width: '100%',
              alignItems: 'end',
            }}>
            <Box sx={{mb: 3, width: 100, marginLeft: 3}}>
              <InputLabel variant="standard" required>
                Year
              </InputLabel>
              <FormControl sx={{minWidth: 150}} size="small">
                <Select value={hmYears2} onChange={handleChange2}>
                  {holidayLeaveYearData.map(year => (
                    <MenuItem key={year.id} value={year.value}>
                      {year.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{mb: 3, width: 200, marginLeft: 10}}>
              <InputLabel variant="standard" required>
                Country
              </InputLabel>
              <Autocomplete
                options={countryList}
                freeSolo
                size="small"
                disabled={true}
                disableClearable
                renderInput={renderDivisionDropdownInput}
                onChange={onChangeCountry}
                value={seletedCountry}
              />
            </Box>
            <Box
              sx={{
                mb: 3,
                marginLeft: 3,
                flex: 0.8,
                position: 'absolute',
                right: 17,
              }}>
              <Button variant="contained" onClick={() => onClickRefresh()}>
                Refresh
              </Button>
            </Box>
          </div>
        )}
        {title === 'Staff Holiday Master' && staffName && (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              position: 'absolute',
              left: 6,
              width: '100%',
              alignItems: 'end',
            }}>
            <Box
              sx={{
                mb: 3,
                flex: 1,
                marginLeft: 2,
                flexDirection: 'row',
                display: 'flex',
                marginTop: 3,
              }}>
              <Label
                textColor={theme.colors.primary}
                size={16}
                title={'Selected Staff : ' + staffName}
              />
            </Box>
            <Box
              sx={{
                mb: 3,
                flex: 1,
                marginLeft: 2,
                flexDirection: 'row',
                display: 'flex',
                marginTop: 3,
              }}>
              <Label
                textColor={theme.colors.primary}
                size={16}
                title={
                  'Holiday Allowed : ' +
                  (getHolidayAllowedCount != null ? getHolidayAllowedCount : 0)
                }
              />
            </Box>
            <Box
              sx={{
                mb: 3,
                flex: 1,
                marginLeft: 2,
                flexDirection: 'row',
                display: 'flex',
                marginTop: 3,
              }}>
              <Label
                textColor={theme.colors.primary}
                size={16}
                title={
                  'Selected Holiday : ' +
                  (getSelectedHoliday != null ? getSelectedHoliday : 0)
                }
              />
            </Box>
          </div>
        )}
        {title === 'Holiday Master' && (
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              position: 'absolute',
              left: 6,
              width: '30%',
              alignItems: 'end',
            }}>
            <Box sx={{mb: 3, flex: 1, marginLeft: 2}}>
              <InputLabel variant="standard" required>
                Year
              </InputLabel>
              <FormControl sx={{minWidth: 120}} size="small">
                <Select value={hmYears} onChange={handleChange}>
                  {holidayLeaveYearData.map(year => (
                    <MenuItem key={year.id} value={year.value}>
                      {year.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
