import { API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import { call, takeLatest, put, all } from '@redux-saga/core/effects';
import {appSliceActions} from '@app/store/reducers/appSlice';
import { ToasterService } from '@app/services';
import {ToastType} from '@app/services/toaster';
import { fetchDataCorrectionListCreatorTypeName,dataCorrectionStateActions,updateDataCorrectionCreatorTypeName } from './slice';

/**
 * Data Correction List Watcher
 */
export function* fetchDataCorrectionListWatcher() {
  yield takeLatest(
    fetchDataCorrectionListCreatorTypeName,
    fetchDataCorrectionListWorker,
  );
}

/**
 * Data Correction List Watcher
 */
export function* updateDataCorrectionWatcher() {
  yield takeLatest(
    updateDataCorrectionCreatorTypeName,
    updateDataCorrectionWorker,
  );
}


/**
 * Fetch Data Correction list Worker
 */
export function* fetchDataCorrectionListWorker(action:any): any {
  try {    
    // const request = ;//getDcrUnlockRequestObject(obj);
    const url = API_PATH.dataCorrection.dataCorrectionList;
    const response = yield call(NetworkService.post, url, action.payload);
    if (response?.status === HTTP_OK) {
      const sortedPartiesList = [...response.data]?.sort((a:any,b:any) =>a.name.localeCompare(b.name))
      yield put(
        dataCorrectionStateActions.setPartiesList(sortedPartiesList),
      );
      yield put(
        dataCorrectionStateActions.setPartiesListClone(sortedPartiesList),
      );     
    }    
    yield put(appSliceActions.setAppLoading(false));
  } catch (error) {}
}


/**
 * Update Data Correction Worker
 */
export function* updateDataCorrectionWorker(action:any): any {
  try {    
    // const request = ;//getDcrUnlockRequestObject(obj);
    const url = API_PATH.dataCorrection.updateDataCorrection;
    const response = yield call(NetworkService.post, url, action.payload);
    if (response?.status === HTTP_OK) {
      yield put(
        dataCorrectionStateActions.setDataCorrectionResponse({
          response:response.data,
          partyID:action?.payload?.masterDataList?.updatedId,
          staffPositionId:action?.payload?.staffPositionId,
          isBulkUpdate:action?.payload?.isBulkUpdate,
          startDate:!action?.payload?.isBulkUpdate ? action?.payload?.masterDataList?.startDate : action?.payload?.bulkStartDate 
        }),
      );     
    } else{
      ToasterService.showToaster(
        `Something went wrong while updating record${action?.payload?.isBulkUpdate?'s':''}!`,
        ToastType.ERROR,
      );
    } 
    yield put(appSliceActions.setAppLoading(false));
  } catch (error) {}
}


