import {createAction, createSlice} from '@reduxjs/toolkit';

export const fetchAutoSuggestListForStaffNameCreator =
  createAction<any>('FETCH_STAFF_NAME');
export const fetchAutoSuggestListForStaffNameTypename =
  fetchAutoSuggestListForStaffNameCreator({}).type;

export const fetchAutoSuggestListForItemsCreator = createAction<any>(
  'FETCH_ITEMS_AND_SAMPLES',
);
export const fetchAutoSuggestListForItemsTypename =
  fetchAutoSuggestListForItemsCreator({}).type;

export const fetchLedgerReportDataCreator = createAction<any>(
  'FETCH_LEDGER_REPORT_DATA',
);

export const fetchLedgerReportDataTypename = fetchLedgerReportDataCreator(
  {},
).type;

const inventoryModuleLedgerHandler = createSlice({
  name: 'INVETORY_MODULE_LEDGER_REPORT',
  initialState: {
    autoSuggestListForStaffName: [],
    autoSuggestListItemORSamples: [],
    ledgerReportData: {},
    ledgerReportIsLoading: false,
    ledgerPayloadData: {},
    ledgerReportExcelData: [],
  },
  reducers: {
    setAutoSuggestListofStaff: (prevState, {payload}) => ({
      ...prevState,
      autoSuggestListForStaffName: payload,
    }),
    setAutoSuggestListofItemOrSample: (prevState, {payload}) => ({
      ...prevState,
      autoSuggestListItemORSamples: payload,
    }),
    setLedgerReportData: (prevState, {payload}) => ({
      ...prevState,
      ledgerReportData: payload,
    }),
    setIsLoading: (prevState, {payload}) => ({
      ...prevState,
      ledgerReportIsLoading: payload,
    }),
    setLedgerPayloadData: (prevState, {payload}) => ({
      ...prevState,
      ledgerPayloadData: payload,
    }),
    setLedgerReportExcelData: (prevState, {payload}) => ({
      ...prevState,
      ledgerReportExcelData: payload,
    }),
  },
});

export const inventoryModuleLedgerActions =
  inventoryModuleLedgerHandler.actions;
export const inventoryModuleLedgerReducer =
  inventoryModuleLedgerHandler.reducer;
