import {createSelector} from '@reduxjs/toolkit';

export const getAccessGroupMasterStateSelector = (stateName: string) => {
  return createSelector(
    [(state: any) => state.accessGroupMaster[stateName]],
    state => state,
  );
};

export const accessGroupMasterStateSelector = {
  getAccessGroups: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroups],
      accessGroups => accessGroups,
    );
  },
  getAccessGroupsLoading: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsLoading],
      accessGroupsLoading => accessGroupsLoading,
    );
  },
  getAccessGroupsError: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsError],
      accessGroupsError => accessGroupsError,
    );
  },
  getAccessGroupsSuccess: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsSuccess],
      accessGroupsSuccess => accessGroupsSuccess,
    );
  },
  getAccessGroupsCount: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsCount],
      accessGroupsCount => accessGroupsCount,
    );
  },
  getAccessGroupsErrorMessage: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsErrorMessage],
      accessGroupsErrorMessage => accessGroupsErrorMessage,
    );
  },
  getIsActiveState: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.isActiveState],
      isActiveState => isActiveState,
    );
  },
  getUserSearchText: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.userSearchText],
      userSearchText => userSearchText,
    );
  },

  getEditAccessGroupPopupVisible: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.editAccessGroupPopupVisible],
      editAccessGroupPopupVisible => editAccessGroupPopupVisible,
    );
  },
  getEditAccessGroupValue: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.editAccessGroupValue],
      editAccessGroupValue => editAccessGroupValue,
    );
  },
  getAddAccessGroupPopupVisible: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.addAccessGroupPopupVisible],
      addAccessGroupPopupVisible => addAccessGroupPopupVisible,
    );
  },
  getAddAccessGroupValue: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.addAccessGroupValue],
      addAccessGroupValue => addAccessGroupValue,
    );
  },

  getAccessGroupsAutosuggestData: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsAutosuggestData],
      accessGroupsAutosuggestData => accessGroupsAutosuggestData,
    );
  },
  getAccessGroupsAutosuggestLoading: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsAutosuggestLoading],
      accessGroupsAutosuggestLoading => accessGroupsAutosuggestLoading,
    );
  },
  getAccessGroupsAutosuggestError: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsAutosuggestError],
      accessGroupsAutosuggestError => accessGroupsAutosuggestError,
    );
  },
  getAccessGroupsAutosuggestErrorMessage: () => {
    return createSelector(
      [
        (state: any) =>
          state.accessGroupMaster.accessGroupsAutosuggestErrorMessage,
      ],
      accessGroupsAutosuggestErrorMessage =>
        accessGroupsAutosuggestErrorMessage,
    );
  },
  getAccessGroupsAutosuggestSuccess: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.accessGroupsAutosuggestSuccess],
      accessGroupsAutosuggestSuccess => accessGroupsAutosuggestSuccess,
    );
  },
  getSelectedAccessGroupsInAutoSuggest: () => {
    return createSelector(
      [
        (state: any) =>
          state.accessGroupMaster.selectedAccessGroupsInAutoSuggest,
      ],
      selectedAccessGroupsInAutoSuggest => selectedAccessGroupsInAutoSuggest,
    );
  },
  getAccessGroupsAutosuggestSearchText: () => {
    return createSelector(
      [
        (state: any) =>
          state.accessGroupMaster.accessGroupsAutosuggestSearchText,
      ],
      accessGroupsAutosuggestSearchText => accessGroupsAutosuggestSearchText,
    );
  },

  getEditAccessGroupSuccess: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.editAccessGroupSuccess],
      editAccessGroupSuccess => editAccessGroupSuccess,
    );
  },
  getEditAccessGroupError: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.editAccessGroupError],
      editAccessGroupError => editAccessGroupError,
    );
  },
  getEditAccessGroupErrorMessage: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.editAccessGroupErrorMessage],
      editAccessGroupErrorMessage => editAccessGroupErrorMessage,
    );
  },
  getEditAccessGroupLoading: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.editAccessGroupLoading],
      editAccessGroupLoading => editAccessGroupLoading,
    );
  },
  getAddAccessGroupSuccess: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.addAccessGroupSuccess],
      addAccessGroupSuccess => addAccessGroupSuccess,
    );
  },
  getAddAccessGroupError: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.addAccessGroupError],
      addAccessGroupError => addAccessGroupError,
    );
  },
  getAddAccessGroupErrorMessage: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.addAccessGroupErrorMessage],
      addAccessGroupErrorMessage => addAccessGroupErrorMessage,
    );
  },
  getAddAccessGroupLoading: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.addAccessGroupLoading],
      addAccessGroupLoading => addAccessGroupLoading,
    );
  },
  getDisableAccessGroupSuccess: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.disableAccessGroupSuccess],
      disableAccessGroupSuccess => disableAccessGroupSuccess,
    );
  },
  getDisableAccessGroupError: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.disableAccessGroupError],
      disableAccessGroupError => disableAccessGroupError,
    );
  },
  getDisableAccessGroupErrorMessage: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.disableAccessGroupErrorMessage],
      disableAccessGroupErrorMessage => disableAccessGroupErrorMessage,
    );
  },
  getDisableAccessGroupLoading: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.disableAccessGroupLoading],
      disableAccessGroupLoading => disableAccessGroupLoading,
    );
  },
  getEditChipSelectedValue: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.editChipSelectedValue],
      editChipSelectedValue => editChipSelectedValue,
    );
  },
  getFirstLoad: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.firstLoad],
      firstLoad => firstLoad,
    );
  },

  getResetNavbarVersion: () => {
    return createSelector(
      [(state: any) => state.accessGroupMaster.resetNavbarVersion],
      resetNavbarVersion => resetNavbarVersion,
    );
  },
};
