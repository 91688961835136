import Typography from '@mui/material/Typography';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {routeSelector, routeStateActions} from '@app/router/redux';
import {Box, Button, IconButton} from '@mui/material';
import {useStyles} from './styles';
import {
  activityMappingStateActions,
  activityMappingSelector,
} from '@app/screens/configuration-management/pages/activity-mapping/redux';
import {SearchBar} from '@app/components/widgets/searchBar';
import {useState} from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

export const ActivityMappingNavBar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );
  const showCreateActivity = useSelector(
    activityMappingSelector.getShowCreateActivityScreen(),
  );
  const [searchError, setSearchError] = useState(false);
  const createActivitySearchText = useSelector(
    activityMappingSelector.getCreateActivitySearchText(),
  );
  const gridOptions = useSelector(
    activityMappingSelector.getActivitiesListGridOptions(),
  );
  const activities = useSelector(
    activityMappingSelector.getCloneActivitiesList(),
  );

  const openCreateActivityModal = () => {
    dispatch(activityMappingStateActions.setShowCreateActivityModal(true));
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(activityMappingStateActions.setCreateActivitySearchText(value));
    if (!value?.length || value?.length >= 3) {
      setSearchError(false);
    }
  };

  const fetchListData = () => {
    dispatch(
      activityMappingStateActions.setActivitiesListGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );

    const filteredData = activities?.activityList?.filter(
      (item: any) =>
        item?.name
          ?.toLowerCase()
          .includes(createActivitySearchText?.toLowerCase()) ||
        item?.activityType
          ?.toLowerCase()
          .includes(createActivitySearchText?.toLowerCase()),
    );

    dispatch(
      activityMappingStateActions.setActivitiesList({
        ...activities,
        activityList: filteredData,
      }),
    );
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (
        createActivitySearchText?.length >= 3 ||
        createActivitySearchText?.length === 0
      ) {
        setSearchError(false);
        fetchListData();
      } else {
        setSearchError(true);
      }
    }
  };

  const handleBackPress = () => {
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeading}>Activity Mapping</div>,
      ),
    );
    dispatch(activityMappingStateActions.setShowCreateActivityScreen(false));
  };

  const showCreateActivityScreen = () => {
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarHeading}>
          <IconButton
            className={classes.backIcon}
            onClick={handleBackPress}
            color="primary"
            aria-label="back"
            component="label">
            <ArrowCircleLeftOutlinedIcon />
          </IconButton>
          <div className={classes.navbarComponentHeading}>
            Superman Activity Configuration
          </div>
        </div>,
      ),
    );
    dispatch(activityMappingStateActions.setShowCreateActivityScreen(true));
  };

  return (
    <Box
      className={classes.container}
      paddingBottom={!showCreateActivity ? '55px' : 0}>
      <Box className={classes.flexSpaceBetween}>
        <Typography variant="h6" noWrap component="div">
          {navbarComponentHeading}
        </Typography>
        {showCreateActivity ? (
          <Button
            variant="contained"
            onClick={openCreateActivityModal}
            className={classes.button}
            color={'primary'}
            data-testid={'create-new-activity'}>
            Create New Activity
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={showCreateActivityScreen} // Need to uncomment this when button will be enabled in next release
            className={classes.button}
            color={'primary'}
            disabled={true} // Disabled it for current version
            data-testid={'activity-config'}>
            Superman Activity Configuration
          </Button>
        )}
      </Box>
      {showCreateActivity && (
        <Box width="50%">
          <SearchBar
            searchText={createActivitySearchText}
            handleChange={handleSearchTextChange}
            handleSearch={handleSearchPress}
            showError={searchError}
            placeHolder={'Search by Activity Name'}
            errorMessage={'Please Enter Minimum 3 Characters'}
          />
        </Box>
      )}
    </Box>
  );
};
