import EditIcon from '@app/assets/svg/edit-icon';
import { API_PATH, TOAST_MESSAGES } from '@app/common/constants';
import { routeStateActions } from '@app/router/redux';
import { navbarComponentName } from '@app/router/redux/routeHandler';
import NetworkService from '@app/services/network/NetworkService';
import {
  Box,
  TableRow,
  Grid,
  Divider,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  directoryActions,
  directorySelector,
  actions,
  directoryNavActionHandlerCreator,
} from '../../redux';
import Popup from '../../components/Popup';
import {
  columnManagemnetSelector,
  fetchMappingFieldsListCreator,
  fetchTabListCreator,
  updateColumnsCreator,
} from './redux';
import { DirectoryColumnsProps } from './types';
import TableList from '../../components/TableList';
import {
  directoryPageLists,
  directoryUpdateCurrentPageCreator,
} from '../../redux/slice';
import { ToasterService } from '@app/services';
import { ToastType } from '@app/services/toaster';
import { CustomButton, SearchTextField, StyleInputLabel, StyledAutocomplete, StyledFormControlLabel, StyledTableCell } from '../../components/styledComponents';

const tablecolumns = [
  'Tab Name',
  'Column Name',
  'Mapped Column',
  'Order no.',
  '',
  '',
];

const initialAddColumnData = {
  tabId: 0,
  tabName: '',
  name: '',
  columnMappingId: 0,
  orderNo: 0,
  columnName: '',
  mappedApiColumnName: '',
  id: 0,
  columnMap: null,
  isActive: 1,
};



const ColumnManagement = () => {
  const [addUpdatedInitialColumnData, setAddUpdatedInitialColumnData] =
    useState<any>(initialAddColumnData);
  const [addColumnData, setAddColumnData] = useState<any>(initialAddColumnData);
  const [isUpdatingColumn, setIsUpdatingColumn] = useState(false);
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [isEditing, setIsEditing] = useState(false);

  const dispatch = useDispatch();

  const columns = useSelector(
    columnManagemnetSelector.getColumns(),
    shallowEqual,
  );
  const tabList = useSelector(
    columnManagemnetSelector.getTabList(),
    shallowEqual,
  );
  const columnMappingFields = useSelector(
    columnManagemnetSelector.getColumnMappingFields(),
    shallowEqual,
  );
  const modal = useSelector(
    directorySelector.getDirectoryModal(),
    shallowEqual,
  );
  const loading = useSelector(directorySelector.getLoading(), shallowEqual);
  const isActive = useSelector(directorySelector.getStatus, shallowEqual);
  const search = useSelector(directorySelector.getNavSearch(), shallowEqual);

  const showUpdateColumnModal = useMemo(
    () => modal?.show && modal?.type === actions.addColumn,
    [modal],
  );

  const isColumnsFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.fetchColumns &&
      (!columns || columns.length === 0),
    [loading, columns],
  );
  const isUpdatingData = useMemo(
    () => loading === API_PATH.directoryConfiguration.fetchColumns,
    [loading],
  );
  const isEmpty = useMemo(
    () => !isColumnsFetching && (!columns || columns.length === 0),
    [isColumnsFetching, columns],
  );

  const isColumnFieldMapFetching = useMemo(
    () => loading === API_PATH.directoryConfiguration.fetchColumnMappingFields,
    [loading],
  );

  const cancelAddColumnModal = useCallback(() => {
    dispatch(directoryNavActionHandlerCreator({ show: false, type: null }));
    setAddColumnData(initialAddColumnData);
    setUpdatingError(null);
    setIsEditing(false);
  }, []);
  const unsuccessToaster = () => {
    ToasterService.showToaster(
      isEditing
        ? TOAST_MESSAGES.UNSUCCESSFULL_UPDATE
        : TOAST_MESSAGES.UNSUCCESSFULL_ADD,
      ToastType.ERROR,
    );
  };

  const successToaster = () => {
    ToasterService.showToaster(
      isEditing
        ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
        : TOAST_MESSAGES.SUCCESSFULLY_ADD,
      ToastType.SUCCESS,
    );
  };

  const onAddOrUpdateColumnHandler = async (e: any) => {
    e.preventDefault();
    if (addColumnData.name?.length < 2) {
      setUpdatingError(
        'Name should be alphanumeric - 2-30 char long. No special character allowed.!!',
      );
    } else if (
      addColumnData.tabId?.toString().trim() &&
      addColumnData.name?.toString().trim() &&
      addColumnData.orderNo &&
      addColumnData.columnName?.toString().trim() &&
      (addColumnData.columnMap?.toString().trim() ||
        addColumnData.columnMappingId?.toString().trim())
    ) {
      setIsUpdatingColumn(true);
      const updatedAddColumnData = {
        id: addColumnData.id,
        tabId: addColumnData.tabId,
        name: addColumnData.name,
        orderNo: addColumnData.orderNo,
        isActive: addColumnData.isActive,
        columnName: addColumnData.columnName,
        columnMap: addColumnData.columnMap
          ? {
            ...addColumnData.columnMap,
            apiColumnId: addColumnData.columnMappingId,
          }
          : { apiColumnId: addColumnData.columnMappingId },
      };

      try {
        const response = await NetworkService.post(
          API_PATH.directoryConfiguration.updateColumn,
          updatedAddColumnData,
          {},
        );

        if (response.status !== 200) {
          unsuccessToaster();
          setUpdatingError(response.data.description);
        } else {
          successToaster();
          /**** fetch columns list */
          dispatch(
            updateColumnsCreator({
              index: 1,
              isActive: isActive === 1,
              query: search.query,
            }),
          );

          cancelAddColumnModal();
        }
        setIsUpdatingColumn(false);
      } catch (error: any) {
        setIsUpdatingColumn(false);
        setUpdatingError(error.message);
      }
    } else {
      setUpdatingError('Please fill all required fields!!');
    }
  };

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    setAddColumnData({
      ...addColumnData,
      ...data,
    });
  };

  const onColumnEditHandler = (column: DirectoryColumnsProps) => {
    dispatch(fetchMappingFieldsListCreator({ id: column.tabId }));
    setAddUpdatedInitialColumnData({
      id: column.id,
      tabId: column.tabId,
      tabName: column.tabName,
      name: column.name,
      columnMappingId: column.columnMap?.apiColumnId,
      orderNo: column.orderNo,
      columnName: column.mappedApiColumnName,
      columnMap: column.columnMap,
      isActive: column.isActive,
      // mappedApiColumnName: column.mappedApiColumnName,
    });
    dispatch(
      directoryNavActionHandlerCreator({ show: true, type: actions.addColumn }),
    );
  };

  const onLoadMoreHandler = useCallback((index: number) => {
    dispatch(
      updateColumnsCreator({
        index,
        isActive: isActive === 1,
        query: search?.query,
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      updateColumnsCreator({
        index: 1,
        isActive: isActive === 1,
        query: search?.query,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, search]);

  useEffect(() => {
    setAddColumnData(addUpdatedInitialColumnData);
  }, [addUpdatedInitialColumnData]);

  useEffect(() => {
    /**** fetch tab list */
    dispatch(fetchTabListCreator({}));

    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.directoryConfiguration,
      ),
    );
    dispatch(
      directoryActions.updateNavData({
        title: 'Column Management',
        action: { title: 'Add New', type: actions.addColumn },
        search: { placeholder: 'Search here', query: '' },
        status: { show: true, value: true },
      }),
    );
    dispatch(
      directoryUpdateCurrentPageCreator(directoryPageLists.columnManagement),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      cancelAddColumnModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAddColumnData = useCallback(() => {
    setAddColumnData(addUpdatedInitialColumnData)
  }, [])

  const onClickColumnEdit = useCallback((event) => {
    const column = JSON.parse(event.currentTarget.dataset.current);
    onColumnEditHandler(column);
    setUpdatingError(null);
    setIsEditing(true);
  }, [])

  const onChangeTabName = useCallback((_, val: any) => {
    onFormUpdateHandler({ tabId: val.value, tabName: val.label });
    dispatch(fetchMappingFieldsListCreator({ id: val.value }));
  }, [onFormUpdateHandler])

  const AddColumnNameEdit = useCallback((event) => {
    onFormUpdateHandler({ name: event.target.value })
  }, [onFormUpdateHandler]);

  const onChangeOrderNo = useCallback((event: any) => {
    onFormUpdateHandler({ orderNo: event.target.value })
  }, [onFormUpdateHandler])

  const onChangeColumnMapping = useCallback((_, val: any) =>
    onFormUpdateHandler({
      columnMappingId: val.value,
      columnName: val.label,
    }), [onFormUpdateHandler])

  const addColumnOnOpen = useCallback(() => {
    dispatch(
      fetchMappingFieldsListCreator({ id: addColumnData.tabId }),
    )
  }, [addColumnData])

  const onChangeAddColumnUpdate = useCallback((event: any) => {
    onFormUpdateHandler({
      isActive: event.target.checked ? 1 : 0,
    })
  }, [onFormUpdateHandler])

  const renderDivisionDropdownInput = useCallback(
    params => <SearchTextField {...params} placeholder="Select" required />,
    [],
  );

  
  if (isColumnsFetching) {
    return (
      <Box justifyContent={'center'} display="flex" sx={{ mt: 2 }}>
        <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
      </Box>
    );
  }




  return (
    <>
      <TableList
        columns={tablecolumns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}>
        {columns && columns.length > 0
          ? columns?.map((column: DirectoryColumnsProps, index: number) => (
            <Fragment key={column.id}>
              <TableRow>
                <StyledTableCell>{column.tabName}</StyledTableCell>
                <StyledTableCell>{column.name}</StyledTableCell>
                <StyledTableCell>{column.mappedApiColumnName || '-'}</StyledTableCell>
                <StyledTableCell align="right" sx={{ width: '10%' }}>
                  {column.orderNo}
                </StyledTableCell>

                <StyledTableCell align="right">
                  <IconButton
                    sx={{
                      p: 0,
                      opacity: column.isEditable === 0 ? 0.5 : 1,
                    }}
                    disabled={column.isEditable === 0}
                    data-current={JSON.stringify(column)}
                    onClick={onClickColumnEdit}>
                    <EditIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
              <Divider
                component={TableRow}
                variant="fullWidth"
                sx={{ height: 5, border: 0 }}
              />
            </Fragment>
          ))
          : null}
      </TableList>
      <Popup
        open={!!showUpdateColumnModal}
        isLoading={isUpdatingColumn}
        cancelPopup={cancelAddColumnModal}
        error={updatingError}
        onClose={cancelAddColumnModal}
        title="Map Column">
        <form onSubmit={onAddOrUpdateColumnHandler}>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Tab Name
            </StyleInputLabel>
            <StyledAutocomplete
              options={tabList ? tabList : []}
              freeSolo
              disabled={!tabList || tabList.length === 0}
              renderInput={renderDivisionDropdownInput}
              onChange={onChangeTabName}
              value={addColumnData?.tabName}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Column Name
            </StyleInputLabel>
            <SearchTextField
              fullWidth
              placeholder="Enter Text"
              value={addColumnData.name}
              required
              onChange={AddColumnNameEdit}
              inputProps={{
                maxlength: 30,
              }}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Order Number
            </StyleInputLabel>
            <SearchTextField
              fullWidth
              placeholder="Enter"
              value={addColumnData.orderNo}
              required
              type={'number'}
              onChange={onChangeOrderNo}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Field Mapping
            </StyleInputLabel>
            <StyledAutocomplete
              options={columnMappingFields ? columnMappingFields : []}
              freeSolo
              disabled={!addColumnData.tabId}
              onOpen={addColumnOnOpen}
              loading={isColumnFieldMapFetching}
              renderInput={renderDivisionDropdownInput}
              onChange={onChangeColumnMapping}
              value={addColumnData.columnName}
            />
          </Box>
          <Box sx={{ mb: 5 }}>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={addColumnData.isActive === 1}
                  onChange={onChangeAddColumnUpdate}
                />
              }
              label="Is Active"
              labelPlacement="top"
            />
          </Box>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item mr={3}>
              <CustomButton
                variant="outlined"
                onClick={getAddColumnData}>
                Reset
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton variant="contained" type="submit">
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </Popup>
    </>
  );
};

export default ColumnManagement;
