import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';
export const useStyles = makeStyles({
  input: {
    marginLeft: 15,
    marginRight: 15,
  },
  dialog: {
    width: '100%',
    height: '60%',
  },
  checkbox: {
    color: '#322b7c !important',
  },
  boxtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    padding: 10,
    borderTop: '1px solid #322b7c',
  },
  paperButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
  },
  title: {
    padding: 0,
    marginLeft: 15,
    marginTop: 10,
    marginBottom: 5,
  },
  listItem: {
    padding: 0,
    margin: 0,
    height: 30,
    lineHeight: 30,
  },
  listItemButton: {
    height: 30,
    lineHeight: 30,
  },
});
