import {strings} from '@app/common/strings';
import {useStyles} from '@app/screens/Styles/style';
import {Button, CircularProgress} from '@mui/material';

export const ExportButtons: React.FC<{
  isWriteAccess: boolean;
  onClick: (e: any) => void;
  name: string;
  isExcelDownloading?: boolean;
}> = ({isWriteAccess, onClick, name, isExcelDownloading}) => {
  const classes = useStyles();

  return (
    <Button
      name={name}
      variant="text"
      className={classes.button}
      data-testid="download_excel"
      onClick={onClick}
      disabled={isExcelDownloading}
      startIcon={
        <img
          alt="excel"
          width={20}
          src={require('../../../assets/images/MSExcel.png')}
        />
      }
      sx={{
        ...(!isWriteAccess && {color: 'grey'}),
      }}>
      {isExcelDownloading ? (
        <CircularProgress size={20} />
      ) : (
        strings.DownloadExcel
      )}
    </Button>
  );
};
