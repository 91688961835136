import {createSelector} from '@reduxjs/toolkit';

const getPlanAndMeetState = (state: any) => state.planAndMeetStatusMaster;

const getPlanAndMeetSelector = createSelector([getPlanAndMeetState], data => {
  return data?.planAndMeetStatusList;
});

const getPlanAndMeetLoaderSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.loader,
);

const getPlanAndMeetSelectedRolesSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.roles,
);

const getPlanAndMeetSearchTextSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.searchText,
);

const getPlanAndMeetMonthSelectedSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.monthSelected,
);

const getPlanAndMeetGridOptionSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.gridOptions,
);

const getSTPEditResponseSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.STPEditResponse,
);

const getMTPEditResponseSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.MTPEditResponse,
);

const getDCREditResponseSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.DCREditResponse,
);
const getGSPUnlockResponseSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.gspUnlockResponse,
);

const getWebAccessResponseSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.webAccessResponse,
);

const getPlanAndMeetStateSelector = createSelector(
  [getPlanAndMeetState],
  data => data,
);

const getPlanAndMeetTotalRowSelector = createSelector(
  [getPlanAndMeetState],
  data => data.totalRows,
);

const getTotalGSPUnlockEligibleCount = createSelector(
  [getPlanAndMeetState],
  data => data.totalGSPUnlockEligibleCount,
);

const getTotalGSPUnlockNotEligibleCount = createSelector(
  [getPlanAndMeetState],
  data => data.totalGSPUnlockNotEligibleCount,
);

const getTotalWebUnlockEligibleCount = createSelector(
  [getPlanAndMeetState],
  data => data.totalWebUnlockEligibleCount,
);

const getTotalWebUnlockNotEligibleCount = createSelector(
  [getPlanAndMeetState],
  data => data.totalWebUnlockNotEligibleCount,
);

const getRecommendationsSelector = createSelector(
  [getPlanAndMeetState],
  data => data.recommendations,
);

const getPreferredFilters = createSelector(
  [getPlanAndMeetState],
  data => data.preferredFilters,
);

const getMTPUnlockReasons = createSelector(
  [getPlanAndMeetState],
  data => data?.mtpUnlockReasons,
);

const getSTPMTPUnlockResponse = createSelector(
  [getPlanAndMeetState],
  data => data?.unlockSTPMTPResponse,
);
const getAppliedFilters = createSelector(
  [getPlanAndMeetState],
  data => data?.filters,
);

const getSelectedRows = createSelector(
  [getPlanAndMeetState],
  data => data?.selectedRows,
);

const getIsAllSelected = createSelector(
  [getPlanAndMeetState],
  data => data?.isAllSelected,
);

const getAllDeSelectedRows = createSelector(
  [getPlanAndMeetState],
  data => data?.deselectedRows,
);

const getBulkUpdateSummaryList = createSelector(
  [getPlanAndMeetState],
  data => data?.bulkUpdateSummaryList,
);

const getBulkUpdateSummaryGridOptions = createSelector(
  [getPlanAndMeetState],
  data => data?.bulkUpdateSummaryGridOptions,
);

const getBulkGSPUnlockLoader = createSelector(
  [getPlanAndMeetState],
  data => data?.bulkGSPUnlockLoader,
);

const getBulkActionSelected = createSelector(
  [getPlanAndMeetState],
  data => data?.bulkActionSelected,
);

const getWebAccessStatusSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.webAccessStatus,
);

const getIsAtHierarchyLevel = createSelector(
  [getPlanAndMeetState],
  data => data?.isAtHierarchyLevel,
);

const getAllHierarchyRowsSelected = createSelector(
  [getPlanAndMeetState],
  data => data?.allHierarchyRowsSelected,
);

const getAllHierarchyList = createSelector(
  [getPlanAndMeetState],
  data => data?.allHierarchyData,
);

const getUnfilledDCRResponse = createSelector(
  [getPlanAndMeetState],
  data => data?.unfilledDCRResponse,
);

const getIsFirstTimeLoadSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.isFirstTimeLoad,
);

const getAllStaffPositionIdsSelector = createSelector(
  [getPlanAndMeetState],
  data => data?.allStaffPositionIds,
);

const getRealmUploadResponseSelector = createSelector(
  [getPlanAndMeetState],
  data => data.realmUploadResponse,
);

export const planAndMeetSelector = {
  getPlanAndMeetStatusList: () => getPlanAndMeetSelector,
  getPlanAndMeetLoader: () => getPlanAndMeetLoaderSelector,
  getPlanAndMeetSelectedRoles: () => getPlanAndMeetSelectedRolesSelector,
  getPlanAndMeetSearchText: () => getPlanAndMeetSearchTextSelector,
  getPlanAndMeetMonthSelected: () => getPlanAndMeetMonthSelectedSelector,
  getPlanAndMeetGridOption: () => getPlanAndMeetGridOptionSelector,
  getPlanAndMeetState: () => getPlanAndMeetStateSelector,
  getPlanAndMeetTotalRow: () => getPlanAndMeetTotalRowSelector,
  getSTPEditResponseState: () => getSTPEditResponseSelector,
  getMTPEditResponseState: () => getMTPEditResponseSelector,
  getDCREditResponseState: () => getDCREditResponseSelector,
  getGSPUnlockResponseState: () => getGSPUnlockResponseSelector,
  getWebAccessResponseState: () => getWebAccessResponseSelector,
  getMTPRecommendations: () => getRecommendationsSelector,
  getPreferredFilters: () => getPreferredFilters,
  getMTPUnlockReasons: () => getMTPUnlockReasons,
  getSTPMTPUnlockResponse: () => getSTPMTPUnlockResponse,
  getAppliedFilters: () => getAppliedFilters,
  getSelectedRows: () => getSelectedRows,
  getIsAllSelected: () => getIsAllSelected,
  getAllDeSelectedRows: () => getAllDeSelectedRows,
  getBulkUpdateSummaryList: () => getBulkUpdateSummaryList,
  getBulkUpdateSummaryGridOptions: () => getBulkUpdateSummaryGridOptions,
  getTotalGSPUnlockEligibleCount: () => getTotalGSPUnlockEligibleCount,
  getTotalGSPUnlockNotEligibleCount: () => getTotalGSPUnlockNotEligibleCount,
  getTotalWebUnlockEligibleCount: () => getTotalWebUnlockEligibleCount,
  getTotalWebUnlockNotEligibleCount: () => getTotalWebUnlockNotEligibleCount,
  getBulkGSPUnlockLoader: () => getBulkGSPUnlockLoader,
  getBulkActionSelected: () => getBulkActionSelected,
  getWebAccessStatus: () => getWebAccessStatusSelector,
  getIsAtHierarchyLevel: () => getIsAtHierarchyLevel,
  getAllHierarchyRowsSelected: () => getAllHierarchyRowsSelected,
  getAllHierarchyList: () => getAllHierarchyList,
  getUnfilledDCRResponse: () => getUnfilledDCRResponse,
  getIsFirstTimeLoad: () => getIsFirstTimeLoadSelector,
  getAllStaffPositionIds: () => getAllStaffPositionIdsSelector,
  getRealmUploadResponse: () => getRealmUploadResponseSelector,
};
