import {useEffect, useState} from 'react';
import {Close} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Autocomplete,
  TextField,
} from '@mui/material';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import {useDispatch, useSelector} from 'react-redux';
import {
  downloadInventoryRuleCreator,
  inventoryComplianceSelector,
} from '../../pages/inventory-compliance/redux';
import {filterSelector} from '@app/components/right-sidebar/redux';

const DownloadInventoryExcelModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: any;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedDivisions, setSelectedDivisions] = useState<any[]>([]);
  const [downloadType, setDownloadType] = useState<string>('1');
  const filters = useSelector(filterSelector.getFilterState());
  const masterData = useSelector(
    inventoryComplianceSelector.getInventoryMasterData(),
  );
  const appliedFilter = useSelector(
    inventoryComplianceSelector.getAppliedFilter(),
  );
  const skuStatus = useSelector(inventoryComplianceSelector.getSkuStatus());
  const [selectedFY, setSelectedFY] = useState<string>('');
  const divisions = filters?.divisions?.filterData;

  useEffect(() => {
    if (masterData?.financialYear?.length > 0) {
      setSelectedFY(masterData?.financialYear[0]);
    }
  }, [masterData?.financialYear]);

  const handleFYChange = (event: any) => {
    setSelectedFY(event?.target?.value);
  };

  const closeDialog = () => {
    setSelectedDivisions([]);
    setSelectedFY(masterData?.financialYear[0]);
    setDownloadType('1');
    onClose();
  };

  const label = (title: string) => (
    <Label title={title} fontWeight={'semibold'} size={12} hideToolTip />
  );

  const isApplyDisabled = () => {
    if (selectedFY === '') {
      return;
    }
    if (downloadType === '2') {
      return selectedDivisions.length > 0;
    }
    return true;
  };

  const handleFineRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDownloadType((event.target as HTMLInputElement).value);
  };

  const getIds = (value: any) => {
    return value.map((val: any) => {
      return val?.id;
    });
  };

  const getSkuStatus = () => {
    return `Download Mapped SKU's Data (${
      skuStatus ? 'Active records' : 'Inactive records'
    })`;
  };

  const downloadInventoryExcel = () => {
    const downloadExcelMappedFilters = {
      divisionsIds: appliedFilter?.divisions?.length
        ? appliedFilter?.divisions
        : [],
      skuTypeFiltersIds: appliedFilter?.skuType?.length
        ? appliedFilter?.skuType
        : [],
      partyTypeFiltersIds: appliedFilter?.partyType?.length
        ? appliedFilter?.partyType
        : [],
      isActive: appliedFilter?.skuStatus,
      minAnnualLimit: appliedFilter?.minLimit || 0,
      maxAnnualLimit: appliedFilter?.maxLimit || 0,
    };
    const downloadExcelUnmappedFilters = {
      divisionsIds: getIds(selectedDivisions),
    };
    const payload = {
      fyEnd: selectedFY,
      isMappedSkuData: downloadType === '1',
      filters:
        downloadType === '1'
          ? downloadExcelMappedFilters
          : downloadExcelUnmappedFilters,
    };

    dispatch(downloadInventoryRuleCreator(payload));
    closeDialog();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paper: classes.dialog}}
      scroll="paper">
      <Box className={classes.dialogHeader}>
        <Box className={classes.headerTitle}>
          <DialogTitle className={classes.dialogTitle}>
            Download Excel
          </DialogTitle>
          <Label
            title={
              'Please select which type of SKU data you would like to download'
            }
            size={14}
            hideToolTip
          />
          <FormControl size="medium" sx={{width: 150}}>
            <Select
              size="small"
              labelId="financial-year"
              id="financial-year"
              value={selectedFY}
              onChange={handleFYChange}
              data-testid="financial-year-dropdown"
              inputProps={{'aria-label': 'Without label'}}>
              {masterData?.financialYear?.map((year: any) => (
                <MenuItem
                  value={year}
                  key={`financial-year-${year}`}
                  data-testid={`financial-year-${year}`}>
                  {year - 1}-{year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <IconButton
          color="primary"
          aria-label="add division close"
          component="span"
          id="close-add-modal"
          onClick={closeDialog}
          data-testid={'close-add-modal'}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent>
        <FormControl>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={downloadType}
            onChange={handleFineRadioChange}>
            <FormControlLabel
              value="1"
              control={<Radio />}
              label={getSkuStatus()}
            />
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="Download Unmapped SKU's Data"
            />
          </RadioGroup>
        </FormControl>

        {downloadType === '2' && (
          <FormControl fullWidth sx={{marginBottom: 2}}>
            {label('Division Selection')}
            <Autocomplete
              id="division-autocomplete"
              multiple
              size="small"
              disableCloseOnSelect
              options={divisions}
              getOptionLabel={(option: any) => option.value}
              value={selectedDivisions}
              onChange={(e, newValue) => setSelectedDivisions(newValue)}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={`${
                    selectedDivisions?.length ? '+ add' : 'Select divisions'
                  }`}
                />
              )}
              renderTags={(tagValue, getTagProps) => (
                <Box className={classes.chipContainer}>
                  {tagValue.map((option, index) => {
                    const {key, ...tagProps} = getTagProps({index});
                    return (
                      <Chip
                        {...tagProps}
                        key={option?.id}
                        label={option?.value}
                        deleteIcon={
                          <Close
                            onMouseDown={event => event.stopPropagation()}
                            fontSize="small"
                          />
                        }
                        className={classes.chip}
                      />
                    );
                  })}
                </Box>
              )}
              renderOption={(props, option, {selected}) => {
                return (
                  <MenuItem
                    {...props}
                    key={option?.id}
                    value={option}
                    sx={{padding: '0 !important'}}>
                    <Checkbox size="small" checked={selected} />
                    <ListItemText primary={option?.value} />
                  </MenuItem>
                );
              }}
            />
          </FormControl>
        )}
      </DialogContent>

      <DialogActions>
        <Box className={classes.buttonContainer}>
          <Button
            data-testid="button-cancel"
            variant="contained"
            className={classes.paperButton}
            onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}
            disabled={!isApplyDisabled()}
            onClick={downloadInventoryExcel}>
            Download
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadInventoryExcelModal;
