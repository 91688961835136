import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import {useSelector, useDispatch} from 'react-redux';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {approveExpenseStateActions} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import theme from '@app/themes';
import {strings} from '@app/common/strings';

export const utils: any = {
  handleClose: null,
};
export default function InsufficentFundPopup() {
  const setErrorPopupVisible: boolean = useSelector(
    approveExpenseStateSelector.setErrorPopupVisible(),
  );

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(approveExpenseStateActions.setErrorPopupVisible(false));
  };

  utils.handleClose = handleClose;
  return (
    <div data-testid="InsufficentFundPopup">
      <Dialog
        open={setErrorPopupVisible}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText
            sx={{color: theme.colors.black}}
            mt={1}
            mb={2}
            id="alert-dialog-description">
            {strings.moduleSpecificUtilities.insufficientFund}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="small" variant="contained" onClick={handleClose}>
            {strings.ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
