import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {SearchBar} from '@app/components/widgets/searchBar';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import {Button} from '@mui/material';
import {
  approvalWorkflowSelector,
  approvalWorkflowStateActions,
} from '@app/screens/configuration-management/pages/approval-workflow/redux';

export const ApprovalWorkflowNavbar = () => {
  const dispatch = useDispatch();
  const [searchError, setSearchError] = useState(false);

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles();
  const searchText = useSelector(
    approvalWorkflowSelector.getApprovalWorkflowSearchText(),
  );
  const gridOptions = useSelector(
    approvalWorkflowSelector.getApprovalWorkflowGridOptions(),
  );

  const approvalWorkflowList = useSelector(
    approvalWorkflowSelector.getCloneApprovalWorkflowList(),
  );
  const showCreateScreen = useSelector(
    approvalWorkflowSelector.getCreateWorkflowScreen(),
  );

  const fetchListData = () => {
    dispatch(
      approvalWorkflowStateActions.setApprovalWorkflowGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );

    const filteredData = approvalWorkflowList?.filter(
      (item: any) =>
        item?.division?.name
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        item?.feature?.name?.toLowerCase()?.includes(searchText?.toLowerCase()),
    );

    dispatch(
      approvalWorkflowStateActions.setApprovalWorkflowList(filteredData),
    );
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(approvalWorkflowStateActions.setApprovalWorkflowSearchText(value));
    if (!value?.length || value?.length >= 3) {
      setSearchError(false);
    }
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      if (searchText?.length >= 3 || searchText?.length === 0) {
        setSearchError(false);
        fetchListData();
      } else {
        setSearchError(true);
      }
    }
  };

  const createNewWorkflow = () => {
    // navigate to create workflow screen
    dispatch(approvalWorkflowStateActions.setCreateWorkflowScreen(true));
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />
        {!showCreateScreen && (
          <div>
            <Button
              variant="contained"
              onClick={createNewWorkflow}
              className={classes.paperButton}
              data-testid={'create_new_workflow'}
            >
              Create
            </Button>
          </div>
        )}
      </div>
      <div className={classes.searchContainer}>
        {!showCreateScreen && (
          <SearchBar
            searchText={searchText}
            handleChange={handleSearchTextChange}
            handleSearch={handleSearchPress}
            showError={searchError}
            placeHolder={'Search here'}
            errorMessage={'Please Enter Minimum 3 Characters'}
          />
        )}
      </div>
    </div>
  );
};
