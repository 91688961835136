import {createSelector} from '@reduxjs/toolkit';

export const adminCnfSelector = {
  getIsOpenChallans: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.isOpenChallans],
      state => state,
    );
  },
  getAdminCnfLoading: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.isCnfLoading],
      isCnfLoading => isCnfLoading,
    );
  },
  getAdminCnfError: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.isCnfError],
      adminCnfError => adminCnfError,
    );
  },
  getAdminCnfSuccess: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.cnfData],
      adminCnfSuccess => adminCnfSuccess,
    );
  },
  getCNFStates: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.cnfStates],
      cnfStates => cnfStates,
    );
  },
  getCNFDivisions: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.cnfDivisions],
      cnfDivisions => cnfDivisions,
    );
  },
  getCnfRequestApiCalledStatus: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.cnfRequestApiCalled],
      cnfRequestApiCalled => cnfRequestApiCalled,
    );
  },
  getCNFHeadquarters: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.cnfHeadQuarters],
      cnfHeadQuarters => cnfHeadQuarters,
    );
  },
  getSelectedCnfState: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.selectedCnfState],
      cnfSelectedState => cnfSelectedState,
    );
  },
  getSelectedCnfDivision: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.selectedCnfDivision],
      selectedCnfDivision => selectedCnfDivision,
    );
  },
  getSelectedCnfHeadquarter: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.selectedCnfHeadQuarter],
      selectedCnfHeadQuarter => selectedCnfHeadQuarter,
    );
  },
  getCnfAutoSuggestionData: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.autoSuggestionData],
      state => state,
    );
  },
  getCnfFilteredData: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.filteredData],
      state => state,
    );
  },
  getSelectedCnfReturnItems: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.selectedCnfReturnItems],
      selectedCnfReturnItems => selectedCnfReturnItems,
    );
  },
  getRejectItems: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.rejectItems],
      rejectItems => rejectItems,
    );
  },
  getapprovedItems: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.rejectItems],
      approvedItems => approvedItems,
    );
  },
  getStatesData: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.statesData],
      states => states,
    );
  },
  getDivision: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.division],
      division => division,
    );
  },
  getHQ: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.HQ],
      HQ => HQ,
    );
  },
  getCopySnapshotHQ: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.copySnapshotHQ],
      copySnapshotHQ => copySnapshotHQ,
    );
  },
  getCnfHeadQuarterLoading: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.isLoadingCnfHeadQuarter],
      isLoadingCnfHeadQuater => isLoadingCnfHeadQuater,
    );
  },
  getPageNumber: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.pageNumber],
      pageNumber => pageNumber,
    );
  },
  getCnfAuditDivision: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.cnfAuditDivision],
      cnfAuditDivision => cnfAuditDivision,
    );
  },
  getCnfReturnAudtilDetials: () => {
    return createSelector(
      [(state: any) => state?.inventoryModule?.cnfReturnAudtilDetials],
      cnfReturnAudtilDetials => cnfReturnAudtilDetials,
    );
  },
};
