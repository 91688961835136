import {strings} from '@app/common/strings';
import {fieldCss} from '@app/screens/Styles/style';
import {Typography, TableCell, Box, Tooltip} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import moment from 'moment';

export const util: any = {
  renderE_DetailingCell: null,
  renderSample_GivenCell: null,
  renderSample_QuantityCell: null,
};

export const ModelTable = ({Data}: {Data: any}) => {
  const styles = {
    tableCell: {borderBottom: 'none', padding: 0, width: 220, maxHeight: 100},
    tableCellEmpty: {borderBottom: 'none'},
  };

  const renderE_DetailingCell = (params: GridRenderCellParams) => {
    if (params?.row?.eDetails && params?.row?.eDetails != '') {
      let myarray = params?.row?.eDetails?.split(',');
      return (
        <Tooltip title={params?.row?.eDetails}>
          <TableCell sx={styles.tableCell}>
            {myarray?.map((item: any, _index: number) => {
              return <Box key={item}>{item}</Box>;
            })}
          </TableCell>
        </Tooltip>
      );
    } else {
      return <TableCell sx={styles.tableCellEmpty}>-</TableCell>;
    }
  };
  const renderSample_GivenCell = (params: GridRenderCellParams) => {
    if (params?.row?.sampleItems?.length > 0) {
      return (
        <TableCell sx={styles.tableCell}>
          {params?.row?.sampleItems?.map((item: any) => {
            return <Box key={item?.sampleName}>{item?.sampleName}</Box>;
          })}
        </TableCell>
      );
    } else {
      return <TableCell sx={styles.tableCellEmpty}>-</TableCell>;
    }
  };
  const renderSample_QuantityCell = (params: GridRenderCellParams) => {
    if (params?.row?.sampleItems?.length > 0) {
      return (
        <TableCell sx={styles.tableCell}>
          {params?.row?.sampleItems?.map((item: any) => {
            return <Box key={item?.sampleQuantity}>{item?.sampleQuantity}</Box>;
          })}
        </TableCell>
      );
    } else {
      return <TableCell sx={styles.tableCellEmpty}>-</TableCell>;
    }
  };

  const toolTipWrapper = (cellName: any) => (
    <Tooltip title={cellName}>{cellName}</Tooltip>
  );

  const fieldActivities: GridColDef[] = [
    {
      field: 'partyName',
      headerName: 'PARTY NAME',
      width: 130,
      editable: false,
      renderCell: params => toolTipWrapper(params?.row?.partyName),
    },

    {
      field: 'stationName',
      headerName: 'STATION',
      width: 125,
      editable: false,
      renderCell: params => toolTipWrapper(params?.row?.stationName),
    },
    {
      field: 'visitTimeSlotName',
      headerName: 'ViSITING SLOT',
      width: 110,
      editable: false,
      renderCell: params => toolTipWrapper(params?.row?.visitTimeSlotName),
    },
    {
      field: 'specialityName',
      headerName: 'SPECIALITY',
      width: 110,
      editable: false,
      renderCell: params => toolTipWrapper(params?.row?.specialityName),
    },
    {
      field: 'lastVisitedDate',
      headerName: 'PREVIOUS VISIT DATE',
      width: 120,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        return params?.row?.lastVisitedDate
          ? moment(params?.row?.lastVisitedDate).format('DD/MM/YYYY')
          : '';
      },
    },
    {
      field: 'joinWorkWithName',
      headerName: 'WORK WITH',
      width: 160,
      editable: false,
      renderCell: params => toolTipWrapper(params?.row?.joinWorkWithName),
    },
    {
      field: 'isJointVisit',
      headerName: 'JOINT WORKING',
      width: 110,
      editable: false,
      renderCell: params =>
        toolTipWrapper(params?.row?.isJointVisit?.toString()),
    },
    {
      field: 'openTaskDetails',
      headerName: 'OPEN TASKS',
      width: 130,
      editable: false,
      renderCell: params => toolTipWrapper(params?.row?.openTaskDetails),
    },
    {
      field: 'eDetails',
      headerName: 'E-DETAILING FOR',
      width: 220,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderE_DetailingCell(params);
      },
    },
  ];

  const fieldActivityItems: GridColDef[] = [
    {
      field: 'partyName',
      headerName: 'PARTY NAME',
      width: 200,
      editable: false,
    },

    {
      field: 'itemTypeId',
      headerName: 'ITEM TYPE',
      width: 150,
      editable: false,
    },
    {
      field: 'itemTypeName',
      headerName: 'ITEM',
      width: 150,
      editable: false,
    },
    {
      field: 'itemQuantity',
      headerName: 'QUANTITY',
      width: 100,
      editable: false,
    },
    {
      field: 'sampleName',
      headerName: 'SAMPLES GIVEN',
      width: 250,
      editable: false,

      renderCell: (params: any) => {
        return renderSample_GivenCell(params);
      },
    },
    {
      field: 'sampleQuantity',
      headerName: 'SAMPLE QUANTITY',
      width: 150,
      editable: false,
      renderCell: (params: any) => {
        return renderSample_QuantityCell(params);
      },
    },
    {
      field: 'pobDetail',
      headerName: 'POBS',
      width: 110,
      editable: false,
    },
    {
      field: 'pobQuantity',
      headerName: 'POBS QUANTITY',
      width: 110,
      editable: false,
    },
    {
      field: 'remark',
      headerName: 'REMARKS',
      width: 110,
      editable: false,
    },
  ];
  const NonFiledActivities: GridColDef[] = [
    {
      field: 'activityTypeName',
      headerName: 'ACTIVITY TYPE',
      width: 250,
      editable: false,
    },

    {
      field: 'activityName',
      headerName: 'ACTIVITY NAME',
      width: 300,
      editable: false,
    },
    {
      field: 'activityDurationName',
      headerName: 'ACTIVITY DURATION',
      width: 330,
      editable: false,
    },
    {
      field: 'activityLocationName',
      headerName: 'ACTIVITY LOCATION ',
      width: 330,
      editable: false,
    },
    {
      field: 'callsCount',
      headerName: 'Call Count',
      width: 180,
      editable: false,
    },
  ];
  util.renderE_DetailingCell = renderE_DetailingCell;
  util.renderSample_GivenCell = renderSample_GivenCell;
  util.renderSample_QuantityCell = renderSample_QuantityCell;

  return (
    <>
      {Data?.fieldActivityItems?.length > 0 ||
        (Data?.fieldActivities?.length > 0 && (
          <Typography sx={fieldCss.fieldactivity}>
            {strings.moduleSpecificUtilities.fieldActivity}
          </Typography>
        ))}

      {Data?.fieldActivities?.length > 0 && (
        <div style={fieldCss.fieldActivities}>
          <DataGrid
            sx={fieldCss}
            getRowId={row => row?.partyId}
            rows={Data?.fieldActivities}
            columns={fieldActivities}
          />
        </div>
      )}

      {Data?.fieldActivityItems?.length > 0 && (
        <div style={fieldCss.fieldActivityItems}>
          <DataGrid
            getRowId={row => row?.partyId}
            rows={Data?.fieldActivityItems}
            columns={fieldActivityItems}
          />
        </div>
      )}

      {Data?.nonFieldActivities?.length > 0 && (
        <>
          <Typography sx={fieldCss.fieldactivity}>
            {strings.moduleSpecificUtilities.nonFieldActivity}
          </Typography>
          <div style={fieldCss.fieldActivityItems}>
            <DataGrid
              getRowId={row => row?.activityName + row?.activityDurationName}
              rows={Data?.nonFieldActivities}
              columns={NonFiledActivities}
            />
          </div>
        </>
      )}
    </>
  );
};
