import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {getAccessToken} from '@app/helper/login';
import axios from 'axios';
import env from '@app/env.json';
import FileViewer from 'react-file-viewer';
import {strings} from '@app/common/strings';

export const Putil: any = {
  getFileTypeWeb: null,
  fileDownload: null,
  getNetworkResponse: null,
  client: null,
  getHeight: null,
};
export const getFileTypeWeb = (fileName: any) => {
  if (fileName?.includes('pdf') || fileName?.includes('PDF')) {
    return 'pdf';
  } else if (fileName?.includes('png') || fileName?.includes('PNG')) {
    return 'png';
  } else if (
    fileName?.includes('jpg') ||
    fileName?.includes('JPG') ||
    fileName?.includes('jpeg') ||
    fileName?.includes('JPEG')
  ) {
    return 'jpg';
  } else {
    return '';
  }
};

export const fileDownload = async (url: any, fileName: any) => {
  const accessToken = await getAccessToken();
  const config = {
    baseURL: env.API_HOST,
    method: 'POST',
    url,
    data: {
      blobName: fileName,
    },
    responseType: 'application/json',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return getNetworkResponse(config);
};

const client = axios.create();
client.defaults.timeout = env.API_TIMEOUT || 60000;
export const getNetworkResponse = async (config: any) => {
  return client(config)
    .then(async function (response: any) {
      return response;
    })
    .catch(async (error: any) => {
      return error?.response || error?.message;
    });
};

const PreviewDailog = (props: any) => {
  const {
    dialogOpen,
    setDialogOpen,
    fileType,
    base,
    isAddNewBill,
    setBase,
    setFileType,
  } = props;

  const dialogClose = () => {
    setDialogOpen(false);
    setBase('');
    setFileType?.('');
  };

  const getHeight = () => {
    const fileTypeExt = getFileTypeWeb(fileType);
    return fileTypeExt === 'png' || fileTypeExt === 'jpg' ? '60vh' : 'auto';
  };
  Putil.getFileTypeWeb = getFileTypeWeb;
  Putil.fileDownload = fileDownload;
  Putil.getNetworkResponse = getNetworkResponse;
  Putil.client = client;
  Putil.getHeight = getHeight;

  return (
    <Dialog
      data-testid="crossButton"
      open={dialogOpen}
      onClose={dialogClose}
      maxWidth="md"
      fullWidth
      sx={{
        height: '500',
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>
        <Box display={'flex'} justifyContent="space-between">
          <Box>
            <Typography>{strings.moduleSpecificUtilities.preview}</Typography>
          </Box>
          <Button data-testid="cross" onClick={dialogClose}>
            <ClearOutlinedIcon />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        {base && (
          <div
            style={{
              height: getHeight(),
            }}>
            {
              <FileViewer
                filePath={
                  isAddNewBill ? base : `data:application/pdf;base64,${base}`
                }
                fileType={getFileTypeWeb(fileType)}
              />
            }
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button data-testid="crossButtonnnnn" onClick={dialogClose} autoFocus>
          {strings.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDailog;
