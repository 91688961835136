import {ChangeEvent, useEffect, useState} from 'react';
import {Close} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
} from '@mui/material';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import {useDispatch, useSelector} from 'react-redux';
import {
  getInventoryRuleCreator,
  inventoryComplianceSelector,
  saveInventoryRuleCreator,
} from '../../pages/inventory-compliance/redux';
import Colors from '@app/themes/colors';
import dayjs from 'dayjs';
import {DATE_TIME_SECOND_FORMAT1} from '@app/common/constants';
import {getFormattedDate} from '@app/utils/dateTimeHelper';

const AddInventoryRuleModal = ({
  open,
  onClose,
  editRuleId = null,
}: {
  open: boolean;
  onClose: any;
  editRuleId?: any;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const defaultStartDate = getFormattedDate({
    date: dayjs().add(1, 'day').toDate(),
    format: DATE_TIME_SECOND_FORMAT1,
  });

  const [annualLimit, setAnnualLimit] = useState<string>('');
  const [selectedPartyTypes, setSelectedPartyTypes] = useState<any[]>([]);
  const [skuNames, setSkuNames] = useState<any[]>([]);
  const [selectedSkuNames, setSelectedSkuNames] = useState<any[]>([]);
  const [selectedFY, setSelectedFY] = useState<number>(dayjs().year());
  const [openSkuMenu, setOpenSkuMenu] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const editableData = useSelector(
    inventoryComplianceSelector.getEditableData(),
  );
  const masterData = useSelector(
    inventoryComplianceSelector.getInventoryMasterData(),
  );

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    limit: 30,
  });

  useEffect(() => {
    if (editRuleId) {
      dispatch(getInventoryRuleCreator(editRuleId));
    }
  }, [editRuleId]);

  useEffect(() => {
    if (masterData?.partyTypeList?.length && !editRuleId && open) {
      const defaultPartyTypes = getDefaultPartyTypes();
      setSelectedPartyTypes(defaultPartyTypes);
    }
    if (masterData?.financialYear?.length) {
      setSelectedFY(masterData?.financialYear?.[0]);
    }
    return () => {
      resetFields();
    };
  }, [open]);

  useEffect(() => {
    if (masterData?.skuSampleList?.length) {
      const filteredSkuData = removeDuplicates(masterData?.skuSampleList);
      setSkuNames(filteredSkuData);
    }
    if (masterData?.financialYear?.length) {
      setSelectedFY(masterData?.financialYear?.[0]);
    }
    if (masterData?.partyTypeList?.length && !editRuleId) {
      const defaultPartyTypes = getDefaultPartyTypes();
      setSelectedPartyTypes(defaultPartyTypes);
    }
  }, [masterData]);

  useEffect(() => {
    if (editableData) {
      setAnnualLimit(editableData?.annualLimit);
      const selectedPartyType = masterData?.partyTypeList?.find(
        (party: any) => party?.partyTypeId === editableData?.partyTypeId,
      );
      setSelectedPartyTypes([selectedPartyType]);
      const selectedSku = skuNames?.find(
        sku => sku?.skuId === editableData?.skuId,
      );
      setSelectedSkuNames([selectedSku]);
    }
  }, [editableData]);

  function removeDuplicates(arr: any) {
    const seen: any = {};
    const result: any = [];

    arr.forEach((item: any) => {
      if (!seen[item.skuId]) {
        seen[item.skuId] = true;
        result.push(item);
      }
    });
    return result;
  }

  const resetFields = () => {
    setSelectedPartyTypes([]);
    setSelectedSkuNames([]);
    setAnnualLimit('');
    setSelectedFY(masterData?.financialYear?.[0]);
  };

  const closeDialog = () => {
    onClose();
    resetFields();
  };

  const label = (title: string, isSkuLabel?: boolean) => (
    <Box display="flex" alignItems="center" gap={0.4}>
      <Label title={title} fontWeight={'semibold'} size={12} hideToolTip />
      <Label title="*" textColor={Colors.red[100]} size={18} hideToolTip />
      {isSkuLabel && (
        <Label
          title={'(Enter mininum 3 characters)'}
          size={10}
          textColor={Colors.borderColor}
          hideToolTip
        />
      )}
    </Box>
  );

  const handleAnnualLimit = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event?.target?.value;
    if (/^[1-9]\d*$/.test(val) || val === '') {
      setAnnualLimit(val);
    }
  };

  const isSaveDisabled = () => {
    return (
      selectedFY &&
      selectedPartyTypes?.length &&
      selectedSkuNames?.length &&
      annualLimit &&
      !Boolean(validateAnnualLimit()) &&
      (editRuleId ? editableData?.annualLimit != annualLimit : true)
    );
  };

  const onSaveClick = () => {
    let body: any = {
      fyStart: selectedFY ? selectedFY - 1 : null,
      fyEnd: selectedFY,
      skuDetails: selectedSkuNames?.map(sku => ({
        skuId: sku?.skuId,
        skuName: sku?.skuName,
      })),
      partyTypeDetails: selectedPartyTypes?.map(party => ({
        partyTypeId: party?.partyTypeId,
        partyTypeName: party?.partyTypeName,
      })),
      annualLimit,
      startDate: defaultStartDate,
    };
    if (editRuleId) {
      body['skuDistributionLimitId'] = editRuleId;
      body['isUpdate'] = true;
    }
    dispatch(saveInventoryRuleCreator(body));
    resetFields();
    closeDialog();
  };

  const getDefaultPartyTypes = () => {
    return masterData?.partyTypeList?.filter(
      (party: any) => party?.partyTypeName === 'Doctor',
    );
  };

  const validateAnnualLimit = () => {
    const newLimit = parseInt(annualLimit);
    if (editRuleId && editableData?.annualLimit > newLimit) {
      return 'Annual limit can not be less than the existing value';
    } else if (newLimit > 99999) {
      return 'Annual limit should be less than 99999';
    } else {
      return '';
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{paper: classes.dialog}}
      scroll="paper">
      <Box className={classes.dialogHeader}>
        <DialogTitle className={classes.dialogTitle}>
          {editRuleId ? 'Edit' : 'Add New'} Inventory Configuration
        </DialogTitle>
        <IconButton
          color="primary"
          aria-label="add division close"
          component="span"
          id="close-add-modal"
          onClick={closeDialog}
          data-testid={'close-add-modal'}>
          <Close />
        </IconButton>
      </Box>

      <DialogContent>
        <FormControl size="medium" fullWidth sx={{marginBottom: 2}}>
          {label('Financial Year Selection')}
          <Select
            size="small"
            labelId="financial-year"
            id="financial-year"
            value={selectedFY}
            disabled
            onChange={(e: any) => setSelectedFY(e.target.value)}
            data-testid="financial-year-dropdown">
            {masterData?.financialYear?.map((year: any) => (
              <MenuItem
                value={year}
                key={year}
                data-testid={`financial-year-${year}`}>
                {year - 1}-{year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{marginBottom: 2}}>
          {label('Party Type Selection')}
          <Autocomplete
            id="party-type-autocomplete"
            multiple
            size="small"
            disableCloseOnSelect
            options={masterData?.partyTypeList ?? []}
            getOptionLabel={(option: any) => option.partyTypeName}
            value={selectedPartyTypes}
            onChange={(e, newValue) => setSelectedPartyTypes(newValue)}
            // disabled={editRuleId ? true : false}
            disabled={true}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={`${
                  selectedPartyTypes?.length ? '+ add' : 'Select party type'
                }`}
              />
            )}
            renderTags={(tagValue, getTagProps) => (
              <Box className={classes.chipContainer}>
                {tagValue.map((option, index) => {
                  const {key, ...tagProps} = getTagProps({index});
                  return (
                    <Chip
                      {...tagProps}
                      key={option?.partyTypeId}
                      label={option?.partyTypeName}
                      deleteIcon={
                        <Close
                          onMouseDown={event => event.stopPropagation()}
                          fontSize="small"
                        />
                      }
                      className={classes.chip}
                    />
                  );
                })}
              </Box>
            )}
            renderOption={(props, option, {selected}) => {
              return (
                <MenuItem
                  {...props}
                  key={option?.partyTypeId}
                  value={option}
                  sx={{padding: '0 !important'}}>
                  <Checkbox size="small" checked={selected} />
                  <ListItemText primary={option?.partyTypeName} />
                </MenuItem>
              );
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{marginBottom: 2}}>
          {label('SKU Name Selection', true)}
          <Autocomplete
            id="sku-name-autocomplete"
            multiple
            size="small"
            disableCloseOnSelect
            open={openSkuMenu}
            onOpen={() => {
              if (inputValue?.length >= 2) {
                setOpenSkuMenu(true);
              }
            }}
            onClose={() => setOpenSkuMenu(false)}
            options={skuNames}
            getOptionLabel={(option: any) =>
              `${option?.skuName} | ${option?.skusapCode}`
            }
            filterOptions={filterOptions}
            value={selectedSkuNames}
            onChange={(e, newValue) => setSelectedSkuNames(newValue)}
            disabled={editRuleId ? true : false}
            inputValue={inputValue}
            onInputChange={(e, value) => {
              setInputValue(value);
              if (!value) {
                setOpenSkuMenu(false);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                placeholder={`${
                  selectedSkuNames?.length ? '+ add' : 'Select SKU name or code'
                }`}
              />
            )}
            renderTags={(tagValue, getTagProps) => (
              <Box className={classes.chipContainer}>
                {tagValue.map((option, index) => {
                  const {key, ...tagProps} = getTagProps({index});
                  return (
                    <Chip
                      {...tagProps}
                      key={option?.skuId}
                      label={`${option?.skuName} | ${option?.skusapCode}`}
                      deleteIcon={
                        <Close
                          onMouseDown={event => event.stopPropagation()}
                          fontSize="small"
                        />
                      }
                      className={classes.chip}
                    />
                  );
                })}
              </Box>
            )}
            renderOption={(props, option, {selected}) => {
              return (
                <MenuItem
                  {...props}
                  key={option?.skuId}
                  value={option}
                  sx={{padding: '0 !important'}}>
                  <Checkbox size="small" checked={selected} />
                  <ListItemText
                    primary={`${option?.skuName} | ${option?.skusapCode}`}
                  />
                </MenuItem>
              );
            }}
          />
        </FormControl>

        <Box display="flex" gap={2}>
          <FormControl fullWidth>
            {label('Set Annual Limit (units)')}
            <TextField
              placeholder="Enter annual limit"
              size="small"
              value={annualLimit}
              onChange={handleAnnualLimit}
              error={Boolean(validateAnnualLimit())}
            />
            {validateAnnualLimit() && (
              <Label
                title={validateAnnualLimit()}
                textColor={Colors.red[100]}
                size={12}
                classes={classes.errorText}
                hideToolTip
              />
            )}
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box className={classes.buttonContainer}>
          <Button
            data-testid="button-cancel"
            variant="contained"
            className={classes.paperButton}
            onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            data-testid="button-apply"
            variant="contained"
            className={classes.applyButton}
            disabled={!isSaveDisabled()}
            onClick={onSaveClick}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddInventoryRuleModal;
