import { useCallback, useEffect, useMemo, useState, Fragment } from 'react';
import {
  Box,
  TableRow,
  Divider,
  IconButton,
  Grid,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  styled,
  Popper,
  Tooltip,
} from '@mui/material';
import { searchObj, tabTitleObj } from '../../components/CommonFunctions/CommonFun';
import EditIcon from '@app/assets/svg/edit-icon';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { routeStateActions } from '@app/router/redux';
import { navbarComponentName } from '@app/router/redux/routeHandler';
import {
  directoryActions,
  actions,
  directorySelector,
  directoryNavActionHandlerCreator,
} from '../../redux';
import Popup from '../../components/Popup';
import {
  hoUserSelector,
  hoUserCategoryCreator,
  hoUserDepartmentCreator,
  hoUserDivisionCreator,
  hoUserStateCreator,
  hoUserRegionCreator,
  hoUserCreator,
} from './redux';
import { appSelector } from '@app/store/selectors';
import NetworkService from '@app/services/network/NetworkService';
import { API_PATH, TOAST_MESSAGES } from '@app/common/constants';
import TableList from '../../components/TableList';
import {
  directoryPageLists,
  directoryUpdateCurrentPageCreator,
} from '../../redux/slice';
import { ToasterService } from '@app/services';
import { ToastType } from '@app/services/toaster';
import { CustomButton, SearchTextField, StyleInputLabel, StyledAutocomplete, StyledFormControlLabel, StyledTableCell } from '../../components/styledComponents';

const columns = [
  'Category',
  'Name',
  'Mobile Number',
  'Division',
  'Region',
  'State',
  '',
];

const initialHoUserData = {
  id: 0,
  contactCategoryName: '',
  hoContactCategoryId: 0,
  departmentName: '',
  departmentId: 0,
  divisionName: '',
  hoUserDivisionMap: [],
  stateName: '',
  hoUserStateMap: [],
  hoUserRegionMap: [],
  regionName: '',
  contactPerson: '',
  phoneNo: '',
  email: '',
  whatsappNumber: '',
  isActive: 1,
};

const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  '& .MuiAutocomplete-listbox': {
    padding: 0,
    margin: 0,
  },
  '& .MuiAutocomplete-option': {
    padding: theme.spacing(0), 
  },
}));
const HOUser = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [isUpdatingTab, setIsUpdatingTab] = useState(false);
  const [updatingError, setUpdatingError] = useState<null | string>(null);
  const [updatedHoUserData, setUpdatedHoUserData] = useState(initialHoUserData);
  const [addHoUserData, setAddHoUserData] = useState(updatedHoUserData);
  const [showEdit, setShowEdit] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector(appSelector.getUserInfo());
  const isActive = useSelector(directorySelector.getStatus, shallowEqual);
  const search = useSelector(directorySelector.getNavSearch(), shallowEqual);
  const pageIndex = useSelector(directorySelector.pageIndex, shallowEqual);
  const [stateValue, setStateValue] = useState([]);
  const [regionValue, setRegionValue] = useState([]);
  const [divisionValue, setDivisionValue] = useState([]);
  const [stateCheckAll, setStateCheckAll] = useState(false);
  const [regionCheckAll, setRegionCheckAll] = useState(false);
  const [divisionCheckAll, setDivisionCheckAll] = useState(false);
  const [hoUserRegion, setHoUserRegion] = useState([]);
  const [divisionReset, setDivisionReset] = useState([]);
  const [stateReset, setStateReset] = useState([]);
  const [stateData, setStateData] = useState<any>([]);

  const id = userInfo.id;

  const onPreventDefaultHandler = useCallback(e => e.preventDefault(), []);

  const modal: any = useSelector(
    directorySelector.getDirectoryModal(),
    shallowEqual,
  );

  const hoUserCategory: any = useSelector(
    hoUserSelector.getHoUserCategory(),
    shallowEqual,
  );
  const hoUserDepartment: any = useSelector(
    hoUserSelector.getHoUserDepartments(),
    shallowEqual,
  );
  const hoUserDivision: any = useSelector(
    hoUserSelector.getHoUserDivision(),
    shallowEqual,
  );
  const hoUserState: any = useSelector(
    hoUserSelector.getHoUserState(),
    shallowEqual,
  );
  const hoUserRegion1: any = useSelector(
    hoUserSelector.getHoUserRegion(),
    shallowEqual,
  );

  const hoUserList = useSelector(hoUserSelector.getHoUserList(), shallowEqual);

  const showAddTabModal = useMemo(
    () => modal?.show && modal?.type === actions.addTab,
    [modal],
  );

  const loading: any = useSelector(
    directorySelector.getLoading(),
    shallowEqual,
  );

  const isTabsFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.hoUserList &&
      (!hoUserList || hoUserList.length === 0),
    [loading, hoUserList],
  );

  const isUpdatingData = useMemo(
    () => loading === API_PATH.directoryConfiguration.hoUserList,
    [loading],
  );

  const isEmpty = useMemo(
    () => !isTabsFetching && (!hoUserList || hoUserList.length === 0),
    [isTabsFetching, hoUserList],
  );

  const onFormUpdateHandler = (data: any) => {
    if (updatingError) {
      setUpdatingError(null);
    }
    setAddHoUserData({
      ...addHoUserData,
      ...data,
    });
  };

  const checkAllChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setStateCheckAll(event.target.checked);
    if (event.target.checked) {
      const stateid: any = [];
      const statemap: any = [];
      hoUserState?.forEach((ele: any, _index: number) => {
        stateid.push(ele.value);
        statemap.push({
          stateId: ele.value,
        });
        onFormUpdateHandler({
          hoUserStateMap: statemap,
        });
      });
      setStateValue(hoUserState);
      dispatch(
        hoUserRegionCreator({
          statesId: stateid,
        }),
      );
    } else {
      setStateValue([]);
    }
  }, [hoUserState, onFormUpdateHandler]);

  const regionCheckAllChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRegionCheckAll(event.target.checked);
    if (event.target.checked) {
      const regionmap: any[] = [];
      hoUserRegion?.forEach((ele: any, _index: number) => {
        regionmap.push({
          stateId: ele.parentGeoLocationId,
          regionId: ele.value,
        });
        onFormUpdateHandler({
          hoUserRegionMap: regionmap,
        });
      });
      setRegionValue(hoUserRegion);
    } else {
      setRegionValue([]);
    }
  }, [hoUserRegion, onFormUpdateHandler]);

  const divisionCheckAllChange = useCallback((
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDivisionCheckAll(event.target.checked);
    if (event.target.checked) {
      const divisionId: any = [];
      hoUserDivision?.forEach((ele: any) => {
        divisionId.push({
          divisionId: ele.value,
        });
      });

      onFormUpdateHandler({
        hoUserDivisionMap: divisionId,
      });
      setDivisionValue(hoUserDivision);
    } else {
      setDivisionValue([]);
    }
  }, [onFormUpdateHandler, hoUserDivision]);

  const stateHandleChange = useCallback(async (_event: any, newValue: any, reason: any) => {
    const stateid: any = [];
    const statemap: any = [];
    if (newValue && newValue.length > 0) {
      newValue?.forEach((ele: any, _index: number) => {
        stateid.push(ele.value);
        statemap.push({
          stateId: ele.value,
        });

        onFormUpdateHandler({
          hoUserStateMap: statemap,
        });
      });
    } else {
      onFormUpdateHandler({
        hoUserStateMap: [],
      });
    }
    if (reason === 'selectOption') {
      if (regionValue === undefined) {
        setRegionValue([]);
      }
      setRegionCheckAll(false);
      setStateValue(newValue);
    } else if (reason === 'removeOption') {
      setStateCheckAll(false);
      setRegionCheckAll(false);
      let removedItem: any = {};
      stateValue?.forEach((element: any) => {
        let temp = newValue.findIndex((v: any) => v.value === element.value);
        if (temp === -1) {
          removedItem = element;
        }
      });
      setStateValue(newValue);
      const updateRegionValue: any = regionValue.filter(
        (v: any) => v.parentGeoLocationId !== removedItem.value,
      );
      setRegionValue(updateRegionValue);
    } else if (reason === 'clear') {
      setStateValue([]);
      setStateCheckAll(false);
      setRegionCheckAll(false);
      setRegionValue([])
    }
    if (newValue?.length === hoUserState?.length) {
      setStateCheckAll(true);
      setStateValue(hoUserState ? hoUserState : []);
    }
      if(stateid.length > 0){
        dispatch(
          hoUserRegionCreator({
            statesId: stateid,
          }),
        );
      } else {
        setHoUserRegion([])
      }
  }, [onFormUpdateHandler, hoUserState, regionValue]);

  const handleRegion = useCallback((_event: any, newValue: any, reason: any) => {
    const regionmap: any[] = [];
    if (newValue && newValue.length > 0) {
      newValue?.forEach((ele: any, _index: number) => {
        regionmap.push({
          stateId: ele.parentGeoLocationId,
          regionId: ele.value,
        });
        onFormUpdateHandler({
          hoUserRegionMap: regionmap,
        });
      });
    } else {
      onFormUpdateHandler({
        hoUserRegionMap: [],
      });
    }

    if (reason === 'selectOption') {
      setRegionValue(newValue);
    } else if (reason === 'removeOption') {
      setRegionCheckAll(false);
      setRegionValue(newValue);
    } else if (reason === 'clear') {
      setRegionValue([]);
      setRegionCheckAll(false);
    }
    if (newValue?.length === hoUserRegion?.length) {
      setRegionCheckAll(true);
      setRegionValue(hoUserRegion ? hoUserRegion : []);
    }
  }, [onFormUpdateHandler, hoUserRegion]);

  const handleDivisionChange = useCallback((_event: any, newValue: any, reason: any) => {
    const divisionId: any = [];
    newValue?.forEach((ele: any) => {
      divisionId.push({
        divisionId: ele.value,
      });
    });

    onFormUpdateHandler({
      hoUserDivisionMap: divisionId,
    });
    if (reason === 'selectOption') {
      setDivisionValue(newValue);
    } else if (reason === 'removeOption') {
      setDivisionCheckAll(false);
      setDivisionValue(newValue);
    } else if (reason === 'clear') {
      setDivisionValue([]);
      setDivisionCheckAll(false);
    }
    if (newValue?.length === hoUserDivision?.length) {
      setDivisionCheckAll(true);
      setDivisionValue(hoUserDivision ? hoUserDivision : []);
    }
  }, [onFormUpdateHandler, hoUserDivision]);

  const onLoadMoreHandler = useCallback((index: number) => {
    dispatch(
      hoUserCreator({
        index,
        isActive: isActive === 1,
        query: search?.query,
      }),
    );
  }, [isActive, search]);

  const handleInput = useCallback((e: any) => {
    e.target.value = Math.max(0, parseInt(e.target.value))
      .toString()
      .slice(0, 11);
  }, []);

  const renderTextField = useCallback((_params: any) => {
    return (
      <SearchTextField
        {..._params}
        required
        placeholder="Select"
        inputProps={{
          ..._params.inputProps,
          autoComplete: 'new-password', // disable autocomplete and autofill
        }}
      />
    );
  }, []);

  useEffect(() => {
    setHoUserRegion(hoUserRegion1);
  }, [hoUserRegion1]);

  useEffect(() => {
    let commonSearch: any = searchObj(isActive, search);
    dispatch(hoUserCreator(commonSearch));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, search]);

  useEffect(() => {
    dispatch(hoUserCategoryCreator({}));
    dispatch(hoUserDepartmentCreator({}));
    dispatch(hoUserDivisionCreator({ id: id }));
    dispatch(hoUserStateCreator({}));
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.directoryConfiguration,
      ),
    );
    let tabTitleData = tabTitleObj("List HO User", actions);
    dispatch(
      directoryActions.updateNavData(tabTitleData),
    );
    dispatch(directoryUpdateCurrentPageCreator(directoryPageLists.hoUser));

    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
    };
  }, [dispatch, id]);

  useEffect(() => {
    setAddHoUserData(updatedHoUserData);
  }, [updatedHoUserData]);

  const isHoUserFetching = useMemo(
    () =>
      loading === API_PATH.directoryConfiguration.hoUserList &&
      (!hoUserList || hoUserList.length === 0),
    [loading, hoUserList],
  );

  const isRegionFetching = useMemo(
    () => loading === API_PATH.directoryConfiguration.hoUserRegion,
    [loading],
  );

  const getRegionValue = async (item: any) => {
    const stId: any[] = [];
    item.hoUserStateMap?.forEach((ele: any) => {
      stId.push(ele.stateId);
    });
    setIsEditable(true);
    let response;
    if (stId?.length) {
      response = await NetworkService.post(
        API_PATH.directoryConfiguration.hoUserRegion,
        stId,
        {},
      );
    }
    let hoUserRegionRes = response?.data?.map((ele: any) => {
      return {
        ...ele,
        label: ele.name,
        value: ele.id,
      };
    });

    const defaultRegionCheck = hoUserRegionRes?.filter((div: any) => {
      return item?.hoUserRegionMap?.some((_div: any) => {
        return div.value === _div?.regionId;
      });
    });

    setRegionValue(defaultRegionCheck);
    if (defaultRegionCheck?.length === hoUserRegionRes?.length) {
      setRegionCheckAll(true);
    }
    setHoUserRegion(hoUserRegionRes);
  };

  const handleReset = useCallback(() => {
    setAddHoUserData(updatedHoUserData);
    if (isEditable) {
      setDivisionValue(divisionReset);
      setStateValue(stateReset);
      getRegionValue(stateData);
    }
  }, [updatedHoUserData, isEditable, divisionReset, stateReset, stateData, getRegionValue]);

  const renderHoUserRegionDropdownOption = useCallback((props, option: any) => {
    let isChecked = regionValue?.findIndex(
      (_value: any) => _value?.value === option?.value,
    );
    const ischeck = () => {
      return isChecked === -1 ? false : true;
    };

    return (
      <li {...props}>
        <Checkbox
          style={{ marginRight: 8 }}
          checked={regionCheckAll ? true : ischeck()}
        />
        {option?.label}
      </li>
    );
  }, [regionValue, regionCheckAll, hoUserRegion]);

  const renderHoUserRegionInput = useCallback(params => (
    <SearchTextField {...params} placeholder="Select" />
  ), []);

  const renderStateDropdownOption = useCallback((props, option: any, { selected }) => (
    <li {...props}>
      <Checkbox
        style={{ marginRight: 8 }}
        checked={selected || stateCheckAll}
      />
      {option?.label}
    </li>
  ), [stateCheckAll]);

  const renderHoStatePopperComponent = useCallback(param => (
    <PopperStyledComponent {...param}>
      <Box {...param} />
      <Divider />
      <Box
        sx={{
          backgroundColor: '#fff',
          height: '45px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          paddingLeft: 2,
          paddingTop: 1,
        }}>
        <Checkbox
          checked={stateCheckAll}
          onChange={checkAllChange}
          id="check-all"
          sx={{ marginRight: '8px' }}
          onMouseDown={onPreventDefaultHandler}
        />
        Select All
      </Box>
    </PopperStyledComponent>
  ), [hoUserState, checkAllChange]);

  const renderHoUserRegionPopperComponent = useCallback(param => (
    <PopperStyledComponent {...param}>
      <Box {...param} />
      <Divider />
      <Box
        sx={{
          backgroundColor: '#fff',
          height: '45px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          paddingLeft: 2,
          paddingTop: 1,
        }}>
        <Checkbox
          checked={regionCheckAll}
          onChange={regionCheckAllChange}
          id="check-all"
          sx={{ marginRight: '8px' }}
          onMouseDown={onPreventDefaultHandler}
        />
        Select All
      </Box>
    </PopperStyledComponent>
  ), [regionCheckAll, hoUserRegion, regionCheckAllChange]);

  const getOptionLabel = useCallback((option: any) => option?.label, []);

  const onChangeIsActiveHoUser = useCallback(event =>
    onFormUpdateHandler({
      isActive: event.target.checked ? 1 : 0,
    }), [onFormUpdateHandler]);

  const cancelAddTabModal = useCallback(() => {
    setIsEditable(false);
    dispatch(directoryNavActionHandlerCreator({ show: false, type: null }));
    setUpdatedHoUserData(initialHoUserData);
    setAddHoUserData(initialHoUserData);
    setUpdatingError(null);
    setStateCheckAll(false);
    setRegionCheckAll(false);
    setDivisionCheckAll(false);
    setDivisionValue([]);
    setRegionValue([]);
    setStateValue([]);
  }, []);

  const renderShowEdit = async (updatedHoUser: any) => {
    try {
      const response = await NetworkService.post(
        API_PATH.directoryConfiguration.hoUserUpdate,
        updatedHoUser,
        {},
      );
      setIsUpdatingTab(false);
      if (response.status !== 200) {
        ToasterService.showToaster(
          isEditable
            ? TOAST_MESSAGES.UNSUCCESSFULL_UPDATE
            : TOAST_MESSAGES.UNSUCCESSFULL_ADD,
          ToastType.ERROR
        );
        setUpdatingError(response.data.description);
      } else {
        setShowEdit(false);
        ToasterService.showToaster(
          isEditable
            ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
            : TOAST_MESSAGES.SUCCESSFULLY_ADD,
          ToastType.SUCCESS,
        );
        dispatch(
          hoUserCreator({
            index: 1,
            isActive: isActive === 1,
            query: search?.query,
          }),
        );
        cancelAddTabModal();
      }
    } catch (error: any) {
      setIsUpdatingTab(false);
      setUpdatingError(error.message);
    }
  };
  const renderNotShowEdit = async (updatedHoUser: any) => {
    try {
      const response = await NetworkService.post(
        API_PATH.directoryConfiguration.hoUserSave,
        updatedHoUser,
        {},
      );
      setIsUpdatingTab(false);
      if (response.status !== 200) {
        setUpdatingError(response.data.description);
      } else {
        ToasterService.showToaster(
          isEditable
            ? TOAST_MESSAGES.SUCCESSFULLY_UPDATED
            : TOAST_MESSAGES.SUCCESSFULLY_ADD,
          ToastType.SUCCESS,
        );
        dispatch(
          hoUserCreator({
            index: pageIndex,
            isActive: isActive === 1,
            query: search?.query,
          }),
        );
        cancelAddTabModal();
      }
    } catch (error: any) {
      setIsUpdatingTab(false);
      setUpdatingError(error.message);
    }
  };

  const onAddOrUpdateHoUserHandler = useCallback(async (e: any) => {
    const reg = /^[-.\w]+@(?![^.]{0,2}\.[a-zA-Z]{2,}$)([-a-zA-Z0-9]+\.)+[a-zA-Z]{2,}$/;
    if (!addHoUserData.email.toString().trim()) {
      setUpdatingError('Please fill all required fields!!');
    } else if (!reg.test(addHoUserData.email.toString().trim())) {
      setUpdatingError('Please fill valid email');
    } else if (
      addHoUserData.hoContactCategoryId.toString().trim() &&
      addHoUserData.contactCategoryName.toString().trim() &&
      addHoUserData.departmentId.toString().trim() &&
      addHoUserData.departmentName.toString().trim() &&
      addHoUserData.phoneNo.toString().trim()
    ) {
      setIsUpdatingTab(true);
      const updatedHoUser = { ...addHoUserData };
      if (showEdit) {
        renderShowEdit(updatedHoUser);
      } else {
        renderNotShowEdit(updatedHoUser);
      }
    } else {
      setUpdatingError('Please fill all required fields!!');
    }
  }, [addHoUserData]);

  const tabEditHandler = async (item: any) => {
    setShowEdit(true);
    getRegionValue(item);
    let catogary = hoUserCategory?.filter((ele: any) => {
      return item?.hoContactCategoryId === ele.value;
    });

    let departmentname = hoUserDepartment?.filter((ele: any) => {
      return item?.departmentId === ele.value;
    });

    setUpdatedHoUserData({
      id: item.id,
      contactCategoryName: item.hoContactCategoryId
        ? catogary[0]?.label
        : 'Select',
      hoContactCategoryId: item.hoContactCategoryId,
      departmentName: item.departmentId ? departmentname[0]?.label : 'Select',
      departmentId: item.departmentId,
      divisionName: item.divisionName ? item.divisionName : 'Select',
      hoUserDivisionMap: item.hoUserDivisionMap,
      stateName: item.stateName ? item.stateName : 'Select',
      hoUserStateMap: item.hoUserStateMap,
      hoUserRegionMap: item.hoUserRegionMap,
      regionName: item.regionName ? item.regionName : 'Select',
      contactPerson: item.contactPerson,
      phoneNo: item.phoneNo,
      email: item.email,
      whatsappNumber: item.whatsappNumber,
      isActive: item.isActive,
    });
    dispatch(
      directoryNavActionHandlerCreator({ show: true, type: actions.addTab }),
    );
  };

  const handldeEdit = useCallback(async (event) => {
    const item = JSON.parse(event.currentTarget.dataset.current);
    const response = await NetworkService.get(
      API_PATH.directoryConfiguration.getHoContactDetails,
      { id: item.id },
      {},
    );
    const defaultDivisionCheck = hoUserDivision?.filter(function (div: any) {
      return response.data?.hoUserDivisionMap?.some(function (_div: any) {
        return div.value === _div.divisionId;
      });
    });

    setDivisionValue(defaultDivisionCheck);
    setDivisionReset(defaultDivisionCheck);

    if (defaultDivisionCheck?.length === hoUserDivision?.length) {
      setDivisionCheckAll(true);
    }
    const defaultStateCheck = hoUserState?.filter((div: any) => {
      return response.data?.hoUserStateMap?.some((_div: any) => {
        return div.value === _div?.stateId;
      });
    });
    setStateValue(defaultStateCheck);
    setStateReset(defaultStateCheck);
    if (defaultStateCheck?.length === hoUserState?.length) {
      setStateCheckAll(true);
    }
    setStateData(response?.data);
    tabEditHandler(response?.data);
    setUpdatingError(null);
  }, [hoUserDivision, tabEditHandler, hoUserState]);

  const renderCategoryDropdownInput = useCallback((params: any) => (
    <SearchTextField {...params} required placeholder="Select" />
  ), []);

  const onChangeCategoryHandler = useCallback((_, val: any) =>
    onFormUpdateHandler({
      hoContactCategoryId: val.value,
      contactCategoryName: val.label,
    }), [onFormUpdateHandler]);

  const onChangeDepartmentHandler = useCallback((_, val: any) =>
    onFormUpdateHandler({
      departmentId: val.value,
      departmentName: val.label,
    }), [onFormUpdateHandler]);

  const renderDivisionPopperComponent = useCallback(param => (
    <PopperStyledComponent {...param}>
      <Box {...param} />
      <Divider />
      <Box
        sx={{
          backgroundColor: '#fff',
          height: '45px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          paddingLeft: 2,
          paddingTop: 1,
        }}>
        <Checkbox
          checked={divisionCheckAll}
          onChange={divisionCheckAllChange}
          id="check-all"
          sx={{ marginRight: '8px' }}
          onMouseDown={onPreventDefaultHandler}
        />
        Select All
      </Box>
    </PopperStyledComponent>
  ), [divisionCheckAllChange, divisionCheckAll, onPreventDefaultHandler]);

  const renderDivisionOption = useCallback((props, option: any, { selected }) => (
    <li {...props}>
      <Checkbox
        style={{ marginRight: 8 }}
        checked={selected || divisionCheckAll}
      />
      {option?.label}
    </li>
  ), [divisionCheckAll]);

  const onChangeName = useCallback(event =>
    onFormUpdateHandler({ contactPerson: event.target.value })
    , [onFormUpdateHandler]);

  const onChangeMobile = useCallback(event =>
    onFormUpdateHandler({ phoneNo: event.target.value })
    , [onFormUpdateHandler]);

  const onWhatsappNumberChangeHandler = useCallback(event =>
    onFormUpdateHandler({ whatsappNumber: event.target.value })
    , [onFormUpdateHandler]);

  const onEmailIdChangeHandler = useCallback(event =>
    onFormUpdateHandler({ email: event.target.value })
    , [onFormUpdateHandler]);

  const renderStateDropdownInput = useCallback((params: any) => (
    <SearchTextField
      {...params}
      required
      size="small"
      placeholder="Select"
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
    />
  ), []);


  if (isHoUserFetching) {
    return (
      <Box justifyContent={'center'} display="flex" sx={{ mt: 2 }}>
        <CircularProgress sx={{ color: 'primary.main' }} disableShrink />
      </Box>
    );
  }

  const divName = (__tab: any, from : string) => {
    if( from === 'division'){
      if (__tab?.divisionName.length > 15) {
        return __tab?.divisionName?.slice(0, 18) + '...';
      } else {
        return __tab?.divisionName;
      }
    } else if( from === 'region'){
      if (__tab?.regionName.length > 15) {
        return __tab?.regionName?.slice(0, 18) + '...';
      } else {
        return __tab?.regionName;
      }
    } else {
      if (__tab?.stateName.length > 15) {
        return __tab?.stateName?.slice(0, 18) + '...';
      } else {
        return __tab?.stateName;
      }
    }
  };

  return (
    <>
      <TableList
        columns={columns}
        isEmpty={isEmpty}
        onLoadMore={onLoadMoreHandler}
        isPaging={true}
        isLoading={isUpdatingData}>
        {hoUserList && hoUserList?.length > 0
          ? hoUserList?.map((item: any, index: number) => (
            <Fragment key={item.id.toString()}>
              <TableRow>
                <StyledTableCell>{item?.contactCategoryName || '-'}</StyledTableCell>
                <StyledTableCell sx={{ width: '26%' }}>
                  {item?.contactPerson || '-'}
                </StyledTableCell>
                <StyledTableCell>{item?.phoneNo || '-'}</StyledTableCell>
                <StyledTableCell>
                  <Tooltip title={item?.divisionName}>
                   <div>{item?.divisionName ? divName(item, 'division') : '-'}</div>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title={item?.regionName }>
                    <div>{item?.regionName  ? divName(item, 'region') : ' - '}</div>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <Tooltip title={item?.stateName }>
                    <div>{item?.stateName  ? divName(item, 'state') : ' - '}</div>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton
                    sx={{ p: 0 }}
                    data-current={JSON.stringify(item)}
                    onClick={handldeEdit}>
                    <EditIcon />
                  </IconButton>
                </StyledTableCell>
              </TableRow>
              <Divider
                component={TableRow}
                variant="fullWidth"
                sx={{ height: 5, border: 0 }}
              />
            </Fragment>
          ))
          : null}
      </TableList>
      <Popup
        open={!!showAddTabModal}
        cancelPopup={cancelAddTabModal}
        isLoading={isUpdatingTab}
        error={updatingError}
        title={isEditable ? 'Edit  HO User' : 'Add  HO User'}>
        <Box style={{ maxHeight: 400, overflow: 'auto' }}>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Category
            </StyleInputLabel>

            <StyledAutocomplete
              id="country-select-demo"
              sx={{ width: 450 }}
              freeSolo
              options={hoUserCategory ? hoUserCategory : []}
              disabled={!hoUserCategory || hoUserCategory?.length === 0}
              renderInput={renderCategoryDropdownInput}
              onChange={onChangeCategoryHandler}
              value={addHoUserData.contactCategoryName}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Department
            </StyleInputLabel>
            <StyledAutocomplete
              id="country-select-demo"
              sx={{ width: 450 }}
              freeSolo
              options={hoUserDepartment ? hoUserDepartment : []}
              disabled={!hoUserDepartment || hoUserDepartment?.length === 0}
              renderInput={renderTextField}
              onChange={onChangeDepartmentHandler}
              value={addHoUserData?.departmentName}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Division
            </StyleInputLabel>
            <StyledAutocomplete
              multiple
              disableCloseOnSelect
              freeSolo
              limitTags={3}
              id="country-select-demo"
              sx={{ width: 450 }}
              options={hoUserDivision ? hoUserDivision : []}
              disabled={hoUserDivision?.length === 0}
              renderInput={renderTextField}
              onChange={handleDivisionChange}
              PopperComponent={renderDivisionPopperComponent}
              getOptionLabel={getOptionLabel}
              renderOption={renderDivisionOption}
              value={divisionValue}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Name
            </StyleInputLabel>
            <SearchTextField
              sx={{ width: 450 }}
              fullWidth
              placeholder="Enter Name"
              required
              type="text"
              value={addHoUserData.contactPerson}
              onChange={onChangeName}
              inputProps={{
                maxlength: 30,
              }}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Mobile/Phone Number
            </StyleInputLabel>
            <SearchTextField
              sx={{ width: 450 }}
              fullWidth
              placeholder="Enter No"
              value={addHoUserData.phoneNo}
              type="number"
              onChange={onChangeMobile}
              inputProps={{
                max: 5,
              }}
              onInput={handleInput}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard">Whatsapp Number</StyleInputLabel>
            <SearchTextField
              sx={{ width: 450 }}
              fullWidth
              placeholder="Enter No"
              value={
                addHoUserData.whatsappNumber ? addHoUserData.whatsappNumber : ''
              }
              required
              type="number"
              onChange={onWhatsappNumberChangeHandler}
              onInput={handleInput}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              Email ID
            </StyleInputLabel>
            <SearchTextField
              sx={{ width: 450 }}
              fullWidth
              placeholder="Enter Email"
              value={addHoUserData.email}
              required
              inputProps={{
                inputMode: 'email',
              }}
              onChange={onEmailIdChangeHandler}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard" required>
              State
            </StyleInputLabel>

            <StyledAutocomplete
              multiple
              disableCloseOnSelect
              freeSolo
              limitTags={3}
              id="multiple-limit-tags"
              value={stateValue}
              options={hoUserState ? hoUserState : []}
              disabled={!hoUserState}
              renderInput={renderStateDropdownInput}
              onChange={stateHandleChange}
              PopperComponent={renderHoStatePopperComponent}
              getOptionLabel={getOptionLabel}
              renderOption={renderStateDropdownOption}
            />
          </Box>
          <Box sx={{ mb: 3 }}>
            <StyleInputLabel variant="standard">Region</StyleInputLabel>
            <StyledAutocomplete
              multiple
              disableCloseOnSelect
              limitTags={3}
              sx={{ width: 450 }}
              options={hoUserRegion ? hoUserRegion : []}
              value={regionValue ? regionValue : []}
              freeSolo
              loading={isRegionFetching}
              renderInput={renderHoUserRegionInput}
              onChange={handleRegion}
              PopperComponent={renderHoUserRegionPopperComponent}
              getOptionLabel={getOptionLabel}
              renderOption={renderHoUserRegionDropdownOption}
              style={{ width: '100%' }}
            />
          </Box>

          <Box sx={{ mb: 2, display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={addHoUserData.isActive === 1}
                  onChange={onChangeIsActiveHoUser}
                />
              }
              label="Is Active"
              labelPlacement="top"
            />
          </Box>
        </Box>
        <Box position={'sticky'} sx={{ backgroundColor: 'white', paddingTop: 2 }}>
          <Grid container justifyContent={'center'} alignItems={'center'}>
            <Grid item mr={2}>
              <CustomButton
                variant="outlined"
                onClick={handleReset}>
                Reset
              </CustomButton>
            </Grid>
            <Grid item>
              <CustomButton variant="contained" onClick={onAddOrUpdateHoUserHandler}>
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </Box>
      </Popup>
    </>
  );
};
export default HOUser;