import {createAction, createSlice} from '@reduxjs/toolkit';

export const componentManagementInitialState = {
  expenseType: [],
  isActiveState: true,
  addExpenseTypePopupVisible: false,
  ExpenseTypeLoading: false,
  expenseCategory: [],
  expenseTypeSnaShot: [],
  expenseTypeInAutoSuggest: {},
  expenseTypeInAutoSuggestFilter: {},
  searchText: '',
  editExpenseTypePopupVisible: false,
  editExpenseType: [],
  resetNavbarVersion: 0,
  autoSuggestHq: [],
  expenseTypeOption: [],
  expenseTypeAutoSuggestLoading: false,
};
export const fetchExpenseTypeCreator = createAction<any>(
  'COMPONENT_MANAGEMENT_HANDLER/FETCH_ADMIN_USERS',
);
export const fetchExpenseTypeCreatorTypeName = fetchExpenseTypeCreator({}).type;

export const createExpenseTypeCreator = createAction<any>(
  'COMPONENT_MANAGEMENT_HANDLER/CREATE_EXPENSE_TYPE',
);
export const fetchAutoSuggestHqCreator = createAction<any>(
  'COMPONENT_MANAGEMENT_HANDLER/FETCH_AUTO_SUGGEST_HQ',
);
export const fetchAutoSuggestHqCreatorTypeName = fetchAutoSuggestHqCreator(
  {},
).type;

export const createExpenseTypeCreatorTypeName =
  createExpenseTypeCreator(null).type;

export const editExpenseTypeCreator = createAction<any>(
  'COMPONENT_MANAGEMENT_HANDLER/EDIT_EXPENSE_TYPE',
);
export const editExpenseTypeCreatorTypeName = editExpenseTypeCreator(null).type;
export const fetchExpenseCategoryCreator = createAction(
  'COMPONENT_MANAGEMENT_HANDLER/FETCH_DIVISION_DROPDOWN',
);
export const fetcExpenseCategoryCreatorTypeName =
  fetchExpenseCategoryCreator().type;

export const fetchExpenseTypeActiveInactiveCreator = createAction<any>(
  'COMPONENT_MANAGEMENT_HANDLER/FETCH_EXPENSE_TYPE_ACTIVE_INACTIVE',
);
export const fetchExpenseTypeActiveInactiveCreatorTypeName =
  fetchExpenseTypeActiveInactiveCreator({}).type;

export const fetchExpenseTypeAutoSuggestCreator = createAction(
  'COMPONENT_MANAGEMENT_HANDLER/FETCH_EXPENSE_TYPE_AUTO_SUGGEST',
);
export const fetchExpenseTypeAutoSuggestCreatorTypeName =
  fetchExpenseTypeAutoSuggestCreator().type;
export const fetchExpenseTypeOptionsCreator = createAction(
  'COMPONENT_MANAGEMENT_HANDLER/FETCH_EXPENSE_TYPE_OPTION',
);
export const fetchExpenseTypeOptionsCreatorTypeName =
  fetchExpenseTypeOptionsCreator().type;

const componentManagementStateHandler = createSlice({
  name: 'COMPONENT_MANAGEMENT_HANDLER',
  initialState: componentManagementInitialState,
  reducers: {
    setExpenseType: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseType: payload,
      };
    },
    setAutoSuggestHq: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        autoSuggestHq: payload,
      };
    },
    setExpenseTypeSnaShot: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTypeSnaShot: payload,
      };
    },
    setaddExpenseTypePopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addExpenseTypePopupVisible: payload,
      };
    },
    setEditExpenseTypePopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editExpenseTypePopupVisible: payload,
      };
    },
    setEditExpenseType: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editExpenseType: payload,
      };
    },
    setExpenseTypeOption: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTypeOption: payload,
      };
    },
    setIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isActiveState: payload,
      };
    },
    setExpenseTypeLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        ExpenseTypeLoading: payload,
      };
    },
    setExpenseTypeInAutoSuggest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTypeInAutoSuggest: payload,
      };
    },
    setExpenseTypeInAutoSuggestFilter: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTypeInAutoSuggestFilter: payload,
      };
    },
    setSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        searchText: payload,
      };
    },
    setExpenseCategory: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseCategory: payload,
      };
    },
    setResetNavbarVersion: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        resetNavbarVersion: payload,
      };
    },
    setExpenseTypeAutoSuggestLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        expenseTypeAutoSuggestLoading: payload,
      };
    },
  },
});

export const componentManagementStateActions =
  componentManagementStateHandler.actions;
export const componentManagementStateReducer =
  componentManagementStateHandler.reducer;
