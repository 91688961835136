import {createAction, createSlice} from '@reduxjs/toolkit';

export const accessGroupMasterInitialState = {
  isActiveState: true,
  resetNavbarVersion: 0,
  userSearchText: '',
  firstLoad: false,
  accessGroups: [],
  accessGroupsLoading: false,
  accessGroupsError: false,
  accessGroupsSuccess: false,
  accessGroupsCount: 0,
  accessGroupsErrorMessage: '',

  accessGroupsAutosuggestSearchText: '',
  accessGroupsAutosuggestData: [],
  accessGroupsAutosuggestLoading: false,
  accessGroupsAutosuggestError: false,
  accessGroupsAutosuggestSuccess: false,
  accessGroupsAutosuggestErrorMessage: '',
  selectedAccessGroupsInAutoSuggest: {},

  editAccessGroupSuccess: false,
  editAccessGroupError: false,
  editAccessGroupErrorMessage: '',
  editAccessGroupLoading: false,

  addAccessGroupSuccess: false,
  addAccessGroupError: false,
  addAccessGroupErrorMessage: '',
  addAccessGroupLoading: false,
  addAccessGroupMenuItems: [],

  disableAccessGroupSuccess: false,
  disableAccessGroupError: false,
  disableAccessGroupErrorMessage: '',
  disableAccessGroupLoading: false,

  editAccessGroupPopupVisible: false,
  editAccessGroupValue: {},

  editChipSelectedValue: {},

  addAccessGroupPopupVisible: false,
  addAccessGroupValue: {},
};

export const fetchAccessGroupMasterCreator = createAction<any>(
  'ACCESS_GROUP_MASTER_HANDLER/FETCH_ACCESS_GROUPS',
);
export const fetchAccessGroupMasterCreatorTypeName =
  fetchAccessGroupMasterCreator(null).type;

export const fetchAccessGroupsAutoSuggestCreator = createAction(
  'ACCESS_GROUP_MASTER_HANDLER/FETCH_ACCESS_GROUPS_AUTO_SUGGEST',
);
export const fetchAccessGroupsAutoSuggestCreatorTypeName =
  fetchAccessGroupsAutoSuggestCreator().type;

export const addAccessGroupCreator = createAction<any>(
  'ACCESS_GROUP_MASTER_HANDLER/ADD_ACCESS_GROUP_USER',
);

export const addAccessGroupCreatorTypeName = addAccessGroupCreator({}).type;

export const disableAccessGroupCreator = createAction<any>(
  'ACCESS_GROUP_MASTER_HANDLER/DISABLE_ACCESS_GROUP_USER',
);

export const disableAccessGroupCreatorTypeName = disableAccessGroupCreator(
  {},
).type;

export const editAccessGroupCreator = createAction<any>(
  'ACCESS_GROUP_MASTER_HANDLER/EDIT_ACCESS_GROUP_USER',
);

export const editAccessGroupCreatorTypeName = editAccessGroupCreator({}).type;

export const accessGroupMenuItemsCreator = createAction(
  'ACCESS_GROUP_MASTER_HANDLER/FETCH_ACCESS_GROUP_MENUITEMS',
);

export const accessGroupMenuItemsCreatorTypeName =
  accessGroupMenuItemsCreator().type;

const accessGroupMasterStateHandler = createSlice({
  name: 'ACCESS_GROUP_MASTER_HANDLER',
  initialState: accessGroupMasterInitialState,
  reducers: {
    setAccessGroupMasterStateKeyValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        [payload.key]: payload.value,
      };
    },
    setAccessGroups: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroups: payload,
      };
    },
    setAccessGroupsLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsLoading: payload,
      };
    },
    setAccessGroupsError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsError: payload,
      };
    },
    setAccessGroupsSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsSuccess: payload,
      };
    },
    setAccessGroupsCount: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsCount: payload,
      };
    },
    setAccessGroupsErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsErrorMessage: payload,
      };
    },
    clearError: prevState => {
      return {
        ...prevState,
        accessGroupsError: false,
        accessGroupsErrorMessage: '',
      };
    },

    setIsActiveState: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        isActiveState: payload,
      };
    },
    setUserSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        userSearchText: payload,
      };
    },

    setEditAccessGroupPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editAccessGroupPopupVisible: payload,
      };
    },
    setEditAccessGroupValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editAccessGroupValue: payload,
      };
    },
    setAddAccessGroupPopupVisible: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addAccessGroupPopupVisible: payload,
      };
    },
    setAddAccessGroupValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addAccessGroupValue: payload,
      };
    },

    setAccessGroupsAutosuggestSearchText: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsAutosuggestSearchText: payload,
      };
    },

    setAccessGroupsAutosuggestData: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsAutosuggestData: payload,
      };
    },
    setAccessGroupsAutosuggestLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsAutosuggestLoading: payload,
      };
    },
    setAccessGroupsAutosuggestError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsAutosuggestError: payload,
      };
    },
    setAccessGroupsAutosuggestErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        accessGroupsAutosuggestErrorMessage: payload,
      };
    },
    clearAccessGroupsAutosuggestError: prevState => {
      return {
        ...prevState,
        accessGroupsAutosuggestError: false,
        accessGroupsAutosuggestErrorMessage: '',
      };
    },
    setSelectedAccessGroupsInAutoSuggest: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        selectedAccessGroupsInAutoSuggest: payload,
      };
    },

    setEditAccessGroupSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editAccessGroupSuccess: payload,
      };
    },
    setEditAccessGroupError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editAccessGroupError: payload,
      };
    },
    setEditAccessGroupErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editAccessGroupErrorMessage: payload,
      };
    },
    setEditAccessGroupLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editAccessGroupLoading: payload,
      };
    },
    clearEditAccessGroupError: prevState => {
      return {
        ...prevState,
        editAccessGroupError: false,
        editAccessGroupErrorMessage: '',
      };
    },
    setAddAccessGroupSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addAccessGroupSuccess: payload,
      };
    },
    setAddAccessGroupError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addAccessGroupError: payload,
      };
    },
    setAddAccessGroupErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addAccessGroupErrorMessage: payload,
      };
    },
    setAddAccessGroupLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        addAccessGroupLoading: payload,
      };
    },
    clearAddAccessGroupError: prevState => {
      return {
        ...prevState,
        addAccessGroupError: false,
        addAccessGroupErrorMessage: '',
      };
    },
    setDisableAccessGroupSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableAccessGroupSuccess: payload,
      };
    },
    setDisableAccessGroupError: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableAccessGroupError: payload,
      };
    },
    setDisableAccessGroupErrorMessage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableAccessGroupErrorMessage: payload,
      };
    },
    setDisableAccessGroupLoading: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        disableAccessGroupLoading: payload,
      };
    },
    clearDisableAccessGroupError: prevState => {
      return {
        ...prevState,
        disableAccessGroupError: false,
        disableAccessGroupErrorMessage: '',
      };
    },

    setEditChipSelectedValue: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        editChipSelectedValue: payload,
      };
    },

    setFirstLoad: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        firstLoad: payload,
      };
    },

    setResetNavbarVersion: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        resetNavbarVersion: payload,
      };
    },
  },
});

export const accessGroupMasterStateActions =
  accessGroupMasterStateHandler.actions;
export const accessGroupMasterStateReducer =
  accessGroupMasterStateHandler.reducer;
