import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { TableCell, Box, Stack } from "@mui/material";
import { configurationManagementStateActions, fetchExpenseTypeSettingGridCreator} from "../redux/slice";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Gridstyle } from "@app/components/widgets/grid/grid";

import { ConfigurationManagementSelector } from "../redux/seletors";
import moment from "moment";

import { useCallback, useEffect, useState } from "react";
import { filterSelector, filterStateActions } from "@app/components/right-sidebar/redux";

export const renderDateCell = (date: any) => {
  return <Box>{date ? moment(date)?.format("DD/MM/YYYY"):''}</Box>
};

export const ExpenseTypeSettingGrid = ({ expenseTypeSetting }: { expenseTypeSetting: any }) => {
  const dispatch = useDispatch();


  useEffect(() => {
    return () => {
      dispatch(filterStateActions.setFilterState({}));
      dispatch(filterStateActions.setFilterApplied(false));
      dispatch(configurationManagementStateActions.setExpenseTypeSetting([]))
    };
  }, [dispatch]); 
  const Loading = useSelector(
    ConfigurationManagementSelector.getExpenseTypeLoading()
  );
  const pageNumber = useSelector(
    ConfigurationManagementSelector.getPageNo(),
    shallowEqual
  );
  const totalCount = useSelector(
    ConfigurationManagementSelector.getTotalCount(),
    shallowEqual
  );
  const pageRow = useSelector(
    ConfigurationManagementSelector.getRowPerPage(),
    shallowEqual
  );
  const request = useSelector(
    filterSelector.getFilterOptions()
  );

  const [page, setPage] = useState(pageNumber);
  const [pageSize, setPageSize] = useState(pageRow);
  
useEffect(() => {
  setPage(pageNumber);
  setPageSize(pageRow);
}, [pageNumber, pageRow]);

  useEffect(() => {
    dispatch(configurationManagementStateActions.setPageNo(page));
    dispatch(configurationManagementStateActions.setRowPerPage(pageSize));
  }, [dispatch,page, pageSize]);



  const renderReadOnlyCell = (params: GridRenderCellParams) => {
    if (params?.row?.isReadOnly) {
      return (
        <TableCell>
          <Box>{"Yes"}</Box>
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          <Box>{"No"}</Box>
        </TableCell>
      );
    }
  };
  const renderBillRequiredCell = (params: GridRenderCellParams) => {
    if (params?.row?.isBillRequired) {
      return (
        <TableCell>
          <Box>{"Yes"}</Box>
        </TableCell>
      );
    } else {
      return (
        <TableCell>
          <Box>{"No"}</Box>
        </TableCell>
      );
    }
  };

 const getRowIdHandler = useCallback(function(row:any){
   return row?.expenseTypeSettingId
 },[])

 const PageSizeChange = useCallback(function(newPageSize:any){
  return setPageSize(newPageSize)
},[])
const PageChange = useCallback(function(newPage:any){
  return  setPage(newPage)
},[])

  const columns = [
    {
      field: "division",
      headerName: "Division",
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{ maxWidth: "100%", wordBreak: "break-word" }}>
            {params.row.division}
          </p>
        );
      },
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{ maxWidth: "100%", wordBreak: "break-word" }}>
            {params.row.designation}
          </p>
        );
      },
    },
    {
      field: "hq",
      headerName: "HQ",
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{ maxWidth: "100%", wordBreak: "break-word" }}>
            {params.row.hq}
          </p>
        );
      },
    },
    {
      field: "expenseTypeName",
      headerName: "Expense Type Name",
      width: 180,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <p style={{ maxWidth: "100%", wordBreak: "break-word" }}>
            {params.row.expenseTypeName}
          </p>
        );
      },
    },
    {
      field: "isReadOnly",
      headerName: "Read Only",
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderReadOnlyCell(params);
      },
    },
    {
      field: "isBillRequired",
      headerName: "Bill Required",
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderBillRequiredCell(params);
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      editable: false,
    },
    {
      field: "startDate",
      headerName: "Effective From",
      width: 150,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderDateCell(params?.row?.startDate)
      },
    },
    {
      field: "endDate",
      headerName: "Valid Upto",
      width: 120,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderDateCell(params?.row?.endDate)
       
      },
    },
    
  ];
  useEffect(() => {

    dispatch(configurationManagementStateActions.setPageNo(page));
    dispatch(configurationManagementStateActions.setRowPerPage(pageSize));
    if((Number(page)*Number(pageSize)) > totalCount){
      dispatch(configurationManagementStateActions.setPageNo(0));
      dispatch(filterStateActions.setFilterOptions({
        ...request,
        pageNumber:0,
        pageLimit:pageSize
      }))
    }else{
      dispatch(filterStateActions.setFilterOptions({
        ...request,
        pageNumber:page,
        pageLimit:pageSize
      }))
    }
  
    dispatch(
      fetchExpenseTypeSettingGridCreator(null)
    );
    
  }, [page,pageSize]);
  const noRowsOverlayComponent = useCallback(() => (
    <Stack height="100%" alignItems="center" justifyContent="center">
      No Records Found
    </Stack>
  ),[]);
  return (
    <Box sx={Gridstyle.gridcss}>
      <DataGrid
        columnBuffer={9}
        page={page}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPageChange={PageChange}
        pagination
        loading={Loading}
        paginationMode="server"
        pageSize={pageSize}
        rows={expenseTypeSetting ??[]}
        rowCount={(Number(page)*Number(pageSize)) > totalCount ? (Number(page)*Number(pageSize)) : totalCount}
        getRowId={getRowIdHandler}
        columns={columns}
        onPageSizeChange={PageSizeChange}
        components={{
          NoRowsOverlay: noRowsOverlayComponent
        }}
      />
    </Box>
  );
};
