import {
  Button,
  IconButton,
  Typography,
  Link,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  CircularProgress,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useState} from 'react';
import {FileEditableField} from '../BillPopup/BillDialog';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import {useDispatch, useSelector} from 'react-redux';
import {
  approveExpenseStateActions,
  fetchInvoiceDetailCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {styles} from '../BillPopup/billsStyles';
import {strings} from '@app/common/strings';
import theme from '@app/themes';

export const util: any = {
  onChangeHandler: null,
  dateChange: null,
  saveBillHandler: null,
};
const CollapsibleContent: React.FC<{
  item: {
    isBillVisible: boolean;
    isAdminUploaded: boolean;
    id: number;
    invoiceNumber: string;
    invoiceDate: any;
    vendor: string;
    billAmount: number | string;
    supportingBills: any;
    date: any;
    fileName: any;
    tadaMiscId: number;
    remarks: string;
  };
  onSubmitBtn: (
    item: any,
    id: number,
    date: any,
    fileName: any,
    tadaMiscId: any,
    remarks: string,
  ) => void;
  handlePreview: (file: any) => void;
  handleDownload: (file: any) => void;
  handelDeleteClick: (
    invcNum: any,
    supportingDocId: number | boolean,
    i: number,
  ) => void;
  isDisable?: boolean;
  isHideable: boolean;
}> = ({
  item,
  onSubmitBtn,
  handlePreview,
  handleDownload,
  handelDeleteClick,
  isDisable = false,
  isHideable,
}) => {
  const billStyle = styles();
  const dispatch = useDispatch();
  const [inputField, setInputField] = useState({
    invoiceNumber: item?.invoiceNumber,
    invoiceDate: item?.date,
    vendor: item?.vendor,
    invoiceAmt: item?.billAmount,
    isBillVisible: item?.isBillVisible,
    isAdminUploaded: item?.isAdminUploaded,
    SupportingDoc: [],
  });
  const [remarks, setRemarks] = useState(item?.remarks ?? '');
  const [isDelKeyOrBckspc, setIsDelKeyOrBckspc] = useState('');

  const isInvoiceDoesntExist = useSelector(
    approveExpenseStateSelector.getInvoiceStatusofBill(),
  );
  const isValidationIsLoading = useSelector(
    approveExpenseStateSelector.getIsInvoiceVaildationIsLoading(),
  );
  let divName = isInvoiceDoesntExist?.divName;
  let sapCode = isInvoiceDoesntExist?.sapCode;
  let staffName = isInvoiceDoesntExist?.staffName;

  const onChangeHandler = (e: any) => {
    const {name, value} = e?.target ?? {};
    if (
      item?.remarks &&
      name === strings.moduleSpecificUtilities.invoiceAmount
    ) {
      if (String(value) !== String(item?.billAmount)) {
        setRemarks('');
      } else {
        setRemarks(item?.remarks);
      }
    }

    if (
      (name === strings.moduleSpecificUtilities.invoiceNumber &&
        inputField?.invoiceNumber?.length >= 40 &&
        isDelKeyOrBckspc !== strings.moduleSpecificUtilities.backspace) ||
      (name === strings.moduleSpecificUtilities.invoiceAmount &&
        String(inputField?.invoiceAmt)?.length >= 6 &&
        isDelKeyOrBckspc !== strings.moduleSpecificUtilities.backspace)
    ) {
      return;
    }

    let val =
      name == strings.moduleSpecificUtilities.invoiceAmount
        ? value?.replace(/\D/g, '')
        : value;
    setInputField(prev => ({...prev, [name]: val}));
  };

  const visibilityChange = (e: any) => {
    setInputField({...inputField, isBillVisible: e?.target?.checked});
  };

  const dateChange = (e: any) => {
    const formattedDate = new Date(e);
    const acceptedDate = `${formattedDate.getFullYear()}-${
      formattedDate.getMonth() + 1
    }-${formattedDate.getDate()}`;
    setInputField({...inputField, invoiceDate: acceptedDate});
  };

  util.onChangeHandler = onChangeHandler;
  util.dateChange = dateChange;

  const saveBillHandler = () => {
    onSubmitBtn(
      inputField,
      item?.id,
      item?.date,
      item?.fileName,
      item?.tadaMiscId,
      remarks,
    );
  };

  util.saveBillHandler = saveBillHandler;
  const onValidateBtn = () => {
    const {invoiceNumber, vendor} = inputField;
    dispatch(fetchInvoiceDetailCreator({invoiceNumber, vendorName: vendor}));
  };

  const renderSuppDocs = (bills: any) => {
    return bills?.map(
      (doc: any, i: number) =>
        !!doc && (
          <Box display={'flex'} flexDirection={'row'} key={doc?.id}>
            <Link
              m={2}
              className={billStyle.supDocPrevLink}
              width={'90%'}
              data-testid={`handle-button`}
              onClick={() => handlePreview(doc?.fileName)}>
              {doc?.fileName}
            </Link>
            <IconButton
              onClick={() => handleDownload(doc?.fileName)}
              data-testid={`download-button`}
              color="primary">
              <DownloadIcon />
            </IconButton>
            {isDisable && (
              <IconButton
                onClick={() => handelDeleteClick(item, doc.id, i)}
                data-testid={`delete-button`}
                style={{color: theme.colors.red[1400]}}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ),
    );
  };

  const isDisabledOrNot = () => {
    if (
      inputField?.invoiceNumber === '' ||
      inputField?.invoiceDate === '' ||
      inputField?.vendor === ''
    ) {
      return true;
    }
    if (
      inputField?.invoiceNumber !== item?.invoiceNumber ||
      inputField?.vendor !== item?.vendor
    ) {
      return false;
    }
    return true;
  };

  const isSaveBtnDisable = () => {
    const currentDate = new Date(item?.date);
    const updatedDate = new Date(inputField?.invoiceDate);
    if (
      inputField?.invoiceNumber === '' ||
      inputField?.invoiceDate === '' ||
      inputField?.vendor === '' ||
      inputField?.invoiceAmt === ''
    ) {
      return true;
    }
    if (
      String(item?.billAmount) !== String(inputField?.invoiceAmt) &&
      remarks === ''
    ) {
      return true;
    }
    if (isInvoiceDoesntExist?.isValid === true) {
      return false;
    }
    if (!isDisabledOrNot()) {
      return true;
    }
    if (
      currentDate instanceof Date &&
      updatedDate instanceof Date &&
      areDatesEqual()
    ) {
      return false;
    }
    if (
      (String(item?.billAmount) !== String(inputField?.invoiceAmt) &&
        inputField?.invoiceAmt !== '' &&
        remarks !== '') ||
      item?.isBillVisible !== inputField?.isBillVisible
    ) {
      return false;
    }
    return true;
  };

  const areDatesEqual = () => {
    const currentDate = new Date(item?.date);
    const updatedDate = new Date(inputField?.invoiceDate);
    return (
      currentDate?.getDate() !== updatedDate?.getDate() ||
      currentDate?.getMonth() !== updatedDate?.getMonth() ||
      currentDate?.getFullYear() !== updatedDate?.getFullYear()
    );
  };

  const isResetBtnEnable = () => {
    if (
      inputField?.invoiceNumber !== item?.invoiceNumber ||
      areDatesEqual() ||
      inputField?.vendor !== item?.vendor ||
      +inputField?.invoiceAmt !== +item?.billAmount ||
      inputField?.isBillVisible !== item?.isBillVisible
    ) {
      return false;
    }

    return true;
  };

  const resetBtn = () => {
    dispatch(approveExpenseStateActions.setInvoiceStatus(null));
    setRemarks('');
    const resetData = {
      invoiceNumber: item?.invoiceNumber,
      invoiceDate: item?.date,
      vendor: item?.vendor,
      invoiceAmt: item?.billAmount,
      isBillVisible: item?.isBillVisible,
      isAdminUploaded: item?.isAdminUploaded,
      SupportingDoc: [],
    };
    setInputField(resetData);
    setRemarks(item?.remarks);
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      key={item?.invoiceNumber}
      m={1}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        key={item?.invoiceNumber}
        m={1}>
        <FileEditableField
          columnName={strings.moduleSpecificUtilities.INVOICENUMBER}
          value={inputField?.invoiceNumber}
          name={strings.moduleSpecificUtilities.invoiceNumber}
          data-testid="invoicenumber"
          changeValue={onChangeHandler}
          type={'text'}
          isDisable={!isHideable || isInvoiceDoesntExist?.isValid}
          keyChecker={setIsDelKeyOrBckspc}
        />
        <FileEditableField
          columnName={strings.moduleSpecificUtilities.INVOICEDATE}
          value={inputField?.invoiceDate}
          name={strings.moduleSpecificUtilities.invoiceDate}
          changeValue={dateChange}
          type="date"
          isDisable={!isHideable || isInvoiceDoesntExist?.isValid}
          keyChecker={setIsDelKeyOrBckspc}
        />
        <FileEditableField
          columnName={strings.moduleSpecificUtilities.VENDORNAME}
          value={inputField?.vendor}
          name={strings.moduleSpecificUtilities.vendor}
          changeValue={onChangeHandler}
          type={'text'}
          isDisable={!isHideable || isInvoiceDoesntExist?.isValid}
          keyChecker={setIsDelKeyOrBckspc}
        />
        {isHideable && (
          <Button
            disabled={isDisabledOrNot()}
            data-testid={'validate-button'}
            onClick={() => onValidateBtn()}
            variant="contained"
            color="primary"
            className={billStyle.validateBtnCollapsible}
            endIcon={
              isValidationIsLoading ? (
                <CircularProgress
                  sx={{color: `${theme.colors.white} !important`}}
                  size={16}
                />
              ) : (
                strings.moduleSpecificUtilities.vaildate
              )
            }
            size="small"></Button>
        )}
        <FileEditableField
          columnName={strings.moduleSpecificUtilities.INVOICEAMOUNT}
          value={inputField?.invoiceAmt}
          name={strings.moduleSpecificUtilities.invoiceAmount}
          changeValue={onChangeHandler}
          type={'text'}
          isDisable={!isHideable || isDisable}
          keyChecker={setIsDelKeyOrBckspc}
        />
      </Box>
      <Box>
        {inputField?.isAdminUploaded && (
          <FormGroup sx={{marginLeft: 2, width: '20%'}}>
            <FormControlLabel
              sx={{
                '& .MuiTypography-root': {fontSize: 12},
              }}
              control={
                <Checkbox
                  checked={inputField?.isBillVisible}
                  size="small"
                  onChange={visibilityChange}
                />
              }
              label={strings.label.billVisible}
            />
          </FormGroup>
        )}
        {isInvoiceDoesntExist?.isValid === false && (
          <Typography color="error" sx={{left: 8, position: 'relative'}}>
            Invoice number already exists.({isInvoiceDoesntExist?.month}-
            {isInvoiceDoesntExist?.year}, Division - {divName}, SAP Code -{' '}
            {sapCode} and Staff Name -{staffName} )
          </Typography>
        )}
        {isInvoiceDoesntExist?.isValid === true && (
          <Typography sx={{left: 8, position: 'relative', color: 'green'}}>
            {strings.moduleSpecificUtilities.validationSuccess}
          </Typography>
        )}
        {isHideable && (
          <Button
            disabled={isSaveBtnDisable()}
            data-testid={'save-button'}
            sx={{marginLeft: 1}}
            onClick={saveBillHandler}
            variant="contained">
            {strings.save}
          </Button>
        )}
        {isHideable && (
          <Button
            disabled={isResetBtnEnable()}
            data-testid={'reset-button'}
            sx={{marginLeft: 1}}
            onClick={resetBtn}
            variant="contained">
            {strings.reset}
          </Button>
        )}
        {(item?.remarks ||
          String(inputField?.invoiceAmt) !== String(item?.billAmount)) &&
          isHideable && (
            <Box
              className={billStyle.remarksContainer}
              sx={{
                bottom: inputField?.isAdminUploaded ? 31 : 0,
              }}>
              <Typography className={billStyle.remarksText}>
                {strings.moduleSpecificUtilities.remarks}
              </Typography>
              <TextField
                value={remarks}
                onChange={e => setRemarks(e?.target?.value)}
                className={billStyle.remarksInput}
                inputProps={{
                  style: {
                    paddingTop: 4.5,
                    paddingBottom: 4.5,
                    paddingLeft: 4,
                    paddingRight: 4,
                    fontSize: 11,
                  },
                }}
              />
            </Box>
          )}
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        m={1}
        key={item?.invoiceNumber}>
        <Typography className={billStyle.supportDocInCollapsible}>
          {strings.moduleSpecificUtilities.supportingDoc}
        </Typography>
        {item?.supportingBills?.length ? (
          renderSuppDocs(item?.supportingBills)
        ) : (
          <Typography className={billStyle.noDataFound}>
            {strings.moduleSpecificUtilities.noDataFound}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CollapsibleContent;
