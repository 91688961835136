import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  fetchInventoryApproveName,
  approvedReturnItemActions,
  fetchApproveHeadquartersName,
  fetchApproveStatesAndDivisionsName,
  fetchautoSuggestions,
  fetchFilteredDataApprove,
  fetchApproveReturnItemsFilterName,
  fetchApprovedItems,
  fetchRejectItems,
} from './slice';

export function* fetchInventoryApproveReturnWatcher() {
  yield takeLatest(
    fetchInventoryApproveName,
    fetchInventoryApproveReturnWorker,
  );
}
export function* fetchInventoryApproveReturnWorker(): any {
  try {
    yield put(approvedReturnItemActions.setInventoryAdminApproveLoading());
    const url = `${API_PATH.inventoryModule.adminApprovalreturnItems}`;
    const result = yield call(NetworkService.get, url, {}, {});
    yield put(
      approvedReturnItemActions.setInventoryAdminApproveSuccess(result?.data),
    );
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}
export function* fetchApproveHeadquartersWatcher() {
  yield takeLatest(
    fetchApproveHeadquartersName,
    fetchApproveHeadquartersWorker,
  );
}
export function* fetchApproveHeadquartersWorker(action: any): any {
  yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
  try {
    const url = `${API_PATH.inventoryModule.cnfHeadquarters}`;
    const res = yield call(NetworkService.post, url, action.payload, null);
    yield put(approvedReturnItemActions.setHeadQuarters(res?.data));
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}
export function* fetchApproveStatesAndDivisionsWatcher() {
  yield takeLatest(
    fetchApproveStatesAndDivisionsName,
    fetchApproveStatesAndDivisionsWorker,
  );
}
export function* fetchApproveStatesAndDivisionsWorker(action: any): any {
  yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
  try {
    const urlStates = `${API_PATH.inventoryModule.cnfStates}`;
    const resStates = yield call(NetworkService.get, urlStates, null, null);
    const resDivisions = yield call(
      NetworkService.get,
      //urlDivisions,
      urlStates,
      null,
      null,
    );
    yield put(approvedReturnItemActions.setStatesData(resStates?.data));
    yield put(approvedReturnItemActions.setDivisions(resDivisions?.data));
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}

export function* fetchApproveReturnItemsAutoSuggestionWatcher() {
  yield takeLatest(
    fetchautoSuggestions,
    fetchApproveReturnItemsAutoSuggestionsWorker,
  );
}
export function* fetchApproveReturnItemsAutoSuggestionsWorker(
  action: any,
): any {
  try {
    yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.adminAutoSuggestionData}`;
      const autoSuggestionData = yield call(
        NetworkService.get,
        url,
        {...action.payload},
        null,
      );
      if (autoSuggestionData?.status === 200) {
        yield put(
          approvedReturnItemActions.setApproveAutoSuggestionDataSuccess(
            autoSuggestionData?.data,
          ),
        );
      }
      yield put(approvedReturnItemActions.setInventoryAdminApproveError());
    }
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}

export function* fetchApproveFilteredDataWatcher() {
  yield takeLatest(fetchFilteredDataApprove, fetchFilteredDataWorker);
}
export function* fetchFilteredDataWorker(action: any): any {
  try {
    yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
    if (action && action.payload) {
      const {searchText} = action.payload;
      const url = `${API_PATH.inventoryModule.adminFilteredData}`;
      const filteredData = yield call(
        NetworkService.get,
        url,
        {searchData: searchText},
        null,
      );
      if (filteredData?.status === 200) {
        yield put(
          approvedReturnItemActions.setApproveFilteredData(filteredData?.data),
        );
      }
    }
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}
export function* fetchApproveReturnItemsFilterWatcher() {
  yield takeLatest(
    fetchApproveReturnItemsFilterName,
    fetchApproveReturnItemsFilterWorker,
  );
}

export function* fetchApproveReturnItemsFilterWorker(action: any): any {
  yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
  try {
    const url = `${API_PATH.inventoryModule.adminApprovalReturnFilterData}`;
    const res = yield call(NetworkService.post, url, action.payload, null);
    yield put(
      approvedReturnItemActions.setInventoryAdminApproveSuccess(res?.data),
    );
    yield put(approvedReturnItemActions.getArReqApiCalled(true));
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}

export function* fetchApprovedReturnItemsWatcher() {
  yield takeLatest(fetchApprovedItems, fetchApprovedItemsWorker);
}

export function* fetchApprovedItemsWorker(action: any): any {
  yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
  try {
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.adminApprovalCreate}`;
      const response = yield call(
        NetworkService.post,
        url,
        action?.payload?.newData,
        null,
      );

      if (action.payload.filterSearchDailog !== '') {
        yield put({
          type: fetchFilteredDataApprove,
          payload: {searchText: action?.payload?.filterSearchDailog},
        });
      } else {
        yield put({
          type: fetchApproveReturnItemsFilterName,
          payload: action?.payload?.req,
        });
      }

      if (response?.status === 200) {
        yield put(
          approvedReturnItemActions.setInventoryAdminisApproveLoading(),
        );
        yield put(approvedReturnItemActions.setApprovedItems(response?.data));
      }
    }
    yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}
export function* fetchApproveRejectItemsWatcher() {
  yield takeLatest(fetchRejectItems, fetchRejectItemsWorker);
}
export function* fetchRejectItemsWorker(action: any): any {
  yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
  try {
    if (action && action.payload) {
      const url = `${API_PATH.inventoryModule.adminApprovalReject}`;
      const response = yield call(
        NetworkService.post,
        url,
        action?.payload?.newData,
        null,
      );
      if (action.payload.filterSearchDailog !== '') {
        yield put({
          type: fetchFilteredDataApprove,
          payload: {searchText: action?.payload?.filterSearchDailog},
        });
      } else {
        yield put({
          type: fetchApproveReturnItemsFilterName,
          payload: action?.payload?.req,
        });
      }
      if (response?.status === 200) {
        yield put(
          approvedReturnItemActions.setInventoryAdminisApproveLoading(),
        );
        yield put(approvedReturnItemActions.setrejectItems(response?.data));
      }
    }
    yield put(approvedReturnItemActions.setInventoryAdminisApproveLoading());
  } catch (e) {
    yield put(approvedReturnItemActions.setInventoryAdminApproveError());
  }
}
