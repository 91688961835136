export const GeneralReportTableStyles = {
  container: {
    fontFamily: 'Poppins-Regular',
    fontSize: 12.7,
    fontWeight: 'bolder',
    letterSpacing: 0,
    textTransform: 'none',
    textAlign: 'left',
    color: '#494761',
  },
  ChallanTableCell: {
    color: '#494761',
    fontSize: '12.7px',
    fontStyle: 'normal',
    textAlign: 'left',
    fontFamily: 'Poppins-Regular',
    fontWeight: 'normal',
    letterSpacing: 0,
  },
  search: {
    display: 'flex',
  },
  eyeButton: {
    padding: '8px 22px',
    width: 90,
    height: 30,
    gap: '8px',
    margin: '7px',
    alignContent: 'center',
  },
  searchbar: {
    borderRadius: '0px',
    width: '-webkit-fill-available',
    padding: '0px',
    opacity: '0.6',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '12px',
    display: 'contents',
  },
  footer: {
    margin: '12px',
  },
  reports: {
    padding: '8px 22px',
    width: 'max-content',
    height: 40,
    margin: '7px',
    alignContent: 'center',
    marginRight: -7,
  },
  header: {
    fontSize: 25,
    alignContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  QtyformContainer: {
    display: 'flex',
  },
  QtyformControl: {
    right: 265,
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
    width: 340,
  },
  serachFormControl: {
    borderRadius: '0px',
    width: 450,
    padding: '0px',
    opacity: '0.6',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '12px',
    display: 'block',
    right: 250,
  },
  NoData: {
    margin: 2,
    color: '#322b7c',
  },
  Table: {},
};

export const FilterModalStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexDisplay: {
    display: 'flex',
  },
  inlineFlex: {
    display: 'inline-flex',
    marginRight: '19px',
  },
  formControl: {
    margin: '20px',
    marginLeft: '0px',
    marginRight: '0px',
  },
  Modal: {
    padding: '20px 10px',
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  footer: {
    justifyContent: 'center',
    padding: '25px',
  },
  Button: {
    width: '-webkit-fill-available',
    marginRight: '15px',
    marginLeft: '15px',
  },
  ViewButton: {
    width: 200,
    textTransform: 'none',
  },
};
