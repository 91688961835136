import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    background: Colors.background,
    borderRadius: 10,
    padding: 25,
  },
  datePickerContainer: {
    display: 'flex',
  },
  dcrDialog: {
    display: 'flex',
    width: '100%',
  },
  dcrBoxtitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  dcrTitleContainer: {
    marginLeft: 15,
    marginTop: 10,
    marginBottom: 5,
  },
  dcrTitle: {
    padding: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    padding: 10,
  },
  paperButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
    '&:disabled': {
      backgroundColor: Colors.grey[100],
      border: `3px solid ${Colors.grey[100]}`,
    },
  },
  root: {
    flexDirection: 'row-reverse',
  },
  datePicker: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'transparent',
      },
    },
  },
  errorLabel: {
    marginLeft: 15,
  },
  wrapText: {
    whiteSpace: 'break-spaces',
  },
});
