import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {SearchBar} from '@app/components/widgets/searchBar';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';
import {
  fetchInventoryListCreator,
  inventoryComplianceSelector,
  inventoryComplianceStateActions,
} from '@app/screens/configuration-management/pages/inventory-compliance/redux';
import {Box, Button, FormControl, MenuItem, Select} from '@mui/material';
import Colors from '@app/themes/colors';
import AddInventoryRuleModal from '@app/screens/configuration-management/components/add-inventory-rule-modal';
import {Download, Upload} from '@mui/icons-material';
import DownloadInventoryExcelModal from '@app/screens/configuration-management/components/downlaod-inventory-excel-modal';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import BulkUploadInventoryModal from '@app/screens/configuration-management/components/bulk-upload-inventory-modal';
import { strings } from '@app/common/strings';

export const InventoryComplianceNavbar = () => {
  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles();
  const searchText = useSelector(inventoryComplianceSelector.getSearchText());
  const gridOptions = useSelector(inventoryComplianceSelector.getGridOptions());
  const selectedFY = useSelector(inventoryComplianceSelector.getSelectedFY());
  const masterData = useSelector(
    inventoryComplianceSelector.getInventoryMasterData(),
  );

  const downloadExcelError = useSelector(
    inventoryComplianceSelector.getDownloadExcelError(),
  );
  const bulkUploadResponse = useSelector(
    inventoryComplianceSelector.getBulkUploadInventoryResponse(),
  );

  const { successMessage} = strings?.configurationManagement?.inventoryComplianceConfiguration;

  const handleFYChange = (event: any) => {
    dispatch(
      inventoryComplianceStateActions.setSelectedFY(event?.target?.value),
    );
  };

  const handleSearchTextChange = (value: any) => {
    dispatch(inventoryComplianceStateActions.setSearchText(value));
    if(!value){
      dispatch(inventoryComplianceStateActions.setInventoryComplianceList([]));
      dispatch(fetchInventoryListCreator());
    }
  };

  const handleSearchPress = (e: any) => {
    if (e.key === 'Enter') {
      fetchListData();
    }
  };

  useEffect(() => {
    if (downloadExcelError != null) {
      if (downloadExcelError?.message) {
        ToasterService.showToaster(
          downloadExcelError?.message,
          ToastType.ERROR,
        );
      }
      dispatch(inventoryComplianceStateActions.setDownloadExcelError(null));
    }
  }, [downloadExcelError]);

  useEffect(() => {
    if (bulkUploadResponse != null) {
      if (bulkUploadResponse?.status === 0) {
        ToasterService.showToaster(
          `${bulkUploadResponse?.description}`,
          ToastType.ATTENTION,
        );
      } else {
        let toastMessage = `${bulkUploadResponse?.description}`;
        let toastType = ToastType.SUCCESS;
        if(bulkUploadResponse?.description !== successMessage){
          toastMessage = `${bulkUploadResponse?.description} An error report will be download in your system.`
          toastType = ToastType.ATTENTION;
        }
        ToasterService.showToaster(
          toastMessage,
          toastType,
        );
      }
      dispatch(
        inventoryComplianceStateActions.setBulkUploadInventoryResponse(null),
      );
      dispatch(fetchInventoryListCreator());
    }
  }, [bulkUploadResponse]);

  const fetchListData = () => {
    dispatch(
      inventoryComplianceStateActions.setInventoryListGridOptions({
        ...gridOptions,
        pageNo: 0,
      }),
    );
    dispatch(fetchInventoryListCreator());
  };

  const showAddInventoryRuleModal = () => {
    setShowAddModal(true);
  };

  const closeAddInventoryModal = () => {
    setShowAddModal(false);
  };

  const showDownloadInventoryRuleModal = () => {
    setShowDownloadModal(true);
  };

  const closeDownloadInventoryRuleModal = () => {
    setShowDownloadModal(false);
  };
  const showBulkUploadInventoryModal = (data: any) => {
    setShowBulkUploadModal(true);
  };
  const closeBulkUploadInventoryModal = () => {
    setShowBulkUploadModal(false);
  };

  const fileActionLabel = (title: string) => (
    <Label
      title={title}
      textColor={Colors.primary}
      size={14}
      fontWeight={'semibold'}
      hideToolTip
    />
  );

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Label
          title={navbarComponentHeading}
          variant="h6"
          testID={'superman_admin_navbar_heading'}
          textColor={'#322b7c'}
          fontWeight={'800'}
          hideToolTip
        />

        <Box
          className={classes.fileAction}
          marginLeft="auto"
          onClick={showBulkUploadInventoryModal}>
          <Upload color="primary" />
          {fileActionLabel('Bulk Upload')}
        </Box>
        <Box
          className={classes.fileAction}
          marginLeft={4}
          onClick={showDownloadInventoryRuleModal}>
          <Download color="primary" />
          {fileActionLabel('Download Excel')}
        </Box>
      </div>
      <div className={classes.searchContainer}>
        <FormControl>
          <Select
            id="financial-year"
            value={selectedFY ?? ''}
            data-testid={'financial-year'}
            onChange={handleFYChange}
            className={classes.dropdown}
            inputProps={{'aria-label': 'Without label'}}>
            {masterData?.financialYear?.map((year: any) => (
              <MenuItem
                value={year}
                key={`financial-year-${year}`}
                data-testid={`financial-year-${year}`}>
                {year - 1}-{year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <SearchBar
          searchText={searchText}
          handleChange={handleSearchTextChange}
          handleSearch={handleSearchPress}
          showError={false}
          placeHolder={'SKU name/code or Party type/code'}
          errorMessage={'Please Enter Minimum 3 Characters'}
        />
        <Button
          variant="contained"
          onClick={showAddInventoryRuleModal}
          className={classes.paperButton}
          data-testid={'add_inventory_rule'}>
          Add New
        </Button>
      </div>
      <AddInventoryRuleModal
        open={showAddModal}
        onClose={closeAddInventoryModal}
      />
      <DownloadInventoryExcelModal
        open={showDownloadModal}
        onClose={closeDownloadInventoryRuleModal}
      />
      <BulkUploadInventoryModal
        open={showBulkUploadModal}
        onClose={closeBulkUploadInventoryModal}
      />
    </div>
  );
};
