import { createAction, createSlice } from "@reduxjs/toolkit";

interface InitialStateProps {
  hoContactCategory: {
    id: number;
    // tabId: number;
    name: string;
  }[];
}

const initialState: InitialStateProps = {
  hoContactCategory: [],
};

export const hoContactCategoryListCreator = createAction<any>(
  "DIRECTORY/HO_CONTACT_CATEGORY_LIST"
);
export const hoContactCategoryTypeName =
  hoContactCategoryListCreator(null).type;

const hoContactCategorySlice = createSlice({
  name: "HO_CONTACT_CATEGORY",
  initialState: initialState,
  reducers: {
    fetchHoContactCategory: (state, action) => {
      return {
        ...state,
        hoContactCategory: action.payload,
      };
    },
    fetchHoContactNewCategory: (state: any, action: any) => {
      return {
        ...state,
        hoContactCategory: [...state.hoContactCategory, ...action.payload],
      };
    },
  },
});

export const hoContactCategoryActions = hoContactCategorySlice.actions;
export const hoContactCategoryReducer = hoContactCategorySlice.reducer;
