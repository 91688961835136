import * as React from 'react';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Autocomplete, Typography} from '@mui/material';
import {StatusMangerCard} from './StatusMangerCard';
import {statusManagerStateActions} from '@app/screens/module-specific-utilities/pages/status-manager/redux/index';
import {statusManagerStateSelector} from '@app/screens/module-specific-utilities/pages/status-manager/redux/selectors';
import {
  fetchGetExpenseStatusActionCreator,
  fetchStatusManagerAutoSuggestStaffCreator,
} from '@app/screens/module-specific-utilities/pages/status-manager/redux/slice';
import {appSelector} from '@app/store/selectors';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {generateLastTenYears} from '@app/screens/module-specific-utilities/components/approve-expenses/utils/index';
import {useCallback, useEffect} from 'react';
import {
  MenuProp,
  renderInputStaffCodes,
  useStyles,
} from '@app/screens/Styles/style';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import Colors from '@app/themes/colors';
import {strings} from '@app/common/strings';

export const util: any = {
  handleOnClickGo: null,
  handleChangeMonth: null,
  handleChangeYear: null,
  onChangeStaffName: null,
};
export const StatusMangerHeader = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [division, setDivision] = React.useState('');
  const [month, setMonth] = React.useState('');
  const [staffPositionId, setStaffPositionId] = React.useState('0');
  const [staffUserId, setStaffUserId] = React.useState('0');
  const [staffCode, setStaffCode] = React.useState('0');
  const [staffName, setStaffName] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState({});
  const [year, setYear] = React.useState('');
  const [inputValueN, setInputValueN] = React.useState('');
  const [inputHq, setInputHq] = React.useState('');
  const [validate, setValidate] = React.useState(false);
  const [valueN, setValueN] = React.useState({fullName: ''});
  const [inputValueC, setInputValueC] = React.useState('');
  const [valueC, setValueC] = React.useState({employeeCode: ''});

  const divisionDropDown = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const status = useSelector(statusManagerStateSelector.getActiveStatus());

  const autoSuggestedStaffDetail = useSelector(
    statusManagerStateSelector.getStatusManagerAutoSuggestStaffDetail(),
  );
  const autoSuggestedStaffLoading = useSelector(
    statusManagerStateSelector.getStatusManagerAutoSuggestLoading(),
  );
  const {isWriteAccess = false} = useSelector(
    appSelector.getUserInfo(),
    shallowEqual,
  );

  const handleChangeDiv = (event: {target: {value: any}}) => {
    setDivision(event.target.value);

    setValueC({employeeCode: ''});
    setValueN({fullName: ''});
    setInputValueN('');
    setInputValueC('');
    setYear('');
    setMonth('');
    setStaffCode('0');
    setStaffName('');
  };

  useEffect(() => {
    setValueC({employeeCode: ''});
    setValueN({fullName: ''});
    setInputValueN('');
    setInputValueC('');
    setStaffCode('0');
    setStaffName('');
  }, [status]);
  const handleStatusManagerStaffInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let search = {
      searchText: event?.target?.value ?? '',
      divisionId: [division],
    };
    if (division !== '' && event?.target?.value?.length >= 3) {
      dispatch(fetchStatusManagerAutoSuggestStaffCreator(search));
    }
  };

  const lastTenYears: number[] = generateLastTenYears();
  const [open, setOpen] = React.useState(false);

  const handleClose = useCallback(
    (val: boolean) => {
      setOpen(val);
    },
    [open],
  );
  useEffect(() => {
    return () => {
      dispatch(
        statusManagerStateActions.setStatusManagerAutoSuggestStaffDetail([]),
      );
    };
  }, []);
  const handleOnClickGo = useCallback(() => {
    dispatch(statusManagerStateActions.setStatusManagerAction([]));

    const getActionValue = {
      divisionId: division,
      staffPositionId: staffPositionId,
      staffUserId: staffUserId,
      expenseMonth: month,
      expenseYear: year,
    };
    setSelectedValue(getActionValue);

    dispatch(fetchGetExpenseStatusActionCreator(getActionValue));
    setOpen(true);
  }, [division, staffPositionId, month, year]);

  useEffect(() => {
    if (
      division !== '' &&
      staffPositionId !== '0' &&
      month !== '' &&
      year !== ''
    )
      setValidate(false);
    else setValidate(true);
  }, [division, staffPositionId, month, year]);

  const onClose = useCallback(
    () =>
      dispatch(
        statusManagerStateActions.setStatusManagerAutoSuggestStaffDetail([]),
      ),
    [],
  );

  const handleChangeMonth = useCallback(
    (event: {target: {value: any}}) => {
      setMonth(event.target.value);
    },
    [month],
  );

  const handleChangeYear = useCallback(
    (event: {target: {value: any}}) => {
      setYear(event.target.value);
    },
    [year],
  );

  const getOptionLabelStaffCode = (option: any) => {
    return option?.employeeCode?.toString();
  };

  const onInputChangeStaffCode = (event: any, newInputValue: any) => {
    setInputValueC(newInputValue?.trimStart());
    handleStatusManagerStaffInputChange(event);
  };

  const onInputChangeName = (event: any, newInputValue: any) => {
    setInputValueN(newInputValue?.trimStart());

    handleStatusManagerStaffInputChange(event);
  };
  const keyDownHandle = useCallback(
    (e: any) => ['e', '-', 'E'].includes(e.key) && e.preventDefault(),
    [],
  );
  const onChangeStaffCode = (_event: any, value: any) => {
    setStaffPositionId(value?.staffPositionId || '0');
    setStaffUserId(value?.userId || '0');
    setStaffCode(value?.employeeCode || '0');
    setValueN(value);
    setValueC(value);
    setInputHq(value?.staffHQName);
  };
  const onChangeStaffName = (_event: any, value: any) => {
    setStaffPositionId(value?.staffPositionId || '0');
    setStaffUserId(value?.userId || '0');
    setStaffName(value?.fullName || '');
    setValueN(value);
    setValueC(value);
    setInputHq(value?.staffHQName);
  };

  const renderInputStaffCode = useCallback(function (params1: any) {
    return (
      <TextField
        type="number"
        placeholder="Enter Employee Code"
        {...params1}
        sx={renderInputStaffCodes}
      />
    );
  }, []);
  const renderInputStaffName = useCallback(function (params1: any) {
    return (
      <TextField
        placeholder="Enter Staff Name"
        {...params1}
        sx={renderInputStaffCodes}
      />
    );
  }, []);

  util.handleOnClickGo = handleOnClickGo;
  util.handleChangeMonth = handleChangeMonth;
  util.handleChangeYear = handleChangeYear;
  util.onChangeStaffName = onChangeStaffName;
  return (
    <Stack
      spacing={0.5}
      p={2}
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      style={{
        backgroundColor: '#f4f4f4',
        height: 120,
        borderRadius: 6.7,
        margin: 5,
      }}>
      <Box width={'100%'}>
        <Typography className={classes.label}>
          {strings.ModuleSpecificUtilitie.reverseExpensepage.DIVISION}
        </Typography>
        <FormControl size="small" fullWidth>
          <InputLabel
            sx={{marginTop: 2.3, fontSize: 15}}
            id="division-dropdown">
            Select Division
          </InputLabel>

          <Select
            size="small"
            disabled={!isWriteAccess}
            value={division}
            title={
              divisionDropDown.find(
                (divisionObj: any) => divisionObj.value === division,
              )?.label || ''
            }
            placeholder="Division"
            MenuProps={MenuProp}
            inputProps={{
              'data-testid': 'dropdown-setDivision',
            }}
            onChange={handleChangeDiv}
            label="Select Division"
            sx={{
              top: 18,
              backgroundColor: Colors?.white,
            }}>
            {divisionDropDown.map((divisionValue: any) => (
              <MenuItem key={divisionValue.value} value={divisionValue.value}>
                {divisionValue.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width={'100%'}>
        <Typography className={classes.label}>
          {strings.ModuleSpecificUtilitie.expense.MONTH}
        </Typography>

        <FormControl size="small" fullWidth>
          <InputLabel
            sx={{marginTop: 2.3, fontSize: 15}}
            id="division-dropdown">
            Exp Month
          </InputLabel>
          <Select
            inputProps={{
              'data-testid': 'dropdown-setMonth',
            }}
            disabled={!isWriteAccess}
            value={month}
            title={
              monthMock().find((monthObj: any) => monthObj.key === month)
                ?.value ?? ''
            }
            size="small"
            label="Exp Month"
            onChange={handleChangeMonth}
            sx={{
              top: 17,
              backgroundColor: Colors?.white,
            }}>
            {monthMock().map((monthVal: any) => (
              <MenuItem key={monthVal.key} value={monthVal.key}>
                {monthVal.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width={'100%'}>
        <Typography className={classes.label}>
          {strings.ModuleSpecificUtilitie.expense.YEAR}
        </Typography>

        <FormControl size="small" fullWidth>
          <InputLabel
            sx={{marginTop: 2.3, fontSize: 15}}
            id="division-dropdown">
            Exp Year
          </InputLabel>
          <Select
            inputProps={{
              'data-testid': 'dropdown-setYear',
            }}
            size="small"
            disabled={!isWriteAccess}
            label="Exp Year"
            value={year}
            title={year}
            sx={{top: 17, backgroundColor: Colors?.white}}
            onChange={handleChangeYear}>
            {lastTenYears.map((yearOption: any) => (
              <MenuItem key={yearOption} value={yearOption}>
                {yearOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width={'100%'}>
        <Typography className={classes.label}>
          {strings.ModuleSpecificUtilitie.reverseExpensepage.EMPLOYEE_CODE}{' '}
        </Typography>

        <Autocomplete
          id="autocomplete-suggested-staff-code"
          size="small"
          options={autoSuggestedStaffDetail}
          className={classes.selectPlaceholder}
          loading={autoSuggestedStaffLoading}
          disabled={
            staffName !== '' ||
            !isWriteAccess ||
            division == '' ||
            year == '' ||
            month == ''
          }
          onPaste={event => {
            // Blocking text
            if (!/^\d+$/.test(event?.clipboardData?.getData('text'))) {
              event?.preventDefault();
            }
          }}
          onClose={onClose}
          onKeyDown={keyDownHandle}
          inputValue={inputValueC}
          title={inputValueC}
          value={valueC}
          getOptionLabel={getOptionLabelStaffCode}
          onInputChange={onInputChangeStaffCode}
          onChange={onChangeStaffCode}
          renderInput={renderInputStaffCode}
        />
      </Box>
      <Box width={'100%'}>
        <Typography className={classes.label}>
          {strings.ModuleSpecificUtilitie.reverseExpensepage.STAFF_NAME}
        </Typography>
        <Autocomplete
          id="autocomplete-suggested-staff"
          size="small"
          className={classes.selectPlaceholder}
          options={
            autoSuggestedStaffDetail && autoSuggestedStaffDetail.length > 0
              ? autoSuggestedStaffDetail
              : []
          }
          loading={autoSuggestedStaffLoading}
          disabled={
            staffCode !== '0' ||
            !isWriteAccess ||
            division == '' ||
            year == '' ||
            month == ''
          }
          onClose={onClose}
          getOptionLabel={(option: any) => {
            return option?.fullName;
          }}
          inputValue={inputValueN}
          title={inputValueN}
          value={valueN}
          onPaste={event => {
            // Blocking text
            if (/^\d+$/.test(event?.clipboardData?.getData('text'))) {
              event?.preventDefault();
            }
          }}
          onKeyDown={event => {
            const reg = /^[a-zA-Z .]/;
            if (!reg.test(event?.key)) {
              event.preventDefault();
            }
          }}
          onInputChange={onInputChangeName}
          onChange={onChangeStaffName}
          renderInput={renderInputStaffName}
        />
      </Box>
      <Button
        data-testid="button-handleOnClickGo"
        variant="contained"
        disabled={!isWriteAccess || validate}
        size="large"
        sx={{top: 25, width: 53.3}}
        onClick={handleOnClickGo}>
        {strings.Go}
      </Button>
      {open && (
        <StatusMangerCard
          open={open}
          selectedValue={selectedValue}
          onClose={handleClose}
          employeeName={inputValueN}
          hq={inputHq}
        />
      )}
    </Stack>
  );
};
