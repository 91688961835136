import {useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {useStyles} from './styles';
import {Label} from '@app/components/elements/Label';

export const OnboardNewDivisionNavbar = () => {
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Label
        title={navbarComponentHeading}
        variant="h6"
        testID={'superman_admin_navbar_heading'}
        textColor={'#322b7c'}
        fontWeight={'800'}
        hideToolTip
      />
    </div>
  );
};
