import {createAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

interface NavState {
  title: string | null;
  modalShow: boolean | null;
  buttonVisible: boolean | null;
  staffName: string | null;
  holidayAllowedCount: string | null;
  selectedHoliday: string | null;
}

interface InitialStateProps {
  nav: NavState;
  countrylist: {
    id: number;
    countryName: string;
  }[];
  statelist: {
    id: number;
    stateName: string;
  }[];
  holidayByYearList: {
    id: number;
    name: string;
    description: string;
    date: string;
    isUniversal: boolean;
  }[];
  holidayDivisionList: {
    id: number;
    name: string;
  }[];
  loading: boolean;
  countryId: number;
  year: string;
  refresh: boolean;
  allowedHolidayByCountryYearList: {
    id: number;
    name: string;
    description: string;
    date: string;
    isUniversal: boolean;
  }[];
  HMYear: string;
}
const initialState: InitialStateProps = {
  nav: {
    title: null,
    modalShow: null,
    buttonVisible: null,
    staffName: '',
    holidayAllowedCount: null,
    selectedHoliday: null,
  },
  countrylist: [],
  statelist: [],
  holidayByYearList: [],
  holidayDivisionList: [],
  loading: false,
  countryId: 0,
  year: '',
  refresh: false,
  allowedHolidayByCountryYearList: [],
  HMYear: '',
};

export const updateLeaveHolidayNavDataCreator = createAction<any>(
  'HOLIDAY_MANAGAMENT/UPDATE_LEAVEHOLIDAY_NAV_DATA',
);
export const updateLeaveHolidayNavDataCreatorTypeName =
  updateLeaveHolidayNavDataCreator(null).type;

export const fetchCountryDataCreator = createAction<any>(
  'HOLIDAY_MANAGAMENT/FETCH_COUNTRY',
);
export const fetchCountryDataCreatorTypeName =
  fetchCountryDataCreator(null).type;

export const fetchStateDataCreator = createAction<any>(
  'HOLIDAY_MANAGAMENT/FETCH_STATE',
);
export const fetchStateDataCreatorTypeName = fetchStateDataCreator(null).type;

export const fetchHolidayByYearDataCreator = createAction<any>(
  'HOLIDAY_MANAGAMENT/FETCH_HOLIDAY_BY_YEAR',
);
export const fetchHolidayByYearDataCreatorTypeName =
  fetchHolidayByYearDataCreator(null).type;

export const fetchDivisionDataCreator = createAction<any>(
  'HOLIDAY_MANAGAMENT/FETCH_DIVISION',
);
export const fetchDivisionDataCreatorTypeName =
  fetchDivisionDataCreator(null).type;

export const fetchAllowedHolidayByCountryYearListDataCreator =
  createAction<any>(
    'HOLIDAY_MANAGAMENT/FETCH_HOLIDAY_ALLOWED_BY_YEAR_AND_COUNTRYID',
  );
export const fetchAllowedHolidayByCountryYearCreatorTypeName =
  fetchAllowedHolidayByCountryYearListDataCreator(null).type;

const leaveHolidaySlice = createSlice({
  name: 'HOLIDAY_CONFIGURATION',
  initialState: initialState,
  reducers: {
    updateNavData: (state, action: PayloadAction<Partial<NavState>>) => {
      state.nav = {...state.nav, ...action.payload};
    },
    updateModelShow: (state, action: PayloadAction<boolean>) => {
      state.nav.modalShow = action.payload;
    },
    updateButtonVisible: (state, action: PayloadAction<boolean>) => {
      state.nav.buttonVisible = action.payload;
    },
    updateStaffName: (state, action: PayloadAction<string>) => {
      state.nav.staffName = action.payload;
    },
    updateHolidayAllowedCount: (state, action: PayloadAction<string>) => {
      state.nav.holidayAllowedCount = action.payload;
    },
    updateSelectedHoliday: (state, action: PayloadAction<string>) => {
      state.nav.selectedHoliday = action.payload;
    },
    setCountryList: (state, action) => {
      return {
        ...state,
        countrylist: action.payload,
      };
    },
    setStateList: (state, action) => {
      return {
        ...state,
        statelist: action.payload,
      };
    },
    setHoildayByYearList: (state, action) => {
      return {
        ...state,
        holidayByYearList: action.payload,
      };
    },
    setHoildayDivisionList: (state, action) => {
      return {
        ...state,
        holidayDivisionList: action.payload,
      };
    },
    setLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setCountryId: (state, action) => {
      return {
        ...state,
        countryId: action.payload,
      };
    },
    setYears: (state, action) => {
      return {
        ...state,
        year: action.payload,
      };
    },
    setRefresh: (state, action) => {
      return {
        ...state,
        refresh: action.payload,
      };
    },
    setAllowedHolidayByCountryYearList: (state, action) => {
      return {
        ...state,
        allowedHolidayByCountryYearList: action.payload,
      };
    },
    setHMYears: (state, action) => {
      return {
        ...state,
        HMYear: action.payload,
      };
    },
  },
});

export const {
  updateNavData,
  updateModelShow,
  updateStaffName,
  updateButtonVisible,
  setCountryList,
  setStateList,
  setHoildayByYearList,
  setHoildayDivisionList,
  setLoading,
  setCountryId,
  setYears,
  setRefresh,
  setAllowedHolidayByCountryYearList,
  updateHolidayAllowedCount,
  updateSelectedHoliday,
  setHMYears,
} = leaveHolidaySlice.actions;

export const holidayManagmentReducer = leaveHolidaySlice.reducer;
