export const searchBarStyle = {
    toolbar: {
        color: '#1e1c3b',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0px',
        marginTop: '5px',
      },
      width60: {width: '60%'},
      textField: {
        border: 'transparent',
        background: '#f2f2f2',
        borderRadius: 0,
        width: '100%',
        padding: '0px',
        opacity: 0.6,
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        fontStyle: 'normal',
        textAlign: 'left',
        color: '#322b7c',
        fontSize: 12,
        '& .css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
          borderWidth: '0px',
        },
        '& .css-19im320-MuiAutocomplete-root .MuiOutlinedInput-root': {
          padding: '3px',
        },
      },
      padding5: {
        padding: '5px',
      },
      
}
