import {makeStyles, DefaultTheme} from '@mui/styles';

interface CheckBoxObj {
  months: string[];
  checked: boolean;
}

export const useStyles = makeStyles<DefaultTheme, CheckBoxObj>({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  downloadExcelLogo: {
    width: 25,
    marginRight: 5,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'end',
  },
  dropdown: {
    backgroundColor: '#f2f2f2',
    height: 40,
  },
  buttonGroup: {
    alignSelf: 'end',
    height: 40,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#332b77',
  },
  btn: {
    backgroundColor: '#332b77',
    // width: 60,
    borderRadius: 20,
  },
  root: {
    width: '120px',
    height: '40px',
    padding: '2px',
  },
  switchBase: {
    color: '#818181',
    padding: '1px',
    '&$checked': {
      '& + $track': {
        backgroundColor: '#fff',
      },
    },
  },
  thumb: monthSelected => {
    return {
      color: '#fff',
      backgroundColor: '#332b77',
      width: '60px',
      height: '36px',
      margin: '0px',
      borderRadius: 20,

      left: '9px',
      '&:after, &:before': {
        color: '#fff',
        fontSize: '14px',
        position: 'absolute',
        top: '8px',
      },
      '&:after': {
        content: monthSelected.checked
          ? `'${monthSelected?.months[1]}'`
          : `'${monthSelected?.months[0]}'`,
        left: '15px',
      },
    };
  },
  track: monthSelected => {
    return {
      borderRadius: '20px',
      color: '#fff',
      backgroundColor: '#fff!important',
      opacity: '1 !important',
      '&:after, &:before': {
        color: '#000',
        fontSize: '14px',
        position: 'absolute',
        top: '9px',
      },
      '&:after': {
        content: `'${monthSelected?.months[0]}'`,
        left: '20px',
      },
      '&:before': {
        content: `'${monthSelected?.months[1]}'`,
        right: '20px',
      },
    };
  },
  checked: {
    color: '#23bf58 !important',
    transform: 'translateX(58px) !important',
  },
});
