import {useCallback, useEffect, useState} from 'react';
import {Search} from '@mui/icons-material';
import {
  Autocomplete,
  debounce,
  FormControl,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {expenseTAMasterStateActions} from '@app/screens/master-data-management/pages/expense-ta-master/redux';
import {
  fetchExpenseTAMasterInAutoSuggestCreator,
  fetchExpenseTAMasterGridCreator,
} from '@app/screens/master-data-management/pages/expense-ta-master/redux/slice';
import {expenseTAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-ta-master/redux/selectors';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {searchBarStyle} from '@app/components/widgets/searchBarCss/searchBarCss';
import {
  filterSelector,
  filterStateActions,
} from '@app/components/right-sidebar/redux';
import theme from '@app/themes';
import {strings} from '@app/common/strings';

export const SearchBarExpenseTAMaster = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const isActiveState = useSelector(
    expenseTAMasterStateSelector.getIsActiveState(),
  );
  const SearchText = useSelector(expenseTAMasterStateSelector.getSearchText());
  const ExpenseTAMasterInAutoSuggestFilter = useSelector(
    expenseTAMasterStateSelector.getExpenseTAMasterInAutoSuggestFilter(),
  );
  const request = useSelector(filterSelector.getFilterOptions());
  const autosuggestData = useSelector(
    expenseTAMasterStateSelector.getExpenseTAMasterInAutoSuggest(),
  );
  const resetNavbarVersion = useSelector(
    expenseTAMasterStateSelector.getResetNavbarVersion(),
    shallowEqual,
  );
  const isAutoSuggestLoading = useSelector(
    expenseTAMasterStateSelector.getExpenseTAMasterAutoSuggestLoading(),
  );
  useEffect(() => {
    dispatch(
      filterStateActions.setFilterOptions({
        ...request,
        searchText: ExpenseTAMasterInAutoSuggestFilter?.divisionDesignation,
      }),
    );
    dispatch(fetchExpenseTAMasterGridCreator(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ExpenseTAMasterInAutoSuggestFilter]);
  useEffect(() => {
    dispatch(fetchExpenseTAMasterInAutoSuggestCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchText]);
  const onChangeVal = useCallback(function (_event: any, value: any) {
    if (!value) {
      dispatch(fetchExpenseTAMasterGridCreator(null));
    }
    dispatch(
      expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggestFilter(value),
    );
  }, []);
  const onOpen = useCallback(() => setOpen(true), []);
  const TAactiveInactive = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        expenseTAMasterStateActions.setIsActiveState(event.target.checked),
      );
    },
    [],
  );
  const onClose = useCallback(() => {
    setOpen(false);
    dispatch(expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggest([]));
  }, []);
  const debouncedSearchHandler = debounce((event: any) => {
    if (event?.target?.value?.length >= 3) {
      dispatch(expenseTAMasterStateActions.setSearchText(event.target.value));
    }
  }, 400);
  const getOptionLabelHandler = useCallback(function (option: any) {
    return option.divisionDesignation;
  }, []);
  const renderInput = useCallback(function (params3: any) {
    return (
      <TextField
        onChange={debouncedSearchHandler}
        {...params3}
        InputProps={{
          ...params3.InputProps,
          startAdornment: (
            <InputAdornment position="start" sx={searchBarStyle.padding5}>
              <Search />
            </InputAdornment>
          ),
          endAdornment: <></>,
        }}
        sx={searchBarStyle.textField}
        placeholder={'Search here...'}
      />
    );
  }, []);
  useEffect(() => {
    dispatch(
      filterStateActions.setFilterOptions({
        ...request,
        isActive: isActiveState,
      }),
    );
    dispatch(fetchExpenseTAMasterGridCreator(null));
  }, [isActiveState]);
  useEffect(() => {
    return () => {
      dispatch(
        filterStateActions.setFilterOptions({
          isActive: true,
          searchText: '',
          pageNumber: 0,
          pageLimit: 100,
          divisionIds: [],
          designationIds: [],
          hqIds: [],
        }),
      );
    };
  }, []);
  return (
    <div>
      <Toolbar sx={searchBarStyle.toolbar}>
        <FormControl sx={searchBarStyle.width60}>
          <Autocomplete
            loading={isAutoSuggestLoading}
            key={resetNavbarVersion}
            open={open}
            onChange={onChangeVal}
            onOpen={onOpen}
            onClose={onClose}
            getOptionLabel={getOptionLabelHandler}
            options={
              autosuggestData && autosuggestData?.length > 0
                ? autosuggestData
                : []
            }
            renderInput={renderInput}
          />
        </FormControl>

        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              color={theme.colors.LIGHT_NAVY_BLUE}
              fontWeight={theme.sizing.weight800}
              fontFamily={theme.fonts.poppins}
              fontSize={theme.sizing.fontSize}>
              {strings.inActive}
            </Typography>
            <AntSwitch
              onChange={TAactiveInactive}
              checked={isActiveState}
              inputProps={{'aria-label': 'ant design'}}
            />
            <Typography
              color={theme.colors.LIGHT_NAVY_BLUE}
              fontWeight={theme.sizing.weight800}
              fontFamily={theme.fonts.poppins}
              fontSize={theme.sizing.fontSize}>
              {strings.active}
            </Typography>
          </Stack>
        </FormGroup>
      </Toolbar>
    </div>
  );
};
