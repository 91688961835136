import {API_PATH, HTTP_OK} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {call, takeLatest, put} from '@redux-saga/core/effects';
import {
  inventoryModulePRActions,
  fetchStateAndDivisionTypename,
  fetchHeadquaterTypename,
  fetchPendencyReportTypeName,
  fetchDivisionsStateDataTypeName,
  fetchItemSampleAutoSuggestTypeName,
  fetchSubordinateTypeName,
  fetchUserListDataTypeName,
  fetchItemAndSampleTypeName,
  fetchPendencyExpectedUtilizationDateTypeName,
} from './slice';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
export function* fetchStateAndDivisionWatcher() {
  yield takeLatest(fetchStateAndDivisionTypename, fetchStateAndDivisionWorker);
}

export function* fetchPendencyReportWatcher() {
  yield takeLatest(fetchPendencyReportTypeName, fetchPendencyReportWorker);
}
export function* fetchUserListDataWatcher() {
  yield takeLatest(fetchUserListDataTypeName, fetchUserListDataWorker);
}

export function* fetchDivisioinStateDataWatcher() {
  yield takeLatest(
    fetchDivisionsStateDataTypeName,
    fetchDivisionStateDataWorker,
  );
}
export function* fetchItemSampleAutoSuggestWatcher() {
  yield takeLatest(
    fetchItemSampleAutoSuggestTypeName,
    fetchItemSampleAutoSuggestWorker,
  );
}

export function* fetchPendencySubordinatesWatcher() {
  yield takeLatest(
    fetchSubordinateTypeName,
    fetchPendencyReportSubordinateWorker,
  );
}
export function* fetchItemAndSampleWatcher() {
  yield takeLatest(fetchItemAndSampleTypeName, fetchItemAndSampleWorker);
}
export function* fetchExpectedUtilizationDateWatcher() {
  yield takeLatest(
    fetchPendencyExpectedUtilizationDateTypeName,
    fetchExpectedUtilizationDateWorker,
  );
}
export function* fetchUserListDataWorker(action: any): any {
  try {
    yield put(inventoryModulePRActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.userListData}?_divisionId=${action.payload._divisionId}&SearchType=${action.payload.SearchType}&SearchValue=${action.payload.SearchValue}&IsActive=${action.payload.IsActive}`;
      const response = yield call(NetworkService.post, url);

      if (response?.status === 200) {
        yield put(inventoryModulePRActions.setUserListData(response?.data));
        yield put(inventoryModulePRActions.setLoading(false));
      } else {
        yield put(inventoryModulePRActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(inventoryModulePRActions.setLoading(false));
  }
}

export function* fetchStateAndDivisionWorker(): any {
  yield put(inventoryModulePRActions.setStatesDivisionsLoader());
  try {
    const url = API_PATH.inventoryModule.cnfStates;
    const result = yield call(NetworkService.get, url, null, null);
    if (result.status === HTTP_OK) {
      yield put(inventoryModulePRActions.setStatesDivisions(result?.data));
    } else {
      ToasterService.showToaster(
        'Something went wrong while fetching data',
        ToastType.ERROR,
      );
    }
  } catch (e) {
    ToasterService.showToaster(
      'Something went wrong while fetching data',
      ToastType.ERROR,
    );
  }
}

export function* fetchHeadquaterWatcher() {
  yield takeLatest(fetchHeadquaterTypename, fetchHeadquaterWorker);
}

export function* fetchHeadquaterWorker(action: any): any {
  yield put(inventoryModulePRActions.setHeadQuartersLoader());
  try {
    const url = API_PATH.inventoryModule.cnfHeadquarters;
    const result = yield call(NetworkService.post, url, action.payload, null);
    if (result.status === HTTP_OK) {
      yield put(inventoryModulePRActions.setHeadQuarters(result?.data));
    } else {
      ToasterService.showToaster(
        'Something went wrong while fetching data',
        ToastType.ERROR,
      );
    }
  } catch (e) {
    ToasterService.showToaster(
      'Something went wrong while fetching data',
      ToastType.ERROR,
    );
  }
}

export function* fetchItemAndSampleWorker(action: any): any {
  try {
    yield put(inventoryModulePRActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.itemAndSample}?_DivisionIds=${action.payload.DivisionIds}&SearchType=${action.payload.SearchType}`;
      const response = yield call(NetworkService.post, url, null);

      if (response?.status === 200) {
        yield put(inventoryModulePRActions.setItemAndSample(response?.data));
        yield put(inventoryModulePRActions.setLoading(false));
      } else {
        yield put(inventoryModulePRActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(inventoryModulePRActions.setLoading(false));
  }
}

export function* fetchPendencyReportWorker(action: any): any {
  try {
    if (action.payload?.apiFor !== 'DownLoad') {
      yield put(inventoryModulePRActions.setLoading(true));
    }

    if (action.payload) {
      const url = `${API_PATH.inventoryModule.pendencyReport}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );

      if (response?.status === 200) {
        if (action.payload?.apiFor === 'DownLoad') {
          yield put(
            inventoryModulePRActions.setPendencyReportExcelData(response?.data),
          );
        } else {
          yield put(
            inventoryModulePRActions.setPendencyReportData(response?.data),
          );
        }
        yield put(inventoryModulePRActions.setLoading(false));
      } else {
        yield put(inventoryModulePRActions.setLoading(false));
        ToasterService.showToaster(
          'Please select the desired state from the filter',
          ToastType.ERROR,
        );
      }
    }
  } catch (e) {
    yield put(inventoryModulePRActions.setLoading(false));
    ToasterService.showToaster(
      'Please select the desired state from the filter',
      ToastType.ERROR,
    );
  }
}
export function* fetchDivisionStateDataWorker(action: any): any {
  try {
    yield put(inventoryModulePRActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.divisionState}?_divisionId=${action.payload.divisionId}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );
      if (response?.status === 200) {
        yield put(
          inventoryModulePRActions.setDivisionStateData(response?.data),
        );
        yield put(inventoryModulePRActions.setLoading(false));
      } else {
        yield put(inventoryModulePRActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(inventoryModulePRActions.setLoading(false));
  }
}

export function* fetchItemSampleAutoSuggestWorker(action: any): any {
  try {
    yield put(inventoryModulePRActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.itemSampleAutoSuggest}?_divisionIds=${action.payload._DivisionIds}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );

      if (response?.status === 200) {
        yield put(
          inventoryModulePRActions.setItemSampleAutoSuggest(response?.data),
        );
        yield put(inventoryModulePRActions.setLoading(false));
      } else {
        yield put(inventoryModulePRActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(inventoryModulePRActions.setLoading(false));
  }
}

export function* fetchPendencyReportSubordinateWorker(action: any): any {
  try {
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.subordinateDetails}?UserId=${action.payload.userIds}`;
      const response = yield call(NetworkService.get, url, null, null);

      if (response?.status === 200) {
        yield put(inventoryModulePRActions.setSubordinates(response?.data));
        yield put(inventoryModulePRActions.setLoading(false));
      } else {
        yield put(inventoryModulePRActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(inventoryModulePRActions.setLoading(false));
  }
}

export function* fetchExpectedUtilizationDateWorker(action: any): any {
  try {
    yield put(inventoryModulePRActions.setLoading(true));
    if (action.payload) {
      const {payload = {}} = action;
      const url = `${API_PATH.inventoryModule.pendencyExpectedDate}`;
      const response = yield call(NetworkService.post, url, payload);

      if (response?.status === 200) {
        yield put(
          inventoryModulePRActions.setPendencyExpectedUtilizationDate(
            response?.data,
          ),
        );
        yield put(inventoryModulePRActions.setLoading(false));
      } else {
        yield put(inventoryModulePRActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(inventoryModulePRActions.setLoading(false));
  }
}
