import {createSelector} from '@reduxjs/toolkit';

export const getInventoryManagementConfiguratorStateSelector = (
  stateName: string,
) => {
  return createSelector(
    [(state: any) => state.inventoryManagementConfigurator[stateName]],
    state => state,
  );
};

export const InventoryManagementConfiguratorSelector = {
  getInventoryConfiguratorManagementPage: () => {
    return createSelector(
      [(state: any) => state.inventoryManagementConfigurator.currentPageDefine],
      currentPageDefine => currentPageDefine,
    );
  },
  getInventoryConfiguratorManagementActivePage: () => {
    return createSelector(
      [(state: any) => state.inventoryManagementConfigurator.currentActivePage],
      currentActivePage => currentActivePage,
    );
  },
  getInventoryConfiguratorDivisionLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorDivisionLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorDivisionSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorDivisionSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorDivisionError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryconfiguratorDivisionError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorCustomerTypeLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorCustomerTypeLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorCustomerTypeSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorCustomerTypeSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorCustomerTypeError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorCustomerTypeError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorRegionHeirarchyLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorRegionHeirarchyLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorRegionHeirarchySuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorRegionHeirarchySuccess,
      ],
      state => state,
    );
  },
  getInventoryConfigurationRegionHeirarcyError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryConfigurator.inventoryConfiguratorRegionHeircyError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorDoctorSpecialityLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorDoctorSpecialityLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorDoctorSpecialitySuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorDoctorSpecialitySuccess,
      ],
      state => state,
    );
  },
  getInventoryConfigurationDoctorSpecialityError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryConfigurator
            .inventoryConfiguratorDoctorSpecialityError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorDoctorQualificationLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorDoctorQualificationLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorDoctorQualificationSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorDoctorQualificationSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfigurationDoctorQualificationError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryConfigurator
            .inventoryConfiguratorDoctorQualificationError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorSpecCategoryLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorSpecCategoryLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorSpecCategorySuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorSpecCategorySuccess,
      ],
      state => state,
    );
  },
  getInventoryConfigurationSpecCategoryError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryConfigurator.inventoryConfiguratorSpecCategoryError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorRolesLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorRolesLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorRolesSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorRolesSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorRolesError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator.inventoryConfiguratorRolesError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorClassCodeLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorClassCodeLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorClassCodeSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorClassCodeSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorClassCodeError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorClassCodeError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorItemCategoryLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorItemCategoryLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorItemCategorySuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorItemCategorySuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorItemCategoryError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorItemCategoryError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorItemCodeLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorItemCodeLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorItemCodeSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorItemCodeSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorSetValueSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorSetValueSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorItemCodeError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorItemCodeError,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorQualificationLoading: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorQualificationLoading,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorQualificationSuccess: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorQualificationSuccess,
      ],
      state => state,
    );
  },
  getInventoryConfiguratorQualificationError: () => {
    return createSelector(
      [
        (state: any) =>
          state.inventoryManagementConfigurator
            .inventoryConfiguratorQualificationError,
      ],
      state => state,
    );
  },
};
