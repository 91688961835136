import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  approveExpenseStateActions,
  fetchDivisionDataCreator,
  fetchExpenseApprovalsDataCreator,
  fetchManagerDataCreator,
  fetchStaffDataCreator,
  fetchStateDataCreator,
  fetchStatusDataCreator,
} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';
import {approveExpenseStateSelector} from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import {generateLastTenYears} from './utils';
import {
  SEARCH_OBJECT,
  STATUS_OBJECT,
} from '../../module-specific-utilities.types';
import {appSelector} from '@app/store/selectors';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {MenuProp} from '@app/screens/Styles/style';
import {Checkbox} from '@mui/material';
import styles from './ApproveExpenseHeader.module.css';
import {getPreviousYearMonths} from '../expense-reports/TA-summary/TASummaryReportHeader';
import {withPreventDoubleClick} from '@app/components/hoc';
import {styles as customStyle} from './styles/customStyle';
import {strings} from '@app/common/strings';
import {statusManagerStateSelector} from '../../pages/status-manager/redux/selectors';

export const util: any = {
  handleChange: null,
  debouncedManagerSearch: null,
  editManagerHandler: null,
  debouncedSearchStaff: null,
  editStaff: null,
  handleOnClickGo: null,
  isAutocompleteDisabled: null,
  close: null,
  handleChangeDivisionId: null,
  handleChangeMonthYear: null,
  handleChangeStatus: null,
  handleChangeState: null,
};

export const CustomMenuItem: React.FC<{
  value: any;
  checked: boolean;
  label: string | number;
}> = ({value, checked, label}) => {
  return (
    <div className={styles.checkbox}>
      <input type="checkbox" value={value} checked={checked} />
      <span className={styles.checkmark}></span>
      {label}
    </div>
  );
};

export const ApproveExpenseHeader = () => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(true);
  const stateDataActual = useSelector(
    approveExpenseStateSelector?.getStateData(),
  );
  const stateData = stateDataActual?.filter((state: any) => state?.value !== 0);
  const divisionData = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const {isWriteAccess = false} = useSelector(appSelector?.getUserInfo());

  const monthYearData = [
    {monthDesc: strings.SelectAll, year: 0, month: 0},
  ].concat(getPreviousYearMonths());
  const statusData = useSelector(approveExpenseStateSelector?.getStatusData());
  const filteredData = useSelector(
    approveExpenseStateSelector?.getFilterData(),
  );
  const status = useSelector(statusManagerStateSelector.getToggleStatus());
  const DebouncedBtn = withPreventDoubleClick(Button, 400);

  useEffect(() => {
    dispatch(fetchStateDataCreator());
    dispatch(fetchDivisionDataCreator());
    dispatch(fetchStatusDataCreator());

    dispatch(approveExpenseStateActions?.setMonthsValues(monthMock()));
    const lastTenYears: number[] = generateLastTenYears();
    dispatch(approveExpenseStateActions?.setYearsValues(lastTenYears));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filteredData?.statusId) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          ['statusId']: ['Unapproved'],
        }),
      );
    }
  }, [statusData]);

  const handleChangeStatus = (value: any[], type: string) => {
    dispatch(approveExpenseStateActions.setExpenseApprovalsData([]));
    if (
      filteredData?.statusId?.length === statusData?.length &&
      value?.includes(strings.moduleSpecificUtilities.all)
    ) {
      value?.splice(value?.indexOf(strings.moduleSpecificUtilities.all), 1);
    }
    if (value?.includes(strings.moduleSpecificUtilities.all)) {
      value = statusData?.map((ele: any) => ele?.displayName);
    }

    if (
      !value?.includes(strings.moduleSpecificUtilities.all) &&
      value?.length === filteredData?.statusId?.length - 1 &&
      filteredData?.statusId?.length === statusData?.length
    ) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: [],
        }),
      );
    } else if (
      value?.includes(strings.moduleSpecificUtilities.all) ||
      (value?.length === 3 &&
        !value?.includes(strings.moduleSpecificUtilities.all))
    ) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: statusData?.map((ele: any) => ele?.displayName),
        }),
      );
    } else {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: value,
        }),
      );
    }
    if (value?.length <= 0) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: value,
        }),
      );
    }
  };

  const handleChange = (value: number[], type: string) => {
    dispatch(
      approveExpenseStateActions?.setApproveExpesnseFilterValues({
        [type]: value,
      }),
    );
    dispatch(approveExpenseStateActions.setExpenseApprovalsData([]));
  };

  const handleChangeState = (value: number[], type: string) => {
    dispatch(approveExpenseStateActions?.setStaffData([]));
    value?.forEach((element: any) => {
      if (
        element === 0 &&
        filteredData?.stateId?.length === stateData?.length
      ) {
        dispatch(
          approveExpenseStateActions?.setApproveExpesnseFilterValues({
            [type]: [],
          }),
        );
      } else if (element === 0) {
        dispatch(
          approveExpenseStateActions?.setApproveExpesnseFilterValues({
            [type]: stateData?.map((obj: any) => Object.values(obj)[1]),
          }),
        );
      } else {
        dispatch(
          approveExpenseStateActions?.setApproveExpesnseFilterValues({
            [type]: value,
          }),
        );
      }
    });
    if (value?.length <= 0) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: value,
        }),
      );
    }
  };

  const handleChangeDivisionId = function (value: any[], type: string) {
    dispatch(approveExpenseStateActions.setExpenseApprovalsData([]));
    dispatch(approveExpenseStateActions?.setStaffData([]));
    dispatch(approveExpenseStateActions?.setSelectedStaffData(null));
    dispatch(approveExpenseStateActions?.setSelectedManagerData(null));
    value?.forEach((element: any) => {
      if (
        element === 0 &&
        filteredData?.divisionId?.length === divisionData?.length
      ) {
        dispatch(
          approveExpenseStateActions?.setApproveExpesnseFilterValues({
            [type]: [],
          }),
        );
      } else if (element === 0) {
        dispatch(
          approveExpenseStateActions?.setApproveExpesnseFilterValues({
            [type]: divisionData?.map((obj: any) => Object.values(obj)[1]),
          }),
        );
      } else {
        dispatch(
          approveExpenseStateActions?.setApproveExpesnseFilterValues({
            [type]: value,
          }),
        );
      }
    });
    if (value?.length <= 0) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: value,
        }),
      );
    }
  };

  const handleChangeMonthYear = function (value: any[], type: string) {
    dispatch(approveExpenseStateActions.setExpenseApprovalsData([]));
    if (
      filteredData?.monthYearData?.length === monthYearData?.length &&
      value?.includes(0)
    ) {
      value?.splice(value?.indexOf(0), 1);
    }
    if (value?.includes(0)) {
      value = monthYearData?.map((obj: any) => Object.values(obj)[2]);
    }

    if (
      !value?.includes(0) &&
      value?.length === filteredData?.monthYearData?.length - 1 &&
      filteredData?.monthYearData?.length === monthYearData?.length
    ) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: [],
        }),
      );
    } else if (
      value?.includes(0) ||
      (value?.length === 12 && !value?.includes(0))
    ) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: monthYearData?.map((obj: any) => Object.values(obj)[2]),
        }),
      );
    } else {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: value,
        }),
      );
    }

    if (value?.length <= 0) {
      dispatch(
        approveExpenseStateActions?.setApproveExpesnseFilterValues({
          [type]: value,
        }),
      );
    }
  };

  const selectedManagerData: SEARCH_OBJECT = useSelector(
    approveExpenseStateSelector?.getSelectedManagerData(),
  );

  const managerData: SEARCH_OBJECT[] = useSelector(
    approveExpenseStateSelector?.getManagerData(),
  );

  const managerLoading: boolean = useSelector(
    approveExpenseStateSelector?.getManagerLoading(),
  );

  const debouncedManagerSearch = (criteria: string) => {
    if (filteredData?.divisionId && criteria?.length > 0) {
      dispatch(
        fetchManagerDataCreator({
          searchData: criteria,
          divisionIds: filteredData?.divisionId,
        }),
      );
    }
  };

  const editManagerHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === undefined || e === null) return;
    if (e?.target?.value?.length >= 3) debouncedManagerSearch(e?.target?.value);
  };

  const selectedStaffData: any = useSelector(
    approveExpenseStateSelector?.getSelectedStaffData(),
  );
  const staffData: SEARCH_OBJECT[] = useSelector(
    approveExpenseStateSelector?.getStaffData(),
  );

  const staffLoading: boolean = useSelector(
    approveExpenseStateSelector?.getStaffLoading(),
  );

  const debouncedSearchStaff = (criteria: string | any[]) => {
    if (filteredData?.divisionId && criteria?.length > 0) {
      dispatch(
        fetchStaffDataCreator({
          searchData: criteria,
          divisionIds: filteredData?.divisionId,
          InActive: !status,
        }),
      );
    }
  };

  const editStaff = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e === undefined || e === null) return;
    if (e?.target?.value?.length >= 3) debouncedSearchStaff(e?.target?.value);
  };
  const validation = () => {
    if (
      filteredData?.divisionId?.length > 0 &&
      filteredData?.monthYearData?.length > 0 &&
      filteredData?.statusId?.length > 0
    ) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  };

  const handleOnClickGo = () => {
    dispatch(approveExpenseStateActions?.setExpenseApprovalsData([]));
    dispatch(approveExpenseStateActions?.setSelectedExpenseApprovalsData([]));
    dispatch(
      approveExpenseStateActions?.setIsExpenseAprrovalsAllSelected(false),
    );
    dispatch(approveExpenseStateActions?.setSelectedExpenseApprovalsUser({}));
    dispatch(approveExpenseStateActions.setConfirmExpenseApprovalsReset());
    dispatch(approveExpenseStateActions.setRejectExpenseApprovalsReset());
    dispatch(approveExpenseStateActions.setRejectExpenseModalCheckbox(false));
    dispatch(approveExpenseStateActions.setApproveExpenseModalCheckbox(false));
    dispatch(approveExpenseStateActions.setDailyEditedRowColorIndex([]));
    dispatch(approveExpenseStateActions.setDailyEditedRowColorIndexArr1([]));
    dispatch(approveExpenseStateActions.setMiscEditedRowColorIndex([]));
    statusData
      ?.filter((statusData: any) =>
        filteredData?.statusId?.includes(statusData?.id),
      )
      ?.map((status: any) => status?.displayName);
    const statusVal = () => {
      return statusData
        ?.filter((statusData: any) =>
          filteredData?.statusId?.includes(statusData?.displayName),
        )
        ?.map((status: any) => status?.id);
    };
    const dropdownValues = {
      divisionIds: filteredData?.divisionId,
      statusIds: statusVal(),
      months: monthYearData
        ?.map((ele: any) => {
          if (filteredData?.monthYearData?.includes(ele?.month))
            return {
              month: ele?.month,
              year: ele?.year,
            };
        })
        ?.filter((element: any) => Boolean(element) && element?.month !== 0),
      stateIds: filteredData?.stateId ? filteredData?.stateId : [],
      userIds:
        !selectedManagerData && selectedStaffData?.userId
          ? [selectedStaffData?.userId]
          : selectedStaffData ?? [],
      managerStaffPositionId: selectedManagerData
        ? selectedManagerData?.staffPositionId
        : 0,
      InActive: !status,
    };

    dispatch(fetchExpenseApprovalsDataCreator(dropdownValues));
  };
  const isAutocompleteDisabled = (type: string): boolean => {
    if (!filteredData?.divisionId) {
      return true;
    }
    if (type == strings.ModuleSpecificUtilitie.expenseReportPage.Staff) {
      if (selectedManagerData !== null) {
        return true;
      }
    } else if (selectedStaffData?.length > 0 && !selectedManagerData) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    validation();
  }, [
    filteredData?.divisionId,
    filteredData?.monthYearData,
    filteredData?.statusId,
  ]);

  const close = () => {
    dispatch(approveExpenseStateActions?.setStaffData([]));
    dispatch(approveExpenseStateActions?.setManagerData([]));
    dispatch(approveExpenseStateActions.setExpenseApprovalsData([]));
  };
  const Name = (id: any, arr: any, disName: any, idName: any) => {
    return arr
      ?.map((val: any) => {
        if (val?.['' + idName + ''] == id) {
          return val?.['' + disName + ''];
        }
      })
      ?.filter((element: any) => Boolean(element));
  };

  util.handleChange = handleChange;
  util.debouncedManagerSearch = debouncedManagerSearch;
  util.editManagerHandler = editManagerHandler;
  util.debouncedSearchStaff = debouncedSearchStaff;
  util.editStaff = editStaff;
  util.handleOnClickGo = handleOnClickGo;
  util.isAutocompleteDisabled = isAutocompleteDisabled;
  util.close = close;
  util.handleChangeDivisionId = handleChangeDivisionId;
  util.handleChangeMonthYear = handleChangeMonthYear;
  util.handleChangeStatus = handleChangeStatus;
  util.handleChangeState = handleChangeState;

  return (
    <Grid container spacing={0.2} sx={customStyle.gridStyle}>
      <Grid item xs={1.6}>
        <Box>
          <FormControl required fullWidth size="small">
            <InputLabel id="division-dropdown" style={customStyle.font13}>
              {strings.division}
            </InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-division',
              }}
              labelId="division-dropdown"
              id="division-dropdown"
              size="small"
              disabled={!isWriteAccess}
              value={filteredData?.divisionId || []}
              title={
                filteredData?.divisionId?.includes(0) ||
                filteredData?.divisionId?.length === divisionData?.length
                  ? strings.ALL
                  : divisionData
                      ?.filter((divisionValue: any) =>
                        filteredData?.divisionId?.includes(
                          divisionValue?.value,
                        ),
                      )
                      ?.map((division: any) => division?.label)
                      ?.join(',') || ''
              }
              label="Division*"
              MenuProps={MenuProp}
              onChange={(event: any) =>
                handleChangeDivisionId(event?.target?.value, 'divisionId')
              }
              multiple
              renderValue={(selected: any) => {
                return filteredData?.divisionId?.includes(0) ||
                  filteredData?.divisionId?.length === divisionData?.length ? (
                  <div>{strings.ALL}</div>
                ) : (
                  <div style={customStyle.dropdown}>
                    <span>
                      {divisionData?.length > 0 &&
                        filteredData?.divisionId
                          ?.map((division: any, i: any) => {
                            return (
                              selected?.includes(division) &&
                              `${Name(division, divisionData, 'label', 'value')}
                               `
                            );
                          })
                          ?.filter((element: any) => Boolean(element))
                          ?.join(',')}
                    </span>
                  </div>
                );
              }}>
              <MenuItem value={0} key={0}>
                <CustomMenuItem
                  checked={
                    filteredData?.divisionId?.length === divisionData?.length
                  }
                  label={strings.SelectAll}
                  value={0}
                />
              </MenuItem>
              {(divisionData || [])?.map((divisionValue: any) => {
                return (
                  <MenuItem
                    value={divisionValue?.value}
                    key={divisionValue?.value}>
                    <CustomMenuItem
                      checked={filteredData?.divisionId?.includes(
                        divisionValue?.value,
                      )}
                      label={divisionValue?.label}
                      value={divisionValue?.value}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={1.6}>
        <Box>
          <FormControl required size="small" fullWidth>
            <InputLabel id="month-dropdown" style={customStyle.font13}>
              {strings.MonthYear}
            </InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-month',
              }}
              labelId="month-dropdown"
              id="month-dropdown"
              size="small"
              disabled={!isWriteAccess}
              value={filteredData?.monthYearData || []}
              title={
                filteredData?.monthYearData?.includes(0) ||
                filteredData?.monthYearData?.length === monthYearData?.length
                  ? strings.ALL
                  : monthYearData
                      ?.filter((monthValue: any) =>
                        filteredData?.monthYearData?.includes(
                          monthValue?.month,
                        ),
                      )
                      ?.map((monthvalue: any) =>
                        monthvalue !== 0
                          ? `${monthvalue?.monthDesc}-${monthvalue?.year}`
                          : `${monthvalue?.monthDesc}`,
                      )
                      ?.join(',') || ''
              }
              label={strings.label.MonthYear}
              onChange={(event: any) => {
                handleChangeMonthYear(event?.target?.value, 'monthYearData');
              }}
              renderValue={selected => {
                return filteredData?.monthYearData?.includes(0) ? (
                  <div>{strings.ALL}</div>
                ) : (
                  <div style={customStyle.dropdown}>
                    <span>
                      {monthYearData?.length > 0 &&
                        monthYearData
                          ?.map((monthValue: any) => {
                            return (
                              selected?.includes(monthValue?.month) &&
                              `${monthValue?.monthDesc}-${monthValue?.year}
                        `
                            );
                          })
                          ?.filter((element: any) => Boolean(element))
                          ?.join(',')}
                    </span>
                  </div>
                );
              }}
              multiple>
              {(monthYearData || [])?.map((monthValue: any) => (
                <MenuItem key={monthValue?.month} value={monthValue?.month}>
                  <CustomMenuItem
                    checked={filteredData?.monthYearData?.includes(
                      monthValue?.month,
                    )}
                    label={
                      monthValue?.month != 0
                        ? `${monthValue?.monthDesc}-${monthValue?.year}`
                        : `${monthValue?.monthDesc}`
                    }
                    value={monthValue?.month}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>

      <Grid item xs={1.5}>
        <Box>
          <FormControl required size="small" fullWidth>
            <InputLabel id="approve-expense-status" style={customStyle.font13}>
              {strings.status}
            </InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-status',
              }}
              labelId="approve-expense-status"
              id="approve-expense-select-status"
              size="small"
              disabled={!isWriteAccess}
              value={filteredData?.statusId || []}
              title={
                filteredData?.statusId?.includes(
                  strings.moduleSpecificUtilities.all,
                )
                  ? strings.moduleSpecificUtilities.all
                  : filteredData?.statusId
                      ?.filter((element: any) => Boolean(element))
                      ?.join(',') || ''
              }
              label="Status*"
              onChange={(event: any) =>
                handleChangeStatus(event?.target?.value, 'statusId')
              }
              renderValue={selected => (
                <div style={customStyle.dropdown}>
                  <span>
                    {selected?.includes(strings.moduleSpecificUtilities.all) ||
                    filteredData?.statusId?.length === 3
                      ? strings.moduleSpecificUtilities.all
                      : filteredData?.statusId
                          ?.filter((element: any) => Boolean(element))
                          ?.join(',')}
                  </span>
                </div>
              )}
              multiple>
              {(statusData || [])?.map((option: STATUS_OBJECT) => (
                <MenuItem key={option?.displayName} value={option?.displayName}>
                  <Checkbox
                    checked={
                      filteredData?.statusId?.includes(option?.displayName) ||
                      filteredData?.statusId?.includes('All')
                    }
                  />
                  {option?.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={1.6}>
        <Box>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-simple-State" style={customStyle.font13}>
              {strings.State}
            </InputLabel>
            <Select
              inputProps={{
                'data-testid': 'dropdown-State',
              }}
              labelId="demo-simple-State"
              id="demo-simple-State"
              disabled={!isWriteAccess}
              label={strings.State}
              size="small"
              MenuProps={MenuProp}
              value={filteredData?.stateId || []}
              title={
                filteredData?.stateId?.length === stateData?.length
                  ? strings.ALL
                  : stateData
                      ?.filter((stateValue: any) =>
                        filteredData?.stateId?.includes(stateValue?.value),
                      )
                      ?.map((state: any) => state?.label)
                      ?.join(',') || ''
              }
              onChange={(event: any) =>
                handleChangeState(event?.target?.value, 'stateId')
              }
              multiple
              renderValue={(selected: any) => {
                return filteredData?.stateId?.length === stateData?.length ? (
                  <div>{strings.ALL}</div>
                ) : (
                  <div style={customStyle.dropdown}>
                    <span>
                      {stateData.length > 0 &&
                        filteredData?.stateId
                          ?.map((state: any, i: any) => {
                            return (
                              selected?.includes(state) &&
                              `${Name(state, stateData, 'label', 'value')}
                             `
                            );
                          })
                          ?.filter((element: any) => Boolean(element))
                          ?.join(',')}
                    </span>
                  </div>
                );
              }}>
              <MenuItem key={0} value={0}>
                <CustomMenuItem
                  checked={filteredData?.stateId?.length === stateData?.length}
                  label={strings.ALL}
                  value={0}
                />
              </MenuItem>
              {stateData?.map((states: any) => (
                <MenuItem key={states?.value} value={states?.value}>
                  <CustomMenuItem
                    checked={
                      filteredData?.stateId?.some(
                        (state: any) => state === states?.value,
                      ) || filteredData?.stateId?.length === stateData?.length
                    }
                    label={states?.label}
                    value={states?.value}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box>
          {selectedStaffData?.managerFullName && (
            <TextField
              size="small"
              title={selectedStaffData?.managerFullName}
              value={selectedStaffData?.managerFullName}
              disabled={true}
            />
          )}
          {!selectedStaffData?.managerFullName && (
            <FormControl
              size="small"
              fullWidth
              disabled={isAutocompleteDisabled('manager')}>
              <Autocomplete
                id="autocomplete-suggested-managers"
                size="small"
                options={managerData}
                loading={managerLoading}
                disabled={isAutocompleteDisabled('manager')}
                value={selectedManagerData}
                onClose={close}
                onInputChange={(event: any) => editManagerHandler(event)}
                onChange={(_event: any, value: any) => {
                  dispatch(
                    approveExpenseStateActions?.setSelectedStaffData(null),
                  );
                  dispatch(approveExpenseStateActions?.setStaffData([]));
                  dispatch(
                    fetchStaffDataCreator({
                      searchData: '',
                      divisionIds: filteredData?.divisionId,
                      reportingStaffPositionId: value?.staffPositionId,
                      InActive: !status,
                    }),
                  );
                  dispatch(
                    approveExpenseStateActions?.setSelectedManagerData(value),
                  );
                  if (!value) {
                    dispatch(
                      approveExpenseStateActions.setExpenseApprovalsData([]),
                    );
                  }
                }}
                onKeyDown={event => {
                  const reg = /^[a-zA-Z0-9 ]*(?:\.+\d{1,2})*$/;
                  if (!reg?.test(event?.key)) {
                    event?.preventDefault();
                  }
                }}
                getOptionLabel={(option: SEARCH_OBJECT) =>
                  `${option?.fullName}-${option?.employeeCode}`
                }
                renderInput={(params: any) => {
                  return (
                    <TextField
                      {...params}
                      label="Search Manager"
                      variant="outlined"
                      InputLabelProps={{style: customStyle.font13}}
                    />
                  );
                }}
              />
            </FormControl>
          )}
        </Box>
      </Grid>
      {!selectedManagerData && (
        <Grid item xs={3}>
          <Box>
            <FormControl
              size="small"
              fullWidth
              disabled={isAutocompleteDisabled('staff')}>
              <Autocomplete
                id="autocomplete-suggested-staff"
                size="small"
                options={staffData || []}
                loading={staffLoading}
                disabled={isAutocompleteDisabled('staff')}
                value={selectedStaffData}
                title={selectedStaffData ? selectedStaffData?.fullName : ''}
                onClose={close}
                onInputChange={(event: any) => editStaff(event)}
                onChange={(_event: any, value: any) => {
                  dispatch(
                    approveExpenseStateActions?.setSelectedStaffData(value),
                  );
                  if (!value) {
                    dispatch(
                      approveExpenseStateActions.setExpenseApprovalsData([]),
                    );
                  }
                }}
                onKeyDown={event => {
                  const reg = /^[a-zA-Z0-9 ]*(?:\.+\d)*$/;
                  if (!reg?.test(event?.key)) {
                    event?.preventDefault();
                  }
                }}
                getOptionLabel={(option: SEARCH_OBJECT) =>
                  option?.fullName
                    ? `${option?.fullName}-${option?.employeeCode}`
                    : ''
                }
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={strings.SearchStaff}
                    variant="outlined"
                    InputLabelProps={{style: customStyle.font13}}
                  />
                )}
              />{' '}
            </FormControl>
          </Box>
        </Grid>
      )}
      {selectedManagerData && (
        <Grid item xs={3}>
          <Box>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-simple-State">Staff</InputLabel>
              <Select
                inputProps={{
                  'data-testid': 'dropdown-State',
                }}
                labelId="demo-simple-State"
                id="demo-simple-State"
                disabled={!isWriteAccess}
                label="State"
                size="small"
                multiple
                MenuProps={MenuProp}
                value={selectedStaffData || []}
                title={selectedStaffData
                  ?.map(
                    (staffId: any) =>
                      staffData?.find((staff: any) => staff?.userId == staffId)
                        ?.fullName,
                  )
                  ?.join(', ')}
                onChange={(event: any, value: any) => {
                  dispatch(
                    approveExpenseStateActions?.setSelectedStaffData(
                      event?.target?.value,
                    ),
                  );
                  dispatch(
                    approveExpenseStateActions.setExpenseApprovalsData([]),
                  );
                }}
                renderValue={(selected: any) => {
                  return staffData?.map(
                    (value: any) =>
                      selected?.some((id: any) => id == value?.userId) &&
                      `${value?.fullName}${
                        selected?.indexOf(value?.userId) ===
                        selected?.length - 1
                          ? ''
                          : ', '
                      }`,
                  );
                }}>
                {staffData?.map((staff: any) => (
                  <MenuItem key={staff?.userId} value={staff?.userId}>
                    <CustomMenuItem
                      checked={selectedStaffData?.some(
                        (o: any) => o == staff?.userId,
                      )}
                      label={`${staff?.fullName}-${staff?.employeeCode}`}
                      value={staff?.userId}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      )}
      <Grid item xs={0.5}>
        <DebouncedBtn
          variant="contained"
          size="large"
          onClick={handleOnClickGo}
          disabled={!isWriteAccess || validated}>
          {strings.Go}
        </DebouncedBtn>
      </Grid>
    </Grid>
  );
};
