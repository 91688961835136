import {API_PATH, HTTP_OK} from '@app/common/constants';
import {NetworkService, ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {expenseDAMasterStateSelector} from './selectors';
import {filterSelector} from '@app/components/right-sidebar/redux/filterHandlerSelector';
import {filterStateActions} from '@app/components/right-sidebar/redux/filterHandler';
import {
  activeInactiveExpenseDAMasterCreatorCreatorTypeName,
  AddExpenseValidationDAMasterCreatorTypeName,
  editExpenseDAMasterCreatorTypeName,
  expenseDAMasterStateActions,
  fetchAddExpenseDAMasterCreatorTypeName,
  fetchDesignationsDropdownCreatorTypeName,
  fetchExpenseDAMasterDesignationByDivisionCreatorTypeName,
  fetchExpenseDAMasterGridCreatorTypeName,
  fetchExpenseDAMasterInAutoSuggestCreatorTypeName,
  fetchExportFileCreatorTypeName,
  fetchHqByEdividionDesginationDAMasterCreatorTypeName,
  fetchHQDropdownCreatorTypeName,
  fetchHQTypeDropdownCreatorCreatorTypeName,
} from './slice';
import {strings} from '@app/common/strings';

/**
 * Employee Details Watcher
 */
export function* fetchExpenseDAMasterGridDataWatcher() {
  yield takeLatest(
    fetchExpenseDAMasterGridCreatorTypeName,
    fetchExpenseDAMasterGridDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseDAMasterGridDataWatcherWorker(action: any): any {
  try {
    const filter = yield select(filterSelector.getFilterOptions());

    let request = {
      ...filter,
      pageNumber: filter?.pageNumber + 1,
    };
    yield put(expenseDAMasterStateActions.setExpenseDAMasterLoading(true));

    const url = `${API_PATH.expenseDATAMaster.expenseDAMasterGird}`;

    const gridData = yield call(NetworkService.post, url, request);
    yield put(
      expenseDAMasterStateActions.setTotalCount(gridData?.data?.totalCount),
    );
    yield put(
      expenseDAMasterStateActions.setExpenseDAMasterGrid(gridData?.data?.items),
    );

    yield put(
      expenseDAMasterStateActions.setExpenseDAMasterSnaShot(
        gridData?.data?.items,
      ),
    );

    yield put(expenseDAMasterStateActions.setExpenseDAMasterLoading(false));
  } catch (error) {
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongInDa,
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchExpenseDAMasterInAutoSuggestDataWatcher() {
  yield takeLatest(
    fetchExpenseDAMasterInAutoSuggestCreatorTypeName,
    fetchExpenseDAMasterInAutoSuggestDataWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseDAMasterInAutoSuggestDataWatcherWorker(
  action: any,
): any {
  try {
    yield put(
      expenseDAMasterStateActions.setExpenseDAMasterAutoSuggestLoading(true),
    );
    yield put(expenseDAMasterStateActions.setExpenseDAMasterInAutoSuggest([]));
    const isUserActive = yield select(
      expenseDAMasterStateSelector.getIsActiveState(),
    );

    if (action.payload.length > 0) {
      const url = `${API_PATH.expenseDATAMaster.expenseDATAMasterAutosuggest}`;
      const AutosuggestData = yield call(NetworkService.post, url, {
        isActive: isUserActive,
        searchData: action?.payload,
      });
      yield put(
        expenseDAMasterStateActions.setExpenseDAMasterAutoSuggestLoading(false),
      );
      yield put(
        expenseDAMasterStateActions.setExpenseDAMasterInAutoSuggest(
          AutosuggestData?.data,
        ),
      );
    }
  } catch (error) {
    yield put(
      expenseDAMasterStateActions.setExpenseDAMasterAutoSuggestLoading(false),
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* fetchDesignationsDropdownWatcher() {
  yield takeLatest(
    fetchDesignationsDropdownCreatorTypeName,
    fetchDesignationsDropdownWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchDesignationsDropdownWatcherWorker(): any {
  try {
    const url = `${API_PATH.expenseDATAMaster.dropdowns.designations}`;
    const designationsDropdown = yield call(NetworkService.get, url, {}, {});
    yield put(
      expenseDAMasterStateActions.setDesignationsDropdown(
        designationsDropdown?.data,
      ),
    );
  } catch (error) {}
}
/**
 * Employee Details Watcher
 */
export function* fetchHQDropdownWatcher() {
  yield takeLatest(
    fetchHQDropdownCreatorTypeName,
    fetchHQDropdownWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchHQDropdownWatcherWorker(): any {
  try {
    const url = `${API_PATH.expenseDATAMaster.dropdowns.HQ}`;
    const HQDropdown = yield call(
      NetworkService.post,
      url,
      {divisionIds: [], designationIds: []},
      {},
    );
    yield put(expenseDAMasterStateActions.setHQDropdown(HQDropdown?.data));
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchExpenseDAMasterDesignationByDivisionWatcher() {
  yield takeLatest(
    fetchExpenseDAMasterDesignationByDivisionCreatorTypeName,
    fetchExpenseDAMasterDesignationByDivisionWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchExpenseDAMasterDesignationByDivisionWatcherWorker(
  action: any,
): any {
  try {
    let divisions = action?.payload;
    yield put(expenseDAMasterStateActions.setDesignationArr([]));
    if (divisions !== undefined) {
      const url = `${API_PATH.billLibrary.dropdown.designationbydivision}${divisions}&accountTypeCode=6`;
      const desigantion = yield call(NetworkService.get, url, {}, {});
      yield put(
        expenseDAMasterStateActions.setDesignationArr(
          [{divisionId: 0, designationId: 0, designationName: 'ALL'}].concat(
            desigantion?.data,
          ),
        ),
      );
    }
  } catch (error) {}
}

/**
 * Employee Details Watcher
 */
export function* fetchHQTypeDropdownWatcher() {
  yield takeLatest(
    fetchHQTypeDropdownCreatorCreatorTypeName,
    fetchHQTypeDropdownWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* fetchHQTypeDropdownWatcherWorker(): any {
  try {
    let categoryType = 'HQType';
    const url = `${API_PATH.expenseDATAMaster.dropdowns.HQType}/${categoryType}`;
    const hqType = yield call(NetworkService.get, url, {}, {});

    yield put(expenseDAMasterStateActions.setHqType(hqType?.data));
  } catch (error) {}
}

/**
 * Export Details Watcher
 */
export function* fetchExportFileCreatorWatcher() {
  yield takeLatest(
    fetchExportFileCreatorTypeName,
    fetchExportFileCreatorWatcherWorker,
  );
}
/**
 * Export Details Worker
 */
export function* fetchExportFileCreatorWatcherWorker(action: any): any {
  try {
    yield put(expenseDAMasterStateActions.setIsExcelDownloading(true));
    const filter = yield select(filterSelector.getFilterOptions());

    const url = `/expenseadmin/${
      action?.payload === 'ProRataMiscExpense'
        ? 'expensetypesettings'
        : action?.payload
    }/downloadcsv?returnInBase64=true`;
    const exportFile = yield call(NetworkService.post, url, filter, {});
    if (exportFile?.data) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${exportFile.data}`;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = linkSource;
      downloadLink.target = '_self';
      downloadLink.download = action?.payload;
      downloadLink.click();
    }
    yield put(expenseDAMasterStateActions.setIsExcelDownloading(false));
  } catch (error) {
    yield put(expenseDAMasterStateActions.setIsExcelDownloading(false));
  }
}
/**
 * Employee Details Watcher
 */
export function* activeInactiveExpenseDAMasterWatcher() {
  yield takeLatest(
    activeInactiveExpenseDAMasterCreatorCreatorTypeName,
    activeInactiveExpenseDAMasterWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* activeInactiveExpenseDAMasterWatcherWorker(action: any): any {
  try {
    const isActive = yield select(
      expenseDAMasterStateSelector.getIsActiveState(),
    );

    const url = `${API_PATH.expenseDATAMaster.expenseDAMasterActiveInactive}`;
    const disableUser = yield call(NetworkService.post, url, {
      id: action?.payload?.expenseDAMasterId,
    });
    if (disableUser?.data === true) {
      !isActive &&
        ToasterService.showToaster(
          strings.masterDataManagement.expenseDaActive,
          ToastType.SUCCESS,
        );
      isActive &&
        ToasterService.showToaster(
          strings.masterDataManagement.expenseDaInActive,
          ToastType.SUCCESS,
        );

      yield put({type: fetchExpenseDAMasterGridCreatorTypeName, payload: {}});
    }
  } catch (error) {
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongActiveInActiveMsg,
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* AddExpenseValidationDAMasterWatcher() {
  yield takeLatest(
    AddExpenseValidationDAMasterCreatorTypeName,
    AddExpenseValidationDAMasterWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* AddExpenseValidationDAMasterWatcherWorker(action: any): any {
  try {
    yield put(
      expenseDAMasterStateActions.setaddExpenseDAMasterPopupVisible(false),
    );
    const resetNavbarVersionDAMaster = yield select(
      expenseDAMasterStateSelector.getResetNavbarVersion(),
    );
    yield put(
      filterStateActions.setFilterOptions({
        isActive: true,
        searchText: '',
        pageNumber: 0,
        pageLimit: 100,
        divisionIds: [],
        designationIds: [],
        hqIds: [],
      }),
    );
    const url = `${API_PATH.expenseDATAMaster.addExpensevalidateDAMaster}`;
    const addUser = yield call(NetworkService.post, url, {
      divisionIds: action?.payload?.divisionIds,
      designationIds: action?.payload?.designationIds,
      hqIds: action?.payload?.hqIds,
      hqTypes: action?.payload?.hqType,
      amount: action?.payload?.amount,
      startDate: action?.payload?.startDate
        ? new Date(action?.payload?.startDate)?.toLocaleDateString('en-ca')
        : null,
    });

    yield put({type: fetchExpenseDAMasterGridCreatorTypeName, payload: {}});
    yield put(
      expenseDAMasterStateActions.setExpenseDAMasterInAutoSuggestFilter({}),
    );
    yield put(expenseDAMasterStateActions.setExpenseDAMasterInAutoSuggest([]));
    yield put(expenseDAMasterStateActions.setSearchText(''));
    yield put(
      expenseDAMasterStateActions.setResetNavbarVersion(
        resetNavbarVersionDAMaster + 1,
      ),
    );
    if (addUser?.data?.message) {
      ToasterService.showToaster(addUser?.data?.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(addUser?.data?.message, ToastType.ERROR);
    }

    yield put(
      expenseDAMasterStateActions.setExpenseDAMasterNewData(addUser?.data),
    );
  } catch (error) {
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongAddingDaMasterMsg,
      ToastType.ERROR,
    );
  }
}

/**
 * Employee Details Watcher
 */
export function* addExpenseDAMasterWatcher() {
  yield takeLatest(
    fetchAddExpenseDAMasterCreatorTypeName,
    addExpenseDAMasterWatcherWorker,
  );
}
/**
 * Employee Details Worker
 */
export function* addExpenseDAMasterWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.expenseDATAMaster.addExpenseDAMaster}`;
    const addUser = yield call(NetworkService.post, url, action?.payload);
    yield put(
      expenseDAMasterStateActions.setaddExpenseDAMasterPopupVisible(false),
    );
    yield put({type: fetchExpenseDAMasterGridCreatorTypeName, payload: {}});
    if (addUser?.data?.message) {
      ToasterService.showToaster(addUser?.data?.message, ToastType.SUCCESS);
    } else {
      ToasterService.showToaster(addUser?.data?.message, ToastType.ERROR);
    }
  } catch (error) {
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongAddingDaMasterMsg,
      ToastType.ERROR,
    );
  }
}
/**
 * Employee Details Watcher
 */
export function* editExpenseDAMastereWatcher() {
  yield takeLatest(
    editExpenseDAMasterCreatorTypeName,
    editExpenseDAMasterWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* editExpenseDAMasterWatcherWorker(action: any): any {
  try {
    const url = `${API_PATH.expenseDATAMaster.editExpenseDAMaster}`;
    const editData = yield call(NetworkService.post, url, {
      expenseDAMasterId: action?.payload?.expenseDAMasterId,
      divisionId: action?.payload?.divisionId,
      designationId: action?.payload?.designationId,
      hqId: action?.payload?.hqId,
      hqType: action?.payload?.hqType,
      amount: action?.payload?.amount,
      startDate: new Date(action?.payload?.startDate)?.toLocaleDateString(
        'en-ca',
      ),
      endDate: new Date(action?.payload?.endDate)?.toLocaleDateString('en-ca'),
      isActive: action?.payload?.isActive,
    });
    yield put(
      expenseDAMasterStateActions.setEditExpenseDAMasterPopupVisible(false),
    );
    yield put({type: fetchExpenseDAMasterGridCreatorTypeName, payload: {}});
    if (editData?.data) {
      ToasterService.showToaster(
        strings.masterDataManagement.updateSuccessfull,
        ToastType.SUCCESS,
      );
    } else {
      ToasterService.showToaster(
        strings.masterDataManagement.somethingWrongUpdatingDaMasterMsg,
        ToastType.ERROR,
      );
    }
  } catch (error) {
    yield put(
      expenseDAMasterStateActions.setEditExpenseDAMasterPopupVisible(false),
    );
    ToasterService.showToaster(
      strings.masterDataManagement.somethingWrongEditingDaMasterMsg,
      ToastType.ERROR,
    );
  }
}

export function* fetchHqByEdividionDesginationDAMasterWatcher() {
  yield takeLatest(
    fetchHqByEdividionDesginationDAMasterCreatorTypeName,
    fetchHqByEdividionDesginationDAMasterWatcherWorker,
  );
}

/**
 * Employee Details Worker
 */
export function* fetchHqByEdividionDesginationDAMasterWatcherWorker(
  action: any,
): any {
  let ids;
  if (action?.payload === null) {
    ids = {
      divisionIds: [],
      designationIds: [],
    };
  } else {
    ids = action?.payload;
  }
  yield put(expenseDAMasterStateActions.setHqByDivisionDesignation([]));
  try {
    const url = `${API_PATH.expenseDATAMaster.hqlistbydivisiondesignations}`;
    const response = yield call(NetworkService.post, url, ids, {});
    if (response?.status === HTTP_OK) {
      yield put(
        expenseDAMasterStateActions.setHqByDivisionDesignation(
          [{headQuaterId: 0, headQuaterName: 'ALL'}].concat(response?.data),
        ),
      );
    }
  } catch (error) {}
}
