import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import {useStyles} from './styles';
import FormHelperText from '@mui/material/FormHelperText';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import Colors from '@app/themes/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {Tune} from '@mui/icons-material';

type SearchBarProps = {
  handleChange: (params: any) => any;
  handleSearch: (params: any) => any;
  showError: boolean;
  searchText: string;
  placeHolder: string;
  errorMessage: string;
  disabled?: boolean;
  handleClear?: () => any;
  showSuggestion?: boolean;
  suggestionList?: any;
  suggestionSelected?: any;
  showFilterIcon?: boolean;
  handleFilter?: any;
};

export const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  handleChange,
  handleSearch,
  showError,
  searchText,
  placeHolder,
  errorMessage,
  disabled = false,
  handleClear = () => {},
  showSuggestion = false,
  suggestionList = [],
  suggestionSelected = () => {},
  showFilterIcon = false,
  handleFilter = () => {},
}) => {
  const classes = useStyles();

  const handleTextChange = (event: any) => {
    handleChange(event.target.value);
  };

  const handleClearBtn = () => {
    handleClear();
    handleChange('');
  };

  const renderRow = () =>
    suggestionList?.map((item: any) => (
      <ListItemButton
        key={item?.staffPositionId}
        onClick={() => suggestionSelected(item)}
        data-testid={`${item?.empid}_${item?.staffPositionId}`}
      >
        <ListItem key={`item-${item?.staffPositionId}`}>
          <ListItemText
            primary={`${item?.empName} | ${item?.empid} | ${item?.staffPositionId}`}
          />
        </ListItem>
      </ListItemButton>
    ));

  const renderTextField = () => {
    return (
      <TextField
        value={searchText}
        placeholder={placeHolder}
        id="outlined-start-adornment"
        className={classes.searchField}
        onChange={handleTextChange}
        onKeyDown={handleSearch}
        data-testid={'searchBar'}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: Colors.grey[800],
          },
        }}
        autoComplete="off"
      />
    );
  };

  return (
    <FormControl variant="standard" sx={{mt: 3, flex: 1, width: '100%'}}>
      {showError && (
        <FormHelperText
          id="search-error"
          className={classes.error}
          data-testid={'searchBarError'}
        >
          {errorMessage}
        </FormHelperText>
      )}
      <>
        {disabled ? (
          <Tooltip title={searchText} arrow PopperProps={{disablePortal: true}}>
            {renderTextField()}
          </Tooltip>
        ) : (
          renderTextField()
        )}
        {showSuggestion && (
          <List
            className={classes.searchSuggestion}
            subheader={<li />}
            aria-label="suggestionList"
          >
            {renderRow()}
          </List>
        )}
      </>

      {searchText?.length > 0 && (
        <ClearIcon
          onClick={() => handleClearBtn()}
          className={showFilterIcon ? classes.leftIcon : classes.clear}
          data-testid={'search-clear-btn'}
        />
      )}
      {showFilterIcon && (
        <Tune
          onClick={event => handleFilter(event)}
          className={classes.clear}
          data-testid={'filter-btn'}
        />
      )}
    </FormControl>
  );
};
