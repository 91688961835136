import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IActivityMappingInitialState {
  mappedLoader: boolean;
  unMappedLoader:boolean;
  showDivisionConfigScreen: boolean;
  showCreateActivityModal: boolean;
  showCreateActivityScreen: boolean;
  wsfaActivityList?: any;
  tempWsfaActivityList?:any;
  copiedMappedItem?:any,
  unMappedActivities?:any,
  tempUnMappedActivities?:any,
  mappingRemoved?:any,
  savedResponse?:any,
  newMappingCreated?:any
  activitiesList: any,
  cloneActivitiesList: any,
  activitiesListGridOptions: any;
  divisionMapping: any;
  createActivityResponse: any;
  createActivitySearchText: string;
  searchFlag?:boolean;
  searchedList?:any
}

export const activityMappingInitialState: IActivityMappingInitialState = {
  mappedLoader: false,
  unMappedLoader:false,
  showDivisionConfigScreen: false,
  showCreateActivityModal: false,
  showCreateActivityScreen: false,
  wsfaActivityList: [],
  tempWsfaActivityList:[],
  copiedMappedItem:null,
  tempUnMappedActivities:null,
  unMappedActivities:null,
  mappingRemoved:[],
  savedResponse:null,
  newMappingCreated:[],
  activitiesList: null,
  cloneActivitiesList: null,
  activitiesListGridOptions: {},
  divisionMapping: null,
  createActivityResponse: null,
  createActivitySearchText: '',
  searchFlag:false,
  searchedList:null
}


export const mappedActivityListCreator = createAction(
  'ACTIVITY_MAPPING_HANDLER/FETCH_MAPPED_ACTIVITY_LIST',
)

export const mappedActivityListCreatorTypeName = mappedActivityListCreator().type;


export const unmappedActivityListCreator = createAction(
  'ACTIVITY_MAPPING_HANDLER/FETCH_UNMAPPED_ACTIVITY_LIST',
)

export const unmappedActivityListCreatorTypeName = unmappedActivityListCreator().type;

export const saveResponseListCreator = createAction<any>(
  'ACTIVITY_MAPPING_HANDLER/SAVE_ACTIVITY_MAPPING',
)

export const saveResponseListCreatorTypeName = saveResponseListCreator(null).type;

export const fetchActivitiesListCreator = createAction(
  'ACTIVITY_MAPPING_HANDLER/FETCH_ACTIVITIES',
)

export const fetchActivitiesListCreatorTypeName = fetchActivitiesListCreator().type;

export const divisionMappingCreator = createAction<any>(
  'ACTIVITY_MAPPING_HANDLER/DIVISION_MAPPING',
)

export const divisionMappingCreatorTypeName = divisionMappingCreator(null).type;

export const saveActivitiesCreator = createAction<any>(
  'ACTIVITY_MAPPING_HANDLER/SAVE_ACTIVITIES',
)

export const saveActivitiesCreatorTypeName = saveActivitiesCreator(null).type;

const activityMappingHandler = createSlice({
  name: 'ACTIVITY_MAPPING_HANDLER',
  initialState: activityMappingInitialState,
  reducers: {
    setActivityMappingLoader: (prevState, action) => {
      return {
        ...prevState,
        loader: action.payload,
      };
    },
    setShowCreateActivityModal: (prevState, action) => {
      return {
        ...prevState,
        showCreateActivityModal: action.payload,
      }
    },
    setShowCreateActivityScreen: (prevState, action) => {
      return {
        ...prevState,
        showCreateActivityScreen: action.payload,
      }
    },
    setWsfaActivityList: (prevState, action) => {
      return {
        ...prevState,
        wsfaActivityList: action.payload
      }
    },
    setTempWsfaActivityList: (prevState, action) => {
      return {
        ...prevState,
        tempWsfaActivityList: action.payload
      }
    },
    setCopiedMappedItem: (prevState,action) => {
      return {
        ...prevState,
        copiedMappedItem:action.payload
      }
    },
    setUnMappedActivitiesList: (prevState,action) => {
      return {
        ...prevState,
        unMappedActivities:action.payload
      }
    },
    setTempUnMappedActivitiesList: (prevState,action) => {
      return {
        ...prevState,
        tempUnMappedActivities:action.payload
      }
    },
    setMappingRemoved: (prevState,action) => {
      return {
        ...prevState,
        mappingRemoved:action.payload
      }
    },
    setSaveResponse:(prevState,action) => {
      return {
        ...prevState,
        savedResponse:action.payload
      }
    },
    setNewMappingCreated:(prevState,action) => {
      return {
        ...prevState,
        newMappingCreated:action.payload
      }
    },
    resetState:(prevState,action) => {
      return {
        ...prevState,
        showDivisionConfigScreen: false,
        showCreateActivityScreen: false,
        wsfaActivityList: [],
        tempWsfaActivityList:[],
        copiedMappedItem:null,
        tempUnMappedActivities:null,
        unMappedActivities:null,
        mappingRemoved:[],
        savedResponse:null,
        newMappingCreated:[]
      }
    },
    setMappedLoader: (prevState,action) => {
      return {
        ...prevState,
        mappedLoader:action.payload,
      }
    },
    setUnMappedLoader: (prevState,action) => {
      return {
        ...prevState,
        unMappedLoader:action.payload,
      }
    },
    setActivitiesList: (prevState,action) => {
      return {
        ...prevState,
        activitiesList: action.payload
      }
    },
    setCloneActivitiesList: (prevState,action) => {
      return {
        ...prevState,
        cloneActivitiesList: action.payload
      }
    },
    setActivitiesListGridOptions: (prevState,action) => {
      return {
        ...prevState,
        activitiesListGridOptions: action.payload
      }
    },
    setDivisionMappingData: (prevState,action) => {
      return {
        ...prevState,
        divisionMapping: action.payload
      }
    },
    setCreateActivityResponse: (prevState,action) => {
      return {
        ...prevState,
        createActivityResponse: action.payload
      }
    },
    setCreateActivitySearchText: (prevState,action) => {
      return {
        ...prevState,
        createActivitySearchText: action.payload
      }
    },
    setSearchFlag: (prevState,action) => {
      return {
        ...prevState,
        searchFlag: action.payload
      }
    },
    setSearchedList: (prevState,action) => {
      return {
        ...prevState,
        searchedList: action.payload
      }
    }
  }
});


export const activityMappingStateActions = activityMappingHandler.actions;
export const activityMappingStateReducer = activityMappingHandler.reducer;
