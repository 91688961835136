import Typography from '@mui/material/Typography';
import {useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Box} from '@mui/material';
import {useStyle} from '@app/screens/Styles/style';

export const ModuleSpecificUtilitiesBar = () => {
  const classes = useStyle();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  return (
    <Box className={classes.container}>
      <Typography variant="h6" noWrap component="div">
        {navbarComponentHeading}
      </Typography>
    </Box>
  );
};
