import Typography from '@mui/material/Typography';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Box, Button} from '@mui/material';
import {accessGroupMasterStateActions} from '@app/screens/access-management/pages/access-group-master/redux';
import {SearchBarAccessGroup} from '@app/components/search-bar-access-group';
import {appSelector} from '@app/store/selectors';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_ADD_NEW} from '@app/utils/analytics/constants';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '5px',
    width: '150px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 12.7,
  },
};
export const UserGroupManagementNavBar = () => {
  const dispatch = useDispatch();
  const isEditAccessAvailable = useSelector(
    appSelector.getIsEditAccessAvailable(),
    shallowEqual,
  );
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
    shallowEqual,
  );
  const manageAddNewPopup = () => {
    dispatch(
      accessGroupMasterStateActions.setAccessGroupMasterStateKeyValue({
        key: 'addAccessGroupPopupVisible',
        value: true,
      }),
    );
    trackEvent(EVENT_NAME_ADD_NEW, {});
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.flexSpaceBetween}>
        <Typography variant="h6" noWrap component="div">
          {navbarComponentHeading}
        </Typography>

        <Button
          variant="contained"
          disabled={!isEditAccessAvailable}
          sx={{
            ...styles.button,
            ...(!isEditAccessAvailable && {color: 'grey'}),
          }}
          onClick={manageAddNewPopup}
        >
          Add New
        </Button>
      </Box>

      <SearchBarAccessGroup />
    </Box>
  );
};
