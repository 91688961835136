import {useEffect, useState} from 'react';
import {Search} from '@mui/icons-material';
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormGroup,
  InputAdornment,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  fetchAdminUsersAutoSuggestCreator,
  fetchAdminUsersCreator,
  userManagementStateActions,
} from '@app/screens/access-management/pages/user-management/redux/slice';
import {useSelector, useDispatch} from 'react-redux';
import {userManagementStateSelector} from '@app/screens/access-management/pages/user-management/redux/selectors';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_SEARCH_BAR} from '@app/utils/analytics/constants';
import {
  AntSwitch,
  navbarStyles,
} from '@app/components/widgets/AntSwitch/AntSwitch';
import { searchBarStyle } from '../widgets/searchBarCss/searchBarCss';

export const SearchBarUserManagement = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const isActiveState = useSelector(
    userManagementStateSelector.getIsActiveState(),
  );
  const userSearchText = useSelector(
    userManagementStateSelector.getUserSearchText(),
  );
  const adminUsersAutosuggestLoading = useSelector(
    userManagementStateSelector.getAdminUsersAutosuggestLoading(),
  );
  const adminUsersAutosuggestData = useSelector(
    userManagementStateSelector.getAdminUsersAutosuggestData(),
  );
  const selectedAdminUserInAutoSuggest = useSelector(
    userManagementStateSelector.getSelectedAdminUserInAutoSuggest(),
  );

  const resetNavbarVersion = useSelector(
    userManagementStateSelector.getResetNavbarVersion(),
  );

  useEffect(() => {
    userSearchText.length > 0 && dispatch(fetchAdminUsersAutoSuggestCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSearchText]);

  useEffect(() => {
    selectedAdminUserInAutoSuggest &&
      Object.keys(selectedAdminUserInAutoSuggest).length > 0 &&
      dispatch(fetchAdminUsersCreator(selectedAdminUserInAutoSuggest));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAdminUserInAutoSuggest]);

  return (
    <div>
      <Toolbar sx={navbarStyles.toolbar}>
        <FormControl sx={searchBarStyle.width60}>
          <Autocomplete
            key={
              isActiveState
                ? `active ${resetNavbarVersion}`
                : `inactive ${resetNavbarVersion}`
            }
            open={open}
            onChange={(_event, value) => {
              if (!value) {
                dispatch(fetchAdminUsersCreator(null));
              }
              dispatch(
                userManagementStateActions.setSelectedAdminUserInAutoSuggest(
                  value,
                ),
              );
            }}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            isOptionEqualToValue={(option: any, value: any) =>
              option.name === value.name
            }
            getOptionLabel={(option: any) => option.employeeName}
            options={
              adminUsersAutosuggestData && adminUsersAutosuggestData.length > 0
                ? adminUsersAutosuggestData
                : []
            }
            loading={adminUsersAutosuggestLoading}
            renderInput={params5 => (
              <TextField
                onChange={event => {
                  trackEvent(EVENT_NAME_SEARCH_BAR, {});
                  dispatch(
                    userManagementStateActions.setUserSearchText(
                      event.target.value,
                    ),
                  );
                }}
                {...params5}
                placeholder={'Search here...'}
                sx={navbarStyles.textField}
                InputProps={{
                  ...params5.InputProps,
                  startAdornment: (
                    <InputAdornment position="start" sx={navbarStyles.padding5}>
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {adminUsersAutosuggestLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                    </>
                  ),
                }}
              />
            )}
          />
        </FormControl>

        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>In-Active</Typography>
            <AntSwitch
              onChange={() => {
                dispatch(
                  userManagementStateActions.setIsActiveState(!isActiveState),
                );
                dispatch(
                  userManagementStateActions.setSelectedAdminUserInAutoSuggest(
                    {},
                  ),
                );
                dispatch(
                  userManagementStateActions.setAdminUsersAutosuggestData([]),
                );
                dispatch(userManagementStateActions.setUserSearchText(''));
                setTimeout(() => {
                  dispatch(fetchAdminUsersCreator(null));
                }, 10);
              }}
              checked={isActiveState}
              inputProps={{'aria-label': 'ant design'}}
            />
            <Typography>Active</Typography>
          </Stack>
        </FormGroup>
      </Toolbar>
    </div>
  );
};
