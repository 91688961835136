import {createAction, createSlice} from '@reduxjs/toolkit';

export const intrasistChallanInitialState = {
  inTransistAccessType: '',
  stateDivision: [],
  adminFilterData: {},
  userList: [],
  filteredUserList: [],
  filteredData: {},
  userSearch: {},
  loading: false,
  pageNumber: 0,
  request: {},
  inTransistAccessFlag: false,
};

export const inTransitAccessDivisionStateCreator = createAction(
  'Transit_Access_DivisionState',
);
export const fetchInTransitAccessDivisionState =
  inTransitAccessDivisionStateCreator().type;

export const inTransitAccessUserStateDivisionRoleCreator = createAction(
  'Transit_Access_UserStateDivisionRole',
);
export const fetchInTransitAccessUserStateDivisionRole =
  inTransitAccessUserStateDivisionRoleCreator().type;

export const inTransitAccessUserCreator = createAction<any>(
  'Transit_Access_User',
);
export const fetchInTransitAccessUser = inTransitAccessUserCreator({}).type;

export const inTransitAccessDivisionLockedCreator = createAction<any>(
  'Transit_Access_Division_Locked',
);
export const fetchInTransitAccessDivisionLocked =
  inTransitAccessDivisionLockedCreator({}).type;

export const inTransitAccessStateLockedCreator = createAction<any>(
  'Transit_Access_State_Locked',
);
export const fetchInTransitAccessStateLocked =
  inTransitAccessStateLockedCreator({}).type;

export const inTransitAccessUserLockedCreator = createAction<any>(
  'Transit_Access_User_Locked',
);
export const fetchInTransitAccessUserLocked = inTransitAccessUserLockedCreator(
  {},
).type;

const inTransistAccessHandler = createSlice({
  name: 'IN_TRANSIST-CHALLAN_HANDLER',
  initialState: intrasistChallanInitialState,
  reducers: {
    setInTransistAccessType: (prevState, {payload}) => {
      return {
        ...prevState,
        inTransistAccessType: payload,
      };
    },
    setInTransistAccessUser: (prevState, {payload}) => {
      return {
        ...prevState,
        userList: payload,
      };
    },
    setInTransistAccessFilterUser: (prevState, {payload}) => {
      return {
        ...prevState,
        filteredUserList: payload,
      };
    },
    setInTransistAccesssSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        stateDivision: payload,
        loading: false,
      };
    },
    setInTransitFilteredData: prevState => {
      return {
        ...prevState,
        filteredData: {},
      };
    },
    setInTransitUserSeachData: (prevState, action) => {
      return {
        ...prevState,
        userSearch: action.payload,
      };
    },
    setFilteredDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        filteredData: payload,
      };
    },
    setAdminFilterSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        adminFilterData: payload,
      };
    },
    setLoading: (prevState, {payload}) => {
      return {
        ...prevState,
        loading: payload,
      };
    },
    SetPageNumber: (prevState, {payload}) => {
      return {
        ...prevState,
        pageNumber: payload,
      };
    },
    setRequest: (prevState, {payload}) => {
      return {
        ...prevState,
        request: payload,
      };
    },
    setAccessAdminFlag: (prevState, {payload}) => {
      return {
        ...prevState,
        inTransistAccessFlag: payload,
      };
    },
  },
});

export const inTransistAccessActions = inTransistAccessHandler.actions;
export const inTransistAccessReducer = inTransistAccessHandler.reducer;
