export const ChallonApprovalTableStyle = {
  voucherNum: {
    maxWidth: 100,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: -0.48,
    textAlign: 'left',
    color: '#322b7c',
    padding: 0,
  },
  tableCell: {
    maxWidth: 90,
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: -0.38,
    textAlign: 'left',
    color: '#1c1939',
  },
  headerCell: {
    maxWidth: 100,
    fontFamily: 'Poppins',
    fontSize: 9.5,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.19,
    textAlign: 'left',
    color: '#322b7c',
  },
};
export const DailogModalStyle = {
  headerDailogModal: {
    fontFamily: 'Poppins',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0.21,
    textAlign: 'left',
    color: '#494761',
    paddingLeft: 1.5,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    borderBottom: 0,
  },
  eyeButton: {
    padding: '8px 22px',
    width: 100,
    height: 60,
    gap: '8px',
  },
  nameBox: {
    maxWidth: 300,
  },
  textDailogModal: {
    width: 88.7,
    height: 18,
    opacity: 0.4,
    fontFamily: 'Poppins-Regular',
    fontSize: 12.7,
    fontWeight: 'normal',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#1c1939',
  },
  headerItem: {
    fontFamily: 'Poppins',
    letterSpacing: 0,
    textAlign: 'left',
    color: '#1c1939',
  },
  headerBoxTitle: {
    width: 500,
    border: 0,
    display: 'grid',
    gridTemplateColumns: '1.5fr 3fr 1fr 1fr',
  },
  headerBigDailogModal: {
    width: 153.3,
  },
  rowDailogModal: {
    boxShadow: 2,
    width: 500,
    height: 60,
    border: 0,
    borderRadius: 2,
    marginBottom: 2,
    display: 'grid',
    gridTemplateColumns: '1.5fr 3fr 1fr 1fr',
    padding: 0,
  },
  textInputBoxDM: {
    maxLength: 25,
    height: 61,
    borderRadius: 2,
    marginBottom: 2,
  },
  closeIconDM: {
    color: '#1c1939',
    marginTop: -4,
    marginRight: -2.67,
  },
  resetButtonDM: {
    backgroundColor: '#ffffff',
    color: '#322b7c',
    border: '2px solid #322b7c',
    borderRadius: '5px',
    width: '150px',
    boxShadow: 'none',
    marginRight: '10px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: 12.7,
    '&:hover': {
      background: 'none',
    },
  },
  saveButtonDM: {
    backgroundColor: '#322b7c',
    borderRadius: '5px',
    width: '150px',
    boxShadow: 'none',
    marginLeft: '10px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: 12.7,
    '&:hover': {
      background: '#322b7c',
    },
  },
};
