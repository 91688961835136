import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  dialog: {
    width: '100%',
  },
  dialogHeader: {
    height: 150,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.bgcolor,
    borderBottomRightRadius: 15,
    borderBottomLeftRadius: 15,
    padding: 5,
  },
  dialogTitle: {
    padding: 0,
  },
  headerTitle: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    gap: 15,
    padding: 10,
  },
  paperButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.white,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.primary,
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  applyButton: {
    border: `3px solid ${Colors.primary}`,
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '150px',
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: Colors.white,
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
  },
  chip: {
    height: 30,
    backgroundColor: Colors.bgcolor,
    borderRadius: 5,
  },
});
