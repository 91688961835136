import * as React from 'react';
import {CustomModal} from '@app/components/widgets/CustomModal';
import {Box, Button, DialogContent, TextField, Typography} from '@mui/material';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import MultiSelectDropdown from './multiSelectionChips';
import {nanoid} from '@reduxjs/toolkit';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import DownloadIcon from '@mui/icons-material/Download';
import {useDispatch, useSelector} from 'react-redux';
import dayjs, {Dayjs} from 'dayjs';
import {fetchCnfReturnAudtilDetialsCreator} from './redux/slice';
import {adminCnfSelector} from './redux/selectors';

// ReusableModal component
interface ReusableModalProps {
  open: boolean;
  close: () => void;
  buttonText: string;
}
export const utility: any = {
  handleSelectionChange: null,
  handleEffectiveDate: null,
  handleValidDate: null,
};
const GenerateReportModal: React.FC<ReusableModalProps> = ({
  open,
  close,
  buttonText,
}) => {
  const dispatch = useDispatch();

  const [selectedValues, setSelectedValues] = React.useState<
    Array<{label: string; value: string}>
  >([]);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [minValidDate, setMinValidDate] = React.useState<Dayjs | null>(null);
  const [openAutoComplete, setOpenAutocomplete] =
    React.useState<boolean>(false);

  const handleSelectionChange = (
    values: Array<{label: string; value: string}>,
  ) => {
    setSelectedValues(values);
    setOpenAutocomplete(false);
  };
  const onPressAudtiDetails = () => {
    const body = {
      startDate: startDate,
      endDate: endDate,
      divisionIds: selectedValues.map(item => item.value),
    };
    dispatch(fetchCnfReturnAudtilDetialsCreator({body}));
    setSelectedValues([]);
    setStartDate(dayjs(null));
    setEndDate(dayjs(null));
  };
  const onReset = () => {
    close();
    setSelectedValues([]);
    setStartDate(dayjs(null));
    setEndDate(dayjs(null));
  };

  const cnfAuditDivisionData = useSelector(
    adminCnfSelector.getCnfAuditDivision(),
  );
  const handleEffectiveDate = React.useCallback((newValue: Dayjs | null) => {
    if (newValue) {
      const maxAllowedDate = newValue.add(90, 'day');
      const adjustedValue = newValue.isAfter(maxAllowedDate)
        ? maxAllowedDate
        : newValue;
      setStartDate(adjustedValue);
      // Reset end date when start date changes
      setEndDate(null);

      const calculatedMinValidDate = adjustedValue.add(1, 'day');
      setMinValidDate(calculatedMinValidDate);
    } else {
      setStartDate(null);
      setMinValidDate(null);
    }
  }, []);

  const handleValidDate = React.useCallback(
    (newValue: Dayjs | null) => {
      if (startDate && newValue) {
        const maxAllowedEndDate = startDate.add(90, 'day');
        const adjustedEndDate = newValue.isAfter(maxAllowedEndDate)
          ? maxAllowedEndDate
          : newValue;
        setEndDate(adjustedEndDate);
      } else {
        setEndDate(null);
      }
    },
    [startDate],
  );

  utility.handleSelectionChange = handleSelectionChange;
  utility.handleEffectiveDate = handleEffectiveDate;
  utility.handleValidDate = handleValidDate;

  return (
    <CustomModal
      data-testid="close-modal"
      open={open}
      onClose={onReset}
      header={
        <Typography marginLeft={2.8} fontWeight={'bold'} fontSize={20}>
          Download Report
        </Typography>
      }
      footer={
        <Box marginRight={42} marginBottom={2}>
          <Button
            data-testid="Audit-Report"
            variant="contained"
            sx={{width: 200, textTransform: 'none'}}
            onClick={onPressAudtiDetails}
            disabled={
              selectedValues.length === 0 ||
              !startDate?.isValid() ||
              !endDate?.isValid()
            }>
            {buttonText}
            <DownloadIcon />
          </Button>
        </Box>
      }
      maxWidth={'md'}
      fullWidth={true}>
      <DialogContent>
        <Box flexDirection={'row'} display={'flex'}>
          <Typography color={'#000000de'} fontWeight={'normal'}>
            Division
          </Typography>
          <Typography color={'#FE5B38'}>*</Typography>
        </Box>{' '}
        <Box sx={{left: 20}}>
          <MultiSelectDropdown
            options={cnfAuditDivisionData}
            selectedValues={selectedValues}
            onChange={handleSelectionChange}
            open={openAutoComplete}
            setOpen={setOpenAutocomplete}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          paddingTop={3}>
          <div>
            <span>
              Start Date<span style={{color: 'red'}}>*</span>
            </span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={startDate}
                inputFormat="YYYY/MM/DD"
                key={nanoid()}
                onChange={handleEffectiveDate}
                maxDate={dayjs().startOf('day')}
                PopperProps={{
                  sx: {
                    '& .MuiPaper-root': {
                      position: 'absolute',
                      right: 5,
                      bottom: 3,
                    },
                  },
                }}
                renderInput={params => {
                  return (
                    <TextField
                      data-testid="startDate"
                      {...params}
                      sx={{width: 370}}
                      error={false}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'YYYY/MM/DD',
                      }}
                      onInput={e => {
                        e.preventDefault(); // Prevent manual input
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <span>
              End Date<span style={{color: 'red'}}>*</span>
            </span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={endDate}
                onChange={handleValidDate}
                inputFormat="YYYY/MM/DD"
                key={nanoid()}
                minDate={minValidDate ?? undefined}
                maxDate={startDate?.add(90, 'day') ?? undefined}
                disabled={startDate === null}
                PopperProps={{
                  sx: {
                    '& .MuiPaper-root': {
                      position: 'absolute',
                      left: 5,
                      bottom: 3,
                    },
                  },
                }}
                renderInput={params => {
                  return (
                    <TextField
                      data-testid="endDate"
                      sx={{
                        width: 410,
                        marginRight: -2,
                        '& .MuiInput-root': {
                          position: 'relative', // Ensure relative positioning
                        },
                        '& .MuiPopover-paper': {
                          left: '0 !important',
                          right: 'auto !important',
                          marginLeft: '0 !important', // Adjust this value as needed
                        },
                      }}
                      {...params}
                      error={false}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'YYYY/MM/DD',
                      }}
                      onInput={e => {
                        e.preventDefault(); // Prevent manual input
                      }}
                    />
                  );
                }}
              />
            </LocalizationProvider>
          </div>
        </Box>
      </DialogContent>
    </CustomModal>
  );
};

export default GenerateReportModal;
