import {createSelector} from '@reduxjs/toolkit';

export const challanShortQtyApprovalSelector = {
  getChallanShortQtyApprovalDataList: () => {
    return createSelector(
      [
        (state: any) =>
          state?.challanShortQtyApproval?.challanShortQtyApprovalDataList,
      ],
      state => state,
    );
  },
  getAutoSuggestionChallan: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.autoSuggestionChallan],
      state => state,
    );
  },
  getLoading: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.loading],
      state => state,
    );
  },
  getPageNumber: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.pageNumber],
      state => state,
    );
  },
  getVoucherDetailsList: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.voucherDetailsList],
      state => state,
    );
  },
  getIsRejected: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.isRejected],
      state => state,
    );
  },
  getIsApproved: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.isApproved],
      state => state,
    );
  },
  getRequiredPayload: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.reqPayloadForChallanShortOtyList],
      state => state,
    );
  },
  getChallanSotQTYAuditDivision: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.cnfAuditDivision],
      cnfAuditDivision => cnfAuditDivision,
    );
  },
  getChallanShortQtyAuditTrailData: () => {
    return createSelector(
      [(state:any) => state?.challanShortQtyApproval?.challanShortyQtyAuditTrailData],
      state => state
    );
  },
  getChallanShortQtyAuditTrailPayload: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.challanShortQtyAuditTrailPayload],
      state => state,
    );
  },
  getChallanAuditTrailAutoSearch: () => {
    return createSelector(
        [(state: any) => state?.challanShortQtyApproval?.challanShortQtyAuditTrailAutoSuggest],
        state => state,
      );
  },
  getChallanAuditTrailReportLoader: () => {
    return createSelector(
      [(state: any) => state?.challanShortQtyApproval?.reportDataLoading],
      state => state,
    );
  }

};
