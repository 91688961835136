import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Button,
} from '@mui/material';
import {MenuProp} from '@app/screens/Styles/style';
import {strings} from '@app/common/strings';
import {useDispatch, useSelector} from 'react-redux';
import {approveExpenseStateSelector} from '../../pages/approve-expenses/redux/selectors';
import Colors from '@app/themes/colors';
import {expenseDAMasterStateSelector} from '@app/screens/master-data-management/pages/expense-da-master/redux/selectors';
import {generateReportStateSelector} from '../../pages/generateReport/redux/selectors';
import {withPreventDoubleClick} from '@app/components/hoc';
import {
  fetchGenerateReportCreator,
  generateReportStateActions,
} from '../../pages/generateReport/redux/slice';
import {
  DropDownValues,
  GenerateReportProps,
  HQDropdown,
} from './generate-report-type';
import {SearchComponent} from '../expense-reports/TA-summary/TASummaryReportHeader';
import {filterStateActions} from '@app/components/right-sidebar/redux';

export const GenerateReportHeader = (props: GenerateReportProps) => {
  const dispatch = useDispatch();
  const [selectedDivision, setSelectedDivision] = React.useState<number[]>([]);
  const [selectedDesignation, setSelectedDesignation] = React.useState<
    number[]
  >([]);
  const [selectedHq, setSelectedHq] = React.useState<number[]>([]);
  const [selectedMonth, setSelectedMonth] = React.useState<number[]>([]);
  const [selectedYear, setSelectedYear] = React.useState<number[]>([]);
  const [visibleCount, setVisibleCount] = React.useState(100);
  const [options, setOptions] = React.useState<any>([]);
  const [hqOptions, setHqOptions] = React.useState<any>([]);

  const divisionDropDown = useSelector(
    approveExpenseStateSelector?.getDivisionData(),
  );
  const designations = useSelector(
    expenseDAMasterStateSelector.getDesignationsDropdown(),
  );
  const hqDropDown = useSelector(
    generateReportStateSelector.getHqDropDownForGenerateReport(),
  );
  const yearDropdown = useSelector(
    generateReportStateSelector.getYearDropdown(),
  );
  const monthDropdown = useSelector(
    generateReportStateSelector.getMonthDropdown(),
  );

  const DebouncedBtn = withPreventDoubleClick(Button, 400);

  const [filteredDropdownData, setFilteredDropdownData] = React.useState<any>({
    divisions: [],
    designations: [],
    hqs: [],
  });

  React.useEffect(() => {
    setOptions(designations?.slice(0, visibleCount));
    setHqOptions(hqDropDown?.slice(0, visibleCount));
  }, [designations, visibleCount, hqDropDown]);

  const handleSelectChange = (e: any, setSelected: Function) => {
    const {
      target: {value},
    } = e;
    const selectedValues =
      typeof value === 'string' ? value?.split(',') : value;
    setSelected(selectedValues?.filter(Boolean));
  };

  const _renderValue = (
    selected: number[],
    dropDownValues: any[],
    valueKey = 'value',
    labelKey = 'label',
  ) => {
    const selectedValues = selected?.filter(Boolean)?.map((id: number) => {
      return dropDownValues?.find((item: any) => item?.[valueKey] === id)?.[
        labelKey
      ];
    });
    return selectedValues?.join(', ');
  };

  const handleClickGo = () => {
    const requestBody = {
      divisionIds: selectedDivision,
      designationIds: selectedDesignation,
      hqIds: selectedHq,
      months: selectedMonth,
      years: selectedYear,
      pageNumber: 0,
      pageLimit: 100,
    };
    dispatch(fetchGenerateReportCreator(requestBody));
    dispatch(filterStateActions.setFilterOptions(requestBody));
    dispatch(generateReportStateActions.setGenerateReportPageNo(0));
    props?.getPayloadDetails(requestBody);
  };
  const isGoButtonEnabled = () => {
    return !selectedDivision?.length;
  };

  const loadMoreOptions = () => {
    if (
      visibleCount < designations?.length ||
      visibleCount < hqDropDown?.length
    ) {
      setVisibleCount(prevCount => prevCount + 100);
    }
  };

  const handleScroll = (event: any) => {
    if (
      filteredDropdownData?.designations?.length ||
      filteredDropdownData?.hqs?.length
    ) {
      return;
    }
    const listboxNode = event?.target;
    const {scrollTop, scrollHeight, clientHeight} = listboxNode;

    if (scrollTop + clientHeight >= scrollHeight * 0.5) {
      loadMoreOptions();
    }
  };

  const customMenuProps = {
    PaperProps: {
      onScroll: handleScroll,
    },
  };

  const handleClose = () => {
    setVisibleCount(100);
    setOptions(designations?.slice(0, 100));
    setHqOptions(hqDropDown?.slice(0, 100));
    setFilteredDropdownData({divisions: [], designations: [], hqs: []});
  };

  const filteredResult = (
    arr: any[],
    labelKey: string,
    searchedText: string,
  ) => {
    return arr?.filter((item: any) =>
      item?.[labelKey]?.toLowerCase()?.includes(searchedText?.toLowerCase()),
    );
  };

  const setUpdatedFilterDropdownData = (key: string, filteredArr: any) => {
    setFilteredDropdownData((prev: any) => ({...prev, [key]: filteredArr}));
  };

  const onSearch = (() => {
    let timer: NodeJS.Timeout | null = null;

    return (
      searchedText: string,
      arr: any,
      key: string,
      labelKey = 'label',
    ) => {
      if (timer) {
        clearTimeout(timer);
      }
      if (!searchedText) {
        setOptions(designations?.slice(0, visibleCount));
        setFilteredDropdownData({divisions: [], designations: [], hqs: []});
        return;
      }

      timer = setTimeout(() => {
        const filteredArr = filteredResult(arr, labelKey, searchedText);
        setUpdatedFilterDropdownData(key, filteredArr);
      }, 300);
    };
  })();

  const renderConditionalDatas = (all: any, filtered: any) => {
    return filtered?.length ? filtered : all;
  };

  return (
    <Stack
      spacing={0.5}
      p={1.5}
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      style={{
        backgroundColor: '#f4f4f4',
        height: 80,
        borderRadius: 6.7,
        margin: 2,
      }}>
      <Box width={'18%'}>
        <FormControl size="small" fullWidth>
          <InputLabel sx={{fontSize: 15}} id="division-dropdown">
            {`${strings.division} *`}
          </InputLabel>
          <Select
            size="small"
            value={selectedDivision}
            title={
              divisionDropDown?.find(
                (divisionObj: any) => divisionObj?.value === selectedDivision,
              )?.label || ''
            }
            placeholder={`${strings.division} *`}
            MenuProps={MenuProp}
            inputProps={{
              'data-testid': 'dropdown-setDivision',
            }}
            onChange={(e: any) => handleSelectChange(e, setSelectedDivision)}
            label={`${strings.division} *`}
            sx={{
              backgroundColor: Colors?.white,
            }}
            renderValue={(selected: number[]) =>
              _renderValue(selected, divisionDropDown)
            }
            onClose={handleClose}
            multiple>
            <MenuItem>
              <SearchComponent
                staffEditHandler={({target: {value}}) =>
                  onSearch(value, divisionDropDown, 'divisions')
                }
              />
            </MenuItem>
            {renderConditionalDatas(
              divisionDropDown,
              filteredDropdownData.divisions,
            )?.map((divisionValue: DropDownValues) => (
              <MenuItem key={divisionValue?.value} value={divisionValue?.value}>
                <Checkbox
                  checked={selectedDivision?.includes(divisionValue?.value)}
                />
                <ListItemText primary={divisionValue?.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width={'20%'}>
        <FormControl size="small" fullWidth>
          <InputLabel sx={{fontSize: 15}} id="designation-dropdown">
            {strings.designation}
          </InputLabel>
          <Select
            size="small"
            value={selectedDesignation}
            title={
              designations?.find(
                (designationObj: any) =>
                  designationObj?.value === selectedDesignation,
              )?.label || ''
            }
            placeholder={strings.designation}
            MenuProps={{...MenuProp, ...customMenuProps}}
            inputProps={{
              'data-testid': 'dropdown-setDesignation',
            }}
            onClose={handleClose}
            onChange={(e: any) => handleSelectChange(e, setSelectedDesignation)}
            label={strings.designation}
            sx={{
              backgroundColor: Colors?.white,
            }}
            renderValue={(selected: number[]) =>
              _renderValue(selected, designations)
            }
            multiple>
            <MenuItem>
              <SearchComponent
                staffEditHandler={({target: {value}}) =>
                  onSearch(value, designations, 'designations')
                }
              />
            </MenuItem>
            {renderConditionalDatas(
              options,
              filteredDropdownData.designations,
            )?.map((designation: DropDownValues) => (
              <MenuItem key={designation?.value} value={designation?.value}>
                <Checkbox
                  checked={selectedDesignation?.includes(designation?.value)}
                />
                <ListItemText primary={designation?.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width={'18%'}>
        <FormControl size="small" fullWidth>
          <InputLabel sx={{fontSize: 15}} id="hq-dropdown">
            {strings.hq}
          </InputLabel>
          <Select
            size="small"
            value={selectedHq}
            title={
              hqDropDown.find((hq: any) => hq?.headQuaterId === selectedHq)
                ?.headQuaterName || ''
            }
            placeholder={strings.hq}
            MenuProps={{...MenuProp, ...customMenuProps}}
            inputProps={{
              'data-testid': 'dropdown-setHq',
            }}
            onChange={(e: any) => handleSelectChange(e, setSelectedHq)}
            label={strings.hq}
            sx={{
              backgroundColor: Colors?.white,
            }}
            renderValue={(selected: number[]) =>
              _renderValue(
                selected,
                hqDropDown,
                'headQuaterId',
                'headQuaterName',
              )
            }
            onClose={handleClose}
            multiple>
            <MenuItem>
              <SearchComponent
                staffEditHandler={({target: {value}}) =>
                  onSearch(value, hqDropDown, 'hqs', 'headQuaterName')
                }
              />
            </MenuItem>
            {renderConditionalDatas(hqOptions, filteredDropdownData.hqs)?.map(
              (hq: HQDropdown) => (
                <MenuItem key={hq?.headQuaterId} value={hq?.headQuaterId}>
                  <Checkbox checked={selectedHq?.includes(hq?.headQuaterId)} />
                  <ListItemText primary={hq?.headQuaterName} />
                </MenuItem>
              ),
            )}
          </Select>
        </FormControl>
      </Box>
      <Box width={'18%'}>
        <FormControl size="small" fullWidth>
          <InputLabel sx={{fontSize: 15}} id="month-dropdown">
            {strings.ModuleSpecificUtilitie.generatepage.MONTH}
          </InputLabel>
          <Select
            size="small"
            value={selectedMonth}
            title={
              monthDropdown?.find(
                (month: any) => month?.label === selectedMonth,
              )?.label || ''
            }
            placeholder={strings.ModuleSpecificUtilitie.generatepage.MONTH}
            MenuProps={MenuProp}
            inputProps={{
              'data-testid': 'dropdown-setMonth',
            }}
            onChange={(e: any) => handleSelectChange(e, setSelectedMonth)}
            label={strings.ModuleSpecificUtilitie.generatepage.MONTH}
            sx={{
              backgroundColor: Colors?.white,
            }}
            renderValue={(selected: number[]) =>
              _renderValue(selected, monthDropdown)
            }
            multiple>
            {monthDropdown?.map((month: DropDownValues) => (
              <MenuItem key={month?.value} value={month?.value}>
                <Checkbox checked={selectedMonth?.includes(month?.value)} />
                <ListItemText primary={month?.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width={'18%'}>
        <FormControl size="small" fullWidth>
          <InputLabel sx={{fontSize: 15}} id="year-dropdown">
            {strings.ModuleSpecificUtilitie.generatepage.YEAR}
          </InputLabel>
          <Select
            size="small"
            value={selectedYear}
            title={
              yearDropdown.find(
                (yearObj: any) => yearObj?.value === selectedYear,
              )?.label || ''
            }
            placeholder={strings.ModuleSpecificUtilitie.generatepage.YEAR}
            MenuProps={MenuProp}
            inputProps={{
              'data-testid': 'dropdown-setYear',
            }}
            onChange={(e: any) => handleSelectChange(e, setSelectedYear)}
            label={strings.ModuleSpecificUtilitie.generatepage.YEAR}
            sx={{
              backgroundColor: Colors?.white,
            }}
            renderValue={(selected: number[]) =>
              _renderValue(selected, yearDropdown)
            }
            multiple>
            {yearDropdown?.map((year: DropDownValues) => (
              <MenuItem key={year?.value} value={year?.value}>
                <Checkbox checked={selectedYear?.includes(year?.value)} />
                <ListItemText primary={year?.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width={'8%'}>
        <DebouncedBtn
          variant="contained"
          size="large"
          onClick={handleClickGo}
          disabled={isGoButtonEnabled()}>
          {strings.Go}
        </DebouncedBtn>
      </Box>
    </Stack>
  );
};
