import { createSelector } from "@reduxjs/toolkit";

const navData = (state: any) => state.directoryState.nav;
const modalData = (state: any) => state.directoryState.modal;
const loadingData = (state: any) => state.directoryState.loading;
const searchData = (state: any) => state.directoryState.nav.search;
const pagesData = (state: any) => state.directoryState.pages;
const currentPage = (state: any) => state.directoryState.currentPage;

const searchQuery = createSelector([searchData], (_searchData) =>
  _searchData && _searchData.query ? _searchData.query : ""
);
const getStatus = createSelector([navData], (nav) =>
  nav?.status?.value ? 1 : 0
);
const getShowNavToggle = createSelector([navData], (nav) => nav?.status?.show);
const getNavTitle = createSelector([navData], (nav) => nav.title);
const getNavAction = createSelector([navData], (nav) => nav.action);
const getNavSearch = createSelector([navData], (nav) => nav.search);
const getDirectoryModal = createSelector([modalData], (modal) => modal);
const getLoading = createSelector([loadingData], (loading) => loading);
const getCurrentPage = createSelector(
  [currentPage],
  (_currentPage) => _currentPage
);
const totalRecords = createSelector(
  pagesData,
  currentPage,
  (_pages, _page) => _pages[_page]?.count || 0
);
const pageIndex = createSelector(
  pagesData,
  currentPage,
  (_pages, _page) => _pages[_page]?.pageIndex || 0
);

export const directorySelector = {
  getNavTitle: () => getNavTitle,
  getNavAction: () => getNavAction,
  getDirectoryModal: () => getDirectoryModal,
  getLoading: () => getLoading,
  getNavSearch: () => getNavSearch,
  searchQuery,
  getStatus,
  totalRecords,
  pageIndex,
  getShowNavToggle: () => getShowNavToggle,
  getCurrentPage,
};
