import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {strings} from '@app/common/strings';

export const RowRadioButtonsGroup: React.FC<{value: string}> = ({value}) => {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}>
        <FormControlLabel
          value={strings.moduleSpecificUtilities.returnedhome}
          sx={{
            '.MuiFormControlLabel-label': {
              fontSize: '12px !important',
            },
          }}
          control={<Radio size="small" />}
          label={strings.moduleSpecificUtilities.RETURNEDHOME}
        />
        <FormControlLabel
          value={strings.moduleSpecificUtilities.availednightstay}
          sx={{
            '.MuiFormControlLabel-label': {
              fontSize: '12px !important',
            },
          }}
          control={<Radio size="small" />}
          label={strings.moduleSpecificUtilities.AVAILEDNIGHTSTAY}
        />
      </RadioGroup>
    </FormControl>
  );
};
