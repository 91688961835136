import {call, takeLatest, put} from '@redux-saga/core/effects';
import {API_PATH} from '@app/common/constants';
import NetworkService from '@app/services/network/NetworkService';
import {
  challanShortQtyApprovalActions,
  fetchChallanShortQtyApprovalDataList,
  fetchVoucherDetailsList,
  ChallanQuantitySortApprove,
  ChallanQuantitySortReject,
  FetchAutoSuggestionChallan,
  challanShortQuantityAutoSearchTypeName,
  challanShortQuantityAuditDivisionTypeName,
  challanShortQtyAuditTrailDataTypeName,
  challanShortQtyAuditTrailDownloadTypeName,
} from './slice';

export function* fetchChallanShortQtyApprovalDataListWatcher() {
  yield takeLatest(
    fetchChallanShortQtyApprovalDataList,
    fetchChallanShortQtyApprovalDataListWorker,
  );
}

export function* fetchChallanShortQtyApprovalDataListWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));

    if (action.payload) {
      const url = `${API_PATH.inventoryModule.challanShortQtyApprovalListData}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );
      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setChallanShortQtyApprovalDataList(
            response?.data,
          ),
        );

        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
    yield put(challanShortQtyApprovalActions.setLoading(false));
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchVoucherDetailsListWatcher() {
  yield takeLatest(fetchVoucherDetailsList, fetchVoucherDetailsListWorker);
}

export function* fetchVoucherDetailsListWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));
    if (action.payload) {
      const {
        payload: {voucherNumber},
      } = action;
      const url = `${API_PATH.inventoryModule.voucherNumberData}/${voucherNumber}`;
      const response = yield call(NetworkService.get, url, {}, {});
      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setVoucherDetailsList(response?.data),
        );
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
    yield put(challanShortQtyApprovalActions.setLoading(false));
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchChallanShortQtyApprovalWatcher() {
  yield takeLatest(ChallanQuantitySortApprove, ChallanShortQtyApprovalWorker);
}

export function* ChallanShortQtyApprovalWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));

    if (action.payload) {
      const url = `${API_PATH.inventoryModule.challanShortQtyApprove}/${action.payload.userId}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload.request,
        null,
      );
      if (response?.status === 200) {
        yield put(challanShortQtyApprovalActions.setIsApproved(response?.data));
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
    yield put(challanShortQtyApprovalActions.setLoading(false));
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchChallanShortQtyRejectWatcher() {
  yield takeLatest(ChallanQuantitySortReject, ChallanShortQtyRejectWorker);
}

export function* ChallanShortQtyRejectWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.challanShortQtyReject}/${action.payload.userId}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload.request,
        null,
      );

      if (response?.status === 200) {
        yield put(challanShortQtyApprovalActions.setIsRejected(response?.data));
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* FetchAutoSuggestionChallanWatcher() {
  yield takeLatest(FetchAutoSuggestionChallan, autoSuggestionChallanWorker);
}

export function* autoSuggestionChallanWorker(action: any): any {
  try {
    yield put(challanShortQtyApprovalActions.setLoading(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.autoSuggestChallan}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );

      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setAutoSuggestionChallan(
            response?.data.searchValue,
          ),
        );
        yield put(challanShortQtyApprovalActions.setLoading(false));
      } else {
        yield put(challanShortQtyApprovalActions.setLoading(false));
      }
    }
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchChallanShortQuantityAuditTrailDataWatcher() {
  yield takeLatest(
    challanShortQtyAuditTrailDataTypeName,
    fetchChallanShortQuantityAuditTrailDataWorker,
  );
}

export function* fetchChallanShortQuantityAuditTrailDataWorker(
  action: any,
): any {
  yield put(challanShortQtyApprovalActions.setAuditTrailReportLoader(true));
  try {
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.ChallanShortQtyLoadAuditTrailData}?pageNum=${action?.payload?.pageNumber}`;
      const response = yield call(
        NetworkService.post,
        url,
        action.payload,
        null,
      );

      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setChallanShortQtyAuditTrailData(
            response?.data,
          ),
        );
        yield put(challanShortQtyApprovalActions.setAuditTrailReportLoader(false));

      } else {
        yield put(challanShortQtyApprovalActions.setAuditTrailReportLoader(false));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

export function* fetchChallanShortQuantityAuditDivivionsWatcher() {
  yield takeLatest(
    challanShortQuantityAuditDivisionTypeName,
    fetchChallanShortQtyAuditDivisionWorker,
  );
}

export function* fetchChallanShortQtyAuditDivisionWorker(action: any): any {
  yield put(challanShortQtyApprovalActions.setLoading(true));
  try {
    const resStates = yield call(
      NetworkService.get,
      API_PATH.inventoryModule.cnfDivisions,
      null,
      null,
    );
    yield put(
      challanShortQtyApprovalActions.setchallanShortQtyAuditDivision(
        resStates?.data,
      ),
    );
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setLoading(false));
  }
}

export function* fetchAutoSuggestionChallanShortQtyWatcher() {
  yield takeLatest(
    challanShortQuantityAutoSearchTypeName,
    autoSuggestionChallanQtyWorker,
  );
}

export function* autoSuggestionChallanQtyWorker(action: any): any {
  const {filterValue, pageNumber, filterPayload, searchType} = action.payload;
  const params = {
    _auditTrailsRequestDto: filterPayload,
    adminInTransitChallanMasterSearchRequestDto: {
      searchType: searchType,
      searchValue: filterValue,
    },
  };
  try {
    yield put(challanShortQtyApprovalActions.setAuditTrailReportLoader(true));
    if (action.payload) {
      const url = `${API_PATH.inventoryModule.autoSuggestForShortQtyAuditTrail}`;
      const response = yield call(NetworkService.post, url, params, null);

      if (response?.status === 200) {
        yield put(
          challanShortQtyApprovalActions.setChallanShortQtyAuditTrailData(
            response?.data,
          ),
        );
        yield put(challanShortQtyApprovalActions.setAuditTrailReportLoader(false));
      } else {
        yield put(challanShortQtyApprovalActions.setAuditTrailReportLoader(false));
      }
    }
  } catch (e) {
    yield put(challanShortQtyApprovalActions.setAuditTrailReportLoader(false));
  }
}


export function* fetchChallanAuditTrailExcelSheetWatcher() {
  yield takeLatest(
    challanShortQtyAuditTrailDownloadTypeName,
    fetchChallanAuditTrailExcelSheetWorker,
  );
}

export function* fetchChallanAuditTrailExcelSheetWorker(action: any): any {
  const {filterValue, returnInBase64, filterPayload} = action.payload;

  try {
    const url = `${API_PATH.inventoryModule.challanShortQtyAuditTrailDownload}?searchData=${filterValue}&returnInBase64=${returnInBase64}`;
    const response = yield call(NetworkService.post, url, filterPayload, null);
    if (response?.status === 200) {
      const fileName = 'Audit_Trail_excel_file';
      const url = `data:text/csv;base64,${response.data}`;
      const outputFilename = `${fileName}.csv`;

      // Create a Blob with the CSV data
      const blob = new Blob([response.data], {type: 'text/csv'});

      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', outputFilename);

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  } catch (e) {
    console.error(e);
  }
}
