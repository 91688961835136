import React, {ComponentType, useCallback} from 'react';
import _ from 'lodash';

const withPreventDoubleClick = <P extends object>(
  WrappedComponent: ComponentType<P>,
  debounceTime = 300,
) => {
  type Props = P & {
    onClick: () => void;
    variant: string;
    disabled?: boolean;
    size?: string;
    sx?: any;
  };

  const PreventDoubleClick: React.FC<Props> = props => {
    const onClick = useCallback(
      _.debounce(() => {
        props.onClick && props.onClick();
      }, debounceTime),
      [props.onClick, debounceTime],
    );

    return <WrappedComponent {...props} onClick={onClick} />;
  };

  PreventDoubleClick.displayName = `withPreventDoubleClick(${
    WrappedComponent.displayName ?? WrappedComponent.name ?? 'Component'
  })`;

  return PreventDoubleClick;
};

export default withPreventDoubleClick;
